import PortalApi from '../helpers/api/PortalApiClient';
import { getParams } from '../helpers';
import { TyreManufacturerModel } from '../models/TyreManufacturer.type';
import { Paginator } from 'helpers/api/type';

export default class TyreManufacturer extends PortalApi<TyreManufacturerModel> {
    route = 'tyre-manufacturer';

    getManufacturerTable = async (props): Promise<Paginator<TyreManufacturerModel>> => {
        return this.getByCriteria({ criteria: getParams(props) });
    };
}
