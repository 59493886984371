import React from 'react';
import { AmbientTemperatureContainer, ButtonOpenPopUp, CustomTextField, Loading } from './AmbientTemperature.style';
import { AmbientTemperatureViewProps } from './AmbientTemperature.type';
import UiLegend from '../Ui/Components/UiLegend';
import { useTranslation } from 'react-i18next';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { BarGroupIcon, BarGroupInfo } from 'components/TopBar/TopBar.style';
import { AcUnit } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

export const AmbientTemperatureContent: React.FC<AmbientTemperatureViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const { displayUserUnits } = useConverter();

    const generateContent = (isLoading: boolean, data: number[]): JSX.Element => {
        return (
            <div>
                {isLoading ? (
                    <div style={{ display: 'flex' }}>
                        <Loading />
                    </div>
                ) : (
                    generateTable(data)
                )}
            </div>
        );
    };

    const generateTable = (data: number[]): JSX.Element[] => {
        return data.map((value: number, index: number) => {
            return (
                <CustomTextField
                    disabled
                    id={`outlined-basic-${index}`}
                    key={index}
                    size='small'
                    label={translate(`t.month${index + 1}`)}
                    defaultValue={value}
                    variant='outlined'
                    style={{
                        width: 100
                    }}
                    InputProps={{
                        readOnly: true
                    }}
                />
            );
        });
    };

    return (
        <AmbientTemperatureContainer data-testid='AmbientTemperatureContent'>
            <UiLegend
                title={`${translate('t.monthly_ambient_temperature')} ${displayUserUnits.temperature}`}
                content={generateContent(props.isLoading, props.data)}
                width={375}
                root={
                    <ButtonOpenPopUp color='inherit' variant='text' data-testid='EditDateFormat-Button'>
                        <Tooltip
                            title={translate('t.click_ambient_temperature')}
                            placement='bottom'
                            enterDelay={1000}
                            leaveDelay={0}
                            arrow
                            disableFocusListener
                        >
                            <BarGroupInfo>
                                <BarGroupIcon>
                                    <AcUnit />
                                </BarGroupIcon>
                                {`${props.temperature ?? '-'} ${displayUserUnits.temperature}`}
                            </BarGroupInfo>
                        </Tooltip>
                    </ButtonOpenPopUp>
                }
            />
        </AmbientTemperatureContainer>
    );
};
