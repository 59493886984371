import React from 'react';
import { ActionItem, Icon } from './VehicleDetailsActions.style';
import { faBullhorn } from '@fortawesome/pro-solid-svg-icons';
import { MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import { TyreDetail } from 'models/Wheel.type';
import { useRecoilState, useRecoilValue } from 'recoil';
import { VehicleDetailsWidgetAtom } from '../VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { VehicleDetailsActionMenuAtom } from '../VehicleDetailsActionMenu/VehicleDetailsActionMenu.atom';
import { useTranslation } from 'react-i18next';
import { VehicleModel } from 'models/Vehicle.type';
import useModal from 'components/CustomHooks/ModalHook';
import WheelAlerts from 'components/Alert/WheelAlerts';
import AlertSettingsWizardSaveButton from 'components/Alert/AlertSettingsWizardButtons';
import { RULE_TYPE } from 'models/Rule.type';
import { CustomerSettings } from 'states/global/CustomerSettings';

/*
    Temporal component.
    Will be removed when Alert Menu will be refactored.
 */
const AlertMenu = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const vehicleDetailsWidgetAtom = useRecoilValue(VehicleDetailsWidgetAtom());
    const customerSettings = useRecoilValue(CustomerSettings);
    const [vehicleDetailsActionMenuAtom, setVehicleDetailsActionMenu] = useRecoilState(VehicleDetailsActionMenuAtom);
    const data: TyreDetail | undefined = vehicleDetailsWidgetAtom.tyreDetails?.filter(
        (tyre) => tyre.wheel.position === vehicleDetailsActionMenuAtom.data?.wheel.position
    )[0];
    const { updateModalState } = useModal();
    const [actionMenuState, setActionMenuState] = useRecoilState(VehicleDetailsActionMenuAtom);
    const theme = useTheme();
    const showXsSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

    return (
        <MenuItem
            onClick={() => {
                setVehicleDetailsActionMenu({
                    ...vehicleDetailsActionMenuAtom,
                    isOpen: false
                });
                setActionMenuState({
                    ...actionMenuState,
                    isOpen: false
                });

                updateModalState({
                    id: 'set-alerts',
                    isOpen: true,
                    content: (
                        <WheelAlerts
                            wheelId={data?.wheel.id || 0}
                            vehicleId={(vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.id || 0}
                        />
                    ),
                    leftTitle: `${(vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.name} ${translate(
                        't.wheel_position'
                    )} ${data?.wheel.customPosition}`,
                    rightTitle: '',
                    width: 1200,
                    height: customerSettings.high_hot_pressure_enabled ? (showXsSm ? 93 : 700) : 500,
                    heightUnit: showXsSm ? 'vh' : undefined,
                    buttons: (
                        <AlertSettingsWizardSaveButton
                            ruleType={RULE_TYPE.CUSTOM}
                            wheelId={data?.wheel.id}
                            position={data?.wheel ? data?.wheel.position : undefined}
                        />
                    ),
                    onClose: () => {
                        updateModalState({
                            isOpen: false,
                            buttons: undefined
                        });
                    }
                });
            }}
            data-testid={`Pos-${vehicleDetailsActionMenuAtom.data?.wheel.customPosition}-AlertMenu-Button`}
        >
            <ActionItem>
                <Icon icon={faBullhorn} />
                <Typography>{translate('t.alert_menu')}</Typography>
            </ActionItem>
        </MenuItem>
    );
};

export default AlertMenu;
