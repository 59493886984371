import React, { useMemo } from 'react';
import { AlertsBreakdownPeriodBtnProps } from './AlertsBreakdownPeriodBtn.type';
import Button from '../../Ui/Components/UiButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AlertsBreakdownPeriod, AlertsBreakdownPeriodTitle } from '../state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import Menu from '../../Ui/Components/UiMenu';

const PERIODS_DAYS: number[] = [1, 2, 7, 14, 30];

const periodList = (translate: any, updatePeriod: (value: number | string, title: string | JSX.Element) => void) => {
    return PERIODS_DAYS.map((days) => {
        const listTitle = {
            title: days < 5 ? days * 24 : days,
            translationKey: days < 5 ? 't.hrs' : 't.days'
        };
        return {
            title: `${listTitle.title} ${translate(listTitle.translationKey)}`,
            value: days * 24,
            action: updatePeriod
        };
    });
};

const AlertsBreakdownPeriodBtnContent: React.FC<AlertsBreakdownPeriodBtnProps> = (): JSX.Element => {
    const title = useRecoilValue(AlertsBreakdownPeriodTitle);
    const updatePeriod = useSetRecoilState(AlertsBreakdownPeriod);
    const { t: translate } = useTranslation();

    const handleUpdatePeriod = (value: number | string) => {
        localStorage.setItem('AlertsBreakdownPeriod', `${value}`);
        updatePeriod(typeof value !== 'number' ? Number(value) : value);
    };

    const list = useMemo(() => periodList(translate, handleUpdatePeriod), [translate]);

    return (
        <Menu
            rootEl={
                <Button
                    color='secondary'
                    variant='text'
                    testid='AlertsBreakdown-period-button'
                    title={translate('t.alerts_breakdown_period')}
                >
                    {title.value} {translate(title.translationKey)} <ExpandMoreIcon />
                </Button>
            }
            items={list}
            data-testid='AlertsBreakdownPeriodBtnContent'
        />
    );
};

export default AlertsBreakdownPeriodBtnContent;
