import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { PopupDataType, TableProps, TableRowProps, TypeStage } from '../SystemTotals.type';
import { useTranslation } from 'react-i18next';
import UiCircularGraph from 'components/Ui/Components/UiCircularGraph/UiCircularGraph';
import { Transmitting } from 'models/Sensors.type';
import Popover from 'components/TablePopover/TablePopover';
import { TableBody, TableCell } from '@mui/material';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { CustomRow, GraphCell } from '../SystemTotals.style';
import { SystemTotalsStage } from '../SystemTotals.atom';
import { getColorOfChartForSystemTotals, getPercentage } from 'helpers';

const TableRow = ({ value, label, dataType }: TableRowProps) => {
    const { t: translate } = useTranslation();
    const operatingPercentage: number = getPercentage(
        value?.transmitting?.operating,
        value?.transmitting?.operating + value?.notTransmitting?.operating
    );
    const maintenancePercentage: number = getPercentage(
        value?.transmitting?.maintenance,
        value?.transmitting?.maintenance + value?.notTransmitting?.maintenance
    );
    const outOfServicePercentage: number = getPercentage(
        value?.transmitting?.outOfService,
        value?.transmitting?.outOfService + value?.notTransmitting?.outOfService
    );

    return (
        <>
            <CustomRow>
                <TableCell align='center' rowSpan={2}>
                    {label}
                </TableCell>
                <TableCell align='center'>
                    <UiIcon icon={['fas', 'wifi']} color='#2c9f54' title={translate('t.transmitting')} />
                </TableCell>
                <TableCell align='center'>
                    <Popover
                        value={value?.transmitting?.operating}
                        dataType={dataType}
                        stateType='operating'
                        transmitting={true}
                    />
                </TableCell>
                <GraphCell rowSpan={2} align='center' customColor={getColorOfChartForSystemTotals(operatingPercentage)}>
                    <UiCircularGraph testid='operating-graph' value={operatingPercentage} color='inherit' />
                </GraphCell>
                <TableCell align='center'>
                    <Popover
                        value={value?.transmitting?.maintenance}
                        dataType={dataType}
                        stateType='maintenance'
                        transmitting={true}
                    />
                </TableCell>
                <GraphCell
                    rowSpan={2}
                    align='center'
                    customColor={getColorOfChartForSystemTotals(maintenancePercentage)}
                >
                    <UiCircularGraph testid='maintenance-graph' value={maintenancePercentage} color='inherit' />
                </GraphCell>
                <TableCell align='center'>
                    <Popover
                        value={value?.transmitting?.outOfService}
                        dataType={dataType}
                        stateType='outOfService'
                        transmitting={true}
                    />
                </TableCell>
                <GraphCell
                    rowSpan={2}
                    align='center'
                    customColor={getColorOfChartForSystemTotals(outOfServicePercentage)}
                >
                    <UiCircularGraph testid='outofservice-graph' value={outOfServicePercentage} color='inherit' />
                </GraphCell>
            </CustomRow>
            <CustomRow>
                <TableCell align='center'>
                    <UiIcon icon={['fas', 'wifi-slash']} title={translate('t.not_transmitting')} />
                </TableCell>
                <TableCell align='center'>
                    <Popover
                        value={value?.notTransmitting?.operating}
                        dataType={dataType}
                        stateType='operating'
                        transmitting={false}
                    />
                </TableCell>
                <TableCell align='center'>
                    <Popover
                        value={value?.notTransmitting?.maintenance}
                        dataType={dataType}
                        stateType='maintenance'
                        transmitting={false}
                    />
                </TableCell>
                <TableCell align='center'>
                    <Popover
                        value={value?.notTransmitting?.outOfService}
                        dataType={dataType}
                        stateType='outOfService'
                        transmitting={false}
                    />
                </TableCell>
            </CustomRow>
        </>
    );
};

const SystemTotalsAdvancedTablet: React.FC<TableProps> = ({ data }) => {
    const { t: translate } = useTranslation();
    const systemTotalsStage = useRecoilValue(SystemTotalsStage);

    const sensors = useMemo((): Transmitting => {
        return {
            transmitting: {
                operating:
                    (data?.internal?.transmitting?.operating ?? 0) + (data?.external?.transmitting?.operating ?? 0),
                maintenance:
                    (data?.internal?.transmitting?.maintenance ?? 0) + (data?.external?.transmitting?.maintenance ?? 0),
                outOfService:
                    (data?.internal?.transmitting?.outOfService ?? 0) +
                    (data?.external?.transmitting?.outOfService ?? 0)
            },
            notTransmitting: {
                operating:
                    (data?.internal?.notTransmitting?.operating ?? 0) +
                    (data?.external?.notTransmitting?.operating ?? 0),
                maintenance:
                    (data?.internal?.notTransmitting?.maintenance ?? 0) +
                    (data?.external?.notTransmitting?.maintenance ?? 0),
                outOfService:
                    (data?.internal?.notTransmitting?.outOfService ?? 0) +
                    (data?.external?.notTransmitting?.outOfService ?? 0)
            }
        };
    }, [data]);

    return (
        <TableBody data-testid='SystemTotal-AdvancedTable'>
            <TableRow label={translate('t.vehicle')} value={data?.vehicles} dataType={PopupDataType.VEHICLES} />
            <TableRow label={translate('t.sensors')} value={sensors} dataType={PopupDataType.SENSORS} />
            {systemTotalsStage === TypeStage.ADVANCE_TOTAL && (
                <>
                    <TableRow
                        label={translate('t.internal_sensors')}
                        value={data?.internal}
                        dataType={PopupDataType.INTERNAL}
                    />
                    <TableRow
                        label={translate('t.external_sensors')}
                        value={data?.external}
                        dataType={PopupDataType.EXTERNAL}
                    />
                </>
            )}
        </TableBody>
    );
};

export default SystemTotalsAdvancedTablet;
