import React, { useEffect } from 'react';
import { RoutesTableContent } from './RoutesTable.view';
import { RoutesTableProps } from './RoutesTable.type';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import Menu from 'components/Ui/Components/UiMenu';
import SettingsIcon from '@mui/icons-material/Settings';
import { ActionButton, RemoveTextBox } from './RoutesTable.style';
import Button from 'components/Ui/Components/UiButton/UiButton';
import { Success } from 'components/Popup/Popup';
import { useMutation } from '@tanstack/react-query';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import Route from 'api/Route';
import { CellProps } from 'react-table';
import { CircularProgress, Typography } from '@mui/material';
import { RouteModel } from 'models/Route.type';
import { DARK_STEEL_GREY, BACKGROUND_BLUE, ERROR } from 'components/Ui/colors';
import useConverter from 'components/CustomHooks/Converter/Converter';
import UiLink from 'components/Ui/Components/UiLink/UiLink';
import { applyStyleByMode } from 'helpers';
import { Theme } from 'states/global/Theme';
import { Column } from 'components/Ui/UiTable/UiTable.type';
import { Wrapper } from 'helpers/wrapper';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';

const routeAapi = new Route();

const RoutesTable: React.FC<RoutesTableProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const queryTableFn = useRecoilValue(QueryFn(`routesAll-QueryFn`));

    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom);
    const { fromUTCToTimezone } = useConverter();
    const ThemeMode = useRecoilValue(Theme);

    const createRemoveMessage = (): JSX.Element => {
        return (
            <RemoveTextBox>
                <div>
                    <Typography variant='h6'>{translate('p.are_you_sure_to_delete')}</Typography>
                </div>
            </RemoveTextBox>
        );
    };

    const { mutate: removeRouteMutate, isLoading: removeRouteLoading } = useMutation(routeAapi.removeRoute, {
        onSuccess: () => {
            Success({
                text: translate('t.removed_route')
            });
            queryTableFn.refetch instanceof Function && queryTableFn.refetch();
            modalDispach({ type: ModalActionTypesEnum.CLOSE_MODAL });
        }
    });

    const updateModalRemove = (): void => {
        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...modalAtom,
                buttons: (
                    <Button
                        skin='success'
                        disabled={removeRouteLoading}
                        data-testid={'updateModalRemove-Button-remove'}
                    >
                        {removeRouteLoading ? <CircularProgress size='1.75em' /> : translate('t.remove')}
                    </Button>
                )
            }
        });
    };

    const handleModalRemove = (id: number, name: string): void => {
        const contentModalRemove = {
            id: 'RouteModalActions',
            content: createRemoveMessage(),
            leftTitle: `${translate('t.remove_route')} - ${name} #${id}`,
            width: 400,
            height: 100,
            widthUnit: 'px',
            cancelColor: ERROR,
            buttons: (
                <Button
                    data-testid={'handleModalRemove-Button-remove'}
                    skin='error'
                    color='error'
                    variant='contained'
                    onClick={() => {
                        removeRouteMutate(id);
                    }}
                    disabled={removeRouteLoading}
                >
                    {removeRouteLoading ? <CircularProgress size='1.75em' /> : translate('t.remove')}
                </Button>
            ),
            onClose: () =>
                modalDispach({
                    type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                    ModalPropsPayload: {
                        ...contentModalRemove,
                        isOpen: false
                    }
                }),
            isOpen: true
        };

        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: contentModalRemove
        });
    };

    useEffect(() => {
        updateModalRemove();
    }, [removeRouteLoading]);

    const columns: Column<RouteModel>[] = [
        {
            Header: translate('t.name'),
            accessor: 'name',
            align: 'left',
            Cell: ({ value, row: { original } }: CellProps<RouteModel>) => {
                return (
                    <UiLink
                        data-testid={'RoutesTable-UiLink-originalRoute'}
                        $padding='0'
                        onClick={() => props.setRoute(original?.route)}
                        content={value}
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: DARK_STEEL_GREY,
                            dark: BACKGROUND_BLUE
                        })}
                    />
                );
            }
        },
        {
            Header: translate('t.created_at'),
            accessor: 'createdAt',
            disableFilters: true,
            disableSortBy: true,
            Cell: (props) => <>{fromUTCToTimezone(props.value, false)}</>
        },
        {
            Header: translate('t.actions'),
            accessor: 'id',
            disableFilters: true,
            disableSortBy: true,
            width: 30,
            Cell: ({ value, row: { original } }: CellProps<RouteModel>) => (
                <span>
                    <Menu
                        data-testid={'RoutesTable-Menu-actions'}
                        items={[
                            {
                                title: translate('t.view'),
                                value: value,
                                action: () => {
                                    props.setRoute(original?.route);
                                }
                            },
                            {
                                title: translate('t.remove'),
                                value: value,
                                action: () => {
                                    handleModalRemove(original?.id, original?.name);
                                }
                            }
                        ]}
                        rootEl={
                            <ActionButton
                                aria-controls='simple-menu'
                                aria-haspopup='true'
                                data-testid={'RoutesTable-ActionButton-actions'}
                                title={translate('t.actions')}
                            >
                                <SettingsIcon />
                            </ActionButton>
                        }
                    ></Menu>
                </span>
            )
        }
    ];

    return <RoutesTableContent data-testid='RoutesTable-testid' columns={columns} />;
};

export default Wrapper(RoutesTable);
