import { AxiosResponse } from 'axios';
import PortalApi from 'helpers/api/PortalApiClient';
import { LoginModel } from 'models/Login.type';

export default class Login extends PortalApi<LoginModel> {
    route = 'login';

    getOAuthLoginUrl = (): Promise<AxiosResponse<string>> => {
        return this.post({ extendUrl: 'oauth-login', version: '', apiProject: undefined });
    };

    postOAuthCallbackUrl = (data): Promise<AxiosResponse<{ token: string }>> => {
        return this.post({ extendUrl: 'oauth-callback', version: '', apiProject: undefined, data: data });
    };
}
