import React, { useState } from 'react';
import { EditTyreContent } from './EditTyre.view';
import { EditTyreProps, InitialValues } from './EditTyre.type';
import { Wrapper } from 'helpers/wrapper';
import Tyre from 'api/Tyre';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { TyreModel, TyreQueryKeys } from 'models/Tyre.type';
import { Success } from 'components/Popup/Popup';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import { removeAllQueryKeysFromModel } from 'helpers/cache';

const tyreApi = new Tyre();

const DEFAULT_VALUES = {
    id: 0,
    manufacturer: null,
    model: null
};

const EditTyre: React.FC<EditTyreProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [initialValues, setInitialValues] = useState<InitialValues>(DEFAULT_VALUES);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispatch = ModalDispatcher(modalAtom, setStateModalAtom);
    const queryClient = useQueryClient();

    const { data } = useQuery([TyreQueryKeys.getById, props.id], () => tyreApi.getById({ id: props.id }), {
        refetchOnWindowFocus: false,
        refetchInterval: false,
        staleTime: 0,
        cacheTime: 0,
        onSuccess(data) {
            setInitialValues({ id: data.id, manufacturer: data.tyreManufacturer, model: data.tyreModel });
        }
    });

    const setFormikValuesToDefault = (): void => {
        setInitialValues({
            ...DEFAULT_VALUES
        });
    };

    const sendData = async (values: InitialValues) => {
        let data: Partial<TyreModel> = {
            tyreManufacturerId: values.manufacturer?.id,
            tyreModelId: values.model?.id
        };

        try {
            setIsProcessing(true);
            await tyreApi.patchById({ id: props.id, data });
            Success({ text: `${translate('t.tyre')} ${translate('p.has_been_edited')}` });
        } catch (e) {
            false;
        }
        setIsProcessing(false);
        setFormikValuesToDefault();
        removeAllQueryKeysFromModel(TyreQueryKeys, queryClient);

        modalDispatch({ type: ModalActionTypesEnum.CLOSE_MODAL });
    };

    return (
        <EditTyreContent
            data-testid='EditTyre-testid'
            initialValues={initialValues}
            sendData={sendData}
            isProcessing={isProcessing}
        />
    );
};

export default Wrapper(EditTyre);
