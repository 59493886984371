import React from 'react';
import * as style from './Tooltip.style';
import * as type from './Tooltip.type';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { XDomainConfigDataKey } from 'components/StatisticsGraphs/StatisticsGraphs.type';

const createTooltipTable = (props: type.TooltipProps, showInTooltip: type.tooltipCondition): JSX.Element => {
    const data = props.payload ? [...props.payload] : [];
    const dataIterator = 5;
    let tables: JSX.Element[] = [];

    while (data.length) {
        const d = data.splice(0, dataIterator);
        tables.push(
            <table>
                <thead></thead>
                <tbody>
                    {d
                        ?.filter((filteredTooltip) => showInTooltip(filteredTooltip))
                        .map((tooltip, index) => {
                            const displayNoValue: boolean = tooltip.strokeWidth === 0;
                            const showInfo =
                                tooltip.dataKey === XDomainConfigDataKey.TEMPERATURE && tooltip.payload.sensorType === 2
                                    ? '*'
                                    : '';
                            return (
                                <style.ColoredTr key={index} color={tooltip.stroke}>
                                    <td>
                                        {props.translator(tooltip.name)}
                                        {showInfo}:{' '}
                                    </td>
                                    <style.ValueTd>
                                        {displayNoValue ? '-' : `${tooltip.value} ${tooltip.unit || ''}`}
                                    </style.ValueTd>
                                </style.ColoredTr>
                            );
                        })}
                </tbody>
            </table>
        );
    }

    return <>{tables}</>;
};

const createTooltipTableMobile = (props: type.TooltipProps, showInTooltip: type.tooltipCondition): JSX.Element => {
    const data = props.payload ? [...props.payload] : [];
    const dataIterator = 5;
    let tables: JSX.Element[] = [];

    while (data.length) {
        const d = data.splice(0, dataIterator);
        tables.push(
            <Grid container spacing={0}>
                {d
                    ?.filter((filteredTooltip) => showInTooltip(filteredTooltip))
                    .map((tooltip, index) => {
                        const displayNoValue: boolean = tooltip.strokeWidth === 0;
                        const showInfo =
                            tooltip.dataKey === XDomainConfigDataKey.TEMPERATURE && tooltip.payload.sensorType === 2
                                ? '*'
                                : '';
                        return (
                            <>
                                <Grid item lg={6} sm={6} xs={5} key={index} color={tooltip.stroke} sx={{ mr: 2 }}>
                                    <span>
                                        {props.translator(tooltip.name)}
                                        {showInfo}:{' '}
                                    </span>
                                </Grid>
                                <Grid item lg={6} sm={6} xs={5} key={index} color={tooltip.stroke}>
                                    <span>{displayNoValue ? '-' : `${tooltip.value} ${tooltip.unit || ''}`}</span>
                                </Grid>
                            </>
                        );
                    })}
            </Grid>
        );
    }

    return <>{tables}</>;
};

const TooltipContent: React.FC<type.TooltipProps> = (props): JSX.Element => {
    const showAlienData: boolean =
        props.payload && props.payload?.length ? props.payload[0]?.payload?.showAlienData : false;
    const filteredData = props.payload?.filter(
        (data) => data.dataKey !== 'optimalPressure' && data.dataKey !== 'measuredAtScale'
    );
    const anyData = filteredData && filteredData?.length > 0 && Object.keys(filteredData[0].payload).length > 0;
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const getDataTemperature: type.TooltipPayload | undefined =
        props.payload && props.payload.length
            ? props.payload.find((tooltip) => tooltip.dataKey === XDomainConfigDataKey.TEMPERATURE)
            : undefined;
    const showAdditionalExplanation = getDataTemperature && getDataTemperature?.payload?.sensorType === 2;

    /*(props.payload?.length && props.payload[0].payload?.sensorId) ||
        (props.payload?.length && props.payload[0].payload?.speed);*/
    const showInTooltip: type.tooltipCondition = (value) => !props.excludeFromTooltip.includes(value.dataKey);
    if (props.active && anyData) {
        return (
            <>
                <style.TooltipContent showAlienData={showAlienData} data-testid='TooltipContent'>
                    {isSm ? createTooltipTableMobile(props, showInTooltip) : createTooltipTable(props, showInTooltip)}
                    {showAdditionalExplanation ? (
                        <Box sx={{ color: getDataTemperature.stroke, mt: 2 }} data-testid='Graph-TooltipCustom-footer'>
                            <Box sx={{ borderTop: 'thin solid #c0c0c0', display: 'table', width: '100%', pt: 2 }}>
                                <Typography variant='body2' gutterBottom>
                                    * {props.translator('p.average_temperature_int_sensors')}
                                </Typography>
                            </Box>
                        </Box>
                    ) : (
                        <></>
                    )}
                </style.TooltipContent>
            </>
        );
    }
    return <></>;
};

export default TooltipContent;
