import React, { useEffect, useState } from 'react';
import { GpsIconProps, GpsIndicatorProps } from './GpsIndicator.type';
import { GpsIndicatorContent } from './GpsIndicator.style';
import { getGpsIconValues } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { Theme, ThemeMode } from 'states/global/Theme';
import { useRecoilValue } from 'recoil';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NO_ARIA_LABEL_ALLOWED } from 'variables';

const getKeyTitle = (color): string => {
    if (color === 'blue') {
        return 't.not_transmitting_gps_data';
    }
    if (color === 'orange') {
        return 't.not_transmitting_gps_first';
    }
    return 't.not_transmitting_gps_second';
};

const GpsIndicator: React.FC<GpsIndicatorProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [noGpsIcon, setNoGpsIcon] = useState<GpsIconProps>({
        show: false,
        color: 'red'
    });
    const ThemeState = useRecoilValue(Theme);

    useEffect(() => {
        setNoGpsIcon(getGpsIconValues({ ...props }));
    }, [props]);

    if (!noGpsIcon.show) {
        return <></>;
    }

    const getColorJade = (color): string => {
        if (color === 'red' && ThemeState?.mode === ThemeMode.dark) {
            return '#fd79a8';
        }
        if (color === 'blue' && ThemeState?.mode === ThemeMode.dark) {
            return '#0984e3';
        }
        if (color === 'orange' && ThemeState?.mode === ThemeMode.dark) {
            return '#c15208';
        }
        return color;
    };

    return (
        <Tooltip title={translate(getKeyTitle(noGpsIcon.color))} aria-label={NO_ARIA_LABEL_ALLOWED}>
            <GpsIndicatorContent data-testid='GpsIndicatorContent'>
                <UiIcon
                    icon={['fas', 'map-marker-alt-slash']}
                    size='sm'
                    data-testid={`${props.testid ?? ''}-gpsIcon`}
                    color={getColorJade(noGpsIcon.color)}
                />
            </GpsIndicatorContent>
        </Tooltip>
    );
};

export default GpsIndicator;
