import { Paginator } from './Paginator.type';
import { TeamShiftNameModel } from './TeamShiftName.type';

export type TeamShiftTimeModel = {
    id: number;
    startTime: string;
    endTime: string;
    teamShiftName: TeamShiftNameModel;
};

export type TeamShiftTimePayload = {
    startTime: string;
    endTime: string;
    teamShiftName: number;
};

export type TeamShiftTimeTableModel = {
    items: TeamShiftTimeModel[];
    paginator: Paginator;
};

export type TeamShiftTimeResponse = {
    items: TeamShiftTimeModel[];
    paginator: Paginator;
};

export enum TeamShiftTimeQueryKeys {
    getTeamShiftTimeList = 'teamShiftTimeList',
    getTeamShiftTimeDropDown = 'teamShiftTime-dropDown'
}
