import React, { useCallback, useEffect, useState } from 'react';
import { CustomerSettingsContent } from './CustomerSettings.view';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import CustomerSettingsApiClass from 'api/CustomerSettings';
import {
    CustomerSetting,
    CustomerSettingAll,
    CustomerSettingType,
    CustomerSettingsModel,
    OptionLabels
} from 'models/CustomerSettings.type';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import { CRUD } from 'variables';
import { CustomerSettingEditModeAtom, SelectedCustomerSettingValue } from 'states/component/CustomerSettings';
import { Success } from 'components/Popup/Popup';
import { isAdmin, UserInfo } from 'states/global/User';
import { UserUnits } from 'states/global/User';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { DefaultRequestPropsType } from 'helpers/api/type';
import { CustomerModalContent } from './CustomerSettings.style';
import { Wrapper } from 'helpers/wrapper';
import { CustomerSettings as CustomerSettingsAtom } from 'states/global/CustomerSettings';
import { ERROR, SUCCESS } from 'components/Ui/colors';
import { CustomerSettingsQueryKeys } from 'models/CustomerSettings.type';
import { debounce } from 'lodash';
import { CustomerSettingsState } from './CustomerSetings.atom';
import { CustomerSettingsFilters } from './CustomerSettings.type';

const CustomerSettingsApi = new CustomerSettingsApiClass();

const CustomerSettings: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const {
        dateTimeFormat,
        fromTimezoneToUTC,
        fromUTCtoUserTimezone,
        convertType,
        fromServerToUserUnit,
        fromUserToServerUnit
    } = useConverter();

    const setEditMode = useSetRecoilState(CustomerSettingEditModeAtom);
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom);
    const userInfo = useRecoilValue(UserInfo);
    const isAdminRole = useRecoilValue(isAdmin);
    const setSelectedSettingValue = useSetRecoilState(SelectedCustomerSettingValue);
    const setCustomerSettings = useSetRecoilState(CustomerSettingsAtom);
    const userUnits = useRecoilValue(UserUnits);
    const [typedCustomerSettingsParse, setTypedCustomerSettingsParse] = useState<CustomerSettingsModel>();
    const [customerSettingsOrigin, setCustomerSettingsOrigin] = useState<CustomerSettingsModel>();
    const [customerSettingsState, setCustomerSettingsState] = useRecoilState(CustomerSettingsState);
    const [loading, setLoading] = useState(false);

    const {
        data: TypedCustomerSettings,
        refetch: RefetchTypedCustomerSettings,
        isLoading: CustomerSettingsLoading
    } = useQuery(
        [CustomerSettingsQueryKeys.get, userInfo.user?.customer.id],
        () =>
            CustomerSettingsApi.getByCriteria<CustomerSettingsModel>({
                apiProject: '',
                criteria: { includeReadOnly: 1 }
            }),
        {
            refetchInterval: false,
            onSuccess(data) {
                data.settings = data.settings
                    .filter((setting) => isAdminRole || setting.customerEdit)
                    .map((setting) => {
                        const settingCopy = { ...setting };
                        if (settingCopy.type === CustomerSettingType.DATETIME && settingCopy.value) {
                            settingCopy.value = DateTime.fromFormat(
                                fromUTCtoUserTimezone({
                                    date: settingCopy.value,
                                    format: 'dateTime',
                                    displaySeconds: settingCopy.format?.includes('s')
                                }),
                                dateTimeFormat('dateTime', !!settingCopy.format?.includes('s'))
                            );
                        }

                        if (settingCopy.type === CustomerSettingType.AMBIENT_TEMPERATURE) {
                            settingCopy.value = (settingCopy.value as number[]).map(
                                (val) => +fromServerToUserUnit({ type: convertType.temperature, value: val })
                            );
                        }
                        settingCopy.label = translate(`t.${settingCopy.key}`);
                        return settingCopy;
                    });

                setCustomerSettingsOrigin(groupSettings(data));

                if (customerSettingsState.valueSearch) {
                    setTimeout(() => {
                        searchSettings(customerSettingsState.valueSearch, groupSettings(data));
                    }, 150);
                } else if (
                    customerSettingsState.unusedSettings ||
                    customerSettingsState.showAll ||
                    customerSettingsState.showUsedSettings
                ) {
                    setTimeout(() => {
                        applyFilters(customerSettingsState, false, groupSettings(data), false);
                    }, 150);
                } else {
                    setTypedCustomerSettingsParse(groupSettings(data));
                    setTimeout(() => {
                        setCustomerSettingsState((state) => ({
                            ...state,
                            unusedSettings: false,
                            showAll: false,
                            showUsedSettings: true
                        }));
                    }, 160);
                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                }
            }
        }
    );

    const { refetch: RefetchGlobalCustomerSettings } = useQuery(
        [CustomerSettingsQueryKeys.getAll],
        () => CustomerSettingsApi.getAll(),
        {
            enabled: false,
            onSuccess: (dataOnSuccess) => setCustomerSettings({ ...dataOnSuccess.settings, ready_to_use_data: true })
        }
    );

    const { isLoading: patchIsLoading, mutate: patchSetting } = useMutation<
        DefaultRequestPropsType,
        Error,
        { [key: string]: string | number | number[] | boolean | null | Date | DateTime }
    >({
        mutationKey: ['patchCustomerSetting'],
        mutationFn: (setting) => {
            return CustomerSettingsApi.patch({ data: setting, apiProject: '' });
        },
        onSuccess: () => {
            setEditMode([]);
            Success({
                text: `${translate('t.customer_settings')} ${translate('p.has_been_updated')}`
            });
            modalDispach({
                type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                ModalPropsPayload: {
                    isOpen: false
                }
            });
            setSelectedSettingValue(undefined);
            RefetchTypedCustomerSettings();
            RefetchGlobalCustomerSettings();
        }
    });

    const getModalHeight = (customerSettingType?: CustomerSettingType, customerSettingsKey?: string): number => {
        if (customerSettingType === CustomerSettingType.AMBIENT_TEMPERATURE || customerSettingsKey === 'arcgis_url') {
            return 320;
        }
        if (customerSettingType === CustomerSettingType.MAP_AREA) {
            return 120;
        }
        return 70;
    };

    const getHumanValue = (
        value: unknown,
        customerSetting: CustomerSettingsModel['settings'][0] | undefined
    ): JSX.Element | string | JSX.Element[] => {
        switch (customerSetting?.type) {
            case CustomerSettingType.BOOLEAN:
                return translate(`t.${value ? 'enabled' : 'disabled'}`);
            case CustomerSettingType.LIST:
            case CustomerSettingType.MULTIPLE_LIST:
                if (value === -1) {
                    return translate('t.none');
                }
                if (customerSetting?.options) {
                    return customerSetting.options[value as number | string];
                }
                return value as string | JSX.Element;
            case CustomerSettingType.MAP_AREA:
            case CustomerSettingType.AMBIENT_TEMPERATURE:
                if (!value || value === 'null') {
                    return `${value}`;
                }

                return (value as []).map((val, index) => (
                    <TextField
                        label={
                            CustomerSettingType.MAP_AREA === customerSetting.type
                                ? `${translate('t.coordinates')} ${index + 1}`
                                : `${translate(`t.month${index + 1}`)}`
                        }
                        type='number'
                        size='small'
                        key={index}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            readOnly: true,
                            endAdornment: customerSetting?.type == CustomerSettingType.AMBIENT_TEMPERATURE && (
                                <InputAdornment position='end'>°{userUnits.temperature.toUpperCase()}</InputAdornment>
                            )
                        }}
                        value={val ?? null}
                        style={{
                            marginRight: '10px',
                            marginTop: '10px'
                        }}
                    />
                )) as JSX.Element[];
            case CustomerSettingType.DATETIME:
                if (!value) {
                    return `${value}`;
                }
                return typeof value === 'string'
                    ? value
                    : (value as DateTime).toFormat(dateTimeFormat('dateTime', false));
            default:
                return OptionLabels[value as number | string] || value;
        }
    };

    const getBEValue = (
        value: string | number | number[] | boolean | null | Date | DateTime,
        customerSetting: CustomerSettingsModel['settings'][0] | undefined
    ): string | number | number[] | boolean | null | Date | DateTime => {
        switch (customerSetting?.type) {
            case CustomerSettingType.LIST:
            case CustomerSettingType.MULTIPLE_LIST:
                if (value === -1) {
                    return null;
                }
                return value;
            case CustomerSettingType.AMBIENT_TEMPERATURE:
                return (value as number[]).map((val) =>
                    fromUserToServerUnit({ type: convertType.temperature, value: +val })
                );
            case CustomerSettingType.DATETIME: {
                return value ? fromTimezoneToUTC(value, customerSetting.format?.includes('s'), true) : null;
            }

            default:
                return value;
        }
    };

    const customerSettingsPopup = (
        type: CRUD,
        value: string | number | boolean | null | undefined | DateTime | [] | unknown,
        selectedSetting: CustomerSettingsModel['settings'][0] | undefined
    ): void => {
        const isContentToSave = !!(type === CRUD.ADD || type === CRUD.EDIT || type === CRUD.RETURN_TO_RECOMMENDED);
        const humanValue = getHumanValue(value, selectedSetting);
        const settings = flattenChildren(
            TypedCustomerSettings?.settings as CustomerSetting[],
            value,
            isContentToSave,
            selectedSetting
        )
            .filter((setting) => setting.customValue)
            .reduce((acc, current) => {
                acc[current.key] = getBEValue(current.value, current);
                return acc;
            }, {});
        const isAreaOrTemperature =
            selectedSetting?.type === CustomerSettingType.AMBIENT_TEMPERATURE ||
            selectedSetting?.type === CustomerSettingType.MAP_AREA;

        const title = {
            add: 'add_customer_setting',
            edit: 'edit_customer_setting',
            remove: 'remove_customer_setting',
            return_to_recommended: 'return_to_recommended_value'
        };

        const contentModalAdd = {
            id: 'customerSettingsPopup',
            leftTitle: translate(`t.${title[type]}`),
            content: (
                <CustomerModalContent>
                    <Typography variant='body1' gutterBottom>
                        {translate(
                            `p.${
                                isContentToSave
                                    ? 'save_customer_setting_with_value'
                                    : 'remove_customer_setting_with_value'
                            }`,
                            {
                                setting: translate(`t.${selectedSetting?.key}`),
                                value: ''
                            }
                        )}
                        <code>{isAreaOrTemperature ? <div>{humanValue}</div> : humanValue}</code>
                    </Typography>
                </CustomerModalContent>
            ),
            buttons: (
                <>
                    <UiButton
                        skin={isContentToSave ? SUCCESS : ERROR}
                        color={isContentToSave ? 'primary' : 'error'}
                        variant='contained'
                        loading={patchIsLoading}
                        onClick={() => {
                            if (selectedSetting?.key) {
                                setLoading(true);
                                setTypedCustomerSettingsParse({ settings: [] });
                                const remainingSettingValues = { ...settings };
                                delete remainingSettingValues[selectedSetting?.key];

                                patchSetting(
                                    isContentToSave
                                        ? {
                                              ...settings,
                                              [selectedSetting.key]: getBEValue(value, selectedSetting)
                                          }
                                        : remainingSettingValues
                                );
                            }
                        }}
                    >
                        {translate(`t.${isContentToSave ? 'save' : 'remove'}`)}
                    </UiButton>
                </>
            ),
            width: 500,
            height: getModalHeight(selectedSetting?.type, selectedSetting?.key)
        };

        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalAdd,
                onClose: () => {
                    modalDispach({
                        type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                        ModalPropsPayload: {
                            ...contentModalAdd,
                            isOpen: false
                        }
                    });
                    setEditMode([]);
                    setSelectedSettingValue(undefined);
                },
                isOpen: true
            }
        });
    };

    const flattenChildren = (
        settings: CustomerSetting[],
        value: string | number | boolean | null | undefined | DateTime | [] | unknown,
        isContentToSave: boolean,
        selectedSetting: CustomerSettingsModel['settings'][0] | undefined
    ): CustomerSetting[] => {
        return settings.reduce<CustomerSetting[]>((flattened, item) => {
            const { children, ...rest } = item;
            flattened.push(rest);

            const shouldSkipChildren = (item: CustomerSetting): boolean => {
                if (!selectedSetting) return false;
                const isParentKeyMatch = selectedSetting.key === item.key && !item.parent;
                const isValueFalse = !value;

                return (isValueFalse && isParentKeyMatch) || (!isContentToSave && isParentKeyMatch);
            };

            if (children && Array.isArray(children)) {
                if (!shouldSkipChildren(item)) {
                    flattened.push(...children);
                }
            }

            return flattened;
        }, []);
    };

    const groupSettings = (data: CustomerSettingsModel): CustomerSettingsModel => {
        const parentMap: Record<string, CustomerSetting[]> = {};
        const { settings } = data;

        settings.forEach((setting) => {
            if (setting.parent) {
                if (!parentMap[setting.parent]) {
                    parentMap[setting.parent] = [];
                }
                parentMap[setting.parent].push(setting);
            }
        });

        data.settings = settings
            .filter((setting) => !setting.parent)
            .map((setting) => {
                if (parentMap[setting.key] && setting.customValue) {
                    return {
                        ...setting,
                        children: parentMap[setting.key]
                    };
                }
                return setting;
            });
        return data;
    };

    const handleSetValueInputSearch = (text: string): void => {
        searchSettings(text);
    };

    const searchSettings = useCallback(
        debounce((searchText: string, data?: CustomerSettingsModel) => {
            if (customerSettingsOrigin || data) {
                const settingsList = data ? data : (customerSettingsOrigin as CustomerSettingsModel);

                let filteredSettings: CustomerSettingAll[] = settingsList.settings;

                if (!searchText) {
                    if (
                        customerSettingsState.unusedSettings ||
                        customerSettingsState.showAll ||
                        customerSettingsState.showUsedSettings
                    ) {
                        applyFilters(customerSettingsState, false);
                    } else {
                        setTypedCustomerSettingsParse(settingsList);
                    }
                } else if (settingsList?.settings) {
                    if (customerSettingsState.unusedSettings) {
                        filteredSettings = applyFilters(
                            customerSettingsState,
                            false,
                            settingsList,
                            true
                        ) as CustomerSetting[];
                    }

                    if (customerSettingsState.showUsedSettings) {
                        filteredSettings = applyFilters(
                            customerSettingsState,
                            false,
                            settingsList,
                            true
                        ) as CustomerSetting[];
                    }

                    filteredSettings = filteredSettings
                        .map((setting) => {
                            const filteredChildren = setting.children?.filter((child) =>
                                child.label?.toLowerCase().includes(searchText.toLowerCase())
                            );

                            const matchesSetting = setting.label?.toLowerCase().includes(searchText.toLowerCase());

                            if (matchesSetting || (filteredChildren && filteredChildren.length > 0)) {
                                return {
                                    ...setting,
                                    children: filteredChildren
                                };
                            }

                            return null;
                        })
                        .filter(Boolean) as CustomerSettingAll[];

                    const updatedSettings: CustomerSettingsModel = {
                        ...settingsList,
                        settings: filteredSettings as CustomerSettingAll[]
                    };
                    setTypedCustomerSettingsParse(updatedSettings);
                }
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                setCustomerSettingsState({ ...customerSettingsState, valueSearch: searchText });
            }
        }, 500),
        [customerSettingsOrigin, customerSettingsState]
    );

    const applyFilters = (
        customerSettingsState: CustomerSettingsFilters,
        search = true,
        data?: CustomerSettingsModel,
        applyAndReturn = false
    ): void | CustomerSetting[] => {
        if (customerSettingsOrigin || data) {
            const settingsList = data ? data : (customerSettingsOrigin as CustomerSettingsModel);

            if (search && customerSettingsState.valueSearch) {
                searchSettings(customerSettingsState.valueSearch);
            } else {
                let filteredSettings = settingsList.settings;

                if (customerSettingsState.unusedSettings) {
                    const newSettingsList = settingsList.settings.map((setting) => {
                        const newSetting = { ...setting };
                        if (newSetting.children && newSetting.children.length) {
                            newSetting.children = newSetting.children
                                .filter((child) => !child.customValue)
                                .map((child) => ({ ...child }));
                        }

                        return newSetting;
                    });

                    const newList = newSettingsList.filter((setting) => {
                        if (setting.children && setting.children.length) {
                            setting.children = setting.children.filter((child) => !child.customValue);
                        }
                        return setting.children ? true : !setting.customValue;
                    });

                    if (applyAndReturn) {
                        return newList;
                    }

                    filteredSettings = newList;
                } else if (customerSettingsState.showUsedSettings) {
                    const newSettingsList = settingsList.settings.map((setting) => {
                        const newSetting = { ...setting };
                        if (newSetting.children && newSetting.children.length) {
                            newSetting.children = newSetting.children
                                .filter((child) => child.customValue)
                                .map((child) => ({ ...child }));
                        }

                        return newSetting;
                    });

                    const newList = newSettingsList.filter((setting) => {
                        if (setting.children && setting.children.length) {
                            setting.children = setting.children.filter((child) => child.customValue);
                        }
                        return setting.customValue;
                    });

                    if (applyAndReturn) {
                        return newList;
                    }

                    filteredSettings = newList;
                }

                const updatedSettings: CustomerSettingsModel = {
                    ...settingsList,
                    settings: filteredSettings as CustomerSettingAll[]
                };

                setTypedCustomerSettingsParse(updatedSettings);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
            }
        }
    };

    useEffect(() => {
        return () => {
            searchSettings.cancel();
        };
    }, [searchSettings]);

    useEffect(() => {
        applyFilters(customerSettingsState);
    }, [customerSettingsState.unusedSettings, customerSettingsState.showAll, customerSettingsState.showUsedSettings]);

    useEffect(() => {
        return () => {
            setEditMode([]);
        };
    }, []);

    return (
        <CustomerSettingsContent
            data-testid='CustomerSettings-testid'
            customerSettingsPopup={customerSettingsPopup}
            typedCustomerSettings={typedCustomerSettingsParse}
            patchIsLoading={patchIsLoading}
            isLoading={CustomerSettingsLoading || loading}
            searchSettings={handleSetValueInputSearch}
            groupSettings={groupSettings}
        />
    );
};

export default Wrapper(CustomerSettings);
