import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { TyreLifespanReportViewContent } from './TyreLifespanReport.style';
import { TyreLifespanReportViewProps } from './TyreLifespanReport.type';
import { useTranslation } from 'react-i18next';
import LifespanReportDetails from 'components/Tyre/LifespanReportDetails/LifespanReportDetails';
import LifespanReportHistory from 'components/Tyre/LifespanReportHistory/LifespanReportHistory';
import { Grid } from '@mui/material';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete/LazyloadingAutocomplete';
import Tyre from 'api/Tyre';
import Vehicle from 'api/Vehicle';
import WheelDropDown from 'components/Tyre/WheelDropDown/WheelDropDown';
import { TyreLifeSpanDataAtom } from './TyreLifespanReport.atom';
import { VehicleQueryKeys } from 'models/Vehicle.type';
import { TyreQueryKeys } from 'models/Tyre.type';

const vehicleApi = new Vehicle();
const tyreApi = new Tyre();

export const TyreLifespanReportContent: React.FC<TyreLifespanReportViewProps> = ({
    tyreId,
    setTyreId
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const [tyreLifespanData, setFirstTyreLifespanData] = useRecoilState(TyreLifeSpanDataAtom());
    const emptyLazyLoadingFilter = { id: '', value: { name: '', value: '' } };
    const emptyLazyLoadingValue = { value: NaN, label: 'void', origItem: {} };
    const resetLazyLoadingValue = { value: NaN, label: 'reset', origItem: {} };
    const [serialNumberDefaultValue, setSerialNumberDefaultValue] = useState(emptyLazyLoadingValue);

    useEffect(() => {
        if (tyreId && !isNaN(tyreId) && tyreLifespanData.tyreSerialNumber.length > 0) {
            setSerialNumberDefaultValue({
                value: tyreId,
                label: `${tyreLifespanData.tyreSerialNumber}`,
                origItem: {}
            });
        }
    }, [tyreLifespanData.tyreSerialNumber, tyreId]);

    return (
        <TyreLifespanReportViewContent data-testid='TyreLifespanReportContent'>
            <Grid container xs={12} md={12} spacing={2} justifyContent='center'>
                <Grid item>
                    <LazyloadingAutocomplete
                        $testid='TyreLifespanReport-vehicle-dropdown'
                        id='vehicle-ids'
                        queryId={VehicleQueryKeys.getLazyDropdown}
                        optionKey='name'
                        optionValue='id'
                        width={150}
                        size='small'
                        clearButton={true}
                        query={vehicleApi.getVehicleLazyDropdown}
                        label={translate('t.vehicle')}
                        cacheTime={60}
                        cacheUnit='minutes'
                        onValueChange={(value) => {
                            if (value !== tyreLifespanData.vehicleId && value) {
                                !value && setSerialNumberDefaultValue(resetLazyLoadingValue);
                                setFirstTyreLifespanData({
                                    ...tyreLifespanData,
                                    vehicleId: value ?? NaN,
                                    customPosition: NaN,
                                    tyreSerialNumber: ''
                                });

                                setTyreId(NaN);
                            }
                        }}
                        preselectedValue={
                            tyreLifespanData.vehicleName && tyreLifespanData.vehicleId
                                ? {
                                      value: tyreLifespanData.vehicleId,
                                      label: `${tyreLifespanData.vehicleName}`,
                                      origItem: {}
                                  }
                                : undefined
                        }
                        querySortBy={[
                            {
                                desc: false,
                                id: 'name'
                            }
                        ]}
                    />
                </Grid>

                <Grid item>
                    <WheelDropDown
                        vehicleId={tyreLifespanData.vehicleId}
                        value={tyreLifespanData.customPosition}
                        getValue={(value) => {
                            !value && setSerialNumberDefaultValue(resetLazyLoadingValue);

                            setFirstTyreLifespanData({
                                ...tyreLifespanData,
                                customPosition: value as number,
                                tyreSerialNumber: ''
                            });
                            setTyreId(NaN);
                        }}
                        nameAsId={true}
                        defaultValue={tyreLifespanData.customPosition}
                        testid='TyreLifespanReport-WheelDropDown'
                    />
                </Grid>
                <Grid item>
                    <LazyloadingAutocomplete
                        data-testid='TyreLifespanReportContent-LazyloadingAutocomplete'
                        $testid='TyreLifespanReport-tyres-id-dropdown'
                        id='tyres-ids'
                        queryId={`${TyreQueryKeys.getLazyDropdown}-${tyreId || ''}
                        -${tyreLifespanData.tyreSerialNumber || ''}
                        -${tyreLifespanData.vehicleId || ''}
                        -${tyreLifespanData.customPosition || ''}`}
                        optionKey='tyreSerialNumber'
                        optionValue='id'
                        width={150}
                        size='small'
                        query={tyreApi.getTyreLazyDropdown}
                        querySortBy={[
                            {
                                desc: true,
                                id: 'inspectedAt'
                            }
                        ]}
                        label={translate('t.tyre_serial_number')}
                        onValueChange={(value) => {
                            if (value && value !== tyreId) {
                                setTyreId(value);
                            }
                        }}
                        preselectedValue={serialNumberDefaultValue}
                        defaultFilter={[
                            isNaN(tyreLifespanData.vehicleId)
                                ? emptyLazyLoadingFilter
                                : {
                                      id: 'vehicleId',
                                      value: { name: 'vehicleId', value: `${tyreLifespanData.vehicleId}` }
                                  },
                            !tyreLifespanData.customPosition || isNaN(tyreLifespanData.customPosition)
                                ? emptyLazyLoadingFilter
                                : {
                                      id: 'customPosition',
                                      value: {
                                          name: 'customPosition',
                                          value: `${tyreLifespanData.customPosition}`
                                      }
                                  }
                        ]}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} md={9} sm={12} lg={9} xl={9}>
                    <LifespanReportDetails
                        data-testid='TyreLifespanReportContent-LifespanReportDetails'
                        tyreId={tyreId}
                        customPosition={tyreLifespanData.customPosition}
                        vehicleId={tyreLifespanData.vehicleId}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <LifespanReportHistory
                        tyreId={tyreId}
                        data-testid='TyreLifespanReportContent-LifespanReportHistory'
                    />
                </Grid>
            </Grid>
        </TyreLifespanReportViewContent>
    );
};
