import styled from 'styled-components';
import imgSrc from '../../assets/images/exception_404_background.png';

export const ErrorPageContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url(${imgSrc});
    background-size: cover;
    background-repeat: no-repeat, repeat;
    background-position: center;
`;

export const ErrorTextBox = styled.div`
    position: absolute;
    top: 40%;
    left: 6em;
    h1 {
        font-size: 60px;
        text-transform: uppercase;
        margin-top: 0;
        font-weight: bolder;
    }
    h2 {
        margin-top: 0;
        font-size: 60px;
    }
    && .MuiButton-root {
        width: 17em;
    }
`;

export const LogoBox = styled.div`
    img {
        width: 200px;
    }
    width: 200px;
    position: absolute;
    bottom: 2em;
    left: 6em;
`;
