import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Link, Typography } from '@mui/material';
import LoginApi from 'api/Login';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { LoginShowContent, showContentType } from 'states/global/Login';
import { DateTime } from 'luxon';
import { getSystemName } from 'helpers';

const loginApi = new LoginApi();

const LoginSSORedirectionForm: React.FC = (): JSX.Element => {
    const [second, setSecond] = useState(5);
    const setLoginShowContent = useSetRecoilState(LoginShowContent);
    const Year = DateTime.now().year;

    const [loginSSOUrl, setLoginSSOUrl] = useState<string | undefined>();
    const { t: translator } = useTranslation();

    const getUrl = useCallback(async () => {
        try {
            const url = await loginApi.getOAuthLoginUrl();

            if (url.data) {
                setLoginSSOUrl(url.data);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn('Get url ', error);
        }
    }, [setLoginSSOUrl]);

    const handleRedirect = (): void => {
        location.href = loginSSOUrl as string;
    };

    useEffect(() => {
        if (second === 0) {
            location.href = loginSSOUrl as string;
        }
    }, [second]);

    useEffect(() => {
        let interval;
        if (loginSSOUrl) {
            interval = setInterval(() => {
                if (second > 0) {
                    setSecond((prevCount) => prevCount - 1);
                }
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [loginSSOUrl, second]);

    useEffect(() => {
        getUrl();
    }, []);

    return (
        <React.Fragment data-testid='LoginSSORedirectionForm-Div'>
            <Typography variant='h4' align='center' gutterBottom>
                {translator('t.sign_in_with_sso')}
            </Typography>

            <Alert variant={'filled'} severity='info' data-testid={'Alert-Login-testid'}>
                We are redirecting you to the SSO login site.
                <Typography component='p' data-testid='LoginSSORedirectionForm-Note2-p'>
                    {loginSSOUrl
                        ? translator('p.you_will_be_automatically_redirected', {
                              second: second
                          })
                        : `${translator('t.please_wait')}...`}
                </Typography>
            </Alert>

            <Button disabled={!loginSSOUrl} variant='contained' color='primary' onClick={() => handleRedirect()}>
                {translator('t.redirect')}
            </Button>

            <Typography variant='subtitle1' align='center' gutterBottom>
                <Link
                    href='javascript:void(0)'
                    onClick={() => setLoginShowContent(showContentType.LOGIN)}
                    variant='h5'
                    underline='hover'
                    data-testid='Sign-In-Link'
                >
                    {translator('t.go_back')}
                </Link>
            </Typography>
            <Typography variant='body1' align='center' gutterBottom>
                {getSystemName()} - {Year}
            </Typography>
        </React.Fragment>
    );
};

export default LoginSSORedirectionForm;
