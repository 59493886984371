import React, { useEffect, useState } from 'react';
import {
    CloseButton,
    Content,
    MuteNotificationWarningContainer,
    WarningMessage,
    WarningMessageCover
} from './MuteNotificationWarning.style';
import { MuteNotificationWarningProps } from './MuteNotificationWarning.type';
import { useTranslation } from 'react-i18next';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';
import CloseIcon from '@mui/icons-material/Close';

const MuteNotificationWarningContent: React.FC<MuteNotificationWarningProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [isHidden, setIsHidden] = useState(localStorage.getItem('showDisabledNotification') === 'false');

    useEffect(() => {
        const handleStorageChange = () => {
            setIsHidden(localStorage.getItem('showDisabledNotification') === 'false');
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    useEffect(() => {
        setIsHidden(localStorage.getItem('showDisabledNotification') === 'false');
    }, [localStorage.getItem('showDisabledNotification')]);

    const updateLocalStorage = () => {
        localStorage.setItem('showDisabledNotification', 'false');
        setIsHidden(true);
        window.dispatchEvent(new Event('localStorageUpdated'));
    };

    return (
        <MuteNotificationWarningContainer data-testid='MuteNotificationWarningContent' hide={isHidden}>
            <WarningMessage severity='warning'>
                <Content>
                    <WarningMessageCover>{translate('p.notification_muted')}</WarningMessageCover>
                    <CloseButton>
                        <UiIconButton onClick={updateLocalStorage}>
                            <CloseIcon />
                        </UiIconButton>
                    </CloseButton>
                </Content>
            </WarningMessage>
        </MuteNotificationWarningContainer>
    );
};

export default MuteNotificationWarningContent;
