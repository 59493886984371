import React from 'react';
import { RuleOverviewContainer } from './RuleOverview.style';
import { RuleOverviewViewProps } from './RuleOverview.type';
import UiTable from 'components/Ui/UiTable/UiTable';
import { RuleQueryKeys } from 'models/Rule.type';
import Rule from 'api/Rule';
import RuleOverviewDetail from '../RuleOverviewDetail/RuleOverviewDetail';
import UiWidget from 'components/Ui/Components/UiWidget/UiWidget';
import { useTranslation } from 'react-i18next';

const RuleApi = new Rule();

export const RuleOverviewContent: React.FC<RuleOverviewViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    return (
        <RuleOverviewContainer data-testid='RuleOverviewContent'>
            <UiWidget title={translate('t.rule_overview')}>
                <UiTable
                    columns={props.columns}
                    fetchFn={RuleApi.getActive}
                    refresh={false}
                    hiddenColumns={[]}
                    queryKey={RuleQueryKeys.getActive}
                    isExpandable
                    hideFilterAction
                    expandableContent={(row) => <RuleOverviewDetail ruleId={row.id} />}
                />
            </UiWidget>
        </RuleOverviewContainer>
    );
};
