import React from 'react';
import { AreaEditorMap, AreaEditorViewContent, IconLabel, TdBody, TdTitle } from './AreaEditor.style';
import { AreaEditorViewProps } from './AreaEditor.type';
import Widget from '../../Ui/Components/UiWidget';
import { Alert, Grid } from '@mui/material';
import Map from '../../Map';
import AreaEditorForm from '../AreaEditorForm/AreaEditorForm';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AreaEditorFormAtom } from '../AreaEditorForm/AreaEditorForm.atom';
import { useTranslation } from 'react-i18next';
import HeatmapTopBar from '../../Maps/HeatmapTopBar';
import { MapAction } from '../../../states/global/Map';
import { MapActionsEnum } from '../../Map/Map.type';
import Legend from 'components/Ui/Components/UiLegend/UiLegend';
import { VARIANT_ALERT } from 'components/Ui/variables';
import { Tab, Tabs } from '@mui/material';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { faLocationPen, faKeyboard } from '@fortawesome/pro-solid-svg-icons';
import InputCoordinate from './InputCoordinate/InputCoordinate';

export const LegendContentArea: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <table>
            <tbody>
                <tr>
                    <TdTitle>{translate('t.counter_zone')}:</TdTitle>
                    <TdBody>{translate('p.counter_zone_definition')}</TdBody>
                </tr>
                <tr>
                    <TdTitle>{translate('t.maintenance_zone')}:</TdTitle>
                    <TdBody>{translate('p.maintenance_zone_definition')}</TdBody>
                </tr>
                <tr>
                    <TdTitle>{translate('t.speed_zone')}:</TdTitle>
                    <TdBody>{translate('p.speed_zone_definition')}</TdBody>
                </tr>
                <tr>
                    <TdTitle>{translate('t.temperature_speed_alerts')}:</TdTitle>
                    <TdBody>{translate('p.temperature_speed_definition')}</TdBody>
                </tr>
            </tbody>
        </table>
    );
};

export const AreaEditorContent: React.FC<AreaEditorViewProps> = ({
    activeTab,
    handleActiveTab,
    TabPanel
}): JSX.Element => {
    const areaEditorState = useRecoilValue(AreaEditorFormAtom);
    const { t: translate } = useTranslation();
    const [mapAction, setMapAction] = useRecoilState(MapAction);

    return (
        <AreaEditorViewContent data-testid='AreaEditorContent'>
            <Widget
                data-testid='AreaEditorContent-Widget'
                title={translate('t.area_editor')}
                margin={0}
                height={0}
                onResize={() => {
                    setMapAction({
                        action: MapActionsEnum.RESIZE_MAP,
                        params: {
                            lastAction: mapAction
                        }
                    });
                }}
                headerAction={<Legend width={550} title={translate('t.legend')} content={<LegendContentArea />} />}
            >
                <Grid container spacing={1}>
                    <Grid
                        sx={{
                            order: { xs: 2, sm: 1 },
                            marginTop: { xs: 30, sm: 0 }
                        }}
                        item
                        xs={12}
                        sm={5}
                        md={5}
                        lg={4}
                    >
                        <AreaEditorForm data-testid='AreaEditorContent-AreaEditorForm' reload={activeTab !== 0} />
                    </Grid>
                    <Grid
                        sx={{ order: { xs: 1, sm: 2 } }}
                        item
                        xs={12}
                        sm={7}
                        md={7}
                        lg={8}
                        height='calc(100vh - 200px)'
                    >
                        <Tabs
                            value={activeTab}
                            onChange={handleActiveTab}
                            indicatorColor='primary'
                            textColor='primary'
                            variant='fullWidth'
                            scrollButtons='auto'
                        >
                            <Tab
                                label={
                                    <IconLabel>
                                        <UiIcon
                                            icon={faLocationPen}
                                            fontSize={17}
                                            fixedWidth
                                            transform='up-0 down-1 left-3'
                                        />
                                        {translate('t.create_area_drawing')}
                                    </IconLabel>
                                }
                            />
                            <Tab
                                label={
                                    <IconLabel>
                                        <UiIcon
                                            icon={faKeyboard}
                                            fontSize={17}
                                            fixedWidth
                                            transform='up-0 down-1 left-3'
                                        />
                                        {translate('t.typing_coordinates')}
                                    </IconLabel>
                                }
                            />
                        </Tabs>
                        <TabPanel value={activeTab} index={0}>
                            <Alert
                                variant={VARIANT_ALERT}
                                sx={{ marginTop: 2, marginBottom: 2 }}
                                severity='info'
                                data-testid='MultiEditMode-Alert'
                            >
                                {translate('p.area_creation_instructions')}
                            </Alert>
                            <HeatmapTopBar
                                data-testid='AreaEditorContent-HeatmapTopBar'
                                height='auto'
                                showVehiclePicker
                                showDatetimePicker
                                onApply={(dateRange, vehicles: number[] | undefined) => {
                                    if (dateRange?.dateTo && dateRange?.dateFrom && vehicles) {
                                        setMapAction({
                                            action: MapActionsEnum.LOAD_MULTIPLE_JOURNEY_LINE,
                                            params: {
                                                vehicleIds: vehicles,
                                                dateFrom: dateRange.dateFrom,
                                                dateTo: dateRange.dateTo
                                            }
                                        });
                                    }
                                }}
                            />
                            <AreaEditorMap>
                                <Map
                                    enableEditingArea={areaEditorState.mode === 'EDIT'}
                                    enableDrawing={areaEditorState.mode === 'CREATE' && !!areaEditorState.selectedArea}
                                />
                            </AreaEditorMap>
                        </TabPanel>
                        <TabPanel value={activeTab} index={1}>
                            <InputCoordinate />
                        </TabPanel>
                    </Grid>
                </Grid>
            </Widget>
        </AreaEditorViewContent>
    );
};
