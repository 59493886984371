import React from 'react';
import { useTranslation } from 'react-i18next';
import VehicleManufacturerAndModelView from './VehicleManufacturerAndModel.view';
import { VehicleModelTable } from 'models/VehicleModel.type';
import { Manufacturer } from 'models/VehicleManufacturer.type';
import { Column } from 'components/Ui/UiTable/UiTable.type';
import VehicleSchema from '../NewVehicleSchema/VehicheSchema';
import { Box } from '@mui/material';
import useTableFilter from 'components/CustomHooks/TableFilterState/TableFilterState';
import VehicleManufacturer from 'api/VehicleManufacturer';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete/LazyloadingAutocomplete';

const vehicleManufacturerAPI = new VehicleManufacturer();

const VehicleManufacturerAndModelTable = () => {
    const { t: translate } = useTranslation();

    const columnsManufacturer: Column<Manufacturer>[] = [
        {
            Header: translate('t.name'),
            accessor: 'manufacturer'
        }
    ];

    const columnsModel: Column<VehicleModelTable>[] = [
        {
            Header: translate('t.name'),
            accessor: 'modelName',
            disableSortBy: true
        },
        {
            Header: `${translate('t.vehicle_configuration')}`,
            accessor: (row) => row.vehicleConfiguration?.id,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props: { row: { original: VehicleModelTable } }) => {
                return (
                    <Box height={50} width={50}>
                        <VehicleSchema
                            schema={props.row.original.vehicleConfiguration?.distribution || ''}
                            emptySchema
                        />
                    </Box>
                );
            }
        },
        {
            Header: `${translate('t.vehicle_manufacturer')}`,
            accessor: (row) => row.vehicleManufacturer?.manufacturer,
            disableSortBy: true,
            Filter: ({ column, setFilter, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });
                return (
                    <Box>
                        <LazyloadingAutocomplete
                            id='vehicle-manufacturers'
                            queryId='VehicleManufacturerLazyLoading'
                            query={vehicleManufacturerAPI.getTable}
                            optionKey='manufacturer'
                            fullWidth
                            version='v2'
                            optionValue='id'
                            label={translate('t.vehicle_manufacturer')}
                            width={320}
                            size='small'
                            onValueChange={(value, label) => {
                                const manufacturerValue = value
                                    ? { name: column.Header, value: `${value};${label}` }
                                    : undefined;
                                setFilterValue(manufacturerValue);
                            }}
                        />
                    </Box>
                );
            }
        },
        {
            Header: `${translate('t.vehicle_type')}`,
            accessor: (row) => row.vehicleType?.id,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props: { row: { original: VehicleModelTable } }) => {
                return (
                    <>
                        {props.row.original.vehicleType?.translationKey
                            ? translate(props.row.original.vehicleType?.translationKey.replace('vehicle_type_', ''))
                            : ''}
                    </>
                );
            }
        }
    ];

    return <VehicleManufacturerAndModelView columnsManufacturer={columnsManufacturer} columnsModel={columnsModel} />;
};

export default VehicleManufacturerAndModelTable;
