import React from 'react';
import { DetailTitle, RuleOverviewDetailContainer } from './RuleOverviewDetail.style';
import { RuleOverviewDetailProps } from './RuleOverviewDetail.type';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RuleOverviewTable from 'components/Tyre/RuleOverviewTable/RuleOverviewTable';
import RuleAffectedVehiclesList from '../RuleAffectedVehiclesList/RuleAffectedVehiclesList';

const RuleOverviewDetailContent: React.FC<RuleOverviewDetailProps> = ({ ruleId }): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <RuleOverviewDetailContainer data-testid='RuleOverviewDetailContent'>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                    <DetailTitle variant='h5'>{translate('t.rule_overview')}</DetailTitle>
                    <RuleOverviewTable ruleId={ruleId} />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <DetailTitle variant='h5'>{translate('t.affected_vehicles')}</DetailTitle>
                    <RuleAffectedVehiclesList ruleId={ruleId} />
                </Grid>
            </Grid>
        </RuleOverviewDetailContainer>
    );
};

export default RuleOverviewDetailContent;
