import { DateTime as LuxonDateTime } from 'luxon';

export type CustomerSettingsAll = {
    settings: {
        ac_set_hotspot: string | null;
        ac_ping_reboot_type: string | null;
        ac_set_server_address: string | null;
        ac_set_hub_property: string | null;
        ac_set_hub_modules: string | null;
        alerts_from_foreign_hub: boolean;
        altitude_min: number;
        altitude_max: number;
        ambient_temperature: number[];
        ambient_temperature_command: string;
        area_gps_sat_threshold: number;
        custom_wheel_position: boolean;
        cron_accelerometer_event_log_command: boolean;
        email_service_type: '0' | '1';
        maintenance_zone_radius: number;
        map_area: number[] | null;
        max_speed_cap: number;
        mine_operation_start: string;
        notification_tyre_expiration: number;
        notification_tyre_threshold: number;
        notification_tyre_pressure_threshold: number;
        notification_tyre_temperature_threshold: number;
        require_password_reset: boolean;
        secondary_sensors_alerts: boolean;
        toolbox: boolean;
        speed_event_notification_area_time: number;
        toolbox_test_queue: boolean;
        wet_tyres: boolean;
        wet_tyres_from: string | null;
        wifi_secret_key: string;
        remove_message_request_id: [];
        mdd_bes: boolean;
        mdd_bes_driving_assistance: boolean;
        mdd_bes_display_name: string;
        temperature_prediction: boolean;
        temperature_prediction_hours: number;
        tablet_activation: boolean;
        vehicle_alerts_enabled: boolean;
        high_hot_pressure_enabled: boolean;
        heatmap_speed: boolean;
        heatmap_elevation: boolean;
        heatmap_gps: boolean;
        heatmap_temperature: boolean;
        heatmap_longitudinal_acceleration: boolean;
        heatmap_total_vibration: boolean;
        heatmap_vertical_vibration?: boolean;
        heatmap_slope_inclination: boolean;
        arcgis_url: string | null;
        temperature_prediction_workflow: number;
    };
};

export enum CustomerSettingType {
    AMBIENT_TEMPERATURE = 'AmbientTemperatureType',
    BOOLEAN = 'BooleanType',
    COMMAND = 'CommandType',
    DATETIME = 'DateTimeType',
    INTEGER = 'IntegerType',
    LIST = 'ListType',
    MAP_AREA = 'MapAreaType',
    NUMBER = 'NumberType',
    MULTIPLE_LIST = 'MultipleListType',
    TEXT = 'TextType'
}

enum PressureOptions {
    kpa,
    psi
}

enum TemeperatureOptions {
    c,
    f
}

enum SpeedOptions {
    kmh,
    mph
}

export enum OptionLabels {
    kpa = 'kPa',
    psi = 'psi',
    c = '°C',
    f = '°F',
    kmh = 'kmh',
    mph = 'mph'
}

export type CustomerSettingsModel = {
    settings: (CustomerSettingCmd | CustomerSettingNumber | CustomerSettingList | CustomerSetting | Datetime)[];
};

export type CustomerSettingAll =
    | CustomerSettingCmd
    | CustomerSettingNumber
    | CustomerSettingList
    | CustomerSetting
    | Datetime;

export type CustomerSetting = {
    customValue: boolean;
    customerEdit: boolean;
    defaultValue: string | number | boolean | null | [];
    deprecated: boolean;
    description: string;
    key: string;
    nullable: boolean;
    options?: {};
    required: boolean;
    type: CustomerSettingType;
    value: string | number | boolean | null | number[] | Date | LuxonDateTime;
    min?: number;
    max?: number;
    format?: string;
    parent?: string;
    children?: CustomerSetting[];
    label?: string;
    visibility?: string;
};

type CustomerSettingCmd = CustomerSetting & { command: number };

type CustomerSettingNumber = CustomerSetting & {
    max: number | null;
    min: number | null;
};

export type Datetime = CustomerSetting & {
    format: string;
};

type CustomerSettingList = Omit<CustomerSetting, 'value'> &
    (
        | {
              options: PressureOptions;
              value: keyof typeof PressureOptions;
          }
        | {
              options: TemeperatureOptions;
              value: keyof typeof TemeperatureOptions;
          }
        | {
              options: SpeedOptions;
              value: keyof typeof SpeedOptions;
          }
        | {
              options: '1' | '2' | '3';
              value: '1' | '2' | '3';
          }
    );

export enum CustomerSettingsQueryKeys {
    get = 'customerSettings-get',
    getAll = 'customerSettings-getAll'
}
