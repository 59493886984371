import React from 'react';
import {
    ContainerActionsButtonsHeaderWidget,
    ContainerMessageServerError,
    VehicleStatsContent as VehicleStatsContentStyle
} from './VehicleStatsTable.style';
import { useTranslation } from 'react-i18next';
import { VehicleStatsPropsView } from './VehicleStatsTable.type';
import { useRecoilValue } from 'recoil';
import UiWidget from 'components/Ui/Components/UiWidget/UiWidget';
import VehicleStatsBody from '../VehicleStatsBody/VehicleStatsBody';
import { applyStyleByMode } from 'helpers';
import { WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';
import { faTruck } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import VehicleStatsPeriod from '../VehicleStatsPeriod/VehicleStatsPeriod';
import VehicleStatsLegend from '../VehicleStatsLegend/VehicleStatsLegend';
import VehicleStatsSearch from '../VehicleStatsSearch/VehicleStatsSearch';
import VehicleStatsHideColumns from '../VehicleStatsHideColumns/VehicleStatsHideColumns';
import { useMediaQuery, useTheme } from '@mui/material';

export const VehicleStatsTableContent: React.FC<VehicleStatsPropsView> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div>
            <VehicleStatsContentStyle data-testid='VehicleStatsContent'>
                <UiWidget
                    title={translate('t.vehicle_stats')}
                    testid='Widget-VehicleStats'
                    avatar={
                        <UiIcon
                            icon={faTruck}
                            size='lg'
                            fixedWidth
                            color={applyStyleByMode({
                                theme: ThemeMode?.mode,
                                light: '#0000008a',
                                dark: WHITE
                            })}
                        />
                    }
                    applyPadding={false}
                    $minwidth={483}
                    $contentHeight={472}
                    margin={0}
                    headerAction={
                        <ContainerActionsButtonsHeaderWidget>
                            <VehicleStatsPeriod />
                            {!isMobile && <VehicleStatsSearch />}
                            <VehicleStatsHideColumns />

                            <VehicleStatsLegend />
                        </ContainerActionsButtonsHeaderWidget>
                    }
                    content={
                        props.errorGettingVehicleStats ? (
                            <ContainerMessageServerError>
                                <h3> {translate('t.server_error')} </h3>
                            </ContainerMessageServerError>
                        ) : (
                            <VehicleStatsBody />
                        )
                    }
                />
            </VehicleStatsContentStyle>
        </div>
    );
};
