import Alarm from '../../assets/sounds/alert.mp3';
import SndDiveAlarm from '../../assets/sounds/snd-dive-alarm.mp3';
import ChernobylAlarm from '../../assets/sounds/chernobyl-alarm.mp3';
import AlarmLoopSoundEffect from '../../assets/sounds/alarm-loop-sound-effect.mp3';
import DisconnectedWarn from '../../assets/sounds/disconnected-warn.mp3';
import FactoryAlarm from '../../assets/sounds/factory-alarm.mp3';
import NuclearAlarm from '../../assets/sounds/nuclear-alarm.mp3';

export const getSoundSource = (sound: string) => {
    const sounds = {
        alarm3: Alarm,
        'snd-dive-alarm': SndDiveAlarm,
        'chernobyl-alarm': ChernobylAlarm,
        'alarm-loop-sound-effect': AlarmLoopSoundEffect,
        'autopilot-disconnected': DisconnectedWarn,
        'factory-alarm': FactoryAlarm,
        'nuclear-alarm': NuclearAlarm
    };

    return sounds[sound];
};
