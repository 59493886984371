import React from 'react';
import { RuleByVehicleContainer } from './RuleByVehicle.style';
import { RuleByVehicleViewProps } from './RuleByVehicle.type';
import { Grid } from '@mui/material';
import RuleVehicleAutocomplete from 'components/Vehicle/RuleVehicleAutocomplete';
import AlertSettingOverview from 'components/Alert/AlertSettingOverview';

export const RuleByVehicleContent: React.FC<RuleByVehicleViewProps> = (): JSX.Element => {
    return (
        <RuleByVehicleContainer data-testid='RuleByVehicleContent'>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <RuleVehicleAutocomplete />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <AlertSettingOverview />
                </Grid>
            </Grid>
        </RuleByVehicleContainer>
    );
};
