import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import * as style from './TrackingSettingsForm.style';
import * as type from './TrackingSettingsForm.type';
import { Form, Formik, FormikProps } from 'formik';
import { Alert, CircularProgress, FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import UiColorPicker from 'components/Ui/Components/UiColorPicker/UiColorPicker';
import Button from 'components/Ui/Components/UiButton';
import { GridFieldFormik } from '../ProfileTabManager/ProfileTabManager.style';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { useRecoilValue } from 'recoil';

export const TrackingSettingsFormik: React.FC<type.FormikValidationViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const customerSettings = useRecoilValue(CustomerSettings);

    const schemaValidateForm = Yup.object().shape({
        mapType: Yup.string().required(translate('p.this_field_is_required')),
        showJourneyReport: Yup.string().required(translate('p.this_field_is_required')),
        mapVehicleLabel: Yup.string().required(translate('p.this_field_is_required')),
        trackingTrailColour: Yup.string()
            .matches(
                /^(rgb|hsl)(a?)[(]\s*([\d.]+\s*%?)\s*,\s*([\d.]+\s*%?)\s*,\s*([\d.]+\s*%?)\s*(?:,\s*([\d.]+)\s*)?[)]$/gm,
                translate('t.is_not_in_correct_format')
            )
            .required(translate('p.this_field_is_required')),
        useZoomFeatureInStatisticsPage: Yup.boolean(),
        showIconOnMap: Yup.string()
    });

    return (
        <Formik
            initialValues={props.inicialValuesFormik}
            onSubmit={(values: type.InicialValuesForm, actions) => {
                props.handleSubmit(values, actions.setSubmitting);
            }}
            validationSchema={schemaValidateForm}
        >
            {(propsF: FormikProps<type.InicialValuesForm>) => {
                const { errors, setFieldValue, values, isSubmitting } = propsF;

                return (
                    <Form>
                        <Grid container justify-content='flex-start' direction='row'>
                            <GridFieldFormik item lg={4} md={6} sm={6} xs={12}>
                                <Autocomplete
                                    options={props.mapTypeList}
                                    getOptionLabel={(mapType) => mapType.name}
                                    onChange={(e, mapType) => {
                                        setFieldValue('mapType', mapType ? mapType.value : null);
                                    }}
                                    defaultValue={props.mapTypeList.find((mapType) => mapType.value === values.mapType)}
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.map_type')}
                                            variant='outlined'
                                            name='mapType'
                                            type='text'
                                            value={values.mapType}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={errors.mapType && translate('p.this_field_is_required')}
                                            error={!!errors.mapType}
                                            data-testid='Profile-MapType-Autocomplete'
                                        />
                                    )}
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item lg={4} md={6} sm={6} xs={12}>
                                <Autocomplete
                                    options={props.mapJourneyReportList}
                                    getOptionLabel={(showJourneyReport) => showJourneyReport.name}
                                    onChange={(e, showJourneyReport) => {
                                        setFieldValue(
                                            'showJourneyReport',
                                            showJourneyReport ? showJourneyReport.value : null
                                        );
                                    }}
                                    defaultValue={props.mapJourneyReportList.find(
                                        (showJourneyReport) => showJourneyReport.value === values.showJourneyReport
                                    )}
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.show_journey_report')}
                                            variant='outlined'
                                            type='text'
                                            name='showJourneyReport'
                                            value={values.showJourneyReport}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={
                                                errors.showJourneyReport && translate('p.this_field_is_required')
                                            }
                                            error={!!errors.showJourneyReport}
                                        />
                                    )}
                                    data-testid='Profile-ShowJourneyReport-Autocomplete'
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item lg={4} md={6} sm={6} xs={12}>
                                <Autocomplete
                                    options={props.mapVehicleLabelList}
                                    getOptionLabel={(mapVehicleLabel) => mapVehicleLabel.name}
                                    onChange={(e, mapVehicleLabel) => {
                                        setFieldValue(
                                            'mapVehicleLabel',
                                            mapVehicleLabel ? mapVehicleLabel.value : null
                                        );
                                    }}
                                    defaultValue={props.mapVehicleLabelList.find(
                                        (mapVehicleLabel) => mapVehicleLabel.value === values.mapVehicleLabel
                                    )}
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.map_vehicle_label')}
                                            variant='outlined'
                                            type='text'
                                            name='mapVehicleLabel'
                                            value={values.mapVehicleLabel}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={errors.mapVehicleLabel && translate('p.this_field_is_required')}
                                            error={!!errors.mapVehicleLabel}
                                        />
                                    )}
                                    data-testid='Profile-MapVehicleLabel-Autocomplete'
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item lg={4} md={6} sm={6} xs={12}>
                                <FormControlLabel
                                    value={values.useZoomFeatureInStatisticsPage}
                                    control={
                                        <Switch
                                            color='primary'
                                            checked={values.useZoomFeatureInStatisticsPage}
                                            name='useZoomFeatureInStatisticsPage'
                                            data-testid='Profile-EnableZoomStatistics-Switch'
                                        />
                                    }
                                    label={translate('t.enable_zoom_statistics')}
                                    labelPlacement='end'
                                    onChange={(e) => {
                                        setFieldValue(
                                            'useZoomFeatureInStatisticsPage',
                                            (e.target as HTMLInputElement).checked
                                        );
                                    }}
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item lg={4} md={6} sm={6} xs={12}>
                                <FormControlLabel
                                    value={values.showIconOnMap}
                                    control={
                                        <Switch
                                            color='primary'
                                            checked={values.showIconOnMap == 1 ? true : false}
                                            name='showIconOnMap'
                                            data-testid='Profile-ShowVehicleIconsOnMap-Switch'
                                        />
                                    }
                                    label={translate('t.show_vehicle_icons_on_map')}
                                    labelPlacement='end'
                                    onChange={(e) => {
                                        setFieldValue('showIconOnMap', (e.target as HTMLInputElement).checked ? 1 : 0);
                                    }}
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item lg={4} md={12} sm={12} xs={12}>
                                <UiColorPicker
                                    labelInput={translate('t.tracking_trail_color')}
                                    defaultColor={values.trackingTrailColour}
                                    getColor={(value) => setFieldValue('trackingTrailColour', value)}
                                    error={errors.trackingTrailColour}
                                    testid='Profile-UiCloorPicker-Input'
                                />
                            </GridFieldFormik>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                {!customerSettings.arcgis_url?.length && values.mapType === 'arcgis' && (
                                    <Alert severity='warning' style={{ margin: '10px' }}>
                                        {translate('p.customer_doesnt_allow_arcgis')}
                                    </Alert>
                                )}
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <style.ContainerButton>
                                    <Button
                                        testid='Save-Form-Button-TrackingSettingsFormik'
                                        variant='contained'
                                        type='submit'
                                        disabled={isSubmitting}
                                        skin='success'
                                    >
                                        {isSubmitting ? <CircularProgress size={25} /> : translate('t.save')}
                                    </Button>
                                </style.ContainerButton>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};
