import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
    AlertContainer,
    ContainerActionWidget,
    ContainerToPrint,
    IconButtonTempPress,
    TemperaturePressureTableViewContent,
    TempPressWidgetContentContainer
} from './TemperaturePressureTable.style';
import { ActionButtonInfoType, TemperaturePressureTableViewProps } from './TemperaturePressureTable.type';
import { useTranslation } from 'react-i18next';
import UiWidget from 'components/Ui/Components/UiWidget/UiWidget';
import TemperaturePressureList from '../TemperaturePressureTableList/TemperaturePressureTableList';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { applyStyleByMode } from 'helpers';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import { FiltersForTempPressAtom } from './FiltersTempAndPress';
import TemperaturePredictionActionMenu from '../TemperaturePredictionPopover/TemperaturePredictionPopover';
import { TemperaturePressureTableLegend } from '../TemperaturePressureTableLegend/TemperaturePressureTableLegend';
import { TemperaturePressureTableActionHeader } from '../TemperaturePressureTableActionHeader/TemperaturePressureTableActionHeader';
import {
    faTriangleExclamation,
    faUser,
    faDownload,
    faBarsFilter,
    faRotateRight
} from '@fortawesome/pro-solid-svg-icons';

export const TemperaturePressureTableContent: React.FC<TemperaturePressureTableViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);
    const setFiltersForTempPressAtom = useSetRecoilState(FiltersForTempPressAtom);

    const handleChange = (): void => {
        setFiltersForTempPressAtom((currentValue) => !currentValue);
    };

    const actionButtonInfo: ActionButtonInfoType[] = [
        {
            title: 'refresh',
            icon: faRotateRight,
            onClick: () => props.handleRefresh(true)
        },
        {
            title: 'download',
            icon: faDownload,
            onClick: () => props.setShowScroll(false)
        },
        {
            title: 'filters',
            icon: faBarsFilter,
            onClick: () => handleChange()
        }
    ];

    return (
        <TemperaturePressureTableViewContent data-testid='TemperaturePressureTableContent'>
            <UiWidget
                avatar={
                    <UiIcon
                        icon={faUser}
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            dark: WHITE
                        })}
                    />
                }
                margin={0}
                marginBottom={false}
                padding={0}
                data-testid='NotificationSettingsContent'
                loading={false}
                width={100}
                unit='%'
                applyPadding={false}
                title={
                    <>
                        {translate('t.temperature_pressure')}
                        {props.tempPredServiceProblem ? (
                            <AlertContainer>
                                <UiIcon icon={faTriangleExclamation} size='sm' fixedWidth color='rgb(237, 108, 2)' />
                                {translate('p.predictive_temperature_algorithm_maintenance')}
                            </AlertContainer>
                        ) : (
                            <></>
                        )}
                    </>
                }
                headerAction={
                    <ContainerActionWidget>
                        <TemperaturePressureTableLegend />
                        {actionButtonInfo.map((val, key) => (
                            <IconButtonTempPress
                                key={key}
                                title={translate(`t.${val.title}`)}
                                onClick={val.onClick}
                                testid={`Temp&Press-${val.title}-Button`}
                            >
                                <UiIcon icon={val.icon} size='sm' />
                            </IconButtonTempPress>
                        ))}
                    </ContainerActionWidget>
                }
                content={
                    <TempPressWidgetContentContainer showScroll={props.showScroll}>
                        <TemperaturePressureTableActionHeader {...props} />

                        <ContainerToPrint
                            ref={(el) => (props.componentRef.current = el)}
                            $showScroll={props.showScroll}
                        >
                            <TemperaturePressureList
                                tableDisplay={props.tempAndPressState.tableViewType}
                                valueInputSearch={props.tempAndPressState.filterVehicleName}
                                filterByAlert={props.tempAndPressState.filterAlertLevel}
                                handleRefresh={props.handleRefresh}
                                refresh={props.tempAndPressState.refresh}
                                setFilterByAlert={props.setFilterByAlert}
                                showScroll={props.showScroll}
                                filterVehicleGroup={props.tempAndPressState.filterVehicleGroup}
                                setTempPredServiceProblem={props.setTempPredServiceProblem}
                            />
                        </ContainerToPrint>
                    </TempPressWidgetContentContainer>
                }
            ></UiWidget>
            <TemperaturePredictionActionMenu />
        </TemperaturePressureTableViewContent>
    );
};
