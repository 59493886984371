import React from 'react';
import { useRecoilValue } from 'recoil';
import { Theme } from 'states/global/Theme';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UiIcon from 'components/Ui/Components/UiIcon';
import { applyStyleByMode } from 'helpers';
import { DARK_STEEL_GREY, BACKGROUND_BLUE } from 'components/Ui/colors';
import UiLink from 'components/Ui/Components/UiLink/UiLink';
import { User } from 'states/global/User';
import { PageHelpButtonAtom } from './PageHelpButton.atom';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';

const PageHelpButton: React.FC = (): JSX.Element => {
    const user = useRecoilValue(User);
    const PageHelpState = useRecoilValue(PageHelpButtonAtom);
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);
    const language = ['en_US', 'es_ES', 'fr_CA', 'pt_PT'].includes(user?.userSetting.language || 'en_US')
        ? user?.userSetting.language || 'en_US'
        : 'en_US';

    return PageHelpState.article ? (
        <UiLink
            $padding='0'
            content={
                <Tooltip
                    title={translate('t.page_help')}
                    placement='bottom'
                    enterDelay={1000}
                    leaveDelay={0}
                    arrow
                    disableInteractive
                    disableFocusListener
                >
                    <UiIconButton testid='PageHelp-Button' disabled={!PageHelpState} title={translate('t.page_help')}>
                        <UiIcon
                            icon={['fas', 'circle-info']}
                            size='lg'
                            fixedWidth
                            color={applyStyleByMode({
                                theme: ThemeMode?.mode,
                                light: DARK_STEEL_GREY,
                                dark: BACKGROUND_BLUE
                            })}
                        />
                    </UiIconButton>
                </Tooltip>
            }
            url={`https://help.atmstechnology.com/${language}/${PageHelpState.section || 'get-started'}/${
                PageHelpState.article
            }`}
            openNewTab={!!PageHelpState.article}
            color={applyStyleByMode({
                theme: ThemeMode?.mode,
                light: DARK_STEEL_GREY,
                dark: BACKGROUND_BLUE
            })}
        />
    ) : (
        <UiIconButton
            testid='PageHelp-Button'
            disabled={!PageHelpState.article}
            title={`${translate('t.page_help')} ${translate('t.disabled')}`}
        >
            <UiIcon
                icon={['fas', 'circle-info']}
                size='lg'
                fixedWidth
                color={applyStyleByMode({
                    theme: ThemeMode?.mode,
                    light: '#c5c5c5',
                    dark: '#646464'
                })}
            />
        </UiIconButton>
    );
};

export default PageHelpButton;
