import { atom } from 'recoil';
import { CustomerSettingsFilters } from './CustomerSettings.type';

export const CustomerSettingsState = atom<CustomerSettingsFilters>({
    key: 'CustomerSettingsState',
    default: {
        valueSearch: '',
        unusedSettings: false,
        showAll: false,
        showUsedSettings: false
    }
});
