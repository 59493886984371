import React from 'react';
import { VehicleStatsSearchContainer } from './VehicleStatsSearch.style';
import { VehicleStatsSearchProps } from './VehicleStatsSearch.type';
import UiInputSearch from 'components/Ui/Components/UiInputSearch';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { SearchVehicle } from '../VehicleStatsTable/VehicleStatsTable.atom';

const VehicleStatsSearchContent: React.FC<VehicleStatsSearchProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [search, setSearch] = useRecoilState(SearchVehicle);

    return (
        <VehicleStatsSearchContainer data-testid='VehicleStatsSearchContent'>
            <UiInputSearch
                data-testid={'VehicleStats-search-input'}
                small={true}
                inputSearch={{
                    valueInputSearch: search,
                    setValueInputSearch: setSearch
                }}
                getValueInputSearch={(e: string) => setSearch(e)}
                widthInputSearchInit={200}
                widthInputSearchActive={350}
                searchByEnter={true}
                placeholder={translate('t.vehicle_name')}
            />
        </VehicleStatsSearchContainer>
    );
};

export default VehicleStatsSearchContent;
