import React from 'react';
import {
    HeaderTable,
    RowHeaderTable,
    TableContainerVehicle,
    VehicleStatsTableContent as VehicleStatsTableContentStyle
} from './VehicleStatsBody.style';
import { VehicleStatsBodyPropsView } from './VehicleStatsBody.type';
import { Paper, Table, TableBody, TableHead, TableSortLabel } from '@mui/material';
import VehicleStatsRow from '../VehicleStatsRow/VehicleStatsRow';
import { useRecoilValue } from 'recoil';
import { CustomerSettings } from 'states/global/CustomerSettings';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { ColumnNameVehiclesStatsAtom } from '../VehicleStatsTable/VehicleStatsTable.atom';
import { ColumnIndex } from '../VehicleStatsTable/VehicleStatsTable.type';

export const VehicleStatsBodyContent: React.FC<VehicleStatsBodyPropsView> = ({
    columns,
    handleSortRequest,
    isLoading,
    orderDirection,
    vehicleStatsData,
    showModalAlerts
}): JSX.Element => {
    const customerSettings = useRecoilValue(CustomerSettings);
    const vehicleColumns = useRecoilValue(ColumnNameVehiclesStatsAtom);

    return (
        <VehicleStatsTableContentStyle data-testid='VehicleStatsTableContent'>
            {isLoading && !vehicleColumns.length && <UiLoadingPage size='30px' />}
            {!isLoading && vehicleColumns.length && (
                <Paper sx={{ width: '100%' }} elevation={0}>
                    <TableContainerVehicle>
                        <Table stickyHeader aria-label='sticky collapsible table'>
                            <TableHead>
                                <RowHeaderTable>
                                    {columns.map((column, index) => {
                                        if (vehicleColumns[index].isVisible) {
                                            if (!customerSettings.mdd_bes && index === ColumnIndex.BES) return;
                                            if (column.id == 'vehicleName') {
                                                return (
                                                    <HeaderTable
                                                        key={column.id}
                                                        align={column.align}
                                                        $minWidth={column.minWidth}
                                                        onClick={handleSortRequest}
                                                    >
                                                        <TableSortLabel
                                                            active={true}
                                                            direction={orderDirection ?? 'asc'}
                                                        >
                                                            {column.label}
                                                        </TableSortLabel>
                                                    </HeaderTable>
                                                );
                                            }
                                            return (
                                                <HeaderTable
                                                    key={column.id}
                                                    align={column.align}
                                                    $minWidth={column.minWidth}
                                                >
                                                    {column.label}
                                                </HeaderTable>
                                            );
                                        }
                                    })}
                                </RowHeaderTable>
                            </TableHead>
                            <TableBody>
                                {vehicleStatsData.map((row, i) => (
                                    <VehicleStatsRow key={row.name + i} row={row} showModalAlerts={showModalAlerts} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainerVehicle>
                </Paper>
            )}
        </VehicleStatsTableContentStyle>
    );
};
