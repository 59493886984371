import React from 'react';
import {
    DataExportTopBarView,
    DownloadButton,
    WarningMessage,
    WrapperAutocompleteVehicle,
    UiDateRangePicker2Container,
    ButtonsGrid,
    RefreshButton
} from './TopBar.style';
import { DataExportTopBarViewProps } from './TopBar.type';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@mui/icons-material/Refresh';
import { TopBarAtom } from './TopBar.atom';
import { RefreshSimpleTable } from 'components/Ui/Components/UiSimpleTable/UiSimpleTable.atom';
import { Grid, Typography } from '@mui/material';
import { SIZE_BUTTON } from 'components/Ui/variables';
import { DateTime } from 'luxon';
import LazyloadingAutocomplete from '../../LazyloadingAutocomplete/LazyloadingAutocomplete';
import VehicleApi from 'api/Vehicle';
import UiDateRangePicker2 from 'components/Ui/Components/UiDateRangePicker2/UiDateRangePicker2';
import { PeriodTypeEnum } from 'components/Ui/Components/UiDateRangePicker2/UiDateRangePicker2.type';
import { VehicleQueryKeys } from 'models/Vehicle.type';

export const DataExportTopBarContent: React.FC<DataExportTopBarViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const [topBarAtom, setTopBarAtom] = useRecoilState(TopBarAtom);
    const refreshTable = useSetRecoilState(RefreshSimpleTable);

    const vehicleApi = new VehicleApi();

    return (
        <DataExportTopBarView data-testid='DataExportTopBarContent'>
            <WarningMessage variant='filled' severity='info'>
                {translate('p.report_demo_note')}
                {props.showIntervalWarning && translate('p.data_internal_not_applied_preview')}
            </WarningMessage>
            <Grid container justifyContent='center'>
                <Grid md='auto' marginRight='5px' marginTop={1}>
                    <WrapperAutocompleteVehicle>
                        <LazyloadingAutocomplete
                            data-testid='data-export-vehicle-select'
                            id='data-export-top-bar'
                            size='small'
                            label={translate('t.vehicles')}
                            query={vehicleApi.getTable}
                            getOptionLabel={(option) => ({
                                text: option.name,
                                html: undefined
                            })}
                            queryId={VehicleQueryKeys.getLazyDropdown}
                            querySortBy={[{ id: 'name', desc: false }]}
                            optionKey='name'
                            optionValue='id'
                            onValueChange={(vhicleId) => {
                                const vehile = {
                                    ...topBarAtom.vehicle,
                                    id: vhicleId === 0 ? vhicleId : vhicleId || -1
                                };
                                setTopBarAtom({ ...topBarAtom, vehicle: vehile });
                            }}
                            defaultOption={{
                                id: 0,
                                name: translate('t.all_vehicles'),
                                icon: '',
                                active: false
                            }}
                        />
                    </WrapperAutocompleteVehicle>
                </Grid>
                <Grid md='auto' marginRight='5px'>
                    {props.additionalElement && props.additionalElement}
                </Grid>
                <Grid md='auto' marginTop={1}>
                    {props.showDatePickers && (
                        <UiDateRangePicker2Container>
                            <UiDateRangePicker2
                                dateFrom={topBarAtom.dateFrom}
                                dateTo={topBarAtom.dateTo}
                                adjustToPeriod={{ periodType: PeriodTypeEnum.MONTHS, period: 3 }}
                                dateFromLabel={translate('t.date_from')}
                                dateToLabel={translate('t.date_to')}
                                onDateChange={(newDate) => {
                                    if (newDate !== null) {
                                        if (
                                            DateTime.fromISO(newDate[0]).invalid === null &&
                                            DateTime.fromISO(newDate[1]).invalid === null
                                        ) {
                                            setTopBarAtom({
                                                ...topBarAtom,
                                                dateFrom: newDate[0].set({ second: 0 }),
                                                dateTo: newDate[1]
                                            });
                                        }
                                    }
                                }}
                            />
                        </UiDateRangePicker2Container>
                    )}
                </Grid>
                <ButtonsGrid md='auto'>
                    <DownloadButton
                        testid='download-button'
                        skin='success'
                        color='primary'
                        variant='contained'
                        size={SIZE_BUTTON}
                        disabled={topBarAtom.vehicle.id === -1}
                        onClick={() => {
                            props.downloadFn();
                        }}
                    >
                        <Typography variant='body1'>{translate('t.download_report')}</Typography>
                    </DownloadButton>
                    <RefreshButton
                        testid='refresh-button'
                        title={translate('t.refresh')}
                        onClick={() => {
                            refreshTable(true);
                        }}
                        size={SIZE_BUTTON}
                    >
                        <RefreshIcon />
                    </RefreshButton>
                </ButtonsGrid>
            </Grid>
        </DataExportTopBarView>
    );
};
