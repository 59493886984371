import { MqttResponse } from 'models/NotificationEvent.type';
import { ModelGetTpmsAlerts } from 'models/TpmsAlerts.type';

export type ButtonShowNotificationsProps = {
    MqttData: MqttResponse;
};

export type ButtonShowNotificationsViewProps = {
    alertsData?: ModelGetTpmsAlerts;
    alertsIsLoading: boolean;
    muteNotification: boolean;
    notificationPulse: boolean;
    setNotificationPulse: (pulse: boolean) => void;
};

export type MqttMessage = {
    id: number;
};

export enum NotifcationActions {
    SET_NOTIFICATION_ID = 'SET_NOTIFICATION_ID',
    SET_NOTIFICATION_PULSE = 'SET_NOTIFICATION_PULSE',
    SET_NOTIFICATION_GENERAL = 'SET_NOTIFICATION_GENERAL',
    SET_NOTIFICATION_SOUND = 'SET_NOTIFICATION_SOUND',
    SET_NOTIFICATION_SOUND_ID = 'SET_NOTIFICATION_SOUND_ID'
}

export type generalActionType = {
    type: NotifcationActions.SET_NOTIFICATION_GENERAL;
    payload: {
        notificationId?: number;
        notificationPulse?: boolean;
        notificationSound?: boolean;
        notificationSoundId?: string;
    };
};

export type notificationIdActionType = {
    type: NotifcationActions.SET_NOTIFICATION_ID;
    payload: number;
};

export type notificationPulseActionType = {
    type: NotifcationActions.SET_NOTIFICATION_PULSE;
    payload: boolean;
};

export type notificationSoundActionType = {
    type: NotifcationActions.SET_NOTIFICATION_SOUND;
    payload: boolean;
};

export type stateNotification = {
    notificationId?: number;
    notificationPulse: boolean;
    notificationSound: boolean;
    notificationSoundId: string;
};
