import styled, { keyframes } from 'styled-components';

export const CustomeSettingInputsContainer = styled.div``;

const heightAnimation = keyframes`
  from {
    height: 48px;
  }

  to {
    height: 625px;
  }
`;

const heightMapAnimation = keyframes`
  from {
    height: 48px;
  }

  to {
    height: 225px;
  }
`;

export const ContainerAmbientTemp = styled.div<{ ismap?: boolean }>`
    height: 48px;
    transition: height 0.5s ease-in-out;
    animation: ${({ ismap }) => (ismap ? heightMapAnimation : heightAnimation)} 2s forwards;
`;

export const DatePickerContainer = styled.div`
    &&& .MuiFormControl-root {
        margin: 30px 8px 8px 8px !important;
    }
`;
