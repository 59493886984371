import { Scale } from 'components/Tyre/SeverityRankGraph/SeverityRankGraph.type';
import { VehicleSensor } from '../../models/SensorLog.type';
import { DateTime } from 'luxon';

export type StatisticsGraphsProps = {};

export type SpeedRecord = {
    timeKey: number;
    speedMax?: number | null;
    speedMin?: number | null;
    speedAvg?: number | null;
    measuredAt?: string | null;
};

export type StatisticsGraphsViewProps = {
    allSensors?: VehicleSensor;
    diffScale?: Scale;
    globalYDomain?: { left: number[]; right: number[] };
    getTempPress: (
        dateFrom: DateTime,
        dateTo: DateTime,
        vehicleId: number,
        wheelId: number,
        comparingData: number,
        optimalPressure: number,
        translator: (value) => string,
        sensorId?: number
    ) => Promise<unknown>;
    diffData: unknown[];
    isAllSensorExternal: boolean;
    isReloading: boolean;
    collapsed: string | boolean | { [id: string]: { isSecondary: boolean; collapsed: boolean } };
    setCollapsed: React.Dispatch<{
        type: string;
        collapse?: boolean | undefined;
        collapsedWidget?:
            | {
                  [id: string]: {
                      isSecondary: boolean;
                      collapsed: boolean;
                  };
              }
            | undefined;
        widgetId?: string | undefined;
    }>;
};

export type yDomainConfigType = {
    id: string;
    name: string;
    ticks?: number[];
    isShared?: boolean;
};

export type xDomainConfigType = {
    dataKey: string;
    stroke?: string;
    yAxisId: string;
    name: string;
    unit?: string;
    showDot?: boolean;
    hide?: boolean;
    hideOnlyLine?: boolean;
};

export type Scales = {
    temperature: number[];
    pressure: number[];
};

type wheelToCompare = {
    coldPressure: number;
    hotPressure: number;
    temperature: number;
    timeKey: string | number;
    measuredAt: string | number;
    sensorId: string;
};

export type DataToCompareType = {
    wheel1: (wheelToCompare & Record<string, unknown>)[];
    wheel2: (wheelToCompare & Record<string, unknown>)[];
};

export type yDomainConfig = {
    id: string;
    name: string;
    ticks?: number[];
    isShared?: boolean;
};

export enum XDomainConfigDataKey {
    TEMPERATURE = 'temperature',
    COLD_PRESSURE = 'coldPressure',
    OPTIMAL_PRESSURE = 'optimalPressure'
}
