import React, { useCallback } from 'react';
import {
    Container,
    UITableCell,
    NoVehicleIcon,
    StatusIcon,
    StyledTableRow,
    TdSubTable,
    UiHighPressureIcon,
    UiLowPressureIcon,
    VehicleTableCell
} from './VehicleStatsRow.style';
import { VehicleStatsRowViewProps } from './VehicleStatsRow.type';
import { TableCell, TableRow, Tooltip } from '@mui/material';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import HubConnectionIndicator from 'components/Vehicle/HubConnectionIndicator/HubConnectionIndicator';
import HubBatteryIndicator from 'components/Vehicle/HubBatteryIndicator/HubBatteryIndicator';
import { BESRank } from './BESRank';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { RED_ICON, SECONDARY_DARK_MODE, WHITE } from 'components/Ui/colors';
import { applyStyleByMode, getGpsIconValues } from 'helpers';
import { Theme } from 'states/global/Theme';
import UiLink from 'components/Ui/Components/UiLink/UiLink';
import { CustomerSettings } from 'states/global/CustomerSettings';
import {
    faChartMixedUpCircleCurrency,
    faThermometerThreeQuarters,
    faTriangleExclamation
} from '@fortawesome/pro-solid-svg-icons';
import { faSlash as faSlashRegular } from '@fortawesome/pro-regular-svg-icons';
import { getImgSource } from 'helpers/image';
import VehicleStatsCollapseButton from 'components/Vehicle/VehicleStatsCollapseButton';
import VehicleStatsCollapseTable from 'components/Vehicle/VehicleStatsCollapseTable';
import { CollapseVehicleStatsRow } from 'components/Vehicle/VehicleStatsCollapseButton/VehicleStatsCollapseButton.atom';
import { ColumnNameVehiclesStatsAtom, FilterPeriod } from '../VehicleStatsTable/VehicleStatsTable.atom';
import GpsIndicator from 'components/Vehicle/VehicleStatusWidget/Components/GpsIndicator/GpsIndicator';
import { DateTime } from 'luxon';
import TemperaturePredictionPeriodIcon from 'components/Mixs/TemperaturePredictionPeriodIcon';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { GpsIndicatorProps } from '../VehicleStatusWidget/Components/GpsIndicator/GpsIndicator.type';
import { ColumnIndex } from '../VehicleStatsTable/VehicleStatsTable.type';

export const VehicleStatsRowContent: React.FC<VehicleStatsRowViewProps> = ({
    row,
    vehicleAlerts,
    alertDetailData,
    hasSystemAlert,
    showModalAlerts
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);
    const { convertType, fromServerToUserUnit } = useConverter();
    const customerSettings = useRecoilValue(CustomerSettings);
    const theme = useRecoilValue(Theme);
    const setCollapsedVehicleRow = useSetRecoilState(CollapseVehicleStatsRow);
    const vehicleColumns = useRecoilValue(ColumnNameVehiclesStatsAtom);
    const period = useRecoilValue(FilterPeriod);

    const collapseRow = () => {
        setCollapsedVehicleRow((current) => (current === row.id ? 0 : row.id));
    };

    return (
        <>
            <StyledTableRow data-testid={`vehicleStats-row-${row.id}`} alertlevel={row.alert ?? 0}>
                {vehicleColumns[ColumnIndex.VEHICLE_NAME].isVisible ? (
                    <VehicleTableCell
                        className='firstTd'
                        data-testid={`vehicleStats-row-operated-hours-title-${row.id}`}
                    >
                        <Container display='contents'>
                            <span>
                                <VehicleStatsCollapseButton testId={`expand-action-${row.id}`} vehicleId={row.id} />
                            </span>
                            {row.icon && getImgSource(`${row.icon}`) ? (
                                <img
                                    alt={`${row.id}`}
                                    src={getImgSource(`${row.icon}`)}
                                    width={theme.easyReadingMode ? '30px' : '25px'}
                                />
                            ) : (
                                <NoVehicleIcon>
                                    <UiIcon
                                        icon={['fas', 'ban']}
                                        testid='vehicleStats-noVehicleIcon'
                                        size='1x'
                                        color={applyStyleByMode({
                                            theme: ThemeMode?.mode,
                                            light: SECONDARY_DARK_MODE,
                                            dark: WHITE
                                        })}
                                    />
                                </NoVehicleIcon>
                            )}
                            <UiLink
                                $className='linkVehicleName'
                                color='#40FFA6'
                                content={row.name}
                                title={translate('t.click_see_vehicle')}
                                url={`/vehicle-status?vehicle=${row.id}`}
                                testId={`VehicleStats-VehicleName-${row.id}-link`}
                            />
                        </Container>
                    </VehicleTableCell>
                ) : (
                    <></>
                )}

                {vehicleColumns[ColumnIndex.TPMS_STATUS].isVisible ? (
                    <VehicleTableCell
                        align='justify'
                        onClick={collapseRow}
                        data-testid={`vehicleStats-row-operated-hours-HubConnectionIndicator-${row.id}`}
                    >
                        <Container display='flex'>
                            {row.genericTemperatureAlert ? (
                                <Tooltip title={translate('t.temperature_alert')}>
                                    <StatusIcon
                                        $hide={!vehicleAlerts.temperature && row.genericTemperatureAlert}
                                        onClick={(event) => showModalAlerts(event, row.id, 0)}
                                    >
                                        <UiIcon
                                            icon={faThermometerThreeQuarters}
                                            fixedWidth
                                            testid={`temperature-alert-icon-${row.id}`}
                                            size='1x'
                                            color={applyStyleByMode({
                                                theme: ThemeMode?.mode,
                                                dark: WHITE,
                                                light: '#FF1616'
                                            })}
                                        />
                                    </StatusIcon>
                                </Tooltip>
                            ) : (
                                <></>
                            )}

                            {row.predictionTemperature ? (
                                <StatusIcon
                                    onClick={(event) =>
                                        vehicleAlerts.temperaturePrediction && showModalAlerts(event, row.id, 0)
                                    }
                                >
                                    <Tooltip title={translate('t.temperature_prediction')}>
                                        <TemperaturePredictionPeriodIcon
                                            time={row.lastTemperaturePredictedAt}
                                            testid={`temperature-prediction-statusicon-${row.id}`}
                                            predictionTemperature={row.predictionTemperature}
                                            wheels={row.wheels}
                                        />
                                    </Tooltip>
                                </StatusIcon>
                            ) : (
                                <> </>
                            )}

                            {!row.genericTemperatureAlert ? (
                                <Tooltip title={translate('t.temperature_alert_disabled')}>
                                    <StatusIcon $hide={!vehicleAlerts.temperature && row.genericTemperatureAlert}>
                                        <span className='fa-layers fa-fw'>
                                            <UiIcon
                                                icon={faSlashRegular}
                                                size='1x'
                                                data-testid={`temperature-alert-disabled-slash-icon-${row.id}`}
                                                color={applyStyleByMode({
                                                    theme: ThemeMode?.mode,
                                                    dark: WHITE,
                                                    light: '#787878'
                                                })}
                                            />
                                            <UiIcon
                                                icon={faThermometerThreeQuarters}
                                                fixedWidth
                                                size='1x'
                                                data-testid={`temperature-alert-disabled-thermometer-icon-${row.id}`}
                                                color={applyStyleByMode({
                                                    theme: ThemeMode?.mode,
                                                    dark: WHITE,
                                                    light: '#787878'
                                                })}
                                            />
                                        </span>
                                    </StatusIcon>
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                            {vehicleAlerts.highPressure ? (
                                <StatusIcon
                                    onClick={(event) => vehicleAlerts.highPressure && showModalAlerts(event, row.id, 0)}
                                    $hide={!vehicleAlerts.highPressure}
                                    $pressureTemperatureIcon
                                    $display='flex'
                                >
                                    <Tooltip
                                        title={translate('t.high_pressure_alert')}
                                        data-testid={`high-pressure-alert-icon-${row.id}`}
                                    >
                                        <UiHighPressureIcon color='#FFF' />
                                    </Tooltip>
                                </StatusIcon>
                            ) : (
                                <></>
                            )}
                            {vehicleAlerts.lowPressure ? (
                                <StatusIcon
                                    onClick={(event) => vehicleAlerts.lowPressure && showModalAlerts(event, row.id, 0)}
                                    $hide={!vehicleAlerts.lowPressure}
                                    $pressureTemperatureIcon
                                    $display='flex'
                                >
                                    <Tooltip
                                        title={translate('t.low_pressure_alert')}
                                        data-testid={`low-pressure-alert-icon-${row.id}`}
                                    >
                                        <UiLowPressureIcon />
                                    </Tooltip>
                                </StatusIcon>
                            ) : (
                                <></>
                            )}

                            {vehicleAlerts.temperaturePrediction ? (
                                <Tooltip title={translate('t.temperature_prediction')}>
                                    <StatusIcon
                                        onClick={(event) =>
                                            vehicleAlerts.temperaturePrediction && showModalAlerts(event, row.id, 0)
                                        }
                                    >
                                        <UiIcon
                                            color={applyStyleByMode({
                                                theme: ThemeMode?.mode,
                                                light: SECONDARY_DARK_MODE,
                                                dark: WHITE
                                            })}
                                            testid={`temperature-prediction-icon-${row.id}`}
                                            icon={faChartMixedUpCircleCurrency}
                                            size='1x'
                                        />
                                    </StatusIcon>
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                        </Container>
                    </VehicleTableCell>
                ) : (
                    <></>
                )}

                {vehicleColumns[ColumnIndex.SYSTEM_STATUS].isVisible ? (
                    <VehicleTableCell
                        align='justify'
                        onClick={collapseRow}
                        data-testid={`vehicleStats-row-operated-hours-system-alerts-${row.id}`}
                    >
                        <Container display='flex'>
                            {row.deviceId && row.vehicleInfo.battery && row.vehicleInfo.battery !== -1 ? (
                                <Tooltip title={translate('t.temperature_prediction')}>
                                    <StatusIcon
                                        onClick={(event) => {
                                            if (row.deviceId || row.vehicleInfo.battery > -1)
                                                return showModalAlerts(event, row.id, 3);
                                        }}
                                    >
                                        <HubBatteryIndicator
                                            battery={row.vehicleInfo.battery}
                                            testid={`battery-icon-${row.id}`}
                                            powerSource={row.vehicleInfo.powerSource}
                                        />
                                    </StatusIcon>
                                </Tooltip>
                            ) : (
                                <></>
                            )}

                            {!row.deviceId ? (
                                <Tooltip title={translate('t.vehicle_without_hub')}>
                                    <StatusIcon style={{ marginLeft: '6px' }}>
                                        <UiIcon
                                            color={applyStyleByMode({
                                                theme: ThemeMode?.mode,
                                                light: SECONDARY_DARK_MODE,
                                                dark: WHITE
                                            })}
                                            testid={`vehicle-without-hub-icon-${row.id}`}
                                            icon={['fas', 'rectangle-xmark']}
                                            size='1x'
                                        />
                                    </StatusIcon>
                                </Tooltip>
                            ) : (
                                <></>
                            )}

                            {row.shutdown ? (
                                <Tooltip title={translate('t.hub_shutdown')}>
                                    <StatusIcon $hide={!row.shutdown}>
                                        <UiIcon
                                            color={applyStyleByMode({
                                                theme: ThemeMode?.mode,
                                                light: SECONDARY_DARK_MODE,
                                                dark: WHITE
                                            })}
                                            testid={`hub-shutdown-icon-${row.id}`}
                                            icon={['fas', 'power-off']}
                                            size='1x'
                                        />
                                    </StatusIcon>
                                </Tooltip>
                            ) : (
                                <></>
                            )}

                            {hasSystemAlert ? (
                                <Tooltip title={translate('t.system_alerts')}>
                                    <StatusIcon
                                        onClick={(event) => hasSystemAlert && showModalAlerts(event, row.id, 3)}
                                    >
                                        <UiIcon
                                            color={applyStyleByMode({
                                                theme: ThemeMode?.mode,
                                                dark: WHITE,
                                                light: SECONDARY_DARK_MODE
                                            })}
                                            testid={`system-alerts-icon-${row.id}`}
                                            icon={faTriangleExclamation}
                                            size='1x'
                                        />
                                    </StatusIcon>
                                </Tooltip>
                            ) : (
                                <></>
                            )}

                            {/* Temporary disabled for BE */}
                            {/* <StatusIcon $hide={!(row?.parkingTime > 0)}>
                            <FontAwesomeIcon
                                color={applyStyleByMode({
                                    styleOld: '#000000',
                                    theme: ThemeMode?.mode,
                                    light: SECONDARY_DARK_MODE,
                                    dark: WHITE
                                })}
                                icon={['fas', 'parking']}
                                size='1x'
                            />
                        </StatusIcon> */}
                            {row.maintenance ? (
                                <Tooltip title={translate('t.vehicle_in_maintenance')}>
                                    <StatusIcon>
                                        <UiIcon
                                            color={applyStyleByMode({
                                                theme: ThemeMode?.mode,
                                                light: SECONDARY_DARK_MODE,
                                                dark: WHITE
                                            })}
                                            testid={`vehicle-in-maintenance-icon-${row.id}`}
                                            icon={['fas', 'wrench']}
                                            size='1x'
                                        />
                                    </StatusIcon>
                                </Tooltip>
                            ) : (
                                <></>
                            )}

                            {getGpsIconValues({
                                measuredAt: DateTime.fromISO(row.vehicleInfo.measuredAt) as DateTime,
                                outOfService: Boolean(row.outOfService),
                                maintenance: Boolean(row.maintenance),
                                lastGpsMeasuredAt: DateTime.fromISO(row.vehicleInfo.lastGpsMeasuredAt) as DateTime,
                                powerSource: row.vehicleInfo.powerSource
                            } as GpsIndicatorProps).show ? (
                                <Tooltip title={'GPS'}>
                                    <StatusIcon onClick={(event) => showModalAlerts(event, row.id, 3)}>
                                        <GpsIndicator
                                            powerSource={row.vehicleInfo.powerSource}
                                            deviceId={null}
                                            testid={`gps-icon-${row.id}`}
                                            lastGpsMeasuredAt={DateTime.fromISO(row.vehicleInfo.lastGpsMeasuredAt)}
                                            maintenance={Boolean(row.maintenance)}
                                            outOfService={Boolean(row.outOfService)}
                                            measuredAt={DateTime.fromISO(row.vehicleInfo.measuredAt)}
                                        />
                                    </StatusIcon>
                                </Tooltip>
                            ) : (
                                <></>
                            )}

                            {row.vehicleInfo.measuredAt ? (
                                <Tooltip title={translate('t.hub_connection_indicator')}>
                                    <StatusIcon>
                                        <HubConnectionIndicator
                                            testid={`vehiclestats-hubConnectionIndicator-${row.id}`}
                                            lastTimeConnected={row.vehicleInfo.measuredAt as string}
                                            connectionType={'wifi'}
                                        />
                                    </StatusIcon>
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                        </Container>
                    </VehicleTableCell>
                ) : (
                    <></>
                )}

                {customerSettings.mdd_bes && vehicleColumns[ColumnIndex.BES].isVisible ? (
                    <VehicleTableCell title='' data-testid={`vehicleStats-row-operated-hours-BESRank-${row.id}`}>
                        {!!row.maxBesSeverityRankTotal && (
                            <BESRank maxBesSeverityRankTotal={row.maxBesSeverityRankTotal} vehicleId={row.id} />
                        )}
                    </VehicleTableCell>
                ) : (
                    <></>
                )}

                {vehicleColumns[ColumnIndex.CURRENT_ALERTS].isVisible ? (
                    <VehicleTableCell
                        onClick={collapseRow}
                        data-testid={`vehicleStats-row-operated-hours-alerts-in-the-last-${row.id}`}
                        align='right'
                        title={`${alertDetailData.totalValues.current} ${translate(
                            't.alerts_in_the_last'
                        )} ${period} ${translate('t.hours')}`}
                    >
                        {alertDetailData.totalValues.current}
                    </VehicleTableCell>
                ) : (
                    <></>
                )}

                {vehicleColumns[ColumnIndex.PREVIOUS_ALERTS].isVisible ? (
                    <UITableCell
                        onClick={collapseRow}
                        data-testid={`vehicleStats-row-operated-hours-alerts-previously-to-${row.id}`}
                        align='right'
                        title={`${alertDetailData.totalValues.previous} ${translate(
                            't.alerts_previously_to'
                        )} ${period} ${translate('t.hours')}`}
                    >
                        {alertDetailData.totalValues.previous}
                    </UITableCell>
                ) : (
                    <></>
                )}

                {vehicleColumns[ColumnIndex.DISTANCE].isVisible ? (
                    <UITableCell
                        onClick={collapseRow}
                        $align='right'
                        data-testid={`vehicleStats-row-operated-hours-distance-${row.id}`}
                    >
                        {fromServerToUserUnit({
                            type: convertType.altitude,
                            value: row.odometer ?? 0,
                            displayUnits: true,
                            fixed: 1,
                            toType: convertType.distance
                        })}
                    </UITableCell>
                ) : (
                    <></>
                )}
            </StyledTableRow>
            <TableRow>
                <TdSubTable colSpan={9}>
                    <VehicleStatsCollapseTable vehicleId={row.id} alertDetailData={alertDetailData} />
                </TdSubTable>
            </TableRow>
        </>
    );
};
