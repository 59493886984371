import React, { useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Menu from '../../Ui/Components/UiMenu';
import { Item } from '../../Ui/Components/UiMenu/UiMenu.type';
import { BarGroupIcon } from '../TopBar.style';
import { getImgSource } from 'helpers/image';
import { User } from '../../../states/global/User';
import { useMutation } from '@tanstack/react-query';
import Account from '../../../api/Account';
import { BarGroupLanguage } from './ChooseLanguageButton.style';
import { Button, CircularProgress, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { changeLangOnHTML, setToLocalStorage } from 'helpers';
import { Lang } from '../../../states/global/Translator';
import { CommonTypeTheme } from 'components/Ui/Theme';
import { UiFullScreenLoaderAtom } from 'components/Ui/Components/UiFullScreenLoader/UiFullScreenLoader.atom';
import { useTranslation } from 'react-i18next';

const accountApi = new Account();

const ChooseLanguageButton: React.FC = (): JSX.Element => {
    const [user, setUser] = useRecoilState(User);
    const setLang = useSetRecoilState(Lang);
    const theme = useTheme();
    const showTitleLang = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const setFullScreenLoaderState = useSetRecoilState(UiFullScreenLoaderAtom);
    const { t: translate } = useTranslation();

    const { mutate: notificationMuteMutate, isLoading: notificationMuteLoading } = useMutation(
        accountApi.patchSetting,
        {
            onSuccess: (data) => {
                setUser((current) => {
                    if (current === null) {
                        return current;
                    }
                    return { ...current, userSetting: data.userSetting };
                });
                changeLangOnHTML(data.userSetting.language);
                setFullScreenLoaderState({ open: false });
            }
        }
    );

    const ChangeLanguageAction = (value) => {
        setLang(value);
        setToLocalStorage('language', value);
        setFullScreenLoaderState({ open: true });
        notificationMuteMutate({ language: value });
    };

    const languages: Item[] = useMemo(() => {
        return [
            {
                title: 'English UK',
                value: 'en_GB',
                icon: getImgSource('gb'),
                iconHeight: 15,
                action: ChangeLanguageAction,
                selected: user?.userSetting.language === 'en_GB'
            },
            {
                title: 'English US',
                value: 'en_US',
                icon: getImgSource('us'),
                iconHeight: 15,
                action: ChangeLanguageAction,
                selected: user?.userSetting.language === 'en_US'
            },
            {
                title: 'Français canadien',
                value: 'fr_CA',
                icon: getImgSource('ca'),
                iconHeight: 15,
                action: ChangeLanguageAction,
                selected: user?.userSetting.language === 'fr_CA'
            },
            {
                title: 'Español',
                value: 'es_ES',
                icon: getImgSource('es'),
                iconHeight: 15,
                action: ChangeLanguageAction,
                selected: user?.userSetting.language === 'es_ES'
            },
            {
                title: 'Portugal',
                value: 'pt_PT',
                icon: getImgSource('pt'),
                iconHeight: 15,
                action: ChangeLanguageAction,
                selected: user?.userSetting.language === 'pt_PT'
            },
            {
                title: ' Қазақ тілі',
                value: 'ru_RU',
                icon: getImgSource('kz'),
                iconHeight: 15,
                action: ChangeLanguageAction,
                selected: user?.userSetting.language === 'ru_RU'
            },
            {
                title: 'Українська',
                value: 'uk_UA',
                icon: getImgSource('ua'),
                iconHeight: 15,
                action: ChangeLanguageAction,
                selected: user?.userSetting.language === 'uk_UA'
            },
            {
                title: 'عربي',
                value: 'ar_SA',
                icon: getImgSource('sa'),
                iconHeight: 15,
                action: ChangeLanguageAction,
                selected: user?.userSetting.language === 'ar_SA'
            }
        ];
    }, [user]);

    const lenguagueSelected = useMemo(() => {
        return languages.find((language) => language.value === user?.userSetting.language);
    }, [user]);

    return (
        <Menu
            testid='ChooseLanguage-LanguageList'
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            rootEl={
                <BarGroupLanguage>
                    <Tooltip
                        title={translate('t.select_language')}
                        placement='bottom'
                        enterDelay={1000}
                        leaveDelay={0}
                        arrow
                        disableFocusListener
                    >
                        <Button size='small' data-testid='ChooseLanguage-Button'>
                            {notificationMuteLoading ? (
                                <CircularProgress size='1.4rem' />
                            ) : (
                                <>
                                    <img alt={'lenguague-selected'} src={lenguagueSelected?.icon} width={20} />
                                    {!(theme.palette.common as CommonTypeTheme).bigFonts && !showTitleLang && (
                                        <p>{lenguagueSelected?.title}</p>
                                    )}
                                    <BarGroupIcon>
                                        <ExpandMore />
                                    </BarGroupIcon>
                                </>
                            )}
                        </Button>
                    </Tooltip>
                </BarGroupLanguage>
            }
            items={languages}
        />
    );
};

export default ChooseLanguageButton;
