import React from 'react';
import {
    ActionButton,
    ActionTableCell,
    CustomTableRow,
    CustomTableRow2,
    EmptyTable,
    TableBodyEl,
    TableCellEl
} from './UiTableBody.style';
import { UiTableBodyProps } from './UiTableBody.type';
import UiTableRowCheckbox from '../UiTableRowCheckbox';
import SettingsIcon from '@mui/icons-material/Settings';
import Menu from 'components/Ui/Components/UiMenu';
import { useTranslation } from 'react-i18next';
import { Collapse } from '@mui/material';
import { ColumnInstance, Row } from 'react-table';

const UiTableBodyContent: React.FC<UiTableBodyProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    const TableRowCheckBox = React.useCallback(
        (checkBoxProps) => {
            return (
                <React.Fragment>
                    {checkBoxProps.cellIndex === 0 && (
                        <UiTableRowCheckbox
                            rowData={checkBoxProps.cell.row.original}
                            columnIndex={checkBoxProps.cellIndex}
                            rowIndex={checkBoxProps.rowIndex}
                            getCellProps={checkBoxProps.cell.getCellProps}
                            queryKey={props.queryKey}
                        />
                    )}
                </React.Fragment>
            );
        },
        [props.rows, props.prepareRow]
    );

    return (
        <TableBodyEl
            rowCount={props.rows.length}
            data-testid={`TableBody-${props.queryKey}Table`}
            {...props.getTableBodyProps()}
        >
            {!props.rows.length && (
                <CustomTableRow2 $notData $keyRow={0} data-testid={`TableBody-NoDataContainer-${props.queryKey}Table`}>
                    <EmptyTable>{translate('t.there_no_data')}</EmptyTable>
                </CustomTableRow2>
            )}
            {props.rows.map((row, rowIndex) => {
                props.prepareRow(row);
                return (
                    <React.Fragment key={rowIndex} data-testid={`TableBody-Row-${props.queryKey}Table-${rowIndex}`}>
                        <CustomTableRow
                            {...row.getRowProps()}
                            $keyRow={rowIndex}
                            id={`bodyRowId${rowIndex}`}
                            data-testid={`${props.queryKey}-bodyRow${rowIndex}`}
                            $background={(
                                row.cells[0]?.column as ColumnInstance<{}> & { BackgroundTr }
                            )?.BackgroundTr?.(row.original)}
                        >
                            {row.cells.map((cell, cellIndex) => {
                                return (
                                    <React.Fragment key={cellIndex}>
                                        {props.useColumnCheckbox && (
                                            <TableRowCheckBox cellIndex={cellIndex} cell={cell} rowIndex={rowIndex} />
                                        )}

                                        {props.useColumnAction?.position === cellIndex && cellIndex === 0 && (
                                            <React.Fragment key={`rowActionRow${rowIndex}`}>
                                                <ActionTableCell
                                                    {...cell.getCellProps()}
                                                    data-testid={`rowActionRow${rowIndex}`}
                                                >
                                                    <Menu
                                                        items={
                                                            props.rowActionBtns &&
                                                            props.rowActionBtns(cell.row.original)
                                                        }
                                                        rootEl={
                                                            <ActionButton
                                                                aria-controls='simple-menu'
                                                                aria-haspopup='true'
                                                                title={translate('t.action')}
                                                            >
                                                                <SettingsIcon />
                                                            </ActionButton>
                                                        }
                                                    />
                                                </ActionTableCell>
                                            </React.Fragment>
                                        )}
                                        <TableCellEl
                                            {...cell.getCellProps()}
                                            data-testid={`cellColumn${cellIndex}Row${rowIndex}`}
                                        >
                                            {cell.render('Cell')}
                                        </TableCellEl>
                                        {props.useColumnAction?.position === cellIndex && cellIndex !== 0 && (
                                            <React.Fragment key={`rowActionRow${rowIndex}`}>
                                                <ActionTableCell
                                                    {...cell.getCellProps()}
                                                    data-testid={`rowActionRow${rowIndex}`}
                                                >
                                                    <Menu
                                                        items={
                                                            props.rowActionBtns &&
                                                            props.rowActionBtns(cell.row.original)
                                                        }
                                                        rootEl={
                                                            <ActionButton
                                                                aria-controls='simple-menu'
                                                                aria-haspopup='true'
                                                                title={translate('t.action')}
                                                            >
                                                                <SettingsIcon />
                                                            </ActionButton>
                                                        }
                                                    />
                                                </ActionTableCell>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </CustomTableRow>
                        {props.isExpandable && (
                            <CustomTableRow2
                                key={`subrowExpandable-${rowIndex}`}
                                $keyRow={0}
                                data-testid={`TableBody-ExpandableContent-${props.queryKey}Table-${rowIndex}`}
                            >
                                <Collapse
                                    in={(row as Row<{}> & { isExpanded: boolean }).isExpanded}
                                    timeout='auto'
                                    unmountOnExit
                                >
                                    {props.expandableContent && props.expandableContent(row.original)}
                                </Collapse>
                            </CustomTableRow2>
                        )}
                    </React.Fragment>
                );
            })}
        </TableBodyEl>
    );
};

export default UiTableBodyContent;
