export type VehicleTypeModel = {
    id: number;
    type: string;
    translationKey: string;
};

export enum VehicleTypeQueryKeys {
    get = 'VehicleType-get',
    getCodebook = 'VehicleType-getCodebook',
    getFile = 'VehicleType-getFile',
    getTable = 'VehicleType-getTable',
    getById = 'VehicleType-getById',
    getByCriteria = 'VehicleType-getByCriteria',
    patchById = 'VehicleType-patchById',
    putById = 'VehicleType-putById'
}
