import React, { useMemo } from 'react';
import {
    BESLabel,
    BESText,
    IconWrapper,
    ListWrapper,
    Name,
    NoVehicleIcon,
    VehicleList,
    VehicleListItem,
    VehicleStatusListViewContent,
    VehicleUpdateTime
} from './VehicleStatusList.style';
import { VehicleBoxProps, VehicleStatusGridViewProps } from './VehicleStatusList.type';
import { applyStyleByMode, getBesLabel, getBesRank, isVehicleTyreBayHub } from 'helpers';
import { useRecoilValue } from 'recoil';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { useTranslation } from 'react-i18next';
import { ListItem, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getImgSource } from 'helpers/image';
import VehicleStatusIcons from 'components/Vehicle/VehicleStatusIcons/VehicleStatusIcons';
import { Theme } from 'states/global/Theme';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { WHITE } from 'components/Ui/colors';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';

const VehicleBox: React.FC<VehicleBoxProps> = (props): JSX.Element => {
    const ThemeMode = useRecoilValue(Theme);
    const { fromUTCToTimezone } = useConverter();
    const customerSettings = useRecoilValue(CustomerSettings);
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const isSelected = useMemo(
        () => props.vehicleId === props.data.vehicle.id,
        [props.vehicleId, props.data.vehicle.id]
    );

    return (
        <ListItem disablePadding>
            <VehicleListItem
                alertlevel={props.getHighestAlert(props.data.alert)}
                selected={isSelected}
                $disableVehicleSelection={props.isLoadingVehicle}
                onClick={() => {
                    if (!isSelected) {
                        const url: string = location.href;

                        if (!props.isLoadingVehicle) {
                            props.setVehicleId(props.data.vehicle.id);
                            props.handleShowTab(0);
                        }

                        url.includes('vehicle-status')
                            ? navigate(`?id=${props.data.vehicle.id}`, { replace: true })
                            : navigate(`vehicle-status?id=${props.data.vehicle.id}`, { replace: true });
                    }
                }}
                data-testid='VehicleStatus-SelectVehicle-List-Button'
            >
                <IconWrapper
                    $outOfService={props.data.vehicle.outOfService}
                    data-testid='VehicleBoxList-VehicleIconWrapper-id'
                >
                    {props.data.vehicle.icon ? (
                        // Temporary solution, remove when BE repairs icon in v1/webtrack/vehicle/status
                        <img
                            width='40px'
                            alt='vehicle'
                            src={getImgSource(
                                `${
                                    isVehicleTyreBayHub(props.data.vehicle.vehicleConfigurationId)
                                        ? 'hub/48x48/tyre_bay_hub.png'
                                        : props.data.vehicle.icon
                                }`
                            )}
                        />
                    ) : (
                        <NoVehicleIcon data-testid='VehicleBoxList-novehicle-UiIcon-id'>
                            <UiIcon
                                icon={['fas', 'ban']}
                                size='sm'
                                transform='down-1'
                                color={applyStyleByMode({
                                    theme: ThemeMode?.mode,
                                    dark: WHITE
                                })}
                            />
                        </NoVehicleIcon>
                    )}
                </IconWrapper>
                <Tooltip title={isSelected ? '' : translate('t.click_see_vehicle')} aria-hidden>
                    <Name alertLevel={props.getHighestAlert(props.data.alert)} data-testid='VehicleName'>
                        {props.data.vehicle.name}
                    </Name>
                </Tooltip>
                <VehicleStatusIcons
                    data={props.data}
                    isSelected={isSelected}
                    isLoadingVehicle={props.isLoadingVehicle}
                    lastTemperaturePredictedAt={props.data.vehicle.lastTemperaturePredictedAt}
                    displayAlerts={props.displayAlerts}
                    setVehicleId={props.setVehicleId}
                    handleShowTab={props.handleShowTab}
                />
                <VehicleUpdateTime
                    selected={props.data.vehicle.id === props.vehicleId}
                    data-testid={`VehicleStatusList-DateTime-${props.data.vehicle.id}`}
                >
                    {props.data.vehicle.vehicleInfo.measuredAt !== null
                        ? fromUTCToTimezone(props.data.vehicle.vehicleInfo.measuredAt, false)
                        : '-'}
                </VehicleUpdateTime>

                {customerSettings.mdd_bes && props.data.highestBes > 0 && (
                    <BESLabel
                        rank={getBesRank(customerSettings.mdd_bes_display_name.toUpperCase(), props.data.highestBes)}
                        data-testid='VehicleBoxList-BES-UiIcon-id'
                    >
                        <BESText>
                            {getBesLabel(
                                customerSettings.mdd_bes_display_name.toUpperCase(),
                                props.data.highestBes,
                                translate
                            )}
                        </BESText>
                    </BESLabel>
                )}
            </VehicleListItem>
        </ListItem>
    );
};

const createList = (props: VehicleStatusGridViewProps): JSX.Element => {
    const vehicleList: JSX.Element[] =
        props.data?.vehicleStatus.map((vehicle, index) => (
            <VehicleBox
                data={vehicle}
                key={index}
                isLoadingVehicle={props.isLoadingVehicle}
                setVehicleId={props.setVehicleId}
                vehicleId={props.vehicleId}
                displayAlerts={props.displayAlerts}
                getHighestAlert={props.getHighestAlert}
                handleShowTab={props.handleShowTab}
            />
        )) || [];

    return (
        <ListWrapper>
            <VehicleList>{vehicleList}</VehicleList>
        </ListWrapper>
    );
};

export const VehicleStatusListContent: React.FC<VehicleStatusGridViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <VehicleStatusListViewContent data-testid='VehicleStatusListContent'>
            {props.data?.vehicleStatus.length ? (
                createList(props)
            ) : (
                <Typography align='center' marginTop={1} variant='subtitle1'>
                    {translate('t.there_no_data')}
                </Typography>
            )}
        </VehicleStatusListViewContent>
    );
};
