import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { UiTableInstanceProps, columnVisibilityType } from './UiTableInstance.type';
import {
    ColumnInstance,
    useColumnOrder,
    useExpanded,
    useFilters,
    useFlexLayout,
    useGlobalFilter,
    useGroupBy,
    usePagination,
    useResizeColumns,
    useSortBy,
    useTable
} from 'react-table';
import { useSetRecoilState } from 'recoil';
import {
    ColumnNameIdType,
    ColumnNamesIds,
    ColumnVisibility,
    TableFn,
    TableAtom,
    HiddenColumnsIds
} from '../UiTable/TableAtom';
import { TableActions, TableDispatcher } from 'states/component/Table';
import useUserLocalStorageSettings, {
    useUserLocalStorageSettingsTable
} from 'components/CustomHooks/UserLocalStorageSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import UiTableColumnFilter from '../UiTableColumnFilter';
import { Table } from '@mui/material';
import UiTableBody from '../UiTableBody/UiTableBody';
import UiTableHeader from '../UiTableHeader/UiTableHeader';
import UiTableFilterColumnsPopover from '../UiTableFilterColumnsPopover/UiTableFilterColumnsPopover';
import { Column } from 'components/Ui/UiTable/UiTable.type';
import { TableContainerCustom } from './UiTableInstance.style';

const UiTableInstanceContent: React.FC<UiTableInstanceProps> = ({ columns, data, ...props }): JSX.Element => {
    const setColumnNamesIds = useSetRecoilState(ColumnNamesIds(`${props.queryKey}-ColumnNamesIds`));
    const setColumnVisibility = useSetRecoilState(ColumnVisibility(`${props.queryKey}-ColumnVisibility`));
    const setTableFns = useSetRecoilState(TableFn(`${props.queryKey}-TableFn`));
    const [tableState, setTableState] = useRecoilState(TableAtom(`${props.queryKey}-Table`));
    const [definedColumns, setDefinedColumns] = useState<boolean>(false);
    const [definedFns, setDefinedFns] = useState<boolean>(false);
    const tableDispatch = TableDispatcher(tableState, setTableState);
    const { getUserSettingTable } = useUserLocalStorageSettingsTable();
    const localStorageTableKey = useMemo(() => `table.${props.queryKey}Table`, [props.queryKey]);
    const { setUserSettings } = useUserLocalStorageSettings([`table.${props.queryKey}Table`]);
    const [hiddenColumns, setHiddenColumns] = useRecoilState(HiddenColumnsIds(`${props.queryKey}-HiddenColumnsIds`));
    const resetHiddenColumns = useResetRecoilState(HiddenColumnsIds(`${props.queryKey}-HiddenColumnsIds`));

    const defaultColumn: any = useMemo(() => {
        return {
            Filter: UiTableColumnFilter
        };
    }, []);

    const expandableColumn = {
        id: 'expander',
        width: 10,
        Header: ({ rows, toggleAllRowsExpanded }) => {
            const handleToggleAllRows = () => {
                const areAllRowsExpanded = rows.every((row) => row.isExpanded);
                toggleAllRowsExpanded(!areAllRowsExpanded);
            };

            return (
                <button
                    onClick={handleToggleAllRows}
                    style={{ cursor: 'pointer' }}
                    data-testid={`TableInstance-expandableColumn-button-${props.queryKey}Table`}
                >
                    <FontAwesomeIcon
                        onClick={handleToggleAllRows}
                        icon={['fas', 'chevron-up']}
                        data-testid={`TableInstance-expandableColumn-button-chevronUpIcon-${props.queryKey}Table`}
                    />{' '}
                    :{' '}
                    <FontAwesomeIcon
                        icon={['fas', 'chevron-down']}
                        data-testid={`TableInstance-expandableColumn-button-chevronDownIcon-${props.queryKey}Table`}
                    />
                </button>
            );
        },
        isFoldable: true,
        Cell: ({ row }) => (
            <IconButton
                aria-label='expand row'
                size='small'
                {...row.getToggleRowExpandedProps()}
                data-testid={`Toggle-Expand-Button`}
            >
                {row.isExpanded ? (
                    <FontAwesomeIcon
                        icon={['fas', 'chevron-up']}
                        data-testid={`TableInstance-expandableColumn-IconButton-chevronUpIcon-${props.queryKey}Table`}
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={['fas', 'chevron-down']}
                        data-testid={`TableInstance-expandableColumn-IconButton-chevronDownIcon-${props.queryKey}Table`}
                    />
                )}
            </IconButton>
        )
    };

    props.isExpandable ? columns.unshift(expandableColumn as Column<{}>) : columns;

    const instanceProps = {
        pageCount: props.pageCount,
        autoResetPage: false,
        manualSortBy: true,
        manualFilters: true,
        manualPagination: true
    };

    const tableInstance: any = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: tableState.queryPageIndex,
                pageSize: tableState.queryPageSize,
                sortBy: tableState.queryPageSortBy,
                filters: tableState.queryPageFilter,
                hiddenColumns: hiddenColumns
            } as any,

            defaultColumn,
            ...instanceProps
        },
        useColumnOrder,
        useFilters,
        useGroupBy,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        useFlexLayout,
        usePagination,
        useResizeColumns
    );

    const resetSystemHiddenColumns = (): void => {
        tableInstance.setHiddenColumns(props.hiddenColumns || []);
    };

    const getColumnVisibility = (columns: ColumnInstance[]): columnVisibilityType => {
        let columnVisibility: columnVisibilityType = {};
        for (let i = 0, length = columns.length; i < length; i++) {
            columnVisibility[columns[i].id] = {
                isVisible: columns[i].isVisible,
                name: columns[i].Header
            };
        }
        return columnVisibility;
    };

    const setGeneralHiddenColumns = (hiddenColumnstoJoin) => {
        if (hiddenColumnstoJoin) {
            let newFilter: any = structuredClone(hiddenColumns);

            hiddenColumnstoJoin?.forEach((value2) => {
                const exist = newFilter.some((value1) => value1 === value2);

                if (!exist) {
                    newFilter.push(value2);
                }
            });

            setHiddenColumns([...newFilter]);
        }
    };

    const defineTableFns = useCallback(() => {
        if (!definedFns) {
            setTableFns({
                toggleHideColumn: tableInstance.toggleHideColumn,
                gotoPage: tableInstance.gotoPage,
                resetHiddenColumns: resetHiddenColumns
            });
            setDefinedFns(true);
        }
    }, [tableInstance.toggleHideColumn, tableInstance.gotoPage, definedFns]);

    const defineTableColumns = useCallback(() => {
        if (!definedColumns) {
            const columnNames: ColumnNameIdType[] = tableInstance.allColumns.map((column) => ({
                id: column.id,
                name: column.Header
            }));
            setColumnNamesIds(columnNames);
            setDefinedColumns(true);
        }
        setColumnVisibility(getColumnVisibility(tableInstance.allColumns));
    }, [tableInstance.allColumns, definedColumns]);

    useEffect(() => {
        if (data && props.paginator?.totalCount) {
            tableDispatch({
                type: TableActions.TOTAL_COUNT_CHANGED,
                payload: props.paginator.totalCount
            });
        }
    }, [data, props.paginator?.totalCount]);

    useEffect(() => {
        if (tableInstance.state.pageIndex !== tableState.queryPageIndex) {
            tableDispatch({
                type: TableActions.PAGE_CHANGED,
                payload: tableInstance.state.pageIndex
            });
            return;
        } else if (tableInstance.state.pageSize !== tableState.queryPageSize) {
            tableDispatch({
                type: TableActions.PAGE_SIZE_CHANGED,
                payload: tableInstance.state.pageSize
            });
        } else if (JSON.stringify(tableInstance.state.sortBy) !== JSON.stringify(tableState.queryPageSortBy)) {
            tableDispatch({
                type: TableActions.PAGE_SORT_CHANGED,
                payload: tableInstance.state.sortBy
            });
        }
    }, [
        tableInstance.state.pageIndex,
        tableInstance.state.pageSize,
        tableInstance.state.sortBy,
        tableInstance.state.filters
    ]);

    useEffect(() => {
        if (getUserSettingTable(localStorageTableKey)?.orderBy) {
            tableInstance.setSortBy(getUserSettingTable(localStorageTableKey)?.orderBy);
        }
        if (hiddenColumns) {
            tableInstance.setHiddenColumns(hiddenColumns);
        }
        if (getUserSettingTable(localStorageTableKey)?.pageSize) {
            tableInstance.setPageSize(getUserSettingTable(localStorageTableKey).pageSize);
        }
    }, [
        (getUserSettingTable(localStorageTableKey)?.orderBy || []).toString(),
        hiddenColumns,
        getUserSettingTable(localStorageTableKey)?.pageSize
    ]);

    useEffect(() => {
        setGeneralHiddenColumns(getUserSettingTable(localStorageTableKey)?.userHiddenColumns);
    }, [(getUserSettingTable(localStorageTableKey)?.userHiddenColumns || []).toString()]);

    useEffect(() => {
        setGeneralHiddenColumns(getUserSettingTable(localStorageTableKey)?.defaultHiddenColumns);
    }, [(getUserSettingTable(localStorageTableKey)?.defaultHiddenColumns || []).toString()]);

    useEffect(() => {
        setGeneralHiddenColumns([
            ...new Set([
                ...(getUserSettingTable(localStorageTableKey)?.userHiddenColumns ?? []),
                ...(getUserSettingTable(localStorageTableKey)?.defaultHiddenColumns ?? [])
            ])
        ]);
        return () => {
            resetSystemHiddenColumns();
        };
    }, []);
    useEffect(() => {
        tableInstance.state.pageIndex !== 0 && tableInstance.gotoPage instanceof Function && tableInstance.gotoPage(0);
        if (
            !props.useColumnFiltering &&
            JSON.stringify(tableState.queryPageFilter) !== JSON.stringify(tableInstance.state.filters)
        ) {
            props.queryKey &&
                setUserSettings(localStorageTableKey, {
                    ...getUserSettingTable(localStorageTableKey),
                    pageSize: tableInstance.state.pageSize,
                    orderBy: tableInstance.state.sortBy,
                    filter: getUserSettingTable(localStorageTableKey)?.filter.reduce((acc, curr) => {
                        if (
                            tableState.queryPageFilter.filter((currentFilter) => currentFilter.id === curr.id).length >
                            0
                        ) {
                            acc.push(curr);
                        }

                        return acc;
                    }, [])
                });
            setHiddenColumns(tableInstance.state.hiddenColumns as string[]);
            tableInstance.setAllFilters(tableState.queryPageFilter);
        }
    }, [tableState.queryPageFilter]);

    defineTableColumns();
    defineTableFns();

    return (
        <>
            {!props.useColumnFiltering && tableInstance.allColumns.filter((column) => column.canFilter).length > 0 && (
                <UiTableFilterColumnsPopover table={tableInstance} queryKey={props.queryKey} />
            )}

            <TableContainerCustom $maxHeight={props.maxHeight} $minHeight={props.minHeight} id='UiTableView'>
                <Table size='small' {...tableInstance.getTableProps()} data-testid={`Table-${props.queryKey}-testid`}>
                    <UiTableHeader
                        useColumnFiltering={props.useColumnFiltering}
                        useColumnAction={props.useColumnAction}
                        queryKey={props.queryKey}
                        useColumnCheckbox={props.useColumnCheckbox}
                        headerGroups={tableInstance.headerGroups}
                    />
                    <UiTableBody
                        getTableBodyProps={tableInstance.getTableBodyProps}
                        getTableProps={tableInstance.getTableProps}
                        prepareRow={tableInstance.prepareRow}
                        rows={tableInstance.rows}
                        rowActionBtns={props.rowActionBtns}
                        useColumnAction={props.useColumnAction}
                        useColumnCheckbox={props.useColumnCheckbox}
                        queryKey={props.queryKey}
                        expandableContent={props.expandableContent}
                        isExpandable={props.isExpandable}
                    />
                </Table>
            </TableContainerCustom>
        </>
    );
};

export default UiTableInstanceContent;
