import React from 'react';
import { Skeleton } from '@mui/material';
import { CustomerSettingsLoaderContainer } from './CustomerSettings.style';

const CustomerSettingsLoader: React.FC = (): JSX.Element => {
    const elements = Array.from({ length: 11 }, (_, i) => i);
    return (
        <CustomerSettingsLoaderContainer data-testid='CustomerSettingsLoader-Container'>
            {elements.map((_v, i) => (
                <Skeleton key={i} variant='rounded' width='100%' height={58} />
            ))}
        </CustomerSettingsLoaderContainer>
    );
};

export default CustomerSettingsLoader;
