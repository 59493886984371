import React from 'react';
import { AlertBadge, AlertsTabCustomer, AlertsTabContainer } from './AlertsTab.style';
import { AlertsTabViewProps } from './AlertsTab.type';
import { Tabs } from '@mui/material';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import UiTabContent from 'components/Ui/Components/UiTab/UiTab';
import { LazyLoadingAlertsList } from 'components/Vehicle/VehicleDetails/LazyLoadingAlertsList/LazyLoadingAlertsList';
import { typeAlert } from '../TPMSAlertsListTabManager/TPMSAlertsListTabManager.type';
import MuiDialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';
import { applyStyleByMode } from 'helpers';
import { SECONDARY_DARK_MODE, WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';
import { useRecoilValue } from 'recoil';

const PAGE_COUNT = 5;

const AlertsTabContent: React.FC<AlertsTabViewProps> = ({
    vehicleId,
    activeTab,
    tpmsAlerts,
    gpsAlerts,
    historicalLogAlerts,
    systemAlerts,
    vehicleAlerts,
    handleTabChange,
    changeNumberOfAlerts
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    return (
        <AlertsTabContainer data-testid='AlertsTabContent'>
            <MuiDialogContent sx={{ paddingTop: '10px' }}>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    indicatorColor='primary'
                    textColor='primary'
                    variant='scrollable'
                    scrollButtons='auto'
                >
                    <AlertsTabCustomer
                        label={
                            <AlertBadge badgeContent={`${tpmsAlerts}`} $color='#a90329'>
                                <UiIcon
                                    icon={['fas', 'tire-pressure-warning']}
                                    fontSize={17}
                                    fixedWidth
                                    transform='up-0 down-1 left-3'
                                />
                                TPMS
                            </AlertBadge>
                        }
                        $badge={true}
                    />
                    <AlertsTabCustomer
                        label={
                            <AlertBadge badgeContent={`${vehicleAlerts}`}>
                                <UiIcon
                                    icon={['fas', 'truck']}
                                    fontSize={17}
                                    fixedWidth
                                    transform='up-0 down-1 left-3'
                                />
                                {translate('t.vehicles_alerts')}
                            </AlertBadge>
                        }
                        $badge={true}
                    />
                    <AlertsTabCustomer
                        label={
                            <AlertBadge badgeContent={`${gpsAlerts}`} $color='#a90329'>
                                <UiIcon
                                    icon={['fas', 'map-marker']}
                                    fontSize={17}
                                    fixedWidth
                                    transform='up-0 down-1 left-3'
                                />
                                GPS
                            </AlertBadge>
                        }
                        $badge={true}
                        data-testid='VehicleStatus-GPSTab-Button'
                    />
                    <AlertsTabCustomer
                        label={
                            <AlertBadge badgeContent={`${systemAlerts}`} $color='#a90329'>
                                <UiIcon
                                    icon={['fas', 'triangle-exclamation']}
                                    fontSize={15}
                                    fixedWidth
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        light: SECONDARY_DARK_MODE,
                                        dark: WHITE
                                    })}
                                    transform='left-3'
                                />
                                <div>{translate('t.system_warnings')}</div>
                            </AlertBadge>
                        }
                        $badge={true}
                        data-testid='VehicleStatus-SystemAlertsTab-Button'
                    />
                    <AlertsTabCustomer
                        label={
                            <AlertBadge badgeContent={`${historicalLogAlerts}`} $color='#a90329'>
                                <UiIcon
                                    icon={['fas', 'book']}
                                    fontSize={17}
                                    fixedWidth
                                    transform='up-0 down-1 left-3'
                                />
                                {translate('t.logs')}
                            </AlertBadge>
                        }
                        $badge={true}
                        data-testid='VehicleStatus-LogsTab-Button'
                    />
                </Tabs>
                <UiTabContent value={activeTab} index={0} renderBeforeActive={false} testId='VehicleDetailsTPMS'>
                    <LazyLoadingAlertsList
                        queryId='_alertTPMS'
                        limit={PAGE_COUNT}
                        solved={false}
                        alertType={typeAlert.TPMS}
                        vehicleId={vehicleId}
                        changeNumberOfAlerts={changeNumberOfAlerts}
                        tabNumber={0}
                        displayAlertActions={true}
                        showSolveButton={true}
                        showValidateButton={true}
                        tab='tpms'
                        maxHeight={435}
                    />
                </UiTabContent>
                <UiTabContent
                    value={activeTab}
                    index={1}
                    renderBeforeActive={true}
                    testId='VehicleDetailsVehicleAlerts'
                >
                    <LazyLoadingAlertsList
                        queryId='_alertVehicleAlerts'
                        limit={PAGE_COUNT}
                        solved={false}
                        alertType={typeAlert.VehicleEvent}
                        vehicleId={vehicleId}
                        changeNumberOfAlerts={changeNumberOfAlerts}
                        tabNumber={1}
                        displayAlertActions={false}
                        showSolveButton={false}
                        showValidateButton={false}
                        maxHeight={420}
                    />
                </UiTabContent>
                <UiTabContent
                    value={activeTab}
                    index={2}
                    renderBeforeActive={true}
                    testId='VehicleDetailsVehicleAlerts'
                >
                    <LazyLoadingAlertsList
                        queryId='_alertGPS'
                        limit={PAGE_COUNT}
                        alertType={typeAlert.GPS}
                        vehicleId={vehicleId}
                        changeNumberOfAlerts={changeNumberOfAlerts}
                        tabNumber={2}
                        displayAlertActions={false}
                        maxHeight={420}
                    />
                </UiTabContent>
                <UiTabContent
                    value={activeTab}
                    index={3}
                    renderBeforeActive={true}
                    testId='VehicleDetailsVehicleAlerts'
                >
                    <LazyLoadingAlertsList
                        queryId='_alertSensor'
                        limit={PAGE_COUNT}
                        alertType={typeAlert.Sensor}
                        vehicleId={vehicleId}
                        changeNumberOfAlerts={changeNumberOfAlerts}
                        tabNumber={3}
                        displayAlertActions={false}
                        tab='system'
                        solved={false}
                        maxHeight={420}
                    />
                </UiTabContent>
                <UiTabContent
                    value={activeTab}
                    index={4}
                    renderBeforeActive={true}
                    testId='VehicleDetailsVehicleAlerts'
                >
                    <LazyLoadingAlertsList
                        queryId='_alertHistoryLog'
                        limit={PAGE_COUNT}
                        alertType={typeAlert.HistoricalLog}
                        vehicleId={vehicleId}
                        changeNumberOfAlerts={changeNumberOfAlerts}
                        tabNumber={4}
                        displayAlertActions={false}
                        solved={true}
                        tab='log'
                        maxHeight={420}
                    />
                </UiTabContent>
            </MuiDialogContent>
        </AlertsTabContainer>
    );
};

export default AlertsTabContent;
