import React, { useEffect } from 'react';
import { RuleVehicleAutocompleteContainer } from './RuleVehicleAutocomplete.style';
import { RuleVehicleAutocompleteProps } from './RuleVehicleAutocomplete.type';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete/LazyloadingAutocomplete';
import { useTranslation } from 'react-i18next';
import VehicleApi from 'api/Vehicle';
import { VehicleQueryKeys } from 'models/Vehicle.type';
import { SelectedVehicleId } from 'components/Alert/AlertSettingOverview/AlertSettingOverview.atom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

const Vehicle = new VehicleApi();

const RuleVehicleAutocompleteContent: React.FC<RuleVehicleAutocompleteProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const setVehicleId = useSetRecoilState(SelectedVehicleId);
    const resetVehicleId = useResetRecoilState(SelectedVehicleId);

    useEffect(() => {
        return () => {
            resetVehicleId();
        };
    }, []);

    return (
        <RuleVehicleAutocompleteContainer data-testid='RuleVehicleAutocompleteContent'>
            <LazyloadingAutocomplete
                data-testid='RuleVehicle-LazyloadingAutocomplete'
                id={'rule-vehicle-lazyloading'}
                size='small'
                label={translate('t.vehicle')}
                query={Vehicle.getTable}
                getOptionLabel={(option) => ({
                    text: option.name,
                    html: undefined
                })}
                queryId={VehicleQueryKeys.getVehicleLazyloading}
                querySortBy={[{ id: 'name', desc: false }]}
                optionKey='name'
                optionValue='id'
                customMargin='0'
                onValueChange={(vehicleId) => {
                    vehicleId && setVehicleId(vehicleId);
                }}
            />
        </RuleVehicleAutocompleteContainer>
    );
};

export default RuleVehicleAutocompleteContent;
