import { TeamShiftTimeModel } from './TeamShiftTime.type';

export type TeamShiftModel = {
    id: number;
    shiftDate: string;
    fullStartDateTime: string;
    fullEndDateTime: string;
    shiftTime: TeamShiftTimeModel;
    isSlave: boolean;
    startDateTime: string;
    endDateTime: string;
};

export type TeamShiftPayload = {
    startDate: string;
    endDate: string;
    shiftTime: number;
};

export enum TeamShiftQueryKeys {
    getTeamShiftList = 'teamShift-list',
    removeTeamShift = 'teamShift-removeTeamShift',
    addTeamShift = 'teamShift-addTeamShift',
    updateTeamShift = 'teamShift-updateTeamShift'
}
