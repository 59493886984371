import React, { useState, useEffect, useReducer } from 'react';
import { LifespanReportHistoryContent } from './LifespanReportHistory.view';
import { LifespanReportHistoryProps, TypeInfoEnum } from './LifespanReportHistory.type';
import { VehicleSchemaDiv, CustomTimelineDot, ListTimeLineItem } from './LifespanReportHistory.style';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import useConverter from 'components/CustomHooks/Converter/Converter';
import VehicleSchemaContainer from './Components/VehicleSchemaContainer/VehicleSchemaContainer';
import { HistoryLog, TyreQueryKeys } from 'models/Tyre.type';
import Tyre from 'api/Tyre';
import { Filterlevel, AlertActions } from './Components/AlertsLevelPopUp/AlertsLevelPopUp.type';
import { AlertsByLevelReducer, inicialStateAlertList } from './Components/AlertsLevelPopUp/AlertsLevelPopUp.reducer';
import { snakeToCamel } from 'helpers/converter/text';
import { Wrapper } from 'helpers/wrapper';

const apiTyre = new Tyre();
const PAGE_COUNT = 11;

const LifespanReportHistory: React.FC<LifespanReportHistoryProps> = ({ tyreId }): JSX.Element => {
    const { fromUTCtoUserTimezone, fromServerToUserUnit, convertType } = useConverter();
    const [tyreLifeHistory, setTyreLifeHistory] = useState<HistoryLog[]>([]);
    const { t: translate } = useTranslation();
    const [valueIndex, setValueIndex] = useState<number>(PAGE_COUNT);
    const [state, dispatch] = useReducer(AlertsByLevelReducer, inicialStateAlertList);

    const AddFilterAlertByLevel = (level: Filterlevel) => {
        let filterAlertByLevel = state.level;
        if (filterAlertByLevel) {
            const levelUnique = filterAlertByLevel.filter((Tlevel) => Tlevel === level);
            if (!levelUnique.length) {
                filterAlertByLevel.push(level);
            }
        } else {
            filterAlertByLevel = [level];
        }

        dispatch({
            type: AlertActions.SET_ALERT_FILTER,
            payload: filterAlertByLevel
        });
    };

    const RemoveFilterAlertByLevel = (level: Filterlevel) => {
        let filterAlertByLevel = state.level;

        if (filterAlertByLevel) {
            filterAlertByLevel = filterAlertByLevel.filter((Tlevel) => Tlevel !== level);
            filterAlertByLevel = !filterAlertByLevel.length ? null : filterAlertByLevel;
        }

        dispatch({
            type: AlertActions.SET_ALERT_FILTER,
            payload: filterAlertByLevel
        });
    };

    const { isLoading, refetch } = useQuery(
        [TyreQueryKeys.getTyreLifeHistory, tyreId, state.level ?? 'No-Level'],
        () => apiTyre.getTyreLifeHistory(tyreId, state.level ?? []),
        {
            enabled: false,
            retry: false,
            refetchOnWindowFocus: false,
            refetchInterval: false,
            staleTime: 0,
            cacheTime: 0,
            onSuccess: (result) => {
                setTyreLifeHistory(result.tyreLifeHistory.tyreLifeHistory);
                result.tyreLifeHistory.tyreLifeHistory.length < PAGE_COUNT
                    ? setValueIndex(result.tyreLifeHistory.tyreLifeHistory.length)
                    : setValueIndex(PAGE_COUNT);
            },
            onError: () => setTyreLifeHistory([])
        }
    );

    const handleScroll = (event) => {
        const listboxNode = event.currentTarget;

        const position = listboxNode.scrollTop + listboxNode.clientHeight;
        if (listboxNode.scrollHeight - position <= 1) {
            tyreLifeHistory.length >= valueIndex + PAGE_COUNT
                ? setValueIndex(valueIndex + PAGE_COUNT)
                : setValueIndex(tyreLifeHistory.length);
        }
    };

    const timeLineCell = (key, date, description, hasConnetor, type, additionalData?): JSX.Element => {
        return (
            <ListTimeLineItem
                key={key}
                data-testid={`LifespanReportHistory-timeLineCell-${key}-${snakeToCamel(description)}`}
            >
                <TimelineOppositeContent
                    color='textSecondary'
                    data-testid='LifespanReportHistory-TimelineOppositeContent'
                >
                    {fromUTCtoUserTimezone({
                        date: date,
                        format: type == TypeInfoEnum.REACHED_DISTANCE || TypeInfoEnum.MDD_BES ? 'date' : 'dateTime'
                    })}
                </TimelineOppositeContent>
                <TimelineSeparator data-testid='LifespanReportHistory-TimelineSeparator'>
                    <CustomTimelineDot
                        level={
                            type == TypeInfoEnum.ALERT
                                ? additionalData?.notificationTyre?.notification?.level ?? undefined
                                : undefined
                        }
                    />
                    {hasConnetor && <TimelineConnector data-testid='LifespanReportHistory-TimelineConnector' />}
                </TimelineSeparator>
                <TimelineContent>
                    <div>
                        {`${translate(`t.${description}`)} 
             ${
                 type == TypeInfoEnum.ALERT
                     ? `${
                           additionalData?.notificationTyre?.notification?.level
                               ? `${translate('t.level')} ${
                                     additionalData?.notificationTyre?.notification?.level ?? '-'
                                 }`
                               : ''
                       } ${translate(
                           `t.${
                               additionalData?.notificationTyre && additionalData?.notificationTyre.solved
                                   ? 'solved'
                                   : 'unresolved'
                           }`
                       )}`
                     : ''
             }
            ${type == TypeInfoEnum.MOVEMENT ? `: ${translate(`t.${additionalData?.movement?.action ?? '-'}`)}` : ''}
            ${
                type == TypeInfoEnum.ALERT
                    ? ` - ${translate(`t.vehicle`)} ${
                          additionalData?.notificationTyre?.vehicle?.name ?? '-'
                      } ${translate(`t.pos`)} ${additionalData?.notificationTyre?.position ?? '-'}`
                    : ''
            }
            ${
                type == TypeInfoEnum.MOVEMENT && additionalData.movement.wheel
                    ? ` - ${translate(`t.vehicle`)} ${
                          additionalData?.movement?.wheel?.vehicle?.name ?? '-'
                      } ${translate(`t.pos`)} ${additionalData?.movement?.wheel?.customPosition ?? '-'}`
                    : ''
            }
             `}
                    </div>
                    {type == TypeInfoEnum.MOVEMENT && (
                        <VehicleSchemaDiv>
                            {additionalData && additionalData.movement.wheel && date && (
                                <VehicleSchemaContainer
                                    idVehicle={date}
                                    additionalData={additionalData}
                                    data-testid='LifespanReportHistory-VehicleSchemaContainer'
                                />
                            )}
                        </VehicleSchemaDiv>
                    )}
                    {type == TypeInfoEnum.REACHED_DISTANCE && (
                        <>
                            {`
                ${fromServerToUserUnit({
                    type: convertType.distance,
                    value: Math.floor(additionalData?.odometer / 1000),
                    displayUnits: true,
                    fixed: 0
                })}`}
                        </>
                    )}
                    {type == TypeInfoEnum.MDD_BES && <>{additionalData?.severityMeasurePast1Month}</>}
                </TimelineContent>
            </ListTimeLineItem>
        );
    };

    useEffect(() => {
        !isNaN(tyreId) ? refetch() : setTyreLifeHistory([]);
    }, [tyreId, state]);

    return (
        <LifespanReportHistoryContent
            data-testid='LifespanReportHistory-testid'
            level={state.level ?? []}
            timeLineCell={timeLineCell}
            handleScroll={handleScroll}
            AddFilterAlertByLevel={AddFilterAlertByLevel}
            RemoveFilterAlertByLevel={RemoveFilterAlertByLevel}
            tyreLifeHistory={tyreLifeHistory}
            valueIndex={valueIndex}
            isLoading={!isNaN(tyreId) ? isLoading : false}
        />
    );
};

export default Wrapper(LifespanReportHistory);
