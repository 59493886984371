import React, { useCallback, useEffect } from 'react';
import ModalButton from 'components/Ui/Components/UiButton/UiButton';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import SettingsIcon from '@mui/icons-material/Settings';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { DeviceBatchModel } from 'models/DeviceBatch.type';
import Menu from 'components/Ui/Components/UiMenu/UiMenu';
import { useMutation } from '@tanstack/react-query';
import { Text } from './BatchesTable.style';
import DeviceUpdateBatch from 'api/DeviceUpdateBatch';
import { Success } from 'components/Popup/Popup';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { BatchesTableProps } from './BatchesTable.type';
import { BatchesTableContent } from './BatchesTable.view';
import { faExclamationCircle, faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import JobsTable from '../JobsTable/JobsTable';
import useModal from 'components/CustomHooks/ModalHook/ModalHook';
import { Column } from 'components/Ui/UiTable/UiTable.type';
import { Wrapper } from 'helpers/wrapper';
import UiDatePickerFilter from 'components/Ui/Components/UiDatePickerFilter/UiDatePickerFilter';
import UiLink from 'components/Ui/Components/UiLink/UiLink';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { useMediaQuery } from '@mui/material';

const deviceUpdateBatchApi = new DeviceUpdateBatch();

const BatchesTable: React.FC<BatchesTableProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const { setModalState, updateModalState, modalState } = useModal(0);
    const queryTableFn = useRecoilValue(QueryFn(`batchesHub-QueryFn`));
    const { fromUTCtoUserTimezone } = useConverter();
    const isLg = useMediaQuery('(max-width: 1445px)');

    const updateModal = (isOpen?: boolean): void => {
        updateModalState({
            ...modalState,
            buttons: <ModalButton skin='success'>{<CircularProgress size='1.75em' />}</ModalButton>,
            isOpen: typeof isOpen === 'undefined' ? modalState.isOpen : isOpen
        });
    };

    const { mutate: cancelBatchMutate, isLoading: unassignAdministrationHubLoading } = useMutation(
        deviceUpdateBatchApi.cancelBatch,
        {
            onSuccess: () => {
                Success({
                    text: translate('t.batch_successfully_removed')
                });
                queryTableFn.refetch instanceof Function && queryTableFn.refetch();
            },
            onSettled: () => {
                setModalState({});
            }
        }
    );

    const handleModalRemove = (id: number): void => {
        const contentModalDelete = {
            id: 'RemoveBatchHubActions',
            leftTitle: `${translate('t.cancel_batch')}`,
            content: (
                <Text style={{ margin: '20px 0 0 0 ' }} variant='h6'>
                    {translate('p.are_you_sure_to_cancel_batch')}
                </Text>
            ),
            buttons: (
                <ModalButton
                    skin='success'
                    color='error'
                    variant='contained'
                    onClick={() => {
                        cancelBatchMutate(id);
                    }}
                >
                    {unassignAdministrationHubLoading ? <CircularProgress size='1.75em' /> : translate('t.cancel')}
                </ModalButton>
            ),
            width: 400,
            height: 150,
            customCancelTitle: translate('t.close'),
            isOpen: true
        };

        setModalState({ ...contentModalDelete });
    };

    const jobsContent = useCallback(
        (id) => {
            const contentModalJob = {
                content: <JobsTable batchId={id} />,
                leftTitle: translate('t.jobs'),
                rightTitle: `${translate('t.batch_id')} ${id}`,
                width: isLg ? 98 : 1930,
                widthUnit: isLg ? 'vw' : 'px',
                heightUnit: isLg ? 'vh' : 'px',
                height: isLg ? 80 : 710,
                buttons: <></>,
                customCancelTitle: translate('t.close'),
                isOpen: true
            };
            setModalState({ ...contentModalJob });
        },
        [isLg]
    );

    const columns: Column<DeviceBatchModel>[] = [
        {
            Header: translate('t.actions'),
            width: 88,
            height: 20,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props) => (
                <span>
                    <Menu
                        items={[
                            {
                                title: translate('t.view_jobs'),
                                value: props.value,
                                action: () => {
                                    jobsContent(props.row.original.id);
                                }
                            },

                            {
                                title: translate('t.cancel_batch'),
                                value: props.value,
                                action: () => {
                                    handleModalRemove(props.row.original.id);
                                }
                            }
                        ]}
                        rootEl={
                            <Button
                                aria-controls='simple-menu'
                                aria-haspopup='true'
                                style={{ minWidth: '20px', maxWidth: '20px' }}
                                title={translate('t.actions')}
                            >
                                <SettingsIcon />
                            </Button>
                        }
                    />
                </span>
            )
        },
        {
            Header: 'ID',
            accessor: 'id',
            disableFilters: true,
            disableSortBy: true,
            width: 60,
            height: 20,
            Cell: (props: { row: { original: DeviceBatchModel } }) => (
                <UiLink
                    $padding='0'
                    content={`${props.row.original.id ?? '-'}`}
                    onClick={() => jobsContent(props.row.original.id)}
                />
            )
        },
        {
            Header: translate('t.created_at'),
            accessor: 'createdAt',
            disableFilters: false,
            disableSortBy: true,
            width: 120,
            height: 20,
            Cell: (props: { row: { original: DeviceBatchModel } }) => (
                <>
                    {fromUTCtoUserTimezone({
                        date: props.row.original.createdAt,
                        format: 'dateTime',
                        displaySeconds: true
                    }) ?? '-'}
                </>
            ),
            Filter: ({ setFilter, state, ...props }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='CreatedAtFrom'
                            keyFilter='createdAtFrom'
                            label={`${translate('t.created_at_from')}`}
                        />
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='CreatedAtTo'
                            keyFilter='createdAtTo'
                            label={`${translate('t.created_at_to')}`}
                        />
                    </LocalizationProvider>
                );
            }
        },
        {
            Header: translate('t.finished_at'),
            accessor: 'finishedAt',
            disableFilters: false,
            disableSortBy: true,
            width: 120,
            height: 20,
            Cell: (props: { row: { original: DeviceBatchModel } }) =>
                props.row.original.finishedAt != null ? (
                    <>
                        {fromUTCtoUserTimezone({
                            date: props.row.original.finishedAt,
                            format: 'dateTime',
                            displaySeconds: true
                        })}
                    </>
                ) : (
                    <>-</>
                ),
            Filter: ({ setFilter, state, ...props }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='FinishedAtFrom'
                            keyFilter='finishedAtFrom'
                            label={`${translate('t.finished_at')} ${translate('t.from')}`}
                        />
                        <UiDatePickerFilter
                            applyFiltersFlag={props.applyFiltersFlag}
                            state={state}
                            setFilter={setFilter}
                            testId='FinishedAtTo'
                            keyFilter='finishedAtTo'
                            label={`${translate('t.finished_at')} ${translate('t.to')}`}
                        />
                    </LocalizationProvider>
                );
            }
        },

        {
            Header: translate('t.finished_jobs'),
            accessor: 'finishedJobs',
            disableFilters: true,
            disableSortBy: true,
            width: 180,
            height: 20,
            Cell: (props: { row: { original: DeviceBatchModel } }) => <>{props.row.original.finishedJobs ?? '-'}</>
        },

        {
            Header: translate('t.unfinished_jobs'),
            accessor: 'unfinishedJobs',
            disableFilters: true,
            disableSortBy: true,
            width: 180,
            height: 20,
            Cell: (props: { row: { original: DeviceBatchModel } }) => <>{props.row.original.unfinishedJobs ?? '-'}</>
        },
        {
            Header: translate('t.error_jobs'),
            accessor: 'errorJobs',
            disableFilters: true,
            disableSortBy: true,
            width: 180,
            height: 20,
            Cell: (props: { row: { original: DeviceBatchModel } }) => <>{props.row.original.errorJobs ?? '-'}</>
        },
        {
            Header: translate('t.state'),
            accessor: 'state',
            disableFilters: true,
            disableSortBy: true,
            width: 180,
            height: 20,
            Cell: (props: { row: { original: DeviceBatchModel } }) =>
                props.row.original.finishedAt == null ? (
                    <CircularProgress data-testid={'BatchesTable-CircularProgress-testid'} size='1em' />
                ) : props.row.original.errorJobs > 0 ? (
                    <UiIcon icon={faExclamationCircle} color='red' />
                ) : (
                    <UiIcon icon={faCheckCircle} color='green' />
                )
        }
    ];

    useEffect(() => {
        updateModal();
    }, [unassignAdministrationHubLoading]);

    return <BatchesTableContent data-testid='BatchesTable-testid' columns={columns} />;
};

export default Wrapper(BatchesTable);
