import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { SystemIsRestrictedQueryType, SystemLogoQueryType } from './SystemInfo.type';
import { useSetRecoilState } from 'recoil';
import { ThemeMode } from 'states/global/Theme';
import { BridgestoneLogoQueryKeys } from 'models/BridgestoneLogo.type';
import BridgestoneLogo from 'api/BridgestoneLogo';
import { SystemInfoState } from 'states/global/Portal';
import Restricted from 'api/Restricted';
import { RestrictedModel, RestrictedQueryKeys } from 'models/Restricted.type';
import { getSystemName, setToLocalStorage } from 'helpers';

const bridgestoneLogoApi = new BridgestoneLogo();
const restrictedApi = new Restricted();

export const SystemLogoQuery: React.FC<SystemLogoQueryType> = ({ theme }): JSX.Element => {
    const setSystemInfoState = useSetRecoilState(SystemInfoState);
    const getTheme = theme?.mode === ThemeMode.dark ? '0' : '1';
    useQuery<string, Error>(
        [BridgestoneLogoQueryKeys.get, 'Get', [getTheme]],
        () => bridgestoneLogoApi.getLogo(getTheme),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            retry: 1,
            onSuccess: (deviceDataOnSuccess) =>
                setSystemInfoState((prevState) => ({
                    ...prevState,
                    loading: false,
                    logo: {
                        src: `data:image/png;base64,${deviceDataOnSuccess}`,
                        alt: `${getSystemName()} Logo Icon`
                    }
                }))
        }
    );
    return <></>;
};

export const SystemIsRestrictedQuery: React.FC<SystemIsRestrictedQueryType> = (): JSX.Element => {
    const setSystemInfoState = useSetRecoilState(SystemInfoState);
    useQuery<RestrictedModel, Error>(
        [RestrictedQueryKeys.get, 'Get'],
        () => restrictedApi.get({ apiProject: 'public' }),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            retry: 1,
            onSuccess: (isRestricted) => {
                const systemName: string = isRestricted?.isRestricted ? 'Bridgestone TPMS' : 'Bridgestone iTrack';
                document.title = `${systemName} - Mining TPMS 24/7`;
                setToLocalStorage('systemName', systemName);

                setSystemInfoState((prevState) => ({
                    ...prevState,
                    loading: false,
                    name: systemName
                }));
            }
        }
    );
    return <></>;
};
