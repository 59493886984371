import { Box, Drawer, Theme, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import styled, { CSSObject } from 'styled-components';
import { Menu } from 'react-pro-sidebar';
import { ThemeMode, ThemeType } from 'states/global/Theme';
import { COLOR_GREY, WHITE, DARK_SLATE_GREY, LEAD_GREY, BRIGHT_SKY_BLUE } from '../Ui/colors';
import { FONT_BIG_TITLE_SIZE, SIZE_TEXT_VARIANT, SIZE_TITLE } from '../Ui/variables';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material';

export const MenuItem = styled(ListItem)<{ $active?: boolean; $itemPopover?: boolean }>`
    &.MuiListItem-root {
        padding-left: 25px !important;
        margin-top: ${({ $itemPopover }) => ($itemPopover ? '0px' : '2px')}!important;
        border: transparent solid thin;
        font-weight: bold !important;
        & .MuiTypography-body1 {
            font-weight: bold !important;
        }
        & .MuiListItemIcon-root {
            min-width: 30px;
            margin-right: 5px;
        }

        ${({ $active }) => {
            const theme = useTheme();
            return (
                $active &&
                `
            color: ${
                theme.palette.mode === ThemeMode.light ? theme.palette.background.paper : theme.palette.text.primary
            };
            background: ${theme.palette.primary.main};
            & .MuiListItemIcon-root {
                color: ${
                    theme.palette.mode === ThemeMode.light ? theme.palette.background.paper : theme.palette.text.primary
                };
            }
        `
            );
        }}

        &:hover {
            ${() => {
                const theme = useTheme();
                return `
                    color: ${
                        theme.palette.mode === ThemeMode.light
                            ? useTheme().palette.background.paper
                            : useTheme().palette.text.primary
                    };
                    background: ${theme.palette.primary.main};
                    & .MuiListItemIcon-root {
                        color: ${
                            theme.palette.mode === ThemeMode.light
                                ? theme.palette.background.paper
                                : theme.palette.text.primary
                        };
                    }
                `;
            }}
            transition: 700ms;
        }
    }
`;

export const ListCover = styled.div`
    a {
        color: ${() => useTheme().palette.text.primary};
        text-decoration: none;
    }
`;

export const Sublist = styled(List)<{ section?: number }>`
    background: ${() => useTheme().palette.action.selected};

    & .MuiListItem-root {
        padding-left: ${(props) => (props.section ? props.section * 25 : 25)}px;
    }
`;

export const AvatarContainer = styled.div<{ $collapsedSidebar?: boolean }>`
    display: inline-block;
    vertical-align: middle;
    margin-right: ${({ $collapsedSidebar }) => ($collapsedSidebar ? '0px' : '10px')};
    margin-bottom: ${({ $collapsedSidebar }) => ($collapsedSidebar ? '5px' : '0px')};

    & .MuiAvatar-root {
        width: 34px;
        height: 34px;
    }
`;

export const UserInfoContainer = styled.div<{ $collapsedSidebar?: boolean }>`
    display: inline-block;
    vertical-align: middle;

    ${({ $collapsedSidebar }) => {
        if ($collapsedSidebar) {
            return `
                display: none;
                & .MuiTypography-body1 {
                    font-size: ${SIZE_TEXT_VARIANT(-2)} !important;
                }
            `;
        }
    }}
`;

export const UserPrimaryInfo = styled(Typography)`
    font-weight: bold !important;
`;

export const UserSecondaryInfo = styled(Typography)`
    font-size: 0.6rem !important;
`;

export const ProfileInfo = styled.div<{ $collapsedSidebar?: boolean }>`
    ${({ $collapsedSidebar }) => {
        const theme = useTheme();
        return `
            background: ${theme.palette.background.paper};
            padding: ${$collapsedSidebar ? '10px 0px' : '10px 20px'};
            text-align: ${$collapsedSidebar ? 'center' : 'left'};
            border-top: solid thin ${theme.palette.divider};
            a {
                color ${theme.palette.text.primary};
            }
        `;
    }}
    p {
        margin: 0;
    }
`;

export const HelperActionsSection = styled.div<{ $collapsedSidebar?: boolean }>`
    ${() => {
        const theme = useTheme();
        return `
            background: ${theme.palette.background.paper};
            border-top: solid thin ${theme.palette.divider};
        `;
    }}
    margin-top: 5%;
    p {
        margin: 0;
    }
`;

export const MainMenuContentDrawer = styled(Drawer)`
    .MuiPaper-root {
        width: 260px;
        background: ${() => useTheme().palette.background.paper};
    }

    .MuiList-root {
        padding-bottom: 0px !important;
    }
`;

export const LogoContainer = styled.div`
    width: 100%;
    & img {
        max-width: 205px;
        max-height: 50px;
        margin: auto;
    }
`;

export const SidebarCustomProperties = (easyMode: boolean) => ({
    border: 'none',
    marginTop: easyMode ? '64px' : '49px',
    height: `calc(100% - ${easyMode ? '64px' : '49px'})`,
    transition: '300ms',
    '&::-webkit-scrollbar ': {
        width: '1px !important'
    }
});

export const MenuCustomProperties = (theme: Theme, collapsedSidebar: boolean, themeState: ThemeType): CSSObject => ({
    '& svg': {
        fontSize: themeState.easyReadingMode ? SIZE_TEXT_VARIANT(6) : SIZE_TEXT_VARIANT(3)
    },
    color: theme.palette.text.primary,
    fontWeight: 'bold !important',
    fontSize: SIZE_TITLE,
    '& .ps-menu-label': { fontWeight: '600 !important' },
    '.ps-menuitem-root': {
        'a:hover': {
            backgroundColor: theme.palette.mode === ThemeMode.light ? '#e1e1e1' : '#33404e',
            color: theme.palette.primary.main
        },
        '& .ps-submenu-expand-icon': {
            top: '60%',
            pointerEvents: 'none',
            right: collapsedSidebar ? '2px' : undefined
        }
    }
});

export const MenuItemCustomProperties = (
    level: number,
    active: boolean,
    theme: Theme,
    collapsedSidebar: boolean,
    themeState: ThemeType
) => {
    let backgroundColor: string | undefined = undefined;
    let fontColor: string = active ? WHITE : theme.palette.text.primary;
    let paddingLeft = collapsedSidebar ? '13px' : '13px';
    const boxShadowMenu = theme.palette.mode === ThemeMode.light ? '0px 2px 9px #00000057' : '0px 2px 9px #696969d9';

    switch (level) {
        case 1:
            backgroundColor = theme.palette.mode === ThemeMode.light ? '#ededed' : theme.palette.background.default;
            break;
        case 2:
            backgroundColor = theme.palette.mode === ThemeMode.light ? '#e1e1e1' : '#060a0e';
            paddingLeft = '25px';
            break;
    }

    if ((active && !collapsedSidebar && level == 0) || (active && collapsedSidebar && level == 0)) {
        backgroundColor = theme.palette.mode === ThemeMode.light ? DARK_SLATE_GREY : theme.palette.primary.main;
    }

    if (active && level != 0) {
        fontColor = theme.palette.mode === ThemeMode.light ? LEAD_GREY : BRIGHT_SKY_BLUE;
    }

    return {
        color: fontColor,
        backgroundColor: backgroundColor,
        fontSize: themeState.easyReadingMode ? FONT_BIG_TITLE_SIZE : SIZE_TITLE,
        height: '40px',
        paddingLeft: paddingLeft,
        '&svg': {
            color: theme.palette.text.primary
        },
        marginBottom: level === 0 ? '1.5px' : '0px',
        boxShadow: level === 0 ? (collapsedSidebar ? boxShadowMenu : undefined) : undefined
    };
};

export const SubMenuCustomProperties = (theme: Theme, items: number, hiddenIconExpand?: boolean): CSSObject => {
    let topApply = items * 20;
    return {
        fontSize: SIZE_TITLE,
        fontWeight: 'bold !important',
        color: COLOR_GREY,

        '& .ps-menu-label': { fontWeight: '600 !important' },
        '& .ps-submenu-expand-icon': hiddenIconExpand ? { display: 'none' } : {},
        ':hover': {
            cursor: 'pointer',
            '> a': {
                color: WHITE,
                background: theme.palette.primary.main,
                '& > svg': {
                    color: WHITE
                }
            },
            '.ps-submenu-content': {
                width: 'auto',
                maxWidth: 'min-content',
                backgroundColor:
                    theme.palette.mode === ThemeMode.dark
                        ? `${theme.palette.background.default} !important`
                        : undefined,
                visibility: 'visible',
                top: `${topApply}px !important`,
                left: '-5px !important',
                ':hover': {}
            }
        }
    };
};

export const SubMenuTitleCustomProperties = (theme: Theme): CSSObject => {
    return {
        ':hover': { cursor: 'default' },
        marginBottom: '5px',
        backgroundColor: `${theme.palette.background.paper} !important`,
        '& a': {
            backgroundColor: `${theme.palette.background.paper} !important`,
            boxShadow: '6px 6px 30px #0000001a'
        }
    };
};

export const ContainerButtonConfig = styled.div`
    display: -ms-inline-flexbox;
    display: flex;
    width: 100%;
    border-top: thin solid #e1e1e1;
    height: 60px;
    justify-content: center;
    align-items: center;

    & .notificationsToggleButton {
        font-size: 17px;
        padding: 0;
        align-self: center;
    }
`;

export const ListButtonsSetting = styled.div`
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 13px;
    font-weight: 600;
`;

export const ContainerMenuPro = styled.div`
    overflow: auto;
    height: auto;

    @media (width >= 1600px) {
        max-height: 70vh;
    }
`;

export const MenuSidebar = styled(Menu)`
    .linkGenerated {
        /* overflow: auto;
    height: auto;

    @media (width >= 1600px) {
        max-height: 70vh;
    } */
    }
`;

export const CustomLink = styled(Link)<{ $hasDivider?: boolean }>`
    border-top: ${(props) => (props.$hasDivider ? `thin solid ${useTheme().palette.divider}` : 'none')};
`;

export const MenuBox = styled(Box)`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
`;
