import React from 'react';
import { BarGroupIcon, BarGroupInfo } from '../TopBar.style';
import { Schedule } from '@mui/icons-material';
import UiLegend from '../../Ui/Components/UiLegend/UiLegend';
import { useTranslation } from 'react-i18next';
import { CurrentTimeFormik } from './CurrentTime.formik';
import { CurrentTimeView as CurrentTimeViewType } from './CurrentTime.type';
import { ButtonOpenPopUp } from './CurrentTime.style';
import { Tooltip } from '@mui/material';

const CurrentTimeView: React.FC<CurrentTimeViewType> = ({
    closePopUp,
    handleSubmit,
    inicialValuesFormik,
    timeZoneData,
    datetime
}): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <UiLegend
            title={translate(`t.change_time_format_and_timezone`)}
            width={350}
            mode='light'
            close={closePopUp}
            content={
                <CurrentTimeFormik
                    handleSubmit={handleSubmit}
                    inicialValuesFormik={inicialValuesFormik}
                    timeZoneData={timeZoneData}
                />
            }
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            root={
                <ButtonOpenPopUp color='inherit' variant='text' testid='EditTimeFormat-Button'>
                    <Tooltip
                        title={translate('t.click_to_change_time_format_and_timezone')}
                        placement='bottom'
                        enterDelay={1000}
                        leaveDelay={0}
                        disableFocusListener
                        arrow
                    >
                        <BarGroupInfo>
                            <BarGroupIcon>
                                <Schedule />
                            </BarGroupIcon>
                            {datetime}
                        </BarGroupInfo>
                    </Tooltip>
                </ButtonOpenPopUp>
            }
        />
    );
};
export default CurrentTimeView;
