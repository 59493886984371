import { createTheme as createMuiTheme } from '@mui/material/styles';

import {
    BACKGROUND_BLUE,
    DARK_STEEL_GREY,
    BACKGROUND_TABLE,
    COLOR_GREY,
    ERROR,
    LINK,
    PRIMARY_DARK,
    SECONDARY,
    PRIMARY
} from './colors';
import customProperties, { customScroll } from './CustomProperties';
import { EasyReadingModeOverride } from './EasyReadingMode';
import {
    SIZE_TEXT,
    SIZE_TITLE,
    SIZE_TEXT_VARIANT,
    FONT_BIG_TEXT_SIZE,
    FONT_BIG_TITLE_SIZE,
    FONT_BIG_SUBTITLE_SIZE,
    SIZE_SUBTITLE
} from './variables';

export type CommonTypeTheme = {
    black: string;
    white: string;
    easyMode: boolean;
    bigFonts: boolean;
    textSize: string;
    titleSize: string;
    subTitleSize: string;
};

const createTheme = (EasyReadingMode: boolean, bigFonts: boolean) => {
    const applyEasyReadingMode = EasyReadingMode ? EasyReadingModeOverride : {};
    const getTextSizeEnv = SIZE_TEXT;
    const getTextSize = EasyReadingMode ? FONT_BIG_TEXT_SIZE : getTextSizeEnv;
    const getTitleSize = EasyReadingMode ? FONT_BIG_TITLE_SIZE : SIZE_TITLE;
    const getSubTitleSize = EasyReadingMode ? FONT_BIG_SUBTITLE_SIZE : SIZE_SUBTITLE;

    return createMuiTheme({
        palette: {
            common: {
                easyMode: EasyReadingMode,
                bigFonts: bigFonts,
                textSize: getTextSize,
                titleSize: getTitleSize,
                subTitleSize: getSubTitleSize
            } as CommonTypeTheme,
            text: {
                primary: COLOR_GREY
            },
            primary: {
                main: DARK_STEEL_GREY
            },
            secondary: {
                main: SECONDARY
            },
            error: {
                main: ERROR
            },
            info: {
                main: LINK
            },
            success: {
                main: BACKGROUND_BLUE
            },
            grey: {
                500: COLOR_GREY
            },
            background: {
                default: '#f6f6f6'
            }
        },
        components: {
            ...customProperties(getTextSize /*, getTitleSize*/),
            MuiCssBaseline: {
                styleOverrides: {
                    html: {
                        '& .UI-scroll-body': customScroll('light', '9px')
                    },
                    body: {
                        '& .Ui-scroll': customScroll('light'),
                        '& .MuiAutocomplete-popper': customScroll('light')
                    },
                    '*': {
                        fontWeight: '500'
                    },
                    '& .MuiTypography-root': {
                        fontWeight: '500'
                    },
                    '& .logoCustomerRedirection': {
                        width: '445px',
                        height: '80px'
                    },
                    '& .loadingSystemName': {
                        color: 'black',
                        animation: 'loadingSystemName-effect 0.8s linear infinite',
                        '@keyframes loadingSystemName-effect': {
                            '0%': { color: 'black' },
                            '16.67%': { color: 'gray' },
                            '33.3%': { color: 'gray' },
                            '50%': { color: 'gray' },
                            '66.6%': { color: 'gray' },
                            '73.3%': { color: 'gray' },
                            '83.3%': { color: 'gray' },
                            '96.9%': { color: 'black' },
                            '100%': { color: 'black' }
                        }
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: customScroll('light')
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '& $notchedOutline': {
                            borderColor: '#5a5a5a'
                        },
                        '&$focused $notchedOutline': {
                            borderWidth: '2px'
                        },
                        '&:hover $notchedOutline': {
                            borderColor: PRIMARY_DARK
                        },
                        '&& fieldset': {
                            fontSize: getTitleSize
                        },
                        '& .MuiIconButton-root': {
                            fontSize: SIZE_TEXT_VARIANT(6)
                        },
                        '& .MuiOutlinedInput-input:-webkit-autofill': {
                            WebkitBoxShadow: '0 0 0 100px rgb(233 233 233 / 0%) inset',
                            WebkitTextFillColor: '#0b9444'
                        }
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        '& ': {
                            fontSize: getTitleSize,
                            lineHeight: getTitleSize,
                            fontWeight: '500',

                            '&.MuiInputLabel-shrink': {
                                transform: 'translate(12px, -9px) scale(0.78)',
                                lineHeight: `${SIZE_TEXT_VARIANT(13)} !important`,
                                padding: '0 5px 0 0px'
                            }
                        }
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        '& ': {
                            fontSize: getTitleSize,
                            lineHeight: getTitleSize,
                            fontWeight: '500',
                            '&.MuiInputLabel-shrink': {
                                lineHeight: SIZE_TEXT_VARIANT(12)
                            }
                        }
                    }
                }
            },
            MuiCardHeader: {
                styleOverrides: {
                    root: {
                        '& .MuiCardHeader-title': {
                            color: DARK_STEEL_GREY,
                            fontWeight: 800
                        },
                        '& .MuiCardHeader-avatar': {
                            marginRight: '7px'
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: EasyReadingMode ? '1.4em' : '1.2em'
                        }
                    }
                }
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        borderBottom: '1px solid #c5c5c5',
                        padding: '14px 0px',
                        color: DARK_STEEL_GREY,
                        fontWeight: 800
                    }
                }
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        '& .MuiTableRow-root': {
                            backgroundColor: BACKGROUND_TABLE,
                            fontSize: getTextSize,
                            border: 'none',
                            alignItems: 'unset'
                        },
                        '& .MuiTableCell-root': {
                            fontWeight: '600'
                        },
                        '& .MuiTableSortLabel-root': {
                            fontWeight: '600'
                        }
                    }
                }
            },
            MuiTableBody: {
                styleOverrides: {
                    root: {
                        '& .MuiTableCell-root': {
                            fontWeight: '500'
                        }
                    }
                }
            },
            MuiAlert: {
                styleOverrides: {
                    filledInfo: {
                        backgroundColor: 'rgb(229, 246, 253)',
                        color: 'rgb(1, 67, 97)'
                    },
                    root: {
                        '& .MuiAlert-action': {
                            padding: '0px 0 0 16px'
                        }
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        '& ': {
                            textTransform: 'capitalize'
                        }
                    },
                    containedSizeSmall: {
                        padding: '5.4px 10px'
                    },
                    outlinedSizeSmall: {
                        padding: '4.4px 10px'
                    },
                    textSecondary: {
                        color: PRIMARY
                    },
                    outlinedSecondary: {
                        color: PRIMARY
                    }
                }
            },
            ...applyEasyReadingMode
        },

        typography: {
            fontFamily: 'Roboto, Arial, sans-serif',
            fontWeightBold: 500,
            fontWeightMedium: 500,
            h1: {
                fontSize: '1.65rem',
                lineHeight: 1.2
            },
            h2: {
                fontSize: '1.5rem',
                lineHeight: 1.334
            },
            h3: {
                fontSize: '1.35rem',
                lineHeight: 1.6
            },
            h4: {
                fontSize: '1.20rem',
                lineHeight: 1.4
            },
            h5: {
                fontSize: getTitleSize,
                lineHeight: 1.3
            },
            h6: {
                fontSize: '1.01rem',
                lineHeight: 1.1
            },
            body1: {
                fontSize: getTextSize,
                lineHeight: 1.43
            },
            body2: {
                fontSize: getTextSize,
                lineHeight: 1.43
            },
            subtitle1: {
                fontSize: getTextSize,
                lineHeight: 1.43,
                fontWeight: 600
            }
        }
    });
};

export const AlertFontSize = '13px';

export default createTheme;
