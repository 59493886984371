import React from 'react';
import { NotificationSettingsPropsView } from './NotificationSettings.type';
import { NotificationSettingsContainer } from './NotificationSettings.style';
import { useRecoilValue } from 'recoil';
import UiWidget from 'components/Ui/Components/UiWidget';
import UiTable from 'components/Ui/UiTable/UiTable';
import { useTranslation } from 'react-i18next';
import Legend from 'components/Ui/Components/UiLegend';
import LegendContentTablet from 'components/Ui/Components/UiLegendContentTablet/UiLegendContentTablet';
import Notification from 'api/Notification';
import NotificationAssignation from 'api/NotificationAssignation';
import { applyStyleByMode } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';

const notificationApi = new Notification();
const ApiNotificationAssignation = new NotificationAssignation();

export const NotificationSettingsContent: React.FC<NotificationSettingsPropsView> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    return (
        <NotificationSettingsContainer data-testid='NotificationSettingsContent'>
            <UiWidget
                data-testid='NotificationSettingsContent'
                loading={false}
                title={translate('t.notification_settings')}
                headerAction={
                    <Legend
                        title={translate('t.legend')}
                        content={
                            <LegendContentTablet
                                items={[
                                    {
                                        icon: 'laptop',
                                        color: 'rgb(113, 113, 113)',
                                        title: translate('t.portal')
                                    },
                                    {
                                        icon: 'envelope',
                                        color: 'rgb(113, 113, 113)',
                                        title: translate('t.email')
                                    },
                                    {
                                        icon: 'mobile',
                                        color: 'rgb(113, 113, 113)',
                                        title: 'Whatsapp'
                                    },
                                    {
                                        icon: 'message-sms',
                                        color: 'rgb(113, 113, 113)',
                                        title: 'SMS'
                                    }
                                ]}
                            />
                        }
                    />
                }
                content={
                    <UiTable
                        columns={props.columns}
                        fetchFn={notificationApi.getNotification}
                        exportFn={ApiNotificationAssignation.getAllExport}
                        exportName='Notification Settings'
                        onlyCSVExport
                        hiddenColumns={[]}
                        defaultSortBy={{
                            id: 'type',
                            desc: false
                        }}
                        refresh={false}
                        queryKey={'notificationSetting'}
                    />
                }
                avatar={
                    <UiIcon
                        icon={['fas', 'bullhorn']}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
            ></UiWidget>
        </NotificationSettingsContainer>
    );
};
