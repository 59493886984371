import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material';
import { UiHighPressureIcon, UiLowPressureIcon } from '../VehicleStatsRow/VehicleStatsRow.style';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const VehicleStatsLegendContainer = styled(Box)``;

export const CustomizedUiLowPressureIcon = styled(UiLowPressureIcon)`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        const sizes = common.easyMode ? '25px' : '17px';
        return `width: ${sizes} !important;
        height: ${sizes} !important;`;
    }}
`;

export const CustomizedUiHighPressureIcon = styled(UiHighPressureIcon)`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        const sizes = common.easyMode ? '25px' : '17px';
        return `width: ${sizes} !important;
    height: ${sizes} !important;`;
    }}
`;

export const PressueIconCover = styled(Box)`
    padding-right: 7px !important;

    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        const marginLeft = common.easyMode ? '-10px' : '-2px';
        return `margin-left: ${marginLeft} !important;`;
    }}
`;

export const TemperatureGenericDisabled = styled(Box)`
    padding-right: 9px !important;
    margin-left: -5px !important;
`;

export const OldTemperatureIcon = styled('span')`
    width: 47px;
    margin-left: -13px;
    margin-top: 3px;
`;
