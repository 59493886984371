import React from 'react';
import * as view from './VehicleTypesTable.view';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ShowVehicleTypesAddModal } from 'states/global/Modal';
import { VehicleTypeAddState } from 'states/component/VehicleType';
import Menu from '../../Ui/Components/UiMenu/UiMenu';
import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import { BACKGROUND_BLUE } from '../../Ui/colors';
import { useTranslation } from 'react-i18next';
import useConverter from '../../CustomHooks/Converter/Converter';
import { Theme, ThemeMode } from 'states/global/Theme';
import { getImgSource } from 'helpers/image';
import { Column } from 'components/Ui/UiTable/UiTable.type';
import { VehicleGroupModel } from 'models/VehicleGroup.type';
import { VehicleModel } from 'models/Vehicle.type';
import { Wrapper } from 'helpers/wrapper';
import { useNavigate } from 'react-router-dom';

const VehicleTypesTable: React.FC = (): JSX.Element => {
    const setVehicleTypesModal = useSetRecoilState(ShowVehicleTypesAddModal);
    const { fromServerToUserUnit, displayUserUnits, convertType } = useConverter();
    const setVehicleAddType = useSetRecoilState(VehicleTypeAddState);
    const { t: translate } = useTranslation();
    const theme = useRecoilValue(Theme);
    const navigate = useNavigate();

    const createVehiclesDropDown = (
        vehicles: Pick<VehicleModel, 'id' | 'name'>[]
    ): [{ title: string; value: number; action: () => void }] => {
        let items: [{ title: string; value: number; action: () => void }] = [{ title: '', value: 0, action: () => {} }];
        vehicles?.forEach((vehicle) =>
            items.push({
                title: vehicle.name,
                value: vehicle.id,
                action: () => {
                    navigate(`${process.env.PUBLIC_URL}/vehicle-status?vehicle=${vehicle.id}`);
                }
            })
        );
        items.shift();
        return items;
    };

    const columns: Column<VehicleGroupModel>[] = [
        {
            Header: translate('t.icon'),
            accessor: 'icon',
            disableFilters: true,
            disableSortBy: true,
            Cell: (props) => props.value && <img width={35} height={35} alt='img' src={getImgSource(props.value)} />
        },
        {
            Header: translate('t.group'),
            accessor: 'name',
            Cell: (props) => {
                return <>{props.value}</>;
            }
        },
        {
            Header: `${translate('t.max_speed')} (${displayUserUnits.speed})`,
            accessor: 'maxSpeed',
            disableFilters: true,
            Cell: (props) => {
                return (
                    <>
                        {fromServerToUserUnit({
                            type: convertType.speed,
                            value: props.value,
                            fixed: 1
                        })}
                    </>
                );
            }
        },
        {
            Header: translate('t.vehicles'),
            accessor: 'vehicle',
            disableFilters: true,
            disableSortBy: true,
            Cell: (props) => (
                <span data-testid='vehicles-menu'>
                    <Menu
                        items={createVehiclesDropDown(props.value)}
                        rootEl={
                            <span
                                style={{
                                    color: theme?.mode === ThemeMode.light ? BACKGROUND_BLUE : BACKGROUND_BLUE,
                                    cursor: 'pointer'
                                }}
                            >
                                {props.value.length}
                            </span>
                        }
                    />
                </span>
            )
        },
        {
            Header: translate('t.actions'),
            accessor: 'id',
            disableFilters: true,
            disableSortBy: true,
            Cell: (props) => (
                <span>
                    <Menu
                        items={[
                            {
                                title: translate('t.edit'),
                                value: props.value,
                                action: () => {
                                    setVehicleTypesModal(true);
                                    setVehicleAddType({
                                        action: 'edit',
                                        name: props.row.original.name,
                                        id: props.row.original.id,
                                        maxSpeed: props.row.original.maxSpeed || 0,
                                        icon: props.row.original.icon || '',
                                        vehicle: props.row.original.vehicle,
                                        enableTemperatureCheckbox: props.row.original.temperatureBasedMaxSpeed,
                                        maxSpeedTemperatureL1: props.row.original.maxSpeedTemperatureL1,
                                        maxSpeedTemperatureL2: props.row.original.maxSpeedTemperatureL2,
                                        maxSpeedTemperatureL3: props.row.original.maxSpeedTemperatureL3
                                    });
                                }
                            },
                            {
                                title: translate('t.remove'),
                                value: props.value,
                                action: () => {
                                    setVehicleTypesModal(true);
                                    setVehicleAddType({
                                        action: 'remove',
                                        name: props.row.original.name,
                                        id: props.row.original.id,
                                        maxSpeed: props.row.original.maxSpeed || 0,
                                        icon: props.row.original.icon || '',
                                        vehicle: props.row.original.vehicle
                                    });
                                }
                            }
                        ]}
                        rootEl={
                            <Button
                                data-testid='vehicle-group-actions'
                                aria-controls='simple-menu'
                                aria-haspopup='true'
                                style={{ minWidth: '20px', maxWidth: '20px' }}
                                title={translate('t.actions')}
                            >
                                <SettingsIcon />
                            </Button>
                        }
                    ></Menu>
                </span>
            )
        }
    ];

    return <view.VehicleTypesContent data-testid='VehicleTypes-testid' columns={columns} />;
};

export default Wrapper(VehicleTypesTable);
