import PortalApi from 'helpers/api/PortalApiClient';
import {
    DefaultVehicles,
    DefaultVehiclesWithPaginator,
    RuleModel,
    RuleModelWheel,
    RuleOverviewTable
} from '../models/Rule.type';
import { getParams } from 'helpers';

export default class Rule extends PortalApi<RuleModel> {
    route = 'rule';

    getDefault = () => {
        return this.get({ extendUrl: 'default' });
    };

    getWheel = (wheelId: number) => {
        return this.get<RuleModelWheel>({ extendUrl: `wheel/${wheelId}` });
    };

    getDefaultVehicles = (params: DefaultVehicles) => {
        return this.getTable<DefaultVehiclesWithPaginator>({ extendUrl: 'default/list-vehicles', criteria: params });
    };

    getActive = (props) => {
        return this.getTable<RuleOverviewTable>({
            criteria: getParams(props),
            extendUrl: 'active'
        });
    };
}
