import React from 'react';
import { useRecoilValue } from 'recoil';
import { ChangesPasswordPaper, ChangesPasswordViewContent } from './ChangesPassword.style';
import { ChangesPasswordViewProps, InicialvaluesForm } from './ChangesPassword.type';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { PASSWORD_REGEX } from 'variables';
import { Form, Formik, FormikProps } from 'formik';
import { Grid, TextField, Typography } from '@mui/material';
import PasswordHelperText from 'components/Mixs/HelperText/PasswordHelperText';
import HelperText from 'components/Mixs/HelperText/HelperText';
import Button from 'components/Ui/Components/UiButton';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { SystemInfoState } from 'states/global/Portal';
import { getSystemName } from 'helpers';

export const ChangesPasswordContent: React.FC<ChangesPasswordViewProps> = ({
    valuesInitForm,
    isLoading,
    updatePassword
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const { hash }: any = useParams();
    const Year = DateTime.now().year;
    const systemInfoState = useRecoilValue(SystemInfoState);

    const schemaValidateForm = Yup.object({
        password: Yup.string()
            .required()
            .min(6)
            .test('passwords-match', translate('p.current_password_cannot_be_equal_to_new_password'), function (value) {
                return this.parent.current_password !== value;
            })
            .matches(PASSWORD_REGEX),
        confirmPassword: Yup.string()
            .required(translate('p.this_field_is_required'))
            .oneOf([Yup.ref('password'), null], translate('t.passwords_must_match')),
        currentPassword: !hash
            ? Yup.string().required(translate('p.this_field_is_required'))
            : Yup.string().notRequired()
    });

    return (
        <ChangesPasswordViewContent data-testid='ChangesPasswordContent'>
            <ChangesPasswordPaper elevation={1}>
                <img src={systemInfoState.logo.src} className='logoTopbar' alt={systemInfoState.logo.alt} />
                <Typography variant='h4' align='center' marginTop={2} marginBottom={2} gutterBottom>
                    {translate('t.change_password')}
                </Typography>
                <Formik
                    initialValues={valuesInitForm}
                    onSubmit={(values: InicialvaluesForm) => {
                        updatePassword(values.password, values.currentPassword);
                    }}
                    validationSchema={schemaValidateForm}
                >
                    {(propsF: FormikProps<InicialvaluesForm>) => {
                        const { values, touched, errors, handleBlur, handleChange } = propsF;
                        return (
                            <Form>
                                <Grid container spacing={1} justifyContent='space-around' direction='row'>
                                    {!hash && (
                                        <Grid item md={12}>
                                            <TextField
                                                id='currentPassword'
                                                label={translate('t.current_password')}
                                                value={values.currentPassword}
                                                variant='outlined'
                                                size='small'
                                                name='currentPassword'
                                                type='password'
                                                fullWidth
                                                onChange={handleChange}
                                                inputProps={{ step: 0.1, min: 0 }}
                                                InputLabelProps={{ shrink: true }}
                                                onBlur={handleBlur}
                                                error={!!errors.currentPassword && !!touched.currentPassword}
                                                helperText={
                                                    !!errors.currentPassword && !!touched.currentPassword ? (
                                                        <PasswordHelperText
                                                            passwordToValidate={values.currentPassword}
                                                            error={
                                                                !!errors.currentPassword && !!touched.currentPassword
                                                            }
                                                        />
                                                    ) : (
                                                        ''
                                                    )
                                                }
                                                data-testid='Profile-CurrentPassword-Input'
                                            />
                                        </Grid>
                                    )}
                                    <Grid item md={12}>
                                        <TextField
                                            id='password'
                                            label={translate('t.new_password')}
                                            value={values.password}
                                            variant='outlined'
                                            size='small'
                                            name='password'
                                            type='password'
                                            fullWidth
                                            onChange={handleChange}
                                            inputProps={{ step: 0.1, min: 0 }}
                                            InputLabelProps={{ shrink: true }}
                                            onBlur={handleBlur}
                                            error={!!errors.password && !!touched.password}
                                            helperText={
                                                !!errors.password && !!touched.password ? (
                                                    <PasswordHelperText
                                                        passwordToValidate={values.password}
                                                        error={!!errors.password && !!touched.password}
                                                    />
                                                ) : (
                                                    ''
                                                )
                                            }
                                            data-testid='Profile-NewPassword-Input'
                                        />
                                    </Grid>

                                    <Grid item md={12}>
                                        <TextField
                                            id='confirmPassword'
                                            label={translate('t.new_password_repeat')}
                                            value={values.confirmPassword}
                                            variant='outlined'
                                            size='small'
                                            name='confirmPassword'
                                            type='password'
                                            fullWidth
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            inputProps={{ step: 0.1, min: 0 }}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors.confirmPassword && !!touched.confirmPassword}
                                            helperText={
                                                !!errors.confirmPassword && !!touched.confirmPassword ? (
                                                    <HelperText
                                                        defaultText={translate('t.required')}
                                                        text={errors.confirmPassword}
                                                        error={!!errors.confirmPassword && !!touched.confirmPassword}
                                                    />
                                                ) : (
                                                    ''
                                                )
                                            }
                                            data-testid='Profile-NewPasswordRepeat-Input'
                                        />
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Button
                                            variant='contained'
                                            testid='Send-RecoveryPassowrd-Button'
                                            type='submit'
                                            size='large'
                                            disabled={isLoading}
                                            loading={isLoading}
                                            sx={{ fontSize: '0.93rem' }}
                                        >
                                            {translate('t.send')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
                <Typography variant='body1' align='center' gutterBottom>
                    {`© ${getSystemName()}`} - {Year}
                </Typography>
            </ChangesPasswordPaper>
        </ChangesPasswordViewContent>
    );
};
