import React from 'react';
import { LinkContainer } from './VehicleItem.style';
import { VehicleItemProps } from './VehicleItem.type';
import { ListItem, ListItemText } from '@mui/material';
import UiLink from 'components/Ui/Components/UiLink/UiLink';
import { applyStyleByMode } from 'helpers';
import { useRecoilValue } from 'recoil';
import { Theme } from 'states/global/Theme';
import { COLOR_GREY, WHITE } from 'components/Ui/colors';

const VehicleItem: React.FC<VehicleItemProps> = ({ vehicle }): JSX.Element => {
    const ThemeMode = useRecoilValue(Theme);

    return (
        <ListItem
            component='a'
            key={vehicle.id}
            style={{
                background: applyStyleByMode({
                    theme: ThemeMode?.mode,
                    light: '#F2F2F2',
                    dark: '#485562'
                }),
                borderBottom: applyStyleByMode({
                    theme: ThemeMode?.mode,
                    light: '1px solid rgb(255, 255, 255)',
                    dark: '1px solid #1d2630'
                })
            }}
            alignItems='flex-start'
        >
            <LinkContainer>
                <UiLink
                    $padding='0'
                    styleCustom={{ width: '100%', display: 'flex' }}
                    content={<ListItemText key={vehicle.name} primary={vehicle.name} />}
                    url={`/vehicle-status?vehicle=${vehicle.id}`}
                    color={applyStyleByMode({
                        theme: ThemeMode?.mode,
                        light: COLOR_GREY,
                        dark: WHITE
                    })}
                />
            </LinkContainer>
        </ListItem>
    );
};

export default VehicleItem;
