import { atom } from 'recoil';
import { CustomerSettingsModel } from '../../models/CustomerSettings.type';
import { CRUD } from 'variables';
import { DateTime } from 'luxon';

type CustomerSettingsProps = {
    settingId?: number;
    action?: CRUD;
    settingName?: string;
};

export const CustomerSettingsActionAtom = atom<CustomerSettingsProps>({
    key: 'CustomerSettings',
    default: {
        settingId: 0,
        action: CRUD.EMPTY,
        settingName: ''
    }
});

export const CustomerSettingEditModeAtom = atom<string[]>({
    key: 'CustomerSettingEditMode',
    default: []
});

export type SelectedCustomerSettingsType =
    | {
          customerSetting: CustomerSettingsModel['settings'][0] | undefined;
          actionType: CRUD;
      }
    | undefined;

export const SelectedCustomerSettingValue = atom<string | number | boolean | null | undefined | DateTime | []>({
    key: 'SelectedCustomerSettingValue',
    default: undefined
});
