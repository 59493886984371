import styled from 'styled-components';
import Chip from '@mui/material/Chip';
import { SECONDARY_DARK_MODE, ERROR, PRIMARY, GRAY_LIGHT, DARK_STEEL_GREY, WHITE } from 'components/Ui/colors';
import { AlertFontSize, CommonTypeTheme } from 'components/Ui/Theme';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from 'states/global/Theme';
import { useTheme } from '@mui/material';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';

export const AlertIcon = styled(UiIcon)`
    padding-right: 5px;
`;

export const AlertByVehicleContainer = styled.div`
    .strong {
        font-weight: 600;
    }
    .redText {
        ${() => {
            if (useTheme().palette.mode === ThemeMode.light) return `color: ${ERROR};`;
        }}
    }
    .orangeText {
        ${() => {
            if (useTheme().palette.mode === ThemeMode.light) return `color: rgb(205, 110, 10) !important;`;
        }}
    }
`;
export const AlertContainer = styled.div<{ height?: number }>`
    padding: 10px 10px 0px 10px;
    ${(props) => (props.height ? `height: ${props.height}px;` : '')}
    ${(props) => (props.height ? 'overflow: auto;' : '')}
`;
export const AlertBodyContainer = styled.div`
    padding-top: 5px;
    width: 100%;
`;
export const AlertTitle = styled.div<{ dense?: boolean }>`
    font-weight: 600 !important;
    margin-bottom: ${(props) => (props.dense ? '0' : '5px')};
    display: ${(props) => (props.dense ? 'inline-block' : 'block')};
    width: 100%;
    p {
        font-weight: 600; 
        margin: 0px 0px 13px 0px;
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            if (useTheme().palette.mode === ThemeMode.light) {
                return `color: ${PRIMARY}; font-size: ${common.textSize};`;
            } else {
                return `color: ${WHITE}; font-size: ${common.textSize}; `;
            }
        }}}
    }
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        if (useTheme().palette.mode === ThemeMode.light) {
            return `color: ${PRIMARY}; font-size: ${common.textSize};`;
        } else {
            return `color: ${WHITE}; font-size: ${common.textSize}; `;
        }
    }}}
`;
export const AlertBody = styled.div`
    padding-bottom: 5px;
    .strong {
        font-weight: 600;
    }
    .tabletAlert {
        width: 100% !important;
        margin: 0.2em;
        line-height: 1em;
    }
    .alignRight {
        text-align: right;
    }
    ${() => {
        if (useTheme().palette.mode === ThemeMode.light) {
            return `color: ${PRIMARY};`;
        }
    }}
`;

export const NotResult = styled.div`
    font-size: ${AlertFontSize};
    padding-bottom: 5px;
    text-align: center;
    ${() => {
        if (useTheme().palette.mode === ThemeMode.light) {
            return `color: ${PRIMARY};`;
        }
    }}
    p {
        font-weight: 600 !important;
    }
    svg {
        color: ${DARK_STEEL_GREY};
        border-radius: 50%;
        border: 4px solid ${GRAY_LIGHT};
    }
`;

export const ChipActionsContainer = styled.div`
    display: block;
    width: 100%;
`;

export const ChipActions = styled(Chip)`
    background: ${PRIMARY} !important;
    color: #fff !important;
    height: 25px;
    width: 143px;
    font-size: ${AlertFontSize};
    margin-bottom: 4px !important;
    &:hover {
        background: ${PRIMARY} !important;
        color: #fff !important;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 4px !important;
    }
`;

export const ChipActionsFilter = styled(Chip)`
    border-radius: 4px !important;
    ${() => {
        return applyStyleByMode({
            styleJade: `
            background: ${useTheme().palette.mode === ThemeMode.light ? PRIMARY : SECONDARY_DARK_MODE} !important;
            `
        });
    }}

    color: #fff !important;
    height: 25px;
    min-width: 85px;
    width: auto;
    font-size: ${AlertFontSize} !important;
    margin-bottom: 4px !important;
    margin-left: 4px !important;
    &:hover {
        background: ${PRIMARY} !important;
        color: #fff !important;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
        margin-bottom: 4px !important;
    }
`;

export const LoadingContent = styled.div<{ height?: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    height: ${(props) => `${props.height}px` || '100%'};
`;

export const DenseLabel = styled.p<{ $date?: boolean }>`
    margin: 0 !important;
    ${(props) => props.$date && 'font-weight: 200 !important;'}
`;

export const LightAlertTitle = styled.p`
    font-weight: 100 !important;
`;
