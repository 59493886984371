import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { UiVehicleDropdownProps, DropdownVehicleList, DrowpdownVehicleType } from './UiVehicleDropdown.type';
import VehicleApi from 'api/Vehicle';
import { useTranslation } from 'react-i18next';
import Menu from '../UiMenu';
import Button from '../UiButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { VehicleLoader, VehicleLoaderContent } from './UiVehicleDropdown.style';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { VehicleQueryKeys } from 'models/Vehicle.type';

const findVehicleNameById = (id, vehicleData) => {
    return vehicleData.filter((vehicle) => vehicle.id === id);
};

const vehicleList = (translate, vehicleData, updateVehicle): DropdownVehicleList => {
    if (!vehicleData) {
        return [];
    }
    return [
        {
            title: translate('t.all_vehicles'),
            value: 'all',
            action: () =>
                updateVehicle({
                    title: translate('t.all_vehicles'),
                    value: 'all'
                })
        }
    ].concat(
        vehicleData?.map((vehicle) => ({
            title: vehicle.name,
            value: vehicle.id,
            action: (e) => {
                let vehicle = findVehicleNameById(e, vehicleData);
                if (vehicle.length) {
                    updateVehicle({
                        title: vehicle[0].name,
                        value: vehicle[0].id
                    });
                }
            }
        }))
    );
};

const Vehicle = new VehicleApi();

const UiVehicleDropdownContent: React.FC<UiVehicleDropdownProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [vehicle, updateVehicle] = useState<DrowpdownVehicleType>({ title: 'all', value: 0 });

    const { data, isLoading } = useQuery([VehicleQueryKeys.getAllAsCodebook], () => Vehicle.getAllAsCodebook(), {
        staleTime: cacheTimeToMilliseconds(2, 'hours'),
        cacheTime: cacheTimeToMilliseconds(2, 'hours')
    });

    const vehicles = useMemo(() => vehicleList(translate, data, updateVehicle), [data]);
    const title = useMemo(() => {
        return vehicle.title.includes('all') ? translate('t.all_vehicles') : vehicle.title;
    }, [vehicle]);

    useEffect(() => {
        props.onVehicleChange && props.onVehicleChange(vehicle);
    }, [vehicle]);

    const btn: JSX.Element = isLoading ? (
        <VehicleLoaderContent>
            <VehicleLoader />
        </VehicleLoaderContent>
    ) : (
        <Button
            color='secondary'
            variant='text'
            title={translate('t.select_vehicle')}
            testid='AlertsBreakdown-Vehicles-Button'
        >
            {title} <ExpandMoreIcon />
        </Button>
    );

    return <Menu rootEl={btn} items={vehicles} data-testid='AlertsBreakdownVehicleBtnContent' />;
};

export default UiVehicleDropdownContent;
