import React, { useEffect, useReducer } from 'react';
import ReactHowler from 'react-howler';
import { ButtonShowNotificationsContent } from './ButtonShowNotifications.view';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { toast } from 'react-toastify';
import { ButtonShowNotificationsProps, MqttMessage, NotifcationActions } from './ButtonShowNotifications.type';
import { FloatAlertNotifications } from 'components/AlertNotification/FloatAlertNotifications/FloatAlertNotifications';
import { User } from 'states/global/User';
import TpmsAlertsQuery from 'components/Queries/TpmsAlertQuery/TpmsAlertQuery';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { InicialStateNotification, NotificationReducer } from './ButtonShowNotifications.reducer';
import { getSoundSource } from 'helpers/sound';
import useMqtt from 'helpers/mqtt/useMqtt';
import NotificationEvent from 'api/NotificationEvent';
import { NotificationEventQueryKeys, NotificationTyre } from 'models/NotificationEvent.type';
import { ModalAtom } from 'states/global/Modal';
import { DEFAULT_SOUND } from 'components/Ui/variables';

const notificationEventAPI = new NotificationEvent();

const ButtonShowNotifications: React.FC<ButtonShowNotificationsProps> = ({ MqttData }): JSX.Element => {
    const user = useRecoilValue(User);
    const resetModalAtom = useResetRecoilState(ModalAtom);
    const { message } = useMqtt(
        MqttData.config.username,
        MqttData.config.password,
        MqttData.config.client,
        MqttData.topics
    );
    const queryClient = useQueryClient();
    const [state, dispatch] = useReducer(NotificationReducer, InicialStateNotification);
    const customConfigReactQuery = {
        refetchOnWindowFocus: false
    };

    const setNotificationPulse = (pulse: boolean) => {
        dispatch({
            type: NotifcationActions.SET_NOTIFICATION_PULSE,
            payload: pulse
        });
    };

    useQuery(
        [NotificationEventQueryKeys.get, 'extendUrl', state.notificationId],
        () => notificationEventAPI.get<NotificationTyre>({ extendUrl: `${state.notificationId}` }),
        {
            enabled: !!state.notificationId,
            refetchOnWindowFocus: false,
            staleTime: 0,
            cacheTime: 0,
            onSuccess(data) {
                if (Object.entries(data).length) {
                    const { notificationEvent } = data;
                    queryClient.invalidateQueries(['topBarAlerts']);
                    if (!user?.userSetting.muteNotification) {
                        FloatAlertNotifications({ notificationEvent, markAsRead });
                        const soundFilePath: string = notificationEvent.notification?.sound?.filePath || '';
                        const notificationSoundId = soundFilePath
                            ? soundFilePath.substring(0, soundFilePath.lastIndexOf('.'))
                            : DEFAULT_SOUND;

                        dispatch({
                            type: NotifcationActions.SET_NOTIFICATION_GENERAL,
                            payload: {
                                notificationId: undefined,
                                notificationPulse: true,
                                notificationSound: true,
                                notificationSoundId: notificationSoundId
                            }
                        });

                        setTimeout(() => {
                            dispatch({
                                type: NotifcationActions.SET_NOTIFICATION_SOUND,
                                payload: false
                            });
                        }, 3000);
                    }
                }
            }
        }
    );

    const markAsRead = async (event: React.MouseEvent, notificationId: number): Promise<void> => {
        event.stopPropagation();
        toast.dismiss(`float-alert-${notificationId}`);
        dispatch({
            type: NotifcationActions.SET_NOTIFICATION_PULSE,
            payload: false
        });
    };

    if (message) {
        const messageObject: MqttMessage = JSON.parse(message);
        if (state.notificationId !== messageObject.id) {
            dispatch({
                type: NotifcationActions.SET_NOTIFICATION_ID,
                payload: messageObject.id
            });
        }
    }
    useEffect(() => {
        resetModalAtom();
    }, []);

    return (
        <>
            <TpmsAlertsQuery
                keyQuery='topBarAlerts'
                currentPage={1}
                limit={150}
                solved={false}
                customConfig={customConfigReactQuery}
                tab='tpms'
            >
                {({ data, isLoading }) => (
                    <>
                        <ButtonShowNotificationsContent
                            data-testid={'ButtonShowNotifications-testid'}
                            muteNotification={user?.userSetting.muteNotification ?? false}
                            alertsData={data}
                            alertsIsLoading={isLoading}
                            notificationPulse={state.notificationPulse}
                            setNotificationPulse={setNotificationPulse}
                        />
                    </>
                )}
            </TpmsAlertsQuery>

            {state.notificationSound && (
                <ReactHowler src={getSoundSource(state.notificationSoundId)} playing={state.notificationSound} />
            )}
        </>
    );
};

export default ButtonShowNotifications;
