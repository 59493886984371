import { createTheme as createMuiTheme } from '@mui/material/styles';

import { BACKGROUND_DARK_MODE, BACKGROUND_PAPER_DARK_MODE, BLUE, PRIMARY_DARK, BACKGROUND_BLUE, WHITE } from './colors';
import customProperties, { customScroll } from './CustomProperties';
import { EasyReadingModeOverride } from './EasyReadingMode';
import { CommonTypeTheme } from './Theme';
import {
    FONT_BIG_SUBTITLE_SIZE,
    FONT_BIG_TEXT_SIZE,
    FONT_BIG_TITLE_SIZE,
    SIZE_SUBTITLE,
    SIZE_TEXT,
    SIZE_TEXT_VARIANT,
    SIZE_TITLE
} from './variables';

const createThemeDark = (EasyReadingMode: boolean, bigFonts: boolean) => {
    const applyEasyReadingMode = EasyReadingMode ? EasyReadingModeOverride : {};
    const getTextSizeEnv = SIZE_TEXT;
    const getTextSize = EasyReadingMode ? FONT_BIG_TEXT_SIZE : getTextSizeEnv;
    const getTitleSize = EasyReadingMode ? FONT_BIG_TITLE_SIZE : SIZE_TITLE;
    const getSubTitleSize = EasyReadingMode ? FONT_BIG_SUBTITLE_SIZE : SIZE_SUBTITLE;

    return createMuiTheme({
        palette: {
            mode: 'dark',
            common: {
                easyMode: EasyReadingMode,
                bigFonts: bigFonts,
                textSize: getTextSize,
                titleSize: getTitleSize,
                subTitleSize: getSubTitleSize
            } as CommonTypeTheme,
            primary: {
                main: BACKGROUND_BLUE
            },
            secondary: {
                main: WHITE
            },
            info: {
                main: BLUE
            },
            background: {
                paper: BACKGROUND_PAPER_DARK_MODE,
                default: BACKGROUND_DARK_MODE
            }
        },
        components: {
            ...customProperties(getTextSize),
            MuiCssBaseline: {
                styleOverrides: {
                    html: {
                        '& .UI-scroll-body': customScroll('dark', '9px')
                    },
                    body: {
                        '& .Ui-scroll': customScroll('dark'),
                        '& .MuiAutocomplete-popper': customScroll('dark')
                    },
                    '*': {
                        fontWeight: '500'
                    },
                    '& .MuiTypography-root': {
                        fontWeight: '500'
                    },
                    '& .MuiMenuItem-root': {
                        fontWeight: '500'
                    },
                    '& .logoCustomerRedirection': {
                        width: '445px',
                        height: '80px'
                    },
                    '& .loadingSystemName': {
                        color: '#c0c0c0',
                        animation: 'loadingSystemName-effect 0.8s linear infinite',
                        '@keyframes loadingSystemName-effect': {
                            '0%': { color: '#c0c0c0' },
                            '16.67%': { color: 'gray' },
                            '33.3%': { color: 'gray' },
                            '50%': { color: 'gray' },
                            '66.6%': { color: 'gray' },
                            '73.3%': { color: 'gray' },
                            '83.3%': { color: 'gray' },
                            '96.9%': { color: '#c0c0c0' },
                            '100%': { color: '#c0c0c0' }
                        }
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: customScroll('dark')
                }
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        '& .MuiTableRow-root': {
                            backgroundColor: BACKGROUND_PAPER_DARK_MODE,
                            fontSize: getTextSize,
                            border: 'none',
                            alignItems: 'unset'
                        },
                        '& .MuiTableCell-root': {
                            fontWeight: '600'
                        },
                        '& .MuiTableSortLabel-root': {
                            fontWeight: '600'
                        }
                    }
                }
            },
            MuiTableBody: {
                styleOverrides: {
                    root: {
                        '& .MuiTableCell-root': {
                            fontWeight: '500'
                        }
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '& $notchedOutline': {
                            borderColor: WHITE
                        },
                        '&$focused $notchedOutline': {
                            borderWidth: '2px'
                        },
                        '&:hover $notchedOutline': {
                            borderColor: PRIMARY_DARK
                        },
                        '&& fieldset': {
                            fontSize: getTitleSize
                        },
                        '& .MuiIconButton-root': {
                            fontSize: SIZE_TEXT_VARIANT(6)
                        },
                        '& .MuiOutlinedInput-input:-webkit-autofill': {
                            WebkitBoxShadow: '0 0 0 100px #00000073 inset',
                            WebkitTextFillColor: '#ffffff'
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#ffffff',
                            borderWidth: '2px'
                        }
                    }
                }
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        '& ': {
                            fontSize: getTitleSize,
                            lineHeight: getTitleSize,
                            fontWeight: '500',
                            '&.Mui-focused': {
                                color: '#ffffff'
                            },
                            '&.MuiInputLabel-shrink': {
                                transform: 'translate(12px, -9px) scale(0.78)',
                                lineHeight: `${SIZE_TEXT_VARIANT(13)} !important`,
                                padding: '0 5px 0 0px'
                            }
                        }
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        '& ': {
                            fontSize: getTitleSize,
                            lineHeight: getTitleSize,
                            fontWeight: '500',
                            color: WHITE,
                            '&.MuiInputLabel-shrink': {
                                lineHeight: SIZE_TEXT_VARIANT(12)
                            }
                        }
                    },
                    outlined: {
                        '&$shrink': {
                            transform: 'translate(140px, -6px) scale(.9)'
                        }
                    }
                }
            },
            MuiCardHeader: {
                styleOverrides: {
                    root: {
                        '& .MuiCardHeader-avatar': {
                            marginRight: '7px'
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: EasyReadingMode ? '1.4em' : '1.2em'
                        }
                    }
                }
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        borderBottom: '1px solid #c5c5c5'
                    }
                }
            },
            MuiDialog: {
                styleOverrides: {
                    root: {
                        '& .MuiDialog-paper': {
                            backgroundImage: 'none'
                        }
                    }
                }
            },
            MuiAlert: {
                styleOverrides: {
                    filledInfo: {
                        backgroundColor: '#00638d',
                        color: WHITE
                    },
                    root: {
                        '& .MuiAlert-action': {
                            padding: '0px 0 0 16px'
                        }
                    }
                }
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& label': {
                            padding: '0 5px 0 0',
                            background: 'transparent'
                        },
                        '& .MuiInputLabel-shrink': {
                            transform: 'translate(12px, -9px) scale(0.78)'
                        }
                    }
                }
            },
            ...applyEasyReadingMode,
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        '& svg': {
                            '&:hover': {
                                color: BACKGROUND_BLUE
                            }
                        }
                    }
                }
            }
        },
        typography: {
            fontFamily: 'Roboto, Arial, sans-serif',
            fontWeightBold: 600,
            fontWeightMedium: 600,
            h1: {
                fontSize: '1.65rem',
                lineHeight: 1.2
            },
            h2: {
                fontSize: '1.5rem',
                lineHeight: 1.334
            },
            h3: {
                fontSize: '1.35rem',
                lineHeight: 1.6
            },
            h4: {
                fontSize: '1.20rem',
                lineHeight: 1.4
            },
            h5: {
                fontSize: getTitleSize,
                lineHeight: 1.3
            },
            h6: {
                fontSize: '1.01rem',
                lineHeight: 1.1
            },
            body1: {
                fontSize: getTextSize,
                lineHeight: 1.43
            },
            body2: {
                fontSize: getTextSize,
                lineHeight: 1.43
            },
            subtitle1: {
                fontSize: '12px',
                lineHeight: 1.43,
                fontWeight: 600
            }
        }
    });
};

export default createThemeDark;
