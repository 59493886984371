import React, { useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ExportButton, UiTableExportDropdownViewContent } from './UiTableExportDropdown.style';
import { ExportState, UiTableExportDropdownProps } from './UiTableExportDropdown.type';
import { useTranslation } from 'react-i18next';
import UiMenu from '../Components/UiMenu/UiMenu';
import { ContentCopy, Download, ExpandMore, Print } from '@mui/icons-material';
import { Item } from '../Components/UiMenu/UiMenu.type';
import { useQuery } from '@tanstack/react-query';
import { Errors, Success } from 'components/Popup/Popup';
import { ShowScroll, TableAtom } from '../UiTable/TableAtom';
import { createCSV } from 'helpers';

const UiTableExportDropdownContent: React.FC<UiTableExportDropdownProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const tableState = useRecoilValue(TableAtom(`${props.queryKey}-Table`));
    const setShowScroll = useSetRecoilState(ShowScroll);
    const [exportState, setExportState] = useState<ExportState>(ExportState.SET_NONE);

    const { isFetching, refetch } = useQuery(
        [`${props.queryKey}-TableQuery`],
        () =>
            (props as { exportFn: (props, additionalProps) => Promise<string> }).exportFn(
                { ...tableState },
                props.additionalProps
            ),
        {
            refetchInterval: false,
            retry: false,
            keepPreviousData: true,
            enabled: false,
            refetchOnWindowFocus: false,
            onSuccess(data) {
                exportState === ExportState.SET_COPY && createCopy(data);
                exportState === ExportState.SET_CSV &&
                    createCSV(data, props.exportName || props.queryKey, setExportState);
            }
        }
    );

    const createRefetch = (exportState: ExportState): void => {
        setExportState(exportState);
        refetch();
    };

    const createCopy = (data) => {
        navigator.clipboard
            .writeText(data ?? ' ')
            .then(() => {
                Success({
                    text: translate('t.copied')
                });
            })
            .catch(() => {
                Errors({ text: translate('p.error_copying_clipboard') });
            });

        setExportState(ExportState.SET_NONE);
    };

    const menuItems = useMemo(() => {
        let items: Item[] = [
            {
                title: 'CSV',
                value: 'CSV',
                iconUI: <Download />,
                actionGeneral: () => createRefetch(ExportState.SET_CSV)
            },
            {
                title: translate('t.copy'),
                value: 'copy',
                iconUI: <ContentCopy />,
                actionGeneral: () => createRefetch(ExportState.SET_COPY)
            },
            {
                title: translate('t.print'),
                value: 'print',
                iconUI: <Print />,
                actionGeneral: () => setShowScroll(false)
            }
        ];

        if (props.onlyCSVExport) {
            items.splice(-2);
        }

        return items;
    }, []);

    return (
        <UiTableExportDropdownViewContent data-testid={`UiTableExportDropdownContent-${props.queryKey}Table`}>
            <UiMenu
                testid={`Menu-export-${props.queryKey}Table`}
                rootEl={
                    <ExportButton
                        endIcon={<ExpandMore />}
                        testid={`Menu-Export-${props.queryKey}Table-Button`}
                        size={'small'}
                        variant='outlined'
                        loading={isFetching}
                        title={translate('t.export_info')}
                    >
                        {translate('t.export')}
                    </ExportButton>
                }
                items={menuItems}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            />
        </UiTableExportDropdownViewContent>
    );
};

export default UiTableExportDropdownContent;
