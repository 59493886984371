import { VehicleStatusModel } from 'components/Vehicle/VehicleStatsRow/VehicleStatsRow.type';
import PortalApi from 'helpers/api/PortalApiClient';
import TrackLogModel, {
    AccelerationResponse,
    BasicHeatmapProps,
    ElevationProps,
    ElevationResponse,
    GetSlopeInclinationDataParams,
    GpsResponse,
    GpsStatistics,
    SlopeInclinationHeatmapResponse,
    SpeedHeatmapProps,
    SpeedHeatmapResponse,
    SpeedParams,
    SpeedResponse,
    TrackLogReportTable,
    VehicleStatsData,
    VehicleStatsModel
} from 'models/TrackLog.type';
import { DateTime } from 'luxon';

export default class TrackLog extends PortalApi<TrackLogModel | VehicleStatsModel> {
    route = 'track-log';

    getSpeed = async (
        vehicleId: number,
        measuredFrom: string,
        measuredTo: string,
        granularity?: number
    ): Promise<SpeedResponse> => {
        let params: SpeedParams = {};
        if (measuredFrom) {
            params['measuredFrom'] = measuredFrom;
        }
        if (measuredTo) {
            params['measuredTo'] = measuredTo;
        }
        if (granularity) {
            params['granularity'] = granularity;
        }
        return await this.getByCriteria({
            extendUrl: `vehicle/${vehicleId}/speed`,

            criteria: params
        });
    };

    getTrackLogReports = async (props): Promise<TrackLogReportTable> => {
        let criteria = {
            measuredFrom: props.additionalProps.measuredFrom,
            measuredTo: props.additionalProps.measuredTo,
            limit: 20
        };

        if (props.additionalProps.vehicleId > 0) {
            criteria['vehicle'] = props.additionalProps.vehicleId;
        }
        return await this.getByCriteria({ extendUrl: `preview`, criteria });
    };

    getWebtrackVehicleStats = async (params: { [key: string]: string | number }): Promise<VehicleStatsData> => {
        return await this.getByCriteria({ extendUrl: `vehicle/stats`, version: 'v1', criteria: params });
    };

    getVehicleStatusDetail = async (
        params: { period: number },
        vehicleId: number | string
    ): Promise<VehicleStatusModel> => {
        return await this.getByCriteria({
            extendUrl: `vehicle/${vehicleId}/stats-detail`,

            criteria: params
        });
    };

    getTrackLogCsv = async (vehicleId: number, dateFrom: string, dateTo: string): Promise<TrackLogReportTable> => {
        let criteria = {
            dateFrom: dateFrom,
            dateTo: dateTo
        };

        if (vehicleId > 0) {
            criteria['vehicleId'] = vehicleId;
        }
        return await this.getByCriteria({ extendUrl: `report`, criteria: criteria });
    };

    getSpeedHeatmap = async (params: SpeedHeatmapProps): Promise<SpeedHeatmapResponse> => {
        const heatmapParams: SpeedHeatmapProps = {
            measuredFrom: params.measuredFrom,
            measuredTo: params.measuredTo,
            area: params.position
                ? params.position
                      .reduce((acc, curr) => {
                          return acc + encodeURIComponent(curr) + ',';
                      }, '')
                      .slice(0, -1)
                : undefined,
            zoomLevel: params.zoomLevel,
            limit: params.limit
        };
        if (params.vehicleIds && params.vehicleIds.length) {
            heatmapParams.vehicleIds = params.vehicleIds as number[];
            heatmapParams.vehicleIds = heatmapParams.vehicleIds
                .reduce((acc, curr) => {
                    return acc + encodeURIComponent(curr) + ',';
                }, '')
                .slice(0, -1);
        }

        return await this.getByCriteria({
            extendUrl: 'speed-heatmap',
            criteria: heatmapParams as Record<string, any>
        });
    };

    getElevationHeatmap = async (params: ElevationProps): Promise<ElevationResponse> => {
        const heatmapParams: ElevationProps = {
            measuredFrom: params.measuredFrom,
            measuredTo: params.measuredTo,
            zoomLevel: params.zoomLevel,
            limit: params.limit,
            area: params.area
        };
        return await this.getByCriteria({
            extendUrl: `heatmap-elevation`,

            criteria: heatmapParams as Record<string, string | number | string[]>
        });
    };

    getGPSHeatmap = async (params: BasicHeatmapProps): Promise<GpsResponse> => {
        const heatmapParams: BasicHeatmapProps = {
            measuredFrom: params.measuredFrom,
            measuredTo: params.measuredTo,
            zoomLevel: params.zoomLevel,
            limit: params.limit,
            area: params.area
        };

        if (params.vehicleIds && params.vehicleIds.length) {
            heatmapParams.vehicleIds = params.vehicleIds;
        }
        return await this.getByCriteria({
            extendUrl: `heatmap-gps`,

            criteria: heatmapParams as Record<string, string | number | string[]>
        });
    };

    getGPSStatistics = async (deviceId): Promise<GpsStatistics> => {
        return await this.get({
            extendUrl: `satellites/${deviceId}/${DateTime.now().minus({ hours: 24 })}/${DateTime.now()}`
        });
    };
    getAccelerationHeatmap = async (params: BasicHeatmapProps): Promise<AccelerationResponse> => {
        const heatmapParams: BasicHeatmapProps = {
            measuredFrom: params.measuredFrom,
            measuredTo: params.measuredTo,
            zoomLevel: params.zoomLevel,
            limit: params.limit,
            area: params.area
        };

        if (params.vehicleIds && params.vehicleIds.length) {
            heatmapParams.vehicleIds = params.vehicleIds;
        }
        return await this.getByCriteria({
            extendUrl: `acceleration-heatmap`,

            criteria: heatmapParams as Record<string, string | number | string[]>
        });
    };

    getSlopeInclinationHeatmap = async (
        params: GetSlopeInclinationDataParams
    ): Promise<SlopeInclinationHeatmapResponse> => {
        const a = {
            vehicleIds: params.vehicleId,
            measuredFrom: params.measuredFrom,
            measuredTo: params.measuredTo,
            odometerSum: params.odometerSum,
            satellites: params.satellites
        };

        return await this.getByCriteria({
            extendUrl: `slope-inclination-heatmap`,
            criteria: { ...a, zoomLevel: 14 }
        });
    };

    //HEATMAP_API_AUTOMATIC_GENERATOR
}
