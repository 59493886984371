import React from 'react';
import { NotificationsToggleButtonViewProps } from './NotificationsToggleButton.type';
import { ToggleButton } from './NotificationsToggleButton.style';
import CircularProgress from '@mui/material/CircularProgress';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { Box, Tooltip } from '@mui/material';
import { ERROR, WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';
import { useRecoilValue } from 'recoil';
import { applyStyleByMode } from 'helpers';
import { User } from 'states/global/User';

export const NotificationsToggleButtonContent: React.FC<NotificationsToggleButtonViewProps> = (props): JSX.Element => {
    const ThemeMode = useRecoilValue(Theme);
    const user = useRecoilValue(User);

    return (
        <Tooltip
            title={props.toolTipText}
            placement='bottom'
            arrow
            enterDelay={1000}
            leaveDelay={0}
            disableFocusListener
        >
            <Box>
                <ToggleButton
                    $statusNotification={props.muteNotification}
                    onClick={() => props.toggleNotification()}
                    disabled={props.notificationMuteLoading}
                    className='notificationsToggleButton'
                    testid='EnableOrDisableNotifications-Button'
                >
                    {props.notificationMuteLoading ? (
                        <CircularProgress size={'1.35rem'} />
                    ) : (
                        <UiIcon
                            size='sm'
                            icon={['far', props.muteNotification ? 'bell-slash' : 'bell-on']}
                            color={applyStyleByMode({
                                theme: ThemeMode?.mode,
                                light: user?.userSetting.muteNotification ? ERROR : '#0000008a',
                                dark: user?.userSetting.muteNotification ? ERROR : WHITE
                            })}
                        />
                    )}
                </ToggleButton>
            </Box>
        </Tooltip>
    );
};
