import { Box, TableCell } from '@mui/material';
import styled from 'styled-components';

export const RuleOverviewTableContainer = styled.div`
    padding: 10px 0;
`;

export const LevelIndicator = styled(TableCell)((props: { background?: string }) => ({
    backgroundImage: `linear-gradient(to right, ${props.background}, ${props.background
        ?.replace('rgb(', 'rgba(')
        .replace('1)', '0)')})`,
    minWidth: 150
}));

export const TableBoxCover = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between'
});

export const TableBox = styled(Box)((props: { color?: string }) => ({
    display: 'flex',
    alignItems: 'center',
    color: props.color || 'inherit'
}));
