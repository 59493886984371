import React from 'react';
import { useTranslation } from 'react-i18next';
import { atom, useSetRecoilState } from 'recoil';
import { NotificationsSettingsFormSoundProps } from './NotificationsSettingsForm.type';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete/LazyloadingAutocomplete';
import { Sound, SoundQueryKeys } from 'models/Sound.type';
import ApiSound from 'api/Sound';

const apiSound = new ApiSound();

export const NotificationsSettingsFormSoundAtom = atom<{ playing: boolean; sound: Sound | null }>({
    key: 'NotificationsSettingsFormSoundAtom',
    default: {
        playing: false,
        sound: null
    }
});

export const NotificationsSettingsFormSound: React.FC<NotificationsSettingsFormSoundProps> = ({
    notificationType,
    setData,
    index
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const setSoundAtom = useSetRecoilState(NotificationsSettingsFormSoundAtom);

    const handleSoundChange = (sound: Sound): void => {
        setSoundAtom({ playing: true, sound });
    };

    return (
        <LazyloadingAutocomplete
            data-testid={`SoundList-${notificationType.id}-LazyloadingAutocomplete`}
            id={`sound-list-${notificationType.id}-lazyloading`}
            queryId={`${SoundQueryKeys.getTable}-${notificationType.id}`}
            optionKey='name'
            optionValue='id'
            size='small'
            fullWidth
            defaultOption={{
                id: 0,
                name: translate('t.no_custom_sound'),
                filePath: ''
            }}
            preselectedValue={{
                value: notificationType.sound?.id || 0,
                label: notificationType.sound?.name || translate('t.no_custom_sound')
            }}
            style={{ paddingLeft: '2px', paddingRight: '2px' }}
            query={apiSound.getTable}
            label={translate('t.sound')}
            onValueChange={(id_, name_, sound: Sound) => {
                if (sound && setData) {
                    const isCustomSound: boolean = sound?.id !== 0;
                    isCustomSound && handleSoundChange(sound);
                    setData((current) => {
                        let a = { ...current };
                        a[notificationType.type][notificationType.type2][index].sound = isCustomSound ? sound : null;
                        return a;
                    });
                }
            }}
            getOptionLabel={(value) => {
                return {
                    text: value?.name,
                    html: undefined
                };
            }}
        />
    );
};
