import React, { useEffect, useState } from 'react';
import { ShiftTimeNameActionsContent } from './TeamShiftNameActions.view';
import { ShiftTimeNameActionsProps, InitialValues } from './TeamShiftNameActions.type';
import { Wrapper } from 'helpers/wrapper';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Success } from 'components/Popup/Popup';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState, useRecoilState } from 'recoil';
import TeamShiftName from 'api/TeamShiftName';
import { CRUD } from 'variables';
import { ShowTeamShiftNameActionsModal } from 'states/global/Modal';
import { ShiftTimeNameActionsState } from './TeamShiftNameActions.atom';
import { DefaultRequestPropsType, PatchPutByIdType, WithRequiredProperty } from 'helpers/api/type';
import { TeamShiftNameModel, TeamShiftNameQueryKeys } from 'models/TeamShiftName.type';

const teamShiftNameApi = new TeamShiftName();

const ShiftTimeNameActions: React.FC<ShiftTimeNameActionsProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const showTeamShiftNameActionsModal = useSetRecoilState(ShowTeamShiftNameActionsModal);
    const [shiftTimeNameActionsState, setShiftTimeNameActionsState] = useRecoilState(ShiftTimeNameActionsState);
    const [initialValues, setInitialValues] = useState<InitialValues>({ name: '' });
    const queryClient = useQueryClient();

    const { mutate: addTeamShiftName, isLoading: addTeamShiftNameLoading } = useMutation<
        DefaultRequestPropsType,
        unknown,
        InitialValues
    >(teamShiftNameApi.addTeamShiftName, {
        onSuccess: (data) => {
            if (data) {
                Success({
                    text: `${translate('t.team')} ${translate('p.has_been_added')}`
                });
                showTeamShiftNameActionsModal(false);
                setShiftTimeNameActionsState({
                    action: CRUD.EMPTY,
                    id: '',
                    name: ''
                });
                setInitialValues({ name: '' });
                queryClient.refetchQueries([`${TeamShiftNameQueryKeys.getTeamShiftNameList}-TableQuery`]);
                queryClient.invalidateQueries([TeamShiftNameQueryKeys.getTeamShiftNameDropDown]);
            }
        },
        onError: (e) => {
            // eslint-disable-next-line no-console
            console.warn(e);
        }
    });

    const { mutate: updateTeamShiftName, isLoading: updateTeamShiftNameLoading } = useMutation<
        DefaultRequestPropsType,
        unknown,
        PatchPutByIdType<TeamShiftNameModel, DefaultRequestPropsType>
    >(teamShiftNameApi.patchById, {
        onSuccess: () => {
            Success({ text: `${translate('t.team')} ${translate('p.has_been_edited')}` });
            showTeamShiftNameActionsModal(false);
            setShiftTimeNameActionsState({
                action: CRUD.EMPTY,
                id: '',
                name: ''
            });
            setInitialValues({ name: '' });
            queryClient.refetchQueries([`${TeamShiftNameQueryKeys.getTeamShiftNameList}-TableQuery`]);
            queryClient.invalidateQueries([TeamShiftNameQueryKeys.getTeamShiftNameDropDown]);
        }
    });

    const { mutate: teamShiftNameDelete, isLoading: teamShiftNameDeleteLoading } = useMutation<
        any,
        unknown,
        WithRequiredProperty<DefaultRequestPropsType, 'id'>
    >(teamShiftNameApi.delete, {
        onSuccess: () => {
            Success({ text: `${translate('t.team')} ${translate('p.has_been_deleted')}` });
            showTeamShiftNameActionsModal(false);
            queryClient.refetchQueries([`${TeamShiftNameQueryKeys.getTeamShiftNameList}-TableQuery`]);
            queryClient.invalidateQueries([TeamShiftNameQueryKeys.getTeamShiftNameDropDown]);
        }
    });

    const handleSubmit = (values: InitialValues): void => {
        if (shiftTimeNameActionsState.action === CRUD.ADD) {
            addTeamShiftName(values);
        } else {
            updateTeamShiftName({
                id: shiftTimeNameActionsState.id ? Number(shiftTimeNameActionsState.id) : NaN,
                data: values
            });
        }
    };

    const removeTeamShiftName = (): void => {
        teamShiftNameDelete({ id: shiftTimeNameActionsState.id ? Number(shiftTimeNameActionsState.id) : NaN });
    };

    useEffect(() => {
        if (shiftTimeNameActionsState.name !== '') {
            setInitialValues({ name: shiftTimeNameActionsState.name ?? '' });
        }

        return () => {
            setInitialValues({ name: '' });
        };
    }, [shiftTimeNameActionsState]);

    return (
        <ShiftTimeNameActionsContent
            data-testid='ShiftTimeNameActions-testid'
            addTeamShiftNameLoading={
                addTeamShiftNameLoading || updateTeamShiftNameLoading || teamShiftNameDeleteLoading
            }
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            removeTeamShiftName={removeTeamShiftName}
        />
    );
};

export default Wrapper(ShiftTimeNameActions);
