import React from 'react';
import { FilterChip, RuleOverviewFiltersContainer } from './RuleOverviewFilters.style';
import { RuleOverviewFiltersProps } from './RuleOverviewFilters.type';
import { useTranslation } from 'react-i18next';
import { RULE_TYPE } from 'models/Rule.type';

const RuleOverviewFiltersContent: React.FC<RuleOverviewFiltersProps> = ({ filters }): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <RuleOverviewFiltersContainer data-testid='RuleOverviewFiltersContent'>
            {filters.vehicleModel && <FilterChip label={`${translate('t.vehicle_model')}: ${filters.vehicleModel}`} />}
            {filters.position && (
                <FilterChip
                    label={`${translate('t.tyre_position')}: ${
                        Array.isArray(filters.position) ? filters.position.join(', ') : filters.position
                    }`}
                />
            )}
            {filters.wheel && <FilterChip label={`${translate('t.vehicle')}: ${filters.wheel.vehicle?.name}`} />}
            {filters.vehicle && <FilterChip label={`${translate('t.vehicle')}: ${filters.vehicle.name}`} />}
            {filters.ruleType.id === RULE_TYPE.DEFAULT && <FilterChip label={`${translate('t.default')}`} />}
            {filters.vehicleGroup && (
                <FilterChip label={`${translate('t.vehicle_group')}: ${filters.vehicleGroup.name}`} />
            )}
            {filters.tyreModel && <FilterChip label={`${translate('t.tyre_model')}: ${filters.tyreModel.name}`} />}
            {filters.vehicleConfiguration && (
                <FilterChip
                    label={`${translate('t.vehicle_configuration')}: ${filters.vehicleConfiguration.distribution}`}
                />
            )}
        </RuleOverviewFiltersContainer>
    );
};

export default RuleOverviewFiltersContent;
