import { DARK_STEEL_GREY, SUCCESS } from './colors';
import { SIZE_TEXT } from './variables';

const customProperties = (getTextSize = SIZE_TEXT) => {
    return {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    height: 49,
                    fontSize: '0.84rem',
                    borderBottom: '3px solid #b5b5b5',
                    '& .MuiToolbar-regular': {
                        minHeight: 45
                    },
                    '& .Header-logo': {
                        width: 205
                    },
                    '& button': {
                        fontSize: '1.1rem'
                    },
                    '& .MuiSvgIcon-root': {
                        fontSize: '1.1rem'
                    },
                    '& p': {
                        fontSize: '0.84rem'
                    },
                    '& .CollapsSidebarButton': {
                        padding: 15
                    },
                    '& .MuiBreadcrumbs-root': {
                        position: 'fixed',
                        top: '65px',
                        left: 240
                    }
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    '& .MuiPaper-root': {
                        width: 220,
                        maxWidth: 220
                    },
                    '& .MuiListItem-root': {
                        fontSize: '0.85rem'
                    },
                    '& .MuiTypography-body1': {
                        fontSize: '0.85rem'
                    }
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    padding: '10px 4px',
                    fontSize: getTextSize,
                    maxWidth: 'none'
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    '& svg': {
                        fontSize: '1em',

                        '&:hover': {
                            color: DARK_STEEL_GREY
                        }
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    '& ': {
                        fontSize: getTextSize,
                        border: 'none',
                        padding: '4px 14px'
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '& ': {
                        textTransform: 'capitalize'
                    }
                },
                containedSizeSmall: {
                    padding: '5.4px 10px'
                },
                outlinedSizeSmall: {
                    padding: '4.4px 10px'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '& ': {
                        fontSize: getTextSize,
                        minHeight: '30px',
                        maxHeight: '30px',
                        minWidth: 0,
                        padding: '0 10px',
                        textTransform: 'capitalize !important',
                        fontWeight: '600 !important'
                    },
                    '&.MuiTab-root + .MuiTab-root': {
                        borderLeft: '1px solid #bbb'
                    },
                    '& .MuiBadge-root': {
                        fontWeight: '600 !important'
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                scrollButtons: ({ theme }) => ({
                    [theme.breakpoints.down('sm')]: {
                        display: 'flex !important'
                    }
                }),
                root: {
                    width: '100%',
                    '&': {
                        borderBottom: '1px solid #c5c5c5 !important',
                        minHeight: '35px'
                    },
                    '& .MuiTabs-scrollButtons': {
                        padding: '0px'
                    },
                    '& .MuiTabs-indicator': {
                        display: 'none'
                    },
                    '& .MuiTabScrollButton-root': {
                        width: '20px'
                    }
                }
            }
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    backgroundImage: 'none'
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    color: '#ccc'
                },
                colorSecondary: {
                    '&$checked': {
                        color: SUCCESS
                    }
                },
                colorPrimary: {
                    '&$checked': {
                        color: SUCCESS
                    }
                },
                track: {
                    '$checked$checked + &': {
                        backgroundColor: SUCCESS
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    // '&:hover': {
                    //     background: '#ebebeb',
                    //     transition: 'background-color 500ms linear'
                    // },
                    fontSize: getTextSize,
                    '& svg': {
                        fontSize: '1.3em'
                    }
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    alignItems: 'center',
                    borderBottom: '1px solid #d1d1d1'
                }
            }
        },
        MuiTableFooter: {
            styleOverrides: {
                root: {
                    '& .MuiTableRow-root': {
                        borderBottom: 'none',
                        '& .MuiTableCell-root': {
                            paddingTop: '0px'
                        }
                    }
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiAutocomplete-tag': {
                        margin: '0px',
                        marginRight: '2px'
                    },
                    '&& .MuiChip-root': {
                        height: '19px'
                    }
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '0px 20px'
                }
            }
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    width: '37px',
                    height: '37px'
                }
            }
        }
    };
};

export default customProperties;

export const customScroll = (theme: 'light' | 'dark', width = '7px') => {
    const colorScroll = theme === 'light' ? 'rgb(0 0 0 / 30%)' : 'rgb(163 163 163)';
    const colorHoverScroll = theme === 'light' ? 'rgb(0 0 0 / 42%)' : 'rgb(195 195 195)';
    const backgroundHoverScroll = theme === 'light' ? '#89898963' : '#cfcfcf63';

    return {
        '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: backgroundHoverScroll,
            width: width,
            height: width,
            borderRadius: 8
        },
        '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: colorScroll,
            minHeight: 24
        },
        '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: colorHoverScroll
        },
        '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: colorHoverScroll
        },
        '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: colorHoverScroll
        }
    };
};
