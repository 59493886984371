import { DialogActions, DialogContent, DialogTitle, Paper, styled as muiStyled } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import { BACKGROUND_BLUE, BACKGROUND_PAPER_DARK_MODE } from '../../Ui/colors';
import { SIZE_TEXT_VARIANT } from 'components/Ui/variables';
import { ThemeMode } from 'states/global/Theme';

export const ModalWindow1 = muiStyled(Paper)<{
    width?: number;
    widthUnit?: string;
    height?: number;
    heightUnit?: string;
}>`
    &.MuiPaper-root {
        width: ${(props) => props.width}${(props) => props.widthUnit};
        min-width: ${(props) => props.width}${(props) => props.widthUnit};
        height: ${(props) => props.height}${(props) => props.heightUnit};
        min-height: ${(props) => props.height}${(props) => props.heightUnit};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        ${({ theme }) => theme.breakpoints.between('xs', 'md')} {
            width: 99vw;
            min-width: 99vw;
            max-height: 93vh;
        }
    }
`;

export const ModalWindow = muiStyled(Paper)<{
    width?: number;
    widthUnit?: string;
    height?: number;
    heightUnit?: string;
}>(
    ({ theme, width, widthUnit, heightUnit, height }) => `
        &.MuiPaper-root {
            width: ${width}${widthUnit};
            min-width: ${width}${widthUnit};
            height: ${height}${heightUnit};
            min-height: ${height}${heightUnit};
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            ${theme.breakpoints.between('xs', 'md')} {
                width: 99vw;
                min-width: 99vw;
                max-height: 93vh;
            }
        }
    `
);

export const Header = muiStyled(DialogTitle)<{ $theme?: ThemeMode }>`
    ${({ $theme }) =>
        applyStyleByMode({
            styleJade: `font-size: ${SIZE_TEXT_VARIANT(2)};`,
            theme: $theme || ThemeMode.light,
            light: `background: #fff; color: #${BACKGROUND_BLUE};`,
            dark: `background: ${BACKGROUND_PAPER_DARK_MODE}; color: #${BACKGROUND_BLUE};`
        })}
    
    padding: 7px 24px;
    overflow: auto;
    border-radius: 3px 3px 0 0;
`;

export const Title = muiStyled('span')<{ $left?: boolean }>`
    float: ${(props) => (props.$left ? 'left' : 'right')};
`;

export const Content = muiStyled(DialogContent)<{ $withoutFooter?: boolean }>`
    &.MuiDialogContent-root {
        padding: 8px 24px;
        height: calc(100% - ${(props) => (props.$withoutFooter ? '30px' : '83px')});
    }
`;

export const Footer = muiStyled(DialogActions)<{ $theme?: ThemeMode }>`
        background: ${({ $theme }) =>
            applyStyleByMode({
                theme: $theme || ThemeMode.light,
                light: '#fff;',
                dark: `${BACKGROUND_PAPER_DARK_MODE};`
            })}
        border-top: solid thin rgb(235, 235, 235);
        border-radius: 0 0 3px 3px;
    }
`;
