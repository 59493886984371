import PortalApi from 'helpers/api/PortalApiClient';
import { TyreModelModel } from '../models/TyreModel.type';
import { getParams } from '../helpers';
import { Paginator } from 'helpers/api/type';

export default class TyreModel extends PortalApi<TyreModelModel> {
    route = 'tyre-model';

    getModelTable = async (props): Promise<Paginator<TyreModelModel>> => {
        return this.getByCriteria({ criteria: getParams(props) });
    };
}
