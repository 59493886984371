import Feature from 'ol/Feature';
import Vector from 'ol/source/Vector';
import * as OlProj from 'ol/proj';
import { CustomLayers, DefaultSettings, MapActions, refControllerType } from '../Map.type';
import Point from 'ol/geom/Point';
import SpeedEventApi from 'api/SpeedEvent';
import { Geometry } from 'ol/geom';
import { Icon, Style } from 'ol/style';
import { getImgSource } from 'helpers/image';
import { DateTime } from 'luxon';
import { SpeedIncident } from 'models/SpeedEvent.type';
import { MapActionDataType } from 'states/global/Map';

export const createSpeedPoint = (
    pointSettings: SpeedIncident,
    defaultSettings: DefaultSettings,
    enableLoaded: boolean
): Feature<Geometry> => {
    const loadedIcon = enableLoaded ? (pointSettings.loaded ? 'speedPointLoaded' : 'speedPointUnloaded') : 'speedPoint';
    const featureId = `speed-${pointSettings.id}`;
    let speedFeature = new Feature({
        geometry: new Point(
            OlProj.transform(
                [pointSettings.positionAt.latitude, pointSettings.positionAt.longitude],
                defaultSettings.epsg[0],
                defaultSettings.epsg[1]
            )
        ),
        id: featureId
    });

    speedFeature.setId(featureId);
    const speedPointStyle = new Style({
        zIndex: 9999,
        image: new Icon({
            opacity: 1,
            src: getImgSource(loadedIcon),
            scale: 0.5
        })
    });

    let styles: Style[] = [speedPointStyle];
    speedFeature.setStyle(styles);

    return speedFeature;
};

export const drawSpeedIncidents = async (
    map: MapActions,
    mapData: MapActionDataType,
    storeMapActionData: (params: MapActionDataType) => void,
    refController: refControllerType['refController'],
    timezoneName?: string
): Promise<false | void> => {
    const SpeedEvent = new SpeedEventApi();
    refController.current = SpeedEvent.controller;
    const heatmapLayer = map.findLayerByName(CustomLayers.SPEED_INCIDENTS);
    let loadedCounts = {
        loaded: 0,
        unloaded: 0,
        total: 0
    };

    if (!mapData.heatmapDateRange?.dateFrom || !mapData.heatmapDateRange?.dateFrom) {
        return false;
    }

    if (heatmapLayer.length) {
        map.updateLoadingMapStatus(true);
        map.toggleMapInteractions();
        const heatmapSource = new Vector();
        const dateFromDefault = mapData.heatmapDateRange?.dateFrom || DateTime.local().minus({ days: 30 });
        const dateToDefault = mapData.heatmapDateRange?.dateTo || DateTime.local();
        const dateFromParam = DateTime.fromMillis(
            dateFromDefault.toUnixInteger() * 1000,
            timezoneName || 'Europe/Bratislava'
        )
            .toUTC()
            .toISO();
        const dateToParam = DateTime.fromMillis(
            dateToDefault.toUnixInteger() * 1000,
            timezoneName || 'Europe/Bratislava'
        )
            .toUTC()
            .toISO();

        const SpeedIncidents = await SpeedEvent.getSpeedIncidents({
            vehicle: mapData.selectedVehicles?.length ? mapData.selectedVehicles : null,
            vehicleIds: mapData.selectedVehicles?.length ? mapData.selectedVehicles?.join(',') : null,
            measuredFrom: dateFromParam,
            measuredTo: dateToParam,
            loaded: mapData.loaded,
            vehicleOperator: mapData.vehicleOperator
        });

        if (SpeedIncidents.speedIncidents) {
            SpeedIncidents.speedIncidents.forEach((speedIncident) => {
                heatmapSource.addFeature(createSpeedPoint(speedIncident, map.defaultSettings, !!mapData.enableLoaded));
                if (speedIncident.loaded) {
                    loadedCounts.loaded += 1;
                } else {
                    loadedCounts.unloaded += 1;
                }
            });
            loadedCounts.total = SpeedIncidents.total;
            storeMapActionData({ loadedCounts });

            heatmapLayer[0].setSource(heatmapSource);
        }

        map.updateLoadingMapStatus(false);
        map.toggleMapInteractions();
    }
};
