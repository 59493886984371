import PortalApi from 'helpers/api/PortalApiClient';

// temporal? unknown final SSO LOGIN repository result

export default class ItrackLogin extends PortalApi<unknown> {
    /* logout = async (): Promise<unknown> => {
        return await this.instance
            .get(`${process.env.REACT_APP_ITRACK_LOGIN_URL || 'https://login-dev.atmstechnology.com'}/logout`, {
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(this.SUCCESS);
    }; */

    logout = async (): Promise<unknown> => {
        return await fetch(
            `${process.env.REACT_APP_ITRACK_LOGIN_URL || 'https://login-dev.atmstechnology.com'}/logout-webtrack`,
            {
                mode: 'no-cors',
                method: 'POST',
                credentials: 'include'
            }
        ).then((response) => {
            this.SUCCESS;
        });
    };
}
