import React, { useCallback, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { FormType, LoginProps, LoginResponse } from './Login.type';
import { useTranslation } from 'react-i18next';
import ApiLogin from 'api/LoginCheck';
import { setJWT, loggedIn } from 'helpers/authentication';
import * as Yup from 'yup';
import { Alert, Grid, Link, TextField, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import Checkbox from 'components/Ui/Components/UiCheckbox';
import Button from 'components/Ui/Components/UiButton';
import { LoginShowContent, showContentType } from 'states/global/Login';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import HelperText from 'components/Mixs/HelperText/HelperText';
import { AlertContainer, CustomDivider, LoginViewContent } from './Login.style';
import { RedirectParams } from 'pages/Redirect/Redirect.atom';
import { getSystemName } from 'helpers';

const apiLogin = new ApiLogin();
const CONTACT_EMAIL = 'customer.accounts@bmst.bridgestone';

const Login: React.FC<LoginProps> = (): JSX.Element => {
    const { t: translator } = useTranslation();
    const setLoginShowContent = useSetRecoilState(LoginShowContent);
    const Year = DateTime.now().year;
    const navigate = useNavigate();
    const redirectParams = useRecoilValue(RedirectParams);

    const valuesInitForm = {
        username: '',
        password: '',
        stay_signed_in: true
    };

    const Formschema = Yup.object().shape({
        username: Yup.string().required(translator('p.this_field_is_required')),
        password: Yup.string().required(translator('p.this_field_is_required'))
    });

    const handleSubmit = async (e, setSubmitting) => {
        try {
            const d: object = { username: e.username, password: e.password };

            if (location.href.includes('helpjuice')) {
                handleHelpjuice(d, setSubmitting);
            } else {
                apiLogin.showError404 = false;
                const data: LoginResponse = await apiLogin.post({ data: d, version: '', apiProject: undefined });
                setJWT(data.data.token);
                setSubmitting(false);
                navigate(redirectParams.redirectTo ? redirectParams.redirectTo : '/dashboard');
            }
        } catch (error) {
            setSubmitting(false);
        }
    };

    const handleHelpjuice = async (userData, setSubmitting) => {
        try {
            const data = await apiLogin.getHelpJuice(userData);
            location.href = `https://helpjuice.com/jwt/atms?jwt=${data.token}`;
        } catch (e) {
            setSubmitting(false);
        }
    };

    const isLoggedIn = useCallback(() => {
        return loggedIn() && !location.href.includes('helpjuice');
    }, [loggedIn()]);

    useEffect(() => {
        if (isLoggedIn()) {
            navigate('/dashboard');
        }
    }, [isLoggedIn()]);

    return (
        <LoginViewContent>
            <Typography variant='h4' align='center' gutterBottom data-testid='LoginToAccountHeader'>
                {translator('t.login_to_your_account')}
            </Typography>
            <Formik
                initialValues={valuesInitForm}
                onSubmit={(values: FormType, actions) => {
                    handleSubmit(values, actions.setSubmitting);
                }}
                validationSchema={Formschema}
            >
                {(propsF: FormikProps<FormType>) => {
                    const { values, touched, errors, handleBlur, handleChange, isSubmitting } = propsF;
                    return (
                        <Form>
                            <Grid container spacing={1} justifyContent='space-around' direction='row'>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <TextField
                                        name='username'
                                        id='username'
                                        label={translator('t.username')}
                                        value={values.username}
                                        type='username'
                                        helperText={
                                            <HelperText
                                                error={!!errors.username && !!touched.username}
                                                text={errors.username}
                                            />
                                        }
                                        error={!!(errors.username && touched.username)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        variant='outlined'
                                        size='small'
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <TextField
                                        name='password'
                                        id='password'
                                        label={translator('t.password')}
                                        value={values.password}
                                        type='password'
                                        helperText={
                                            <HelperText
                                                error={!!errors.password && !!touched.password}
                                                text={errors.password as string}
                                            />
                                        }
                                        error={!!(errors.password && touched.password)}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        variant='outlined'
                                        size='small'
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Checkbox
                                        label={translator('p.stay_signed_in')}
                                        checked={values.stay_signed_in}
                                        onChange={handleChange}
                                        name='stay_signed_in'
                                        sx={{ fontSize: '0.93rem' }}
                                        testid='StaySignedIn'
                                    />
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Button
                                        variant='contained'
                                        type='submit'
                                        size='large'
                                        disabled={isSubmitting}
                                        loading={isSubmitting}
                                        sx={{ fontSize: '0.93rem' }}
                                    >
                                        {translator('t.continue')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>

            <Typography variant='subtitle1' align='center' gutterBottom>
                <Link
                    href='javascript:void(0)'
                    onClick={() => setLoginShowContent(showContentType.RECOVERY_PASSWORD)}
                    variant='h5'
                    underline='hover'
                    data-testid='Forgotten-Password-Link'
                >
                    {translator('t.forgotten_password')}
                </Link>
                {' - '}
                <Link
                    href='javascript:void(0)'
                    onClick={() => setLoginShowContent(showContentType.USERNAME_RECOVERY)}
                    variant='h5'
                    underline='hover'
                    data-testid='Forgot-Username-Link'
                >
                    {translator('t.forgot_username')}
                </Link>
            </Typography>
            {/* ---SSO LOGIN Temporarly removed---
           
           <CustomDivider data-testid={'Options-Divider'}> {translator('t.or_login')}</CustomDivider>

            <Grid
                container
                spacing={1}
                justifyContent='space-around'
                direction='row'
                data-testid={'Aditional-Login-Options-Container'}
            >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Button
                        data-testid={'Login-Options-SSO-Btn'}
                        variant='outlined'
                        type='submit'
                        size='large'
                        onClick={() => setLoginShowContent(showContentType.LOGIN_SSO)}
                        //disabled={isSubmitting}
                        //loading={isSubmitting}
                        sx={{ fontSize: '0.93rem' }}
                    >
                        {translator('t.sign_in_with_sso')}
                    </Button>
                </Grid>
            </Grid> 
            ---SSO LOGIN Temporarly removed--- */}

            <AlertContainer>
                <Alert variant={'filled'} severity='info' data-testid={'Alert-Login-testid'}>
                    {`${translator('p.info_problem_login_message')} `}
                    <Link data-testid={'Link-contact-email-login-testid'} href={`mailto: ${CONTACT_EMAIL}`}>
                        {CONTACT_EMAIL}
                    </Link>
                </Alert>
            </AlertContainer>
            <Typography variant='body1' align='center' gutterBottom>
                <Link href='/privacy-policy' target='_blank' variant='h5' underline='hover'>
                    {`${translator('t.privacy_policy')} `}
                </Link>
                | © {getSystemName()} - {Year}
            </Typography>
        </LoginViewContent>
    );
};

export default Login;
