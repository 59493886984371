import React from 'react';
import { TyreFilterContainer } from './TyreFilter.style';
import { TyreFilterProps } from './TyreFilter.type';
import { Grid } from '@mui/material';
import TyreManufacturerLazyLoading from '../TyreManufacturerLazyLoading/TyreManufacturerLazyLoading';
import TyreModelLazyLoading from '../TyreModelLazyLoading/TyreModelLazyLoading';
import { useRecoilState } from 'recoil';
import { TyreFilter } from './TyreFilter.atom';

const TyreFilterContent: React.FC<TyreFilterProps> = (): JSX.Element => {
    const [tyreFilter, setTyreFilter] = useRecoilState(TyreFilter);

    return (
        <TyreFilterContainer data-testid='TyreFilterContent'>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TyreManufacturerLazyLoading
                        onChangeManufacturer={(id, label) => {
                            if (id && label) {
                                setTyreFilter((current) => ({
                                    ...current,
                                    tyreManufacturer: {
                                        value: id,
                                        label
                                    }
                                }));
                            } else {
                                setTyreFilter((current) => ({ ...current, tyreManufacturer: undefined }));
                            }
                        }}
                        clearButton
                        manufacturerValue={
                            tyreFilter?.tyreManufacturer?.value
                                ? tyreFilter?.tyreManufacturer
                                : { label: 'reset', value: 0 }
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TyreModelLazyLoading
                        onChangeModel={(id, label) => {
                            if (id && label) {
                                setTyreFilter((current) => ({
                                    ...current,
                                    tyreModel: {
                                        value: id,
                                        label
                                    }
                                }));
                            } else {
                                setTyreFilter((current) => ({
                                    ...current,
                                    tyreModel: undefined
                                }));
                            }
                        }}
                        clearButton
                        queryFilter={
                            tyreFilter.tyreManufacturer?.value
                                ? { tyreManufacturer: tyreFilter.tyreManufacturer?.value }
                                : {}
                        }
                        modelValue={tyreFilter.tyreModel?.value ? tyreFilter?.tyreModel : { label: 'reset', value: 0 }}
                    />
                </Grid>
            </Grid>
        </TyreFilterContainer>
    );
};

export default TyreFilterContent;
