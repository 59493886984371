import { Typography } from '@mui/material';
import styled from 'styled-components';

export const RuleOverviewDetailContainer = styled.div`
    padding: 10px 20px;
`;

export const DetailTitle = styled(Typography)`
    font-weight: 100 !important;
`;
