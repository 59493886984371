import React from 'react';
import * as style from './HubConnectionIndicator.style';
import * as type from './HubConnectionIndicator.type';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NO_ARIA_LABEL_ALLOWED } from 'variables';

const getKeyTitleTooltip = (hubColor: string, isNotTransmittingHub: boolean | undefined): string => {
    let keyTitleTooltip = isNotTransmittingHub ? 't.not_transmitting_hub' : 't.transmitting_hub';

    if (hubColor === 'orange') {
        keyTitleTooltip = 't.transmitting_hub_15_mns_1_day';
    }

    return keyTitleTooltip;
};

export const HubConnectionIndicatorContent: React.FC<type.HubConnectionIndicatorViewProps> = ({
    isNotTransmittingHub,
    color,
    connectionType,
    testid
}): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <Tooltip
            title={translate(getKeyTitleTooltip(color.colorString, isNotTransmittingHub))}
            aria-label={NO_ARIA_LABEL_ALLOWED}
        >
            <style.HubConnectionIndicatorContent data-testid='HubConnectionIndicatorContent'>
                <style.IndicatorIcon
                    color={color.colorHex}
                    data-testid={`${testid ?? 'hubConnectionIcon'}`}
                    icon={['fas', connectionType === 'eth0' ? 'signal' : isNotTransmittingHub ? 'wifi-slash' : 'wifi']}
                    size='sm'
                />
            </style.HubConnectionIndicatorContent>
        </Tooltip>
    );
};
