import React from 'react';
import { TyreManufacturerLazyLoadingProps } from './TyreManufacturerLazyLoading.type';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete';
import TyreManufacturer from 'api/TyreManufacturer';
import { useTranslation } from 'react-i18next';

const tyreManufacturerAPI = new TyreManufacturer();

const TyreManufacturerLazyLoading: React.FC<TyreManufacturerLazyLoadingProps> = ({
    onChangeManufacturer,
    error,
    helperText,
    manufacturerValue,
    clearButton
}) => {
    const { t: translate } = useTranslation();

    return (
        <LazyloadingAutocomplete
            id='tyre-manufacturers'
            queryId='TyreManufacturerLazyLoading'
            query={tyreManufacturerAPI.getTable}
            optionKey='name'
            fullWidth
            optionValue='id'
            label={translate('t.tyre_manufacturer')}
            width={320}
            error={error}
            preselectedValue={manufacturerValue}
            size='small'
            helperText={helperText}
            clearButton={clearButton}
            onValueChange={onChangeManufacturer}
        />
    );
};

export default TyreManufacturerLazyLoading;
