import React, { useEffect, useMemo, useState } from 'react';
import { RuleAffectedVehiclesListContainer, SchemaCover, VehicleCover } from './RuleAffectedVehiclesList.style';
import { RuleAffectedVehiclesListProps } from './RuleAffectedVehiclesList.type';
import { Skeleton, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import VehicleSchema from 'components/Vehicle/NewVehicleSchema/VehicheSchema';
import {
    SchemaBox,
    SchemaContent,
    VehicleLabel,
    VehicleListContainer
} from 'components/Alert/AlertsAffectedVehiclesList/AlertsAffectedVehiclesList.style';
import { useQuery } from '@tanstack/react-query';
import { VehicleByRulePaginator, VehicleQueryKeys } from 'models/Vehicle.type';
import VehicleApi from 'api/Vehicle';

const Vehicle = new VehicleApi();

const RuleAffectedVehiclesListContent: React.FC<RuleAffectedVehiclesListProps> = ({ ruleId }): JSX.Element => {
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const theme = useTheme();
    const xlScreen = useMediaQuery(theme.breakpoints.up('xl'));
    const [vehicleData, setVehicleData] = useState<VehicleByRulePaginator['items'][0][]>([]);

    const { data, isLoading, isRefetching, refetch } = useQuery<any, Error, VehicleByRulePaginator>(
        [VehicleQueryKeys.getAffectedVehiclesByRule, ruleId],
        () =>
            Vehicle.getVehicleByRule(ruleId, {
                limit: xlScreen ? 40 : 30,
                page
            }),
        {
            refetchOnWindowFocus: false,
            enabled: false,
            staleTime: 0,
            cacheTime: 0,
            onSuccess: (data) => {
                if (page === 1) {
                    setVehicleData([]);
                }
                const hasMoreData = (data?.paginator?.page ?? 0) < (data?.paginator?.pageCount ?? 0);
                setVehicleData((current) => [...current, ...data.items]);
                if (hasMoreData) {
                    setPage((current) => current + 1);
                }
                setHasMore(hasMoreData);
            }
        }
    );

    const getColoredWheels = (wheels: VehicleByRulePaginator['items'][0]['wheel']): Record<string, any> => {
        const colors = {};
        for (let i = 1; i < 21; i++) {
            colors[i] = { color: undefined };
        }
        for (let i = 0, length = wheels.length; i < length; i++) {
            colors[wheels[i].position] = {
                color: '#f00'
            };
        }
        return colors;
    };

    const onScroll = (e): void => {
        if (e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight - 10) {
            if (hasMore) {
                refetch();
            }
        }
    };

    const skeletons = useMemo(() => {
        let skeletons: JSX.Element[] = [];
        for (let i = 0; i < 10; i++) {
            skeletons.push(
                <SchemaContent>
                    <VehicleLabel variant='subtitle2' gutterBottom>
                        <Skeleton variant='rectangular' width={100} height={20} />
                    </VehicleLabel>
                    <SchemaBox>
                        <Skeleton variant='rectangular' width={100} height={120} />
                    </SchemaBox>
                </SchemaContent>
            );
        }
        return skeletons;
    }, []);

    useEffect(() => {
        refetch();
    }, [ruleId]);

    return (
        <RuleAffectedVehiclesListContainer data-testid='RuleAffectedVehiclesListContent'>
            <VehicleListContainer onScroll={onScroll} elevation={0}>
                {isLoading
                    ? skeletons
                    : vehicleData.map((vehicle, index) => {
                          return (
                              <SchemaContent key={index}>
                                  <Tooltip key={`tooltip-schema-${index}`} title={vehicle.name}>
                                      <VehicleLabel key={`vehicle-label-${index}`} variant='subtitle2' gutterBottom>
                                          {vehicle.name}
                                      </VehicleLabel>
                                  </Tooltip>
                                  <SchemaBox key={`schema-box-${index}`}>
                                      <VehicleSchema
                                          key={`vehicle-schema-${index}`}
                                          schema={vehicle.vehicleConfigurationV2.distribution}
                                          emptySchema
                                          highlightWheels={getColoredWheels(vehicle.wheel)}
                                      />
                                  </SchemaBox>
                              </SchemaContent>
                          );
                      })}
            </VehicleListContainer>
        </RuleAffectedVehiclesListContainer>
    );
};

export default RuleAffectedVehiclesListContent;
