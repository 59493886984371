import React, { useMemo, useState } from 'react';
import { ShiftTimeCalendarActionContent } from './ShiftTimeCalendarAction.view';
import { InitialValuesType, ShiftTimeCalendarActionProps, TimeRange } from './ShiftTimeCalendarAction.type';
import { Wrapper } from 'helpers/wrapper';
import { DateTime } from 'luxon';
import TeamShiftTime from 'api/TeamShiftTime';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Success, Warning } from 'components/Popup/Popup';
import { DefaultRequestPropsType, PatchPutByIdType } from 'helpers/api/type';
import TeamShift from 'api/TeamShift';
import { TeamShiftPayload, TeamShiftQueryKeys } from 'models/TeamShift.type';
import { TeamShiftTimeQueryKeys, TeamShiftTimeTableModel } from 'models/TeamShiftTime.type';
import useFreeShiftChecker from '../ShiftTimeCalendar/FreeShiftChecker';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { cacheTimeToMilliseconds } from 'helpers/cache';

const teamShiftTimeApi = new TeamShiftTime();
const teamShiftApi = new TeamShift();

export const optionsDate: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false
};

const ShiftTimeCalendarAction: React.FC<ShiftTimeCalendarActionProps> = ({ scheduler }): JSX.Element => {
    const { fromUTCtoUserTimezone, fromUserTimezonetoUTC } = useConverter();
    const dateFrom = useMemo(() => {
        return scheduler.state?.start?.value
            ? DateTime.fromFormat(
                  scheduler.state.start.value.toLocaleTimeString('en-US', optionsDate),
                  'M/d/yyyy, HH:mm:ss'
              )
            : null;
    }, [scheduler.state]);

    const { isRangeFreeTime } = useFreeShiftChecker();
    const { t: translate } = useTranslation();
    const shift = scheduler.edited;
    const [initialValues] = useState<InitialValuesType>({
        start: dateFrom ?? DateTime.local(),
        shiftTime: shift?.shiftTime ?? ''
    });
    const [loading, setLoading] = useState(false);
    const queryClient = useQueryClient();

    const { data: teamShiftTimeData, isLoading: teamShiftTimeDataDataLoading } = useQuery<TeamShiftTimeTableModel>(
        [TeamShiftTimeQueryKeys.getTeamShiftTimeDropDown],
        () => teamShiftTimeApi.getByCriteria({ criteria: { limit: 100 } }),
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(55, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(60, 'minutes')
        }
    );

    const { mutate: addTeamShift } = useMutation<DefaultRequestPropsType, unknown, TeamShiftPayload>(
        teamShiftApi.addTeamShift,
        {
            onSuccess: (data) => {
                if (data) {
                    Success({
                        text: `${translate('t.shift')} ${translate('p.has_been_added')}`
                    });
                    scheduler.close();
                    setLoading(false);
                    queryClient.invalidateQueries([TeamShiftQueryKeys.getTeamShiftList]);
                }
            },
            onError: (e) => {
                console.error(e);
            }
        }
    );

    const { mutate: updateTeamShift } = useMutation<
        DefaultRequestPropsType,
        unknown,
        PatchPutByIdType<TeamShiftPayload, DefaultRequestPropsType>
    >(teamShiftApi.patchById, {
        onSuccess: () => {
            Success({ text: `${translate('t.shift')} ${translate('p.has_been_edited')}` });
            scheduler.close();
            setLoading(false);
            queryClient.invalidateQueries([TeamShiftQueryKeys.getTeamShiftList]);
        }
    });

    const handleSubmit = async (value) => {
        try {
            setLoading(true);
            const dateCurrent = DateTime.now().startOf('day');

            if (DateTime.isDateTime(value.start) && dateCurrent.diff(value.start.setZone('utc')).as('day') >= 1) {
                Warning({ text: translate('p.date_before_today') });
                throw new Error(translate('p.date_before_today'));
            }

            const startTime: string = DateTime.fromISO(value.shiftTime?.startTime, { zone: 'utc' }).toFormat(
                'HH:mm:ss'
            );
            const endTime: string = DateTime.fromISO(value.shiftTime?.endTime, { zone: 'utc' }).toFormat('HH:mm:ss');

            const dateFrom: string = value.start?.toFormat('yyyy-MM-dd');
            const timeRange: TimeRange = { startTime, endTime };

            const dateFromAdd = fromUserTimezonetoUTC({
                date: value.shiftTime?.startTime,
                format: 'time',
                customFormat: 'HH:mm:ss'
            });
            const dateToAdd = fromUserTimezonetoUTC({
                date: value.shiftTime?.endTime,
                format: 'time',
                customFormat: 'HH:mm:ss'
            });

            const getDifferentDay = shift ? shift?.differentDay ?? 0 : 1;
            const dateTo: string = value.start.plus({ days: getDifferentDay })?.toFormat('yyyy-MM-dd');
            const differentDay: boolean = shift ? dateFrom !== dateTo : dateFromAdd > dateToAdd;
            const dateRange = { dateFrom, dateTo };

            const getIsRangeFreeTime = await isRangeFreeTime(
                value.start?.toFormat('yyyy-MM-dd'),
                shift?.event_id as number,
                timeRange,
                differentDay,
                dateRange
            );

            if (!getIsRangeFreeTime) {
                const message: string = translate('p.there_is_no_space_shift', {
                    date: value.start?.toFormat('yyyy-MM-dd'),
                    time: `${fromUTCtoUserTimezone({
                        date: value.shiftTime?.startTime,
                        format: 'time'
                    })} - ${fromUTCtoUserTimezone({
                        date: value.shiftTime?.endTime,
                        format: 'time'
                    })}`
                });
                Warning({ text: message, customSetting: { duration: 7000 } });
                throw new Error(message);
            }

            const newValues: TeamShiftPayload = {
                shiftTime: value.shiftTime?.id,
                startDate: value.start?.toFormat('yyyy-MM-dd'),
                endDate: value.start?.toFormat('yyyy-MM-dd')
            };

            if (shift) {
                await updateTeamShift({ id: Number(shift.event_id), data: newValues });
            } else {
                await addTeamShift(newValues);
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    return (
        <ShiftTimeCalendarActionContent
            data-testid='ShiftTimeCalendarAction-testid'
            handleSubmit={handleSubmit}
            inicialvaluesFormik={initialValues}
            teamShiftTimeListsDataLoading={teamShiftTimeDataDataLoading}
            scheduler={scheduler}
            teamShiftTimeData={teamShiftTimeData ? teamShiftTimeData?.items : []}
            loading={loading}
        />
    );
};

export default Wrapper(ShiftTimeCalendarAction);
