import { atom } from 'recoil';

export const FilterPeriod = atom<number | undefined>({
    key: 'FilterPeriod',
    default: undefined
});

export const SearchVehicle = atom<string>({
    key: 'SearchVehicle',
    default: ''
});

export type ColumnNameVehiclesStats = {
    id: string;
    label: string;
    isVisible: boolean;
};

export const ColumnNameVehiclesStatsAtom = atom<ColumnNameVehiclesStats[]>({
    key: 'ColumnNameVehiclesStatsAtom',
    default: []
});
