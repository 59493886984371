import Grid from '@mui/material/Grid';
import { LayoutContent as LayoutContentProps } from './Layout.type';
import { LayoutContainer, MainBodyApp, MainBodyContainer } from './Layout.style';
import React from 'react';
import MainMenu from '../MainMenu';
import TopBar from '../TopBar';
import { Outlet } from 'react-router-dom';
import UiFullScreenLoader from '../Ui/Components/UiFullScreenLoader/UiFullScreenLoader';
import { UserInfo } from 'states/global/User';
import { useRecoilValue } from 'recoil';
import StatedModal from '../Mixs/StatedModal/StatedModal';
import { useProSidebar } from 'react-pro-sidebar';
import Modal from '../StatedModal/Modal/Modal';

import WarnNotifications from 'components/Customer/WarnNotifications/WarnNotifications';
import Footer from 'components/Footer/Footer';
import SystemNotificationMessage from 'components/SystemNotificationMessage/SystemNotificationMessage';
import AlertConnection from './AlertConnection/AlertConnection';
import { GetSystemNotification } from 'components/Queries/SystemNotification/SystemNotification';
import MuteNotificationWarning from 'components/Mixs/MuteNotificationWarning/MuteNotificationWarning';

export const LayoutContent: React.FC<LayoutContentProps> = ({ userData, theme }): JSX.Element => {
    const appIsLoading = !userData;
    const userInfo = useRecoilValue(UserInfo);
    const { collapsed } = useProSidebar();

    return (
        <LayoutContainer data-testid='LayoutContent'>
            <TopBar loading={appIsLoading} title='User-Name'></TopBar>
            <MainMenu loading={appIsLoading} />
            <MainBodyContainer $marginLeft={theme.easyReadingMode ? '220px' : '217px'} $collapsedSidebar={collapsed}>
                <MainBodyApp
                    $marginLeft={theme.easyReadingMode ? '220px' : '217px'}
                    $collapsedSidebar={collapsed}
                    $easyMode={theme.easyReadingMode}
                >
                    <MuteNotificationWarning />
                    <GetSystemNotification>
                        {({ data, isRefetching }) => (
                            <>
                                <SystemNotificationMessage data={data} isRefetching={isRefetching} />
                                <AlertConnection />
                            </>
                        )}
                    </GetSystemNotification>
                    {userInfo?.user?.customer?.id && (
                        <WarnNotifications customerId={userInfo?.user?.customer?.id}></WarnNotifications>
                    )}
                    <Grid container justify-content='space-around' direction='row'>
                        <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            key={`${userInfo?.user?.customer?.id}${userInfo.user?.userSetting.timezone.id}`}
                        >
                            <Outlet />
                        </Grid>
                    </Grid>
                </MainBodyApp>
                {<Footer />}
            </MainBodyContainer>
            <StatedModal />
            <Modal />
            <UiFullScreenLoader />
        </LayoutContainer>
    );
};
