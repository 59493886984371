import React, { useEffect } from 'react';
import { JobsTableContent } from './JobsTable.view';
import { JobsTableProps } from './JobsTable.type';
import { useTranslation } from 'react-i18next';
import useConverter from 'components/CustomHooks/Converter/Converter';
import Menu from 'components/Ui/Components/UiMenu/UiMenu';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button, CircularProgress } from '@mui/material';
import JobsLogsTable from '../JobsLogsTable/JobsLogsTable';
import DeviceUpdateJob from 'api/DeviceUpdateJob';
import { useMutation } from '@tanstack/react-query';
import { Success } from 'components/Popup/Popup';
import { Text } from '../BatchesTable/BatchesTable.style';
import ModalButton from 'components/Ui/Components/UiButton/UiButton';
import useModal from 'components/CustomHooks/ModalHook/ModalHook';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ButtonContainer } from './JobsTable.style';
import { RefreshSimpleTable } from 'components/Ui/Components/UiSimpleTable/UiSimpleTable.atom';
import { Wrapper } from 'helpers/wrapper';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';
const deviceUpdateJobApi = new DeviceUpdateJob();

const JobsTable: React.FC<JobsTableProps> = ({ batchId }): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromUTCToTimezone } = useConverter();
    const { setModalState, updateModalState, modalState } = useModal(1);
    const queryTableFn = useRecoilValue(QueryFn(`batchesHub-QueryFn`));
    const setRefreshSimpleTableAtom = useSetRecoilState(RefreshSimpleTable);

    const updateModal = (isOpen?: boolean): void => {
        updateModalState({
            ...modalState,
            buttons: <ModalButton skin='success'>{<CircularProgress size='1.75em' />}</ModalButton>,
            isOpen: typeof isOpen === 'undefined' ? modalState.isOpen : isOpen
        });
    };

    const { mutate: cancelJobMutate, isLoading: cancelJobMutateLoading } = useMutation(deviceUpdateJobApi.cancelJob, {
        onSuccess: () => {
            Success({
                text: translate('t.job_successfully_cancelled')
            });
        },
        onSettled: () => {
            setModalState({});
            setRefreshSimpleTableAtom(true);
            queryTableFn.refetch instanceof Function && queryTableFn.refetch();
        }
    });

    const { mutate: resetJobMutate, isLoading: resetJobMutateLoading } = useMutation(deviceUpdateJobApi.resetJob, {
        onSuccess: () => {
            Success({
                text: translate('t.job_successfully_rebooted')
            });
        },
        onSettled: () => {
            setModalState({});
            setRefreshSimpleTableAtom(true);
            queryTableFn.refetch instanceof Function && queryTableFn.refetch();
        }
    });

    const logsContent = (id) => {
        const contentModalJob = {
            content: <JobsLogsTable jobId={id} />,
            leftTitle: translate('t.logs'),
            rightTitle: `${translate('t.job_id')} ${id}`,
            width: 700,
            height: 500,
            buttons: <></>,
            isOpen: true,
            customCancelTitle: translate('t.close')
        };

        setModalState({ ...contentModalJob });
    };

    const handleModalCancel = (id: number): void => {
        const contentModalCancel = {
            id: 'CancelJobActions',
            leftTitle: `${translate('t.cancel_job')}`,
            content: (
                <Text style={{ margin: '20px 0 0 0 ' }} variant='h6'>
                    {translate('p.are_you_sure_to_cancel_job')}
                </Text>
            ),
            customCancelTitle: translate('t.close'),
            buttons: (
                <ModalButton
                    skin='success'
                    color='error'
                    variant='contained'
                    onClick={() => {
                        cancelJobMutate(id);
                    }}
                >
                    {cancelJobMutateLoading ? <CircularProgress size='1.75em' /> : translate('t.cancel')}
                </ModalButton>
            ),
            width: 400,
            height: 150,
            widthUnit: 'px',
            isOpen: true
        };
        setModalState({ ...contentModalCancel });
    };

    const handleModalReset = (id: number): void => {
        const contentModalReset = {
            id: 'ResetJobActions',
            leftTitle: `${translate('t.reset_job')}`,
            content: (
                <Text style={{ margin: '20px 0 0 0 ' }} variant='h6'>
                    {translate('p.are_you_sure_to_reset_job')}
                </Text>
            ),
            customCancelTitle: translate('t.close'),
            buttons: (
                <ModalButton
                    skin='success'
                    color='error'
                    variant='contained'
                    onClick={() => {
                        resetJobMutate(id);
                    }}
                >
                    {resetJobMutateLoading ? <CircularProgress size='1.75em' /> : translate('t.reset')}
                </ModalButton>
            ),
            width: 400,
            height: 150,
            widthUnit: 'px',
            isOpen: true
        };
        setModalState({ ...contentModalReset });
    };

    useEffect(() => {
        updateModal();
    }, [cancelJobMutateLoading, resetJobMutateLoading]);

    const columns = [
        {
            header: translate('t.job_id'),
            accessor: 'id',
            parseData: (id) => (
                <span>
                    <Menu
                        items={[
                            {
                                title: translate('t.show_logs'),
                                value: id,
                                action: () => {
                                    logsContent(id);
                                }
                            },
                            {
                                title: translate('t.reset_job'),
                                value: id,
                                action: () => {
                                    handleModalReset(id);
                                }
                            },

                            {
                                title: translate('t.cancel_job'),
                                value: id,
                                action: () => {
                                    handleModalCancel(id);
                                }
                            }
                        ]}
                        rootEl={
                            <ButtonContainer>
                                <Button
                                    aria-controls='simple-menu'
                                    aria-haspopup='true'
                                    style={{ minWidth: '20px', maxWidth: '20px' }}
                                    title={translate('t.actions')}
                                >
                                    <SettingsIcon />
                                </Button>
                                <p>{id ?? '-'}</p>
                            </ButtonContainer>
                        }
                    />
                </span>
            )
        },
        {
            header: translate('t.hub_id'),
            accessor: 'device',
            disableSortBy: true,
            parseData: (device) => device.id ?? '-'
        },
        {
            header: translate('t.state'),
            accessor: 'deviceUpdateState',
            disableSortBy: true,
            parseData: (deviceUpdateState) => deviceUpdateState.state ?? '-'
        },
        {
            header: translate('t.last_transmitted'),
            accessor: 'device',
            disableSortBy: true,
            parseData: (props) =>
                props.deviceInfo.lastTransmission ? fromUTCToTimezone(props.deviceInfo.lastTransmission, true) : '-'
        },
        {
            header: translate('t.download_start'),
            accessor: 'downloadStart',
            parseData: (downloadStart) => (downloadStart ? fromUTCToTimezone(downloadStart, true) : '-')
        },
        {
            header: translate('t.download_finished'),
            accessor: 'downloadFinished',
            parseData: (downloadFinished) => (downloadFinished ? fromUTCToTimezone(downloadFinished, true) : '-')
        },
        {
            header: translate('t.download_attempts'),
            accessor: 'downloadAttempts',
            parseData: (downloadAttempts) => downloadAttempts ?? '-'
        },
        {
            header: translate('t.update_start'),
            accessor: 'updateStart',
            parseData: (updateStart) => (updateStart ? fromUTCToTimezone(updateStart, true) : '-')
        },
        {
            header: translate('t.update_finished'),
            accessor: 'updateFinished',
            parseData: (updateFinished) => (updateFinished ? fromUTCToTimezone(updateFinished, true) : '-')
        },
        {
            header: translate('t.update_attempts'),
            accessor: 'updateAttempts',
            parseData: (updateAttempts) => updateAttempts ?? '-'
        },
        {
            header: translate('t.reboot'),
            accessor: 'reboot',
            parseData: (reboot) => reboot ?? '-'
        },
        {
            header: translate('t.test_start'),
            accessor: 'testStart',
            parseData: (testStart) => (testStart ? fromUTCToTimezone(testStart, true) : '-')
        },
        {
            header: translate('t.test_finished'),
            accessor: 'testFinished',
            parseData: (testFinished) => (testFinished ? fromUTCToTimezone(testFinished, true) : '-')
        },
        {
            header: translate('t.created_at'),
            accessor: 'createdAt',
            parseData: (createdAt) => (createdAt ? fromUTCToTimezone(createdAt, true) : '-')
        },
        {
            header: translate('t.created_by'),
            accessor: 'createdBy',
            parseData: (createdBy) => (createdBy ? `${createdBy.firstname ?? ''} ${createdBy.lastname ?? ''} ` : '-')
        },
        {
            header: translate('t.updated_at'),
            accessor: 'updatedAt',
            parseData: (updatedAt) => (updatedAt ? fromUTCToTimezone(updatedAt, true) : '-')
        },
        {
            header: translate('t.updated_by'),
            accessor: 'updatedBy',
            parseData: (updatedBy) => (updatedBy ? `${updatedBy.firstname ?? ''} ${updatedBy.lastname ?? ''} ` : '-')
        }
    ];

    return <JobsTableContent data-testid={`JobsTable-testid-${batchId}`} columns={columns} batchId={batchId} />;
};

export default Wrapper(JobsTable);
