import React from 'react';
import { AlertSettingOverviewContainer, LoadingSkeletor } from './AlertSettingOverview.style';
import { AlertSettingOverviewProps } from './AlertSettingOverview.type';
import { useQuery } from '@tanstack/react-query';
import { RuleByVehicle, RuleQueryKeys } from 'models/Rule.type';
import RuleApi from 'api/Rule';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { useRecoilValue } from 'recoil';
import { SelectedVehicleId } from './AlertSettingOverview.atom';
import { Alert, Grid, Hidden, Skeleton } from '@mui/material';
import AlertSettingsOverviewTableLine from '../AlertSettingsOverviewTableLine/AlertSettingsOverviewTableLine';
import { useTranslation } from 'react-i18next';

const Rule = new RuleApi();

const AlertSettingOverviewContent: React.FC<AlertSettingOverviewProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const vehicleId = useRecoilValue(SelectedVehicleId);

    const { data, isLoading, isRefetching } = useQuery<any, Error, RuleByVehicle>(
        [RuleQueryKeys.getByVehicle, vehicleId],
        () => Rule.get({ extendUrl: `vehicle/${vehicleId}` }),
        {
            enabled: vehicleId > 0,
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(10, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(10, 'minutes')
        }
    );

    const LoadingSkeletons = (
        <>
            <LoadingSkeletor variant='rectangular' width={'100%'} height={113} />
            <LoadingSkeletor variant='rectangular' width={'100%'} height={113} />
            <LoadingSkeletor variant='rectangular' width={'100%'} height={113} />
            <LoadingSkeletor variant='rectangular' width={'100%'} height={113} />
        </>
    );

    return (
        <AlertSettingOverviewContainer data-testid='AlertSettingOverviewContent'>
            {vehicleId < 1 && <Alert severity='info'>{translate('p.select_vehicle_to_see_rules')}</Alert>}
            {vehicleId > 0 && (
                <Grid container spacing={2}>
                    {isLoading || isRefetching ? (
                        LoadingSkeletons
                    ) : (
                        <>
                            {data?.vehicle?.wheel.map((wheel, index) => (
                                <Grid item key={index} xs={12} md={12} lg={12}>
                                    <AlertSettingsOverviewTableLine
                                        showVehicle
                                        pos={wheel.position}
                                        vehicleConfiguration={data.vehicle.vehicleConfigurationV2.distribution}
                                        limits={wheel.rule.ruleLimit}
                                        optimalColdPressure={wheel.rule.optimalColdPressure}
                                        ruleType={wheel.rule.ruleType}
                                    />
                                </Grid>
                            ))}
                        </>
                    )}
                </Grid>
            )}
        </AlertSettingOverviewContainer>
    );
};

export default AlertSettingOverviewContent;
