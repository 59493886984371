import React from 'react';
import { VehicleManufacturerAndModelType } from './VehicleManufacturerAndModel.type';
import { VehicleManufacturerAndModelContent } from './VehicleManufacturerAndModel.style';
import { Grid } from '@mui/material';
import UiWidget from '../../Ui/Components/UiWidget/UiWidget';
import { VARIANT_BUTTON } from '../../Ui/variables';
import { useTranslation } from 'react-i18next';
import VehicleModel from 'api/VehicleModel';
import VehicleManufacturer from 'api/VehicleManufacturer';
import UiButton from '../../Ui/Components/UiButton/UiButton';
import useModal from 'components/CustomHooks/ModalHook';
import AddVehicleManufacturer from '../AddVehicleManufacturer';
import AddVehicleModel from '../AddVehicleModel';
import UiTable from 'components/Ui/UiTable/UiTable';
import { ShowVehicleModal } from 'states/global/Modal';
import { useSetRecoilState } from 'recoil';

const vehicleModelAPI = new VehicleModel();
const vehicleManufacturerAPI = new VehicleManufacturer();

const VehicleManufacturerAndModelView: React.FC<VehicleManufacturerAndModelType> = ({
    columnsManufacturer,
    columnsModel
}) => {
    const { t: translate } = useTranslation();
    const { setModalState } = useModal();
    const setVehicleModal = useSetRecoilState(ShowVehicleModal);

    return (
        <VehicleManufacturerAndModelContent>
            <Grid container spacing={2}>
                <Grid item lg={4} sm={12}>
                    <UiWidget
                        testid='vehicle-manufacturer'
                        title={translate('t.manufacturer')}
                        content={
                            <UiTable
                                fetchFn={vehicleManufacturerAPI.getAll}
                                columns={columnsManufacturer}
                                hiddenColumns={[]}
                                queryKey='VehicleManufacturer'
                                defaultSortBy={{
                                    id: 'manufacturer',
                                    desc: true
                                }}
                                rightActionBtns={() => (
                                    <UiButton
                                        testid='vehicle-manufacturer-add-button'
                                        skin='success'
                                        variant={VARIANT_BUTTON}
                                        onClick={() => {
                                            setModalState({
                                                id: 'vehicle-manufacturer-add',
                                                isOpen: true,
                                                content: <AddVehicleManufacturer />,
                                                leftTitle: translate('t.add_manufacturer'),
                                                width: 500,
                                                height: 200
                                            });
                                        }}
                                    >
                                        {translate('t.add_manufacturer')}
                                    </UiButton>
                                )}
                            />
                        }
                    />
                </Grid>
                <Grid item lg={8} sm={12}>
                    <UiWidget
                        testid='vehicle-model'
                        title={translate('t.model')}
                        content={
                            <UiTable
                                fetchFn={vehicleModelAPI.getAll}
                                columns={columnsModel}
                                hiddenColumns={[]}
                                queryKey='VehicleModel'
                                rightActionBtns={() => (
                                    <UiButton
                                        testid='vehicle-model-add-button'
                                        skin='success'
                                        variant={VARIANT_BUTTON}
                                        onClick={() => {
                                            setVehicleModal(true);
                                            setModalState({
                                                id: 'vehicle-model-add',
                                                isOpen: true,
                                                content: <AddVehicleModel />,
                                                leftTitle: translate('t.add_model'),
                                                width: 680,
                                                height: 400,
                                                onClose: () => {
                                                    setVehicleModal(false);
                                                }
                                            });
                                        }}
                                    >
                                        {translate('t.add_model')}
                                    </UiButton>
                                )}
                            />
                        }
                    />
                </Grid>
            </Grid>
        </VehicleManufacturerAndModelContent>
    );
};

export default VehicleManufacturerAndModelView;
