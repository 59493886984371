import PortalApi from 'helpers/api/PortalApiClient';
import { BridgestoneLogoModel } from '../models/BridgestoneLogo.type';

export default class BridgestoneLogo extends PortalApi<BridgestoneLogoModel> {
    route = 'bridgestone-logo';

    getLogo = async (theme: string): Promise<string> => {
        return await this.getFile({ apiProject: 'public', criteria: { darkLogo: theme } });
    };
}
