import React, { useMemo, useRef } from 'react';
import { FaltySensorReporterViewProps } from './FaultySensorReport.type';
import ReportDetail from './ReportDetail/ReportDetail';
import {
    AlertContainer,
    GraphContainer,
    MuiDialogContentStyle,
    SecondStep,
    WarningNoColdPressure
} from './FaultySensorReport.style';
import { DateTimePicker, FaultySensorReporterState, ShowAlerts, Timeline } from 'states/component/FaultySensorReporter';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { UserInfo } from 'states/global/User';
import LeakNotification from '../LeakNotification/LeakNotification';
import { CaptureImageTrigger } from 'states/global/FaultyReporter';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import AlertsList from '../AlertNotification/AlertsList/AlertsList';
import { typeAlert } from '../AlertNotification/TPMSAlertsListTabManager/TPMSAlertsListTabManager.type';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import WidgetStatisticsSpeed from '../StatisticsGraphs/WidgetStatisticsSpeed/WidgetStatisticsSpeed';
import WidgetStatisticsPressure from './WidgetStatisticsPressure/WidgetStatisticsPressure';
import TpmsAlertsQuery from '../Queries/TpmsAlertQuery/TpmsAlertQuery';
import useConverter from '../CustomHooks/Converter/Converter';
import ActionButtons from './ActionButtons/ActionButtons';
import UiButton from '../Ui/Components/UiButton/UiButton';
import RangeActionButtons from './RangeActionButtons/RangeActionButtons';
import { DateRange } from './ReportDetail/ReportDetail.view';
import { Typography } from '@mui/material';
import UiScroll from 'components/Ui/Components/UiScroll/UiScroll';
import { ContainerActions, CustomDialogActions } from 'components/StatedModal/Modal/Modal.style';
import { typeFilter } from '../AlertNotification/TPMSAlertsListTabManager/TPMSAlertsListTabManager.type';
import { ModelTpmsAlerts } from 'models/TpmsAlerts.type';
import ProblemsRadios from './ProblemsRadios/ProblemsRadios';
import WidgetJourneyMap from './WidgetJourneyMap/WidgetJourneyMap';
import { DateTime } from 'luxon';

export const FaultySensorReporterContent: React.FC<FaltySensorReporterViewProps> = (props): JSX.Element => {
    const faultySensorReporterState = useRecoilValue(FaultySensorReporterState);
    const showAlerts = useRecoilValue(ShowAlerts);
    const showMap = faultySensorReporterState.showOnlyGraph;
    const userInfo = useRecoilValue(UserInfo);
    const { t: translate } = useTranslation();
    const setCaptureImageTrigger = useSetRecoilState(CaptureImageTrigger);
    const imageContentRef = useRef() as React.MutableRefObject<HTMLDivElement | null>;
    const userIsSuperAdmin = useMemo(() => checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN]), [userInfo.user]);
    const { dateTimeFormat, fromTimezoneToUTC } = useConverter();
    const [dateTimePicker, setDateTimePicker] = useRecoilState(DateTimePicker);
    const timeline = useRecoilValue(Timeline);
    const resetZoom = dateTimePicker.current.dateFrom.valueOf() !== dateTimePicker.original.dateFrom.valueOf();
    const invalidRangeHour = useMemo(() => {
        return dateTimePicker.current.dateTo.diff(dateTimePicker.current.dateFrom, 'minutes').toObject().minutes > 180;
    }, [dateTimePicker]);
    const externalTemperatureEnabled = useMemo(
        () => !!userInfo.user?.customer.externalTemperatureEnabled,
        [userInfo.user]
    );

    return (
        <>
            <MuiDialogContentStyle data-testid='FaultySensorReport-Modal-Content'>
                <UiScroll height='100%'>
                    <TpmsAlertsQuery
                        currentPage={1}
                        limit={150}
                        keyQuery='getAlertFaultySensorReport'
                        sensorId={faultySensorReporterState.sensorId}
                        vehicleId={faultySensorReporterState.vehicleId}
                        solved={false}
                        measuredFrom={fromTimezoneToUTC(DateTime.now().minus({ days: 30 }).valueOf())}
                        tab='tpms'
                    >
                        {({ data, isLoading }) => (
                            <>
                                <RangeActionButtons
                                    dataPressureLoading={false}
                                    resetZoom={resetZoom}
                                    resetZoomAction={props.resetZoom}
                                    faultySensorReporterState={faultySensorReporterState}
                                    showRangeButtons={
                                        !!faultySensorReporterState.notValidate ||
                                        !faultySensorReporterState.reportDetailId
                                    }
                                />
                                <Grid container rowSpacing={2} spacing={1} sx={{ marginBottom: 2 }}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        lg={showAlerts ? 8 : 12}
                                        sx={{ pl: { xl: 1, lg: 1, md: 1, sm: 0, xs: 0 } }}
                                    >
                                        <GraphContainer ref={imageContentRef}>
                                            {!props.hideReportOptions && !props.leakNotification.show && (
                                                <SecondStep>{translate('p.2_step_report_sensor')}</SecondStep>
                                            )}

                                            {faultySensorReporterState.reportDetailId && resetZoom ? (
                                                <UiButton variant='outlined' onClick={props.resetZoom}>
                                                    {!faultySensorReporterState.reportDetailId
                                                        ? translate('t.reset')
                                                        : translate('t.reset_zoom')}
                                                </UiButton>
                                            ) : (
                                                <></>
                                            )}

                                            <WidgetStatisticsSpeed
                                                vehicleId={faultySensorReporterState.vehicleId}
                                                show={props.hideReportOptions && !props.leakNotification.show}
                                                dateTimePicker={dateTimePicker}
                                                timeline={timeline}
                                                setDateTimePicker={setDateTimePicker}
                                                zoomInExternal={props.zoomInAction}
                                            />

                                            <WidgetStatisticsPressure
                                                faultySensorReporterState={faultySensorReporterState}
                                                externalTemperatureEnabled={externalTemperatureEnabled}
                                                zoomInExternal={props.zoomInAction}
                                            />

                                            {faultySensorReporterState.isExternal &&
                                                !faultySensorReporterState.internalOnVehicle && (
                                                    <WarningNoColdPressure severity='warning'>
                                                        {translate('t.no_cold_pressure_available')}
                                                    </WarningNoColdPressure>
                                                )}

                                            {faultySensorReporterState.isExternal &&
                                                !faultySensorReporterState.internalOnVehicle &&
                                                !userInfo.user?.customer.externalTemperatureEnable && (
                                                    <WarningNoColdPressure severity='warning'>
                                                        {translate('t.no_temperature_available')}
                                                    </WarningNoColdPressure>
                                                )}

                                            {showMap && userIsSuperAdmin && (
                                                <WidgetJourneyMap
                                                    sx={{ marginTop: '10px' }}
                                                    faultySensorReporterState={faultySensorReporterState}
                                                    invalidRangeHour={invalidRangeHour}
                                                />
                                            )}
                                        </GraphContainer>
                                    </Grid>
                                    <Grid
                                        xs={12}
                                        sm={7}
                                        md={6}
                                        lg={4}
                                        sx={{
                                            pt: 2,
                                            pl: 2,
                                            pr: 1,
                                            display: showAlerts ? 'block' : 'none'
                                        }}
                                    >
                                        {!props.hideReportOptions && !props.leakNotification.show && (
                                            <>
                                                <p style={{ width: '100%', float: 'left' }}>
                                                    {translate('p.1_step_report_sensor')}
                                                </p>
                                                <Grid item sm={12} sx={{ pl: { xl: 1, lg: 1, md: 1, sm: 1, xs: 1 } }}>
                                                    <ProblemsRadios />
                                                </Grid>

                                                <DateRange
                                                    title={translate('t.period')}
                                                    dateFrom={dateTimePicker.current.dateFrom.toFormat(
                                                        dateTimeFormat('dateTime', false)
                                                    )}
                                                    dateTo={dateTimePicker.current.dateTo.toFormat(
                                                        dateTimeFormat('dateTime', false)
                                                    )}
                                                />
                                            </>
                                        )}

                                        {faultySensorReporterState.reportDetailId && (
                                            <ReportDetail
                                                detailId={faultySensorReporterState.reportDetailId ?? 0}
                                                setVerifyingAllowed={props.setVerifyingAllowed}
                                            />
                                        )}

                                        <Typography
                                            color='primary'
                                            variant='subtitle1'
                                            textAlign='center'
                                            marginTop={1}
                                            gutterBottom
                                        >
                                            {translate('t.alerts')}
                                        </Typography>
                                        <AlertContainer>
                                            <AlertsList
                                                data={
                                                    checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN])
                                                        ? data?.items
                                                        : data?.items.reduce((acc: ModelTpmsAlerts[], current) => {
                                                              current.notification.type !== typeFilter.BATTERY &&
                                                                  acc.push(current);
                                                              return acc;
                                                          }, [])
                                                }
                                                testId='faultySensorReport'
                                                paginator={data?.paginator || undefined}
                                                alertKey={'_alertTPMS'}
                                                displayAlertActions
                                                typeAlert={typeAlert.TPMS}
                                                isLoading={isLoading}
                                                showSolveButton
                                                height={250}
                                                tabNumber={0}
                                                displayBody
                                                excludeModal={true}
                                            />
                                        </AlertContainer>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </TpmsAlertsQuery>

                    {props.leakNotification.show && (
                        <LeakNotification
                            leakNotificationType={props.leakNotification}
                            dateFrom={dateTimePicker.current.dateFrom.valueOf()}
                            dateTo={dateTimePicker.current.dateTo.valueOf()}
                            sensorId={faultySensorReporterState.sensorId}
                            imageContentRef={imageContentRef}
                            onReport={() => {
                                props.forceCloseModal();
                                props.setLeakNotification({ show: false, id: 0, type: '' });
                                setCaptureImageTrigger(false);
                            }}
                        />
                    )}
                </UiScroll>
            </MuiDialogContentStyle>

            <ContainerActions>
                <CustomDialogActions>
                    <ActionButtons
                        noSelectedIssues={props.noSelectedIssues}
                        hideReportOptions={props.hideReportOptions}
                        isProcessing={props.isProcessing}
                        userIsSuperAdmin={userIsSuperAdmin}
                        faultySensorReporterState={faultySensorReporterState}
                        leakNotification={props.leakNotification}
                        verifyingAllowed={props.verifyingAllowed}
                        createReport={props.createReport}
                        setLeakNotification={props.setLeakNotification}
                        updateHideReportOptions={props.updateHideReportOptions}
                        verifyReport={props.verifyReport}
                        reopenReport={props.reopenReport}
                    />
                </CustomDialogActions>
            </ContainerActions>
        </>
    );
};
