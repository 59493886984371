import React, { useEffect } from 'react';
import { OAuthCallbackType } from './OAuthCallback.type';
import { useNavigate } from 'react-router-dom';
import Login from 'api/Login';
import { setJWT } from 'helpers/authentication';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { RedirectParams } from 'pages/Redirect/Redirect.atom';
import { UiFullScreenLoaderAtom } from 'components/Ui/Components/UiFullScreenLoader/UiFullScreenLoader.atom';
import UiFullScreenLoader from 'components/Ui/Components/UiFullScreenLoader/UiFullScreenLoader';
import { setToLocalStorage } from 'helpers';

const apiLogin = new Login();

export function checkOauthLoginStatus(): OAuthCallbackType {
    let state: string | null;
    let code: string | null;
    let message: string | null;
    const urlParams = new URLSearchParams(location.search);
    state = urlParams.get('state');
    code = urlParams.get('code');
    message = urlParams.get('message');

    return { state, code, message };
}

const OAuthCallback: React.FC = (): JSX.Element => {
    const navigate = useNavigate();
    const redirectParams = useRecoilValue(RedirectParams);
    const setFullScreenLoaderState = useSetRecoilState(UiFullScreenLoaderAtom);

    const handleOauthCallbackStatus = async () => {
        try {
            const { state, code } = checkOauthLoginStatus();
            const data = await apiLogin.postOAuthCallbackUrl({ state: state, code: code });

            if (data.data?.token) {
                setJWT(data.data?.token);
                navigate(redirectParams.redirectTo ? redirectParams.redirectTo : '/dashboard');
            } else {
                navigate('/login');
            }

            setFullScreenLoaderState({ open: false });
        } catch (e) {
            navigate('/login');
            setFullScreenLoaderState({ open: false });
        }
    };

    useEffect(() => {
        setFullScreenLoaderState({ open: true });
        handleOauthCallbackStatus();
    }, []);

    return (
        <>
            <UiFullScreenLoader />
        </>
    );
};

export default OAuthCallback;
