import React from 'react';
import { RuleByVehicleContent } from './RuleByVehicle.view';
import { RuleByVehicleProps } from './RuleByVehicle.type';
import { Wrapper } from 'helpers/wrapper';

const RuleByVehicle: React.FC<RuleByVehicleProps> = (props): JSX.Element => {
    return <RuleByVehicleContent data-testid='RuleByVehicle-testid' />;
};

export default Wrapper(RuleByVehicle);
