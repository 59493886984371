import { Geometry, Polygon } from 'ol/geom';
import Feature from 'ol/Feature';
import { easeOut } from 'ol/easing';
import { fromLonLat } from 'ol/proj';
import { Fill, Stroke, Style, Text } from 'ol/style';
import AreaApi from 'api/Area';
import { AreaFeaturesResponse } from 'models/Area.type';
import Vector from 'ol/source/Vector';
import { CustomLayers, DefaultSettings, MapActions, refControllerType } from '../Map.type';
import { DrawArea } from 'states/global/Map';
import { getCenter } from 'ol/extent';

const createArea = (area: AreaFeaturesResponse, defaultSettings: DefaultSettings): Feature<Geometry> => {
    let polygon = new Polygon([area.area]);
    polygon.transform(defaultSettings.epsg[0], defaultSettings.epsg[1]);

    let feature = new Feature(area);
    feature.setId(area.id);
    feature.setGeometry(polygon);

    const color: string = area.color || 'rgba(255,255,255,0.0)';

    const areaStyle = new Style({
        fill: new Fill({
            color: color
        }),
        stroke: new Stroke({
            color: color.replace(new RegExp(/[0]\.[0-9]/, 'gm'), '1'),
            width: 4
        }),
        text: new Text({
            text: area.name,
            offsetX: 0,
            offsetY: 0,
            font: 'Normal 15px Arial',
            stroke: new Stroke({
                color: '#6f6f6f',
                width: 3
            }),
            fill: new Fill({
                color: '#fff'
            })
        }),
        zIndex: 1
    });

    feature.setStyle(areaStyle);
    return feature;
};

export const drawAreas = async (map: MapActions, refController: refControllerType['refController']): Promise<void> => {
    const Area = new AreaApi();
    refController.current = Area.controller;
    const areas: AreaFeaturesResponse[] = await Area.getAreaFeatures();
    const areaLayer = map.findLayerByName(CustomLayers.AREA);
    const updatedAreaSource = new Vector();
    let areaFeatures: Feature<Geometry>[] = [];
    for (let i = 0, length = areas?.length; i < length; i++) {
        areaFeatures.push(createArea(areas[i], map.defaultSettings));
    }
    updatedAreaSource.addFeatures(areaFeatures);
    areaLayer[0].setSource(updatedAreaSource);
};

export const drawArea = async (
    params: DrawArea['params'],
    map: MapActions,
    refController: refControllerType['refController']
): Promise<void> => {
    const Area = new AreaApi();
    refController.current = Area.controller;

    const area: AreaFeaturesResponse =
        'name' in params.data ? params.data : await Area?.getById({ id: params.data.areaId });
    const areaLayer = map.findLayerByName(CustomLayers.AREA);
    const updatedAreaSource = new Vector();
    const areaFeature = createArea(area, map.defaultSettings);
    updatedAreaSource.addFeature(areaFeature);
    areaLayer[0].setSource(updatedAreaSource);

    map.mapInstance?.getView().animate({
        center: fromLonLat(getCenter(updatedAreaSource.getExtent()), 'EPSG:4326'),
        duration: 1000,
        easing: easeOut,
        zoom: 17
    });
};
