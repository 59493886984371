import { Paginator } from './Paginator.type';
import { Sound } from './Sound.type';

export type ModelTpmsAlerts = {
    id: number;
    measuredAt: string;
    notification: Notification;
    operationLimitTyre: OperationLimitTyre;
    sensor: {
        id: number;
        name: string;
        sensorSerialNumber?: string;
        serialNumberHex?: string;
        sensorType?: {
            id: 1;
            name: string;
        };
    };
    solved: boolean;
    solvedAt: string;
    tyre: number | string | null;
    tyreEventLog: TyreEventLog;
    vehicle: {
        id: number;
        name: string;
    };
    wheel: Wheel;
    vehiclePrediction?: VehiclePredictionType;
};

export type VehiclePredictionType = {
    id: number;
    criticalTemperature?: number;
    criticalTemperatureAt?: string;
    measuredAt?: string;
    createdAt: string;
    remainingHours?: string;
    notificationEventLevel: number;
    notificationEventId?: number;
};

export interface SensorType {
    id: number;
    name: string;
}

export type ModelTyreEvent = {
    id: number;
    measuredAt: string;
    notification: Notification;
    sensor: {
        id: number;
        name: string;
        sensorSerialNumber?: string;
        serialNumberHex?: string;
        sensorType?: SensorType;
    };
    solved: boolean;
    solvedAt: string;
    tyre: number | string | null;
    tyreEventLog: TyreEventLog;
    vehicle: {
        id: number;
        name: string;
    };
    wheel: Wheel;
    ruleLimit: RuleLimitType;
};

type RuleLimitType = {
    id: number;
    createdAt: string;
    legacyOlHistoryId: number;
    limitFrom: number;
    limitTo: number;
};

type OperationLimitTire = {
    id: number;
    pressureHotMaxFrom: number | null;
    pressureHotMaxTo: number | null;
    pressureHotMinFrom: number | null;
    pressureHotMinTo: number | null;
    pressureMaxFrom: number | null;
    pressureMaxTo: number | null;
    pressureMinFrom: number | null;
    pressureMinTo: number | null;
    pressureOptimal: number | null;
    temperatureFrom: number | null;
    temperatureTo: number | null;
};

type Wheel = {
    customPosition: number;
    id: number;
};

export type ModelGetTpmsAlerts = {
    items: ModelTpmsAlerts[];
    paginator: Paginator;
};

export type NotificationParams = {
    limit?: number;
    solved?: number;
    page?: number;
};

type TyreEventLog = {
    ambientPressure: string;
    ambientTemperature: string;
    coldPressure: string;
    hotPressure: string;
    sensorTemperature: string;
    temperature: string;
    targetColdPressure: string;
    targetHotPressure: string;
};

type Notification = {
    level: number;
    type: string;
    type2: string;
    id: number;
    sound?: Sound;
};

type OperationLimitTyre = {
    id: number;
    pressureHotMaxFrom: string | null;
    pressureHotMaxTo: string | null;
    pressureHotMinFrom: number | string | null;
    pressureHotMinTo: string | null;
    pressureMaxFrom: string | null;
    pressureMaxTo: string | null;
    pressureMinFrom: string | null;
    pressureMinTo: string | null;
    pressureOptimal: string | null;
    temperatureFrom: string | null;
    temperatureTo: string | null;
};

export enum TpmsAlertsQueryKeys {
    getNotification = 'tpmsAlerts-GetTpmsAlert',
    getNotificationSolved = 'tpmsAlerts-GetTpmsAlertSolved',
    getNotificationSensorSolved = 'tpmsAlerts-GetSensorAlertSolved'
}
