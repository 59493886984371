import { Paginator } from './Paginator.type';

export type TeamShiftNameModel = {
    name: string;
    id: number;
};

export type TeamShiftNameModelPaginator = {
    items: TeamShiftNameModel[];
    paginator: Paginator;
};

export type TeamShiftNameModelResponse = {
    items: TeamShiftNameModel[];
};

export enum TeamShiftNameQueryKeys {
    getTeamShiftNameList = 'teamShiftNameList',
    getTeamShiftNameDropDown = 'teamShiftName-dropDown'
}
