import React from 'react';
import { EditTyreContainer } from './EditTyre.style';
import { EditTyreViewProps, InitialValues } from './EditTyre.type';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid } from '@mui/material';
import TyreManufacturerLazyLoading from '../TyreManufacturerLazyLoading/TyreManufacturerLazyLoading';
import TyreModelLazyLoading from '../TyreModelLazyLoading/TyreModelLazyLoading';
import { Form, Formik, FormikProps } from 'formik';
import { number, object, string } from 'yup';
import MuiDialogContent from '@mui/material/DialogContent';
import { CancelButton, ContainerActions, CustomDialogActions } from 'components/StatedModal/Modal/Modal.style';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { VARIANT_BUTTON } from 'components/Ui/variables';
import { useRecoilState } from 'recoil';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';

export const EditTyreContent: React.FC<EditTyreViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispatch = ModalDispatcher(modalAtom, setStateModalAtom);

    const schemaValidateForm = object({
        model: object()
            .shape({
                id: number(),
                name: string()
            })
            .required(translate('t.required')),
        manufacturer: object()
            .shape({
                id: number(),
                name: string()
            })
            .required(translate('t.required'))
    });

    return (
        <EditTyreContainer data-testid='EditTyreContent'>
            <Formik
                initialValues={props.initialValues}
                onSubmit={(values: InitialValues) => {
                    props.sendData(values);
                }}
                validationSchema={schemaValidateForm}
                enableReinitialize={true}
            >
                {(propsFormik: FormikProps<InitialValues>) => {
                    const { errors, setFieldValue, values, touched } = propsFormik;
                    return (
                        <Form id='tyre-edit-form'>
                            <MuiDialogContent data-testid='EditTyreContent' sx={{ paddingTop: '20px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <TyreManufacturerLazyLoading
                                            onChangeManufacturer={(id, label) => {
                                                setFieldValue('manufacturer', id ? { id, name: label } : null);
                                            }}
                                            error={!!errors.manufacturer && !!touched.manufacturer}
                                            helperText={
                                                !!errors.manufacturer && !!touched.manufacturer
                                                    ? translate('p.this_field_is_required')
                                                    : ''
                                            }
                                            manufacturerValue={
                                                values.manufacturer
                                                    ? { value: values.manufacturer.id, label: values.manufacturer.name }
                                                    : undefined
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <TyreModelLazyLoading
                                            onChangeModel={(id, label) => {
                                                setFieldValue('model', id ? { id, name: label } : null);
                                            }}
                                            error={!!errors.model && !!touched.model}
                                            helperText={
                                                !!errors.model && !!touched.model
                                                    ? translate('p.this_field_is_required')
                                                    : ''
                                            }
                                            queryFilter={
                                                values.manufacturer?.id
                                                    ? { tyreManufacturer: values.manufacturer?.id }
                                                    : {}
                                            }
                                            modelValue={
                                                values.model
                                                    ? { value: values.model.id, label: values.model.name }
                                                    : undefined
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </MuiDialogContent>
                            <ContainerActions>
                                <CustomDialogActions>
                                    <>
                                        <UiButton
                                            form='tyre-edit-form'
                                            color='primary'
                                            testid='EditTyre-add-button'
                                            variant={VARIANT_BUTTON}
                                            type='submit'
                                            disabled={props.isProcessing || !values.manufacturer || !values.model}
                                        >
                                            {props.isProcessing ? (
                                                <CircularProgress size='1.75em' />
                                            ) : (
                                                translate('t.save')
                                            )}
                                        </UiButton>
                                        <CancelButton
                                            data-testid='EditTyre-cancel-button'
                                            variant='outlined'
                                            color='primary'
                                            size='small'
                                            onClick={() => {
                                                modalDispatch({
                                                    type: ModalActionTypesEnum.CLOSE_MODAL
                                                });
                                            }}
                                        >
                                            {translate('t.cancel')}
                                        </CancelButton>
                                    </>
                                </CustomDialogActions>
                            </ContainerActions>
                        </Form>
                    );
                }}
            </Formik>
        </EditTyreContainer>
    );
};
