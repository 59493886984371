import React, { ReactInstance, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { ContainerToPrint } from './UiTablePrintWrapper.style';
import { UiTablePrintWrapperProps } from './UiTablePrintWrapper.type';
import ReactToPrint from 'react-to-print';
import { ShowScroll } from '../UiTable/TableAtom';
import UiCircularProgress from '../Components/UiCircularProgress/UiCircularProgress';
import { getSystemName } from 'helpers';

const UiTablePrintWrapperContent: React.FC<UiTablePrintWrapperProps> = ({
    queryKey,
    exportName,
    minHeight,
    maxHeight,
    children
}): JSX.Element => {
    const componentToPrintRef = useRef<ReactInstance | null>(null);
    const buttonToPrintRef = React.useRef<HTMLButtonElement>(null);
    const [loadingUnmount, setLoadingUnmount] = React.useState(false);
    const [showScroll, setShowScroll] = useRecoilState(ShowScroll);

    const reactToPrintContent = React.useCallback(() => {
        return componentToPrintRef.current;
    }, [componentToPrintRef]);

    useEffect(() => {
        if (!showScroll && buttonToPrintRef.current) {
            buttonToPrintRef.current.click();

            setLoadingUnmount(true);
        }
        if (showScroll) setLoadingUnmount(false);
    }, [showScroll]);

    return (
        <>
            <div style={{ display: 'none' }}>
                <ReactToPrint
                    data-testid={`PrintWrapper-ReactToPrint-${queryKey}Table`}
                    trigger={() => <button ref={buttonToPrintRef}></button>}
                    content={reactToPrintContent}
                    onAfterPrint={() => setShowScroll(true)}
                    documentTitle={`${exportName || queryKey} ${getSystemName()} - Mining TPMS 24/7`}
                />
            </div>
            {loadingUnmount ? (
                <UiCircularProgress data-testid={`PrintWrapper-CircularProgress-${queryKey}Table`} />
            ) : (
                <ContainerToPrint
                    data-testid={`PrintWrapper-ContainerToPrint-${queryKey}Table`}
                    ref={(el) => (componentToPrintRef.current = el)}
                    $maxHeight={maxHeight}
                    $minHeight={minHeight}
                    $autoMaxHeight={!showScroll}
                    id='UiTableView'
                >
                    {children}
                </ContainerToPrint>
            )}
        </>
    );
};

export default UiTablePrintWrapperContent;
