import React, { useState } from 'react';
import { TemperaturePressureRuleContent } from './TemperaturePressureRule.view';
import { TemperaturePressureRuleProps } from './TemperaturePressureRule.type';
import { Wrapper } from 'helpers/wrapper';

const TemperaturePressureRule: React.FC<TemperaturePressureRuleProps> = (props): JSX.Element => {
    const [tabValue, setTabValue] = useState<number>(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <TemperaturePressureRuleContent
            data-testid='TemperaturePressureRule-testid'
            tabValue={tabValue}
            changeTab={handleChange}
        />
    );
};

export default Wrapper(TemperaturePressureRule);
