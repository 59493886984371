import React from 'react';
import { HeaderLegend, Legend, UiScrollCustom } from './UiLegend.style';
import { UiLegendViewProps } from './UiLegend.type';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UiIconButton from '../UiIconButton/UiIconButton';

// import module

export const UiLegendContent: React.FC<UiLegendViewProps> = (props): JSX.Element => {
    const RootElement: React.ReactElement = props.root || (
        <UiIconButton testid='OpenLegend-Button'>
            <InfoOutlinedIcon titleAccess={props.title} />
        </UiIconButton>
    );

    return (
        <>
            {React.cloneElement(RootElement, {
                onClick: props.openLegend
            })}
            <Legend
                open={Boolean(props.anchorEl)}
                anchorEl={props.anchorEl}
                onClose={() => {
                    props.closeLegend();
                    props.onClose && props.onClose();
                }}
                TransitionProps={{
                    onEnter() {
                        props.onEnter && props.onEnter();
                    }
                }}
                disablePortal
                width={props.width}
                anchorOrigin={{
                    vertical: props.anchorOrigin ? props.anchorOrigin.vertical : 'bottom',
                    horizontal: props.anchorOrigin ? props.anchorOrigin.horizontal : 'right'
                }}
                transformOrigin={{
                    vertical: props.transformOrigin ? props.transformOrigin.vertical : 'top',
                    horizontal: props.transformOrigin ? props.transformOrigin.horizontal : 'right'
                }}
            >
                <HeaderLegend>
                    <Typography variant='body1' className='legend-header'>
                        {/* {props?.icon} */}
                        {props?.title}
                        <span style={{ display: 'inline-block', marginLeft: '10px' }}>{props?.headerElements}</span>
                        <UiIconButton
                            aria-label='close'
                            onClick={() => {
                                props.closeLegend();
                                props.onClose && props.onClose();
                            }}
                            testid='CloseLegendPopup-Button'
                        >
                            <CloseIcon fontSize='small' />
                        </UiIconButton>
                    </Typography>
                </HeaderLegend>
                <div className='legend-body'>
                    <UiScrollCustom $minWidth={props.minWidth}>{props.content || props.children}</UiScrollCustom>
                </div>
            </Legend>
        </>
    );
};
