import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { TextField, Grid } from '@mui/material';
import useModal from 'components/CustomHooks/ModalHook';
import UiButton from 'components/Ui/Components/UiButton';
import { SIZE_BUTTON, VARIANT_BUTTON } from 'components/Ui/variables';
import TyreManufacturerAPI from 'api/TyreManufacturer';
import { useMutation } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { Success } from 'components/Popup/Popup';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';

const tyreManufacturerAPI = new TyreManufacturerAPI();

const AddTyreManufacturer = () => {
    const { t: translate } = useTranslation();
    const { updateModalState, closeModal } = useModal();
    const queryTableFn = useRecoilValue(QueryFn(`TyreManufacturer-QueryFn`));

    const validationSchema = Yup.object().shape({
        name: Yup.string().min(3, 'Too Short!').required(translate('t.required'))
    });

    const { mutate: submit } = useMutation((value: { name: string }) => tyreManufacturerAPI.post({ data: value }), {
        onSettled: () => {
            closeModal();
            queryTableFn.refetch && queryTableFn.refetch instanceof Function && queryTableFn.refetch();
        },
        onSuccess: () => {
            Success({
                text: translate('t.item_created_successfully')
            });
        }
    });

    useEffect(() => {
        updateModalState({
            buttons: (
                <UiButton
                    testid='tyre-manufacturer-add-button'
                    skin='success'
                    size={SIZE_BUTTON}
                    variant={VARIANT_BUTTON}
                    form='tyre-manufacturer-form'
                    type='submit'
                >
                    {translate('t.add')}
                </UiButton>
            )
        });
    }, []);

    return (
        <Grid paddingTop={4} container>
            <Grid item xs={12}>
                <Formik
                    initialValues={{ name: '' }}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        submit(values);
                    }}
                >
                    {(props) => (
                        <Form id='tyre-manufacturer-form'>
                            <TextField
                                data-testid='tyre-manufacturer-name'
                                id='name'
                                name='name'
                                error={!!props.errors.name && props.touched.name}
                                label={translate('t.name')}
                                value={props.values.name}
                                variant='outlined'
                                size='small'
                                fullWidth
                                helperText={props.errors.name && props.touched.name ? props.errors.name : ''}
                                onChange={props.handleChange}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Form>
                    )}
                </Formik>
            </Grid>
        </Grid>
    );
};

export default AddTyreManufacturer;
