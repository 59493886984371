import React, { useMemo } from 'react';
import {
    BESLabel,
    BESText,
    HeaderWrapper,
    IconWrapper,
    NoVehicleIcon,
    StatusIcon,
    TitleWrapper,
    VehicleIconWrapper,
    VehicleListDiv,
    VehicleStatusGridViewContent,
    VehicleTitle,
    VehicleWrapper
} from './VehicleStatusGrid.style';
import { AlertsToDisplay, VehicleBoxProps, VehicleStatusGridViewProps } from './VehicleStatusGrid.type';
import GpsIndicator from '../GpsIndicator/GpsIndicator';
import HubBatteryIndicator from '../../../HubBatteryIndicator/HubBatteryIndicator';
import { applyStyleByMode, getBesLabel, getBesRank, isVehicleTyreBayHub } from 'helpers';
import { Theme } from 'states/global/Theme';
import { useRecoilValue } from 'recoil';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { SECONDARY_DARK_MODE, WHITE } from 'components/Ui/colors';
import HubConnectionIndicator from '../../../HubConnectionIndicator/HubConnectionIndicator';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { getImgSource } from 'helpers/image';
import { faSlash as faSlashRegular } from '@fortawesome/pro-regular-svg-icons';
import { faThermometerThreeQuarters } from '@fortawesome/pro-solid-svg-icons';
import { UiHighPressureIcon, UiLowPressureIcon } from 'components/Vehicle/VehicleStatsRow/VehicleStatsRow.style';
import { CustomerSettings } from 'states/global/CustomerSettings';
import TemperaturePredictionPeriodIcon from 'components/Mixs/TemperaturePredictionPeriodIcon';

const VehicleBox: React.FC<VehicleBoxProps> = (props): JSX.Element => {
    const ThemeMode = useRecoilValue(Theme);
    const displayAlertsIcons: AlertsToDisplay = props.displayAlerts(props.data.alert);
    const customerSettings = useRecoilValue(CustomerSettings);
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const isSelected = useMemo(
        () => props.vehicleId === props.data.vehicle.id,
        [props.vehicleId, props.data.vehicle.id]
    );

    const setVehicleSelected = (event?: React.MouseEvent<HTMLSpanElement, MouseEvent>, showTab?: number) => {
        if (!isSelected) {
            const url: string = location.href;
            let tabParams: string = showTab ? `&tab=${showTab}` : '';

            if (!props.isLoadingVehicle) {
                props.setVehicleId(props.data.vehicle.id);
            }

            url.includes('vehicle-status')
                ? navigate(`?id=${props.data.vehicle.id}${tabParams}`, { replace: true })
                : navigate(`vehicle-status?id=${props.data.vehicle.id}${tabParams}`, { replace: true });
        }

        props.handleShowTab(showTab || 0);
        showTab && event?.stopPropagation();
    };

    return (
        <VehicleWrapper
            selected={isSelected}
            alertLevel={props.getHighestAlert(props.data.alert)}
            onClick={() => setVehicleSelected()}
            $disableVehicleSelection={props.isLoadingVehicle}
            data-testid='VehicleStatus-SelectVehicle-Grid-Button'
        >
            <HeaderWrapper>
                <IconWrapper data-testid='VehicleBoxgrid-SignalStatusWrapper-id'>
                    <HubConnectionIndicator
                        lastTimeConnected={DateTime.fromISO(props.data.vehicle.vehicleInfo.measuredAt)}
                        data-testid='VehicleBoxgrid-HubConnectionIndicator-id'
                        connectionType='wifi'
                    />
                </IconWrapper>
                {props.data.vehicle.maintenance && (
                    <Tooltip title={translate('t.vehicle_in_maintenance')} role='status'>
                        <IconWrapper data-testid='VehicleBoxgrid-MaintenanceIcon-id'>
                            <UiIcon
                                icon={['fas', 'wrench']}
                                size='sm'
                                color={applyStyleByMode({
                                    theme: ThemeMode?.mode,
                                    light: '#0000008a',
                                    dark: WHITE
                                })}
                            />
                        </IconWrapper>
                    </Tooltip>
                )}
                {props.data.device?.id && (
                    <IconWrapper
                        data-testid='VehicleBoxgrid-BatteryWrapper-id'
                        onClick={(event) => setVehicleSelected(event, 5)}
                    >
                        <HubBatteryIndicator
                            battery={props.data.vehicle.vehicleInfo.battery}
                            powerSource={props.data.vehicle.vehicleInfo.powerSource}
                        />
                    </IconWrapper>
                )}
                {!props.data.device?.id && (
                    <Tooltip title={translate('t.vehicle_without_hub')} role='status'>
                        <IconWrapper data-testid='VehicleBoxgrid-HubWrapper-id'>
                            <UiIcon
                                icon={['fas', 'rectangle-xmark']}
                                size='sm'
                                color={applyStyleByMode({
                                    theme: ThemeMode?.mode,
                                    light: '#0000008a',
                                    dark: WHITE
                                })}
                            />
                        </IconWrapper>
                    </Tooltip>
                )}
                {props.data.device?.deviceInfo.shutdown && (
                    <Tooltip title={translate('t.hub_shutdown')} role='status'>
                        <IconWrapper data-testid='VehicleBoxgrid-ShutdownIconWrapper-id'>
                            <UiIcon
                                icon={['fas', 'power-off']}
                                size='sm'
                                color={applyStyleByMode({
                                    theme: ThemeMode?.mode,
                                    light: '#0000008a',
                                    dark: WHITE
                                })}
                            />
                        </IconWrapper>
                    </Tooltip>
                )}
                {props.data.vehicle.genericTemperatureAlert ? (
                    <Tooltip title={translate('t.temperature_alert')} role='status'>
                        <StatusIcon
                            $hide={!displayAlertsIcons.temperature}
                            $temperatureIcon
                            data-testid='VehicleBoxgrid-StatusIcon-temperature-id'
                            onClick={(event) => setVehicleSelected(event, 2)}
                        >
                            <UiIcon
                                icon={['fas', 'thermometer-three-quarters']}
                                size='sm'
                                fixedWidth
                                color={applyStyleByMode({
                                    theme: ThemeMode?.mode,
                                    dark: WHITE,
                                    light: '#FF1616'
                                })}
                            />
                        </StatusIcon>
                    </Tooltip>
                ) : (
                    <Tooltip title={translate('t.temperature_alert_disabled')} role='status'>
                        <StatusIcon $temperatureIcon data-testid='VehicleBoxgrid-StatusIcon-temperature-disabled-id'>
                            <span className='fa-layers fa-fw'>
                                <UiIcon
                                    icon={faSlashRegular}
                                    size='sm'
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        dark: WHITE,
                                        light: '#787878'
                                    })}
                                />
                                <UiIcon
                                    icon={faThermometerThreeQuarters}
                                    fixedWidth
                                    size='sm'
                                    color={applyStyleByMode({
                                        theme: ThemeMode?.mode,
                                        dark: WHITE,
                                        light: '#787878'
                                    })}
                                />
                            </span>
                        </StatusIcon>
                    </Tooltip>
                )}
                {displayAlertsIcons.highPressure && (
                    <StatusIcon
                        $pressureIcon
                        $hide={!displayAlertsIcons.highPressure}
                        data-testid='VehicleBoxgrid-StatusIcon-highpressure-id'
                        onClick={(event) => setVehicleSelected(event, 2)}
                    >
                        <Tooltip title={translate('t.high_pressure_alert')} role='status'>
                            <UiHighPressureIcon color='#FFF' $paddingTop />
                        </Tooltip>
                    </StatusIcon>
                )}
                {displayAlertsIcons.lowPressure && (
                    <StatusIcon
                        $pressureIcon
                        $hide={!displayAlertsIcons.lowPressure}
                        data-testid='VehicleBoxgrid-StatusIcon-lowpressure-id'
                        onClick={(event) => setVehicleSelected(event, 2)}
                    >
                        <Tooltip title={translate('t.low_pressure_alert')} role='status'>
                            <UiLowPressureIcon $paddingTop />
                        </Tooltip>
                    </StatusIcon>
                )}
                {displayAlertsIcons.temperaturePrediction && (
                    <Tooltip title={translate('t.temperature_prediction')} role='status'>
                        <StatusIcon
                            $hide={!displayAlertsIcons.temperaturePrediction}
                            data-testid='VehicleBoxgrid-StatusIcon-prediction-id'
                            onClick={(event) => setVehicleSelected(event, 2)}
                        >
                            <UiIcon
                                icon={['fas', 'chart-mixed-up-circle-currency']}
                                size='sm'
                                fixedWidth
                                color={applyStyleByMode({
                                    theme: ThemeMode?.mode,
                                    dark: WHITE,
                                    light: '#FF1616'
                                })}
                            />
                        </StatusIcon>
                    </Tooltip>
                )}
                {props.data.vehicle.predictionTemperature && (
                    <StatusIcon
                        $hide={!props.data.vehicle.predictionTemperature}
                        data-testid='VehicleBoxgrid-StatusIcon-prediction-period-id'
                        onClick={(event) => setVehicleSelected(event, 2)}
                    >
                        <Tooltip
                            role='status'
                            title={
                                props.data.vehicle.predictionTemperature ? translate('t.temperature_prediction') : ''
                            }
                        >
                            <TemperaturePredictionPeriodIcon
                                time={props.data.vehicle.lastTemperaturePredictedAt}
                                predictionTemperature={props.data.vehicle.predictionTemperature}
                                wheels={props.data.vehicle.wheels}
                            />
                        </Tooltip>
                    </StatusIcon>
                )}
                {props.data.iconAlert.length > 0 && (
                    <Tooltip title={translate('t.system_warnings')} role='status'>
                        <IconWrapper onClick={(event) => setVehicleSelected(event, 5)}>
                            <UiIcon
                                data-testid='VehicleBoxgrid-StatusIcon-system-alerts-id'
                                icon={['fas', 'triangle-exclamation']}
                                size='sm'
                                fixedWidth
                                color={applyStyleByMode({
                                    theme: ThemeMode?.mode,
                                    dark: WHITE,
                                    light: SECONDARY_DARK_MODE
                                })}
                            />
                        </IconWrapper>
                    </Tooltip>
                )}
                <IconWrapper
                    data-testid='VehicleBoxgrid-GpsIconWrapper-id'
                    onClick={(event) => setVehicleSelected(event, 4)}
                >
                    <GpsIndicator
                        powerSource={props.data.vehicle.vehicleInfo.powerSource}
                        deviceId={props.data.device?.id || null}
                        lastGpsMeasuredAt={DateTime.fromISO(props.data.vehicle.vehicleInfo.lastGpsMeasuredAt)}
                        maintenance={props.data.vehicle.maintenance}
                        outOfService={props.data.vehicle.outOfService}
                        measuredAt={DateTime.fromISO(props.data.vehicle.vehicleInfo.measuredAt)}
                    />
                </IconWrapper>
                {/* Temporary disabled for BE */}
                {/* {props.data.vehicle.vehicleInfo.parkingTime > 0 && (
                    <IconWrapper data-testid='VehicleBoxgrid-ParkedIcon-id'>
                        <UiIcon
                            icon={['fas', 'parking']}
                            size='sm'
                            fixedWidth
                            color={applyStyleByMode({
                                theme: ThemeMode?.mode,
                                light: SECONDARY_DARK_MODE,
                                dark: WHITE
                            })}
                        />
                    </IconWrapper>
                )} */}
            </HeaderWrapper>
            <VehicleIconWrapper
                $outOfService={props.data.vehicle.outOfService}
                data-testid='VehicleBoxgrid-VehicleIconWrapper-id'
            >
                {customerSettings.mdd_bes && props.data.highestBes > 0 && (
                    <BESLabel
                        rank={getBesRank(customerSettings.mdd_bes_display_name.toUpperCase(), props.data.highestBes)}
                        data-testid='VehicleBoxgrid-BES-UiIcon-id'
                    >
                        <BESText>
                            {getBesLabel(
                                customerSettings.mdd_bes_display_name.toUpperCase(),
                                props.data.highestBes,
                                translate
                            )}
                        </BESText>
                    </BESLabel>
                )}

                {props.data.vehicle.icon ? (
                    // Temporary solution, remove when BE repairs icon in v1/webtrack/vehicle/status
                    <img
                        width='40px'
                        alt='vehicle'
                        src={getImgSource(
                            `${
                                isVehicleTyreBayHub(props.data.vehicle.vehicleConfigurationId)
                                    ? 'hub/48x48/tyre_bay_hub.png'
                                    : props.data.vehicle.icon
                            }`
                        )}
                    />
                ) : (
                    <NoVehicleIcon data-testid='VehicleBoxgrid-novehicle-UiIcon-id'>
                        <UiIcon
                            icon={['fas', 'ban']}
                            size='sm'
                            color={applyStyleByMode({
                                theme: ThemeMode?.mode,
                                light: '#0000008a',
                                dark: WHITE
                            })}
                        />
                    </NoVehicleIcon>
                )}
            </VehicleIconWrapper>
            <TitleWrapper>
                <Tooltip title={isSelected ? '' : translate('t.click_see_vehicle')} aria-hidden>
                    <VehicleTitle alertLevel={props.getHighestAlert(props.data.alert)}>
                        {props.data.vehicle.name}
                    </VehicleTitle>
                </Tooltip>
            </TitleWrapper>
        </VehicleWrapper>
    );
};

const createList = (props: VehicleStatusGridViewProps): JSX.Element => {
    const vehiclesList: JSX.Element[] =
        props.data?.vehicleStatus.map((vehicle, index) => (
            <VehicleBox
                data={vehicle}
                key={index}
                isLoadingVehicle={props.isLoadingVehicle}
                setVehicleId={props.setVehicleId}
                vehicleId={props.vehicleId}
                getHighestAlert={props.getHighestAlert}
                displayAlerts={props.displayAlerts}
                handleShowTab={props.handleShowTab}
            />
        )) || [];

    return <VehicleListDiv>{vehiclesList}</VehicleListDiv>;
};

export const VehicleStatusGridContent: React.FC<VehicleStatusGridViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <VehicleStatusGridViewContent data-testid='VehicleStatusGridContent'>
            {props.data?.vehicleStatus?.length ? (
                createList(props)
            ) : (
                <Typography align='center' marginTop={1} variant='subtitle1'>
                    {translate('t.there_no_data')}
                </Typography>
            )}
        </VehicleStatusGridViewContent>
    );
};
