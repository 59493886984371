import React, { useEffect, useMemo, useState } from 'react';
import { VehicleDetailsProps } from './VehicleDetails.type';
import { VehicleDetailsContent } from './VehicleDetails.view';
import { ActiveVehicleDetailsTab } from './VehicleDetails.state';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { typeAlert } from 'components/AlertNotification/TPMSAlertsListTabManager/TPMSAlertsListTabManager.type';
import { VehicleDetailsWidgetAtom } from '../VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { VehicleDetailTyreBay } from './VehicleDetailTyreBay/VehicleDetailTyreBay';
import { isVehicleTyreBayHub } from 'helpers';
import { SelectedSensorAtom } from 'components/Tyre/TyreDetails/SelectedSensor.atom';
import { VehicleDetailsActionMenuAtom } from '../VehicleDetailsActionMenu/VehicleDetailsActionMenu.atom';

const VehicleDetails: React.FC<VehicleDetailsProps> = (props): JSX.Element => {
    const [tpmsAlerts, setTPMSAlerts] = useState<number>(0);
    const [gpsAlerts, setGPSAlerts] = useState<number>(0);
    const [historicalLogAlerts, setHistoricalLogAlerts] = useState<number>(0);
    const [systemAlerts, setSystemAlerts] = useState<number>(0);
    const [vehicleAlerts, setVehicleAlerts] = useState<number>(0);
    const vehicleDetailsWidgetAtom = useRecoilValue(VehicleDetailsWidgetAtom());
    const vehicleDetailsActionMenuAtom = useRecoilValue(VehicleDetailsActionMenuAtom);
    const [selectedSensor, setSelectedSensor] = useRecoilState(
        SelectedSensorAtom(vehicleDetailsActionMenuAtom.data?.wheel.position || 0)
    );
    const isVehicleTyreBayHubVerified = useMemo(
        () => isVehicleTyreBayHub(vehicleDetailsWidgetAtom?.vehicleDetails?.vehicleConfigurationV2?.id || 0),
        [vehicleDetailsWidgetAtom.vehicleDetails]
    );

    const setActiveTab = useSetRecoilState(ActiveVehicleDetailsTab);

    const handleTabChange = (event, newTabIndex: number): void => {
        if (props.isVehicleAlertsWidget) {
            setActiveTab((current) => ({ ...current, alertsWidget: newTabIndex }));
        } else {
            setActiveTab((current) => ({ ...current, detailsWidget: newTabIndex }));
        }
    };

    const displayTab = (isVehicleAlertsWidget: boolean, isAlert: boolean): boolean => {
        const isDetailsOnly = location.hash.includes('detail');
        if (isDetailsOnly) {
            return (isVehicleAlertsWidget && !isAlert) || (!isVehicleAlertsWidget && isAlert);
        }
        return false;
    };

    const changeNumberOfAlerts = (type: typeAlert, value: number) => {
        switch (type) {
            case typeAlert.TPMS:
                setTPMSAlerts(value);
                break;
            case typeAlert.GPS:
                setGPSAlerts(value);
                break;
            case typeAlert.HistoricalLog:
                setHistoricalLogAlerts(value);
                break;
            case typeAlert.Sensor:
                setSystemAlerts(value);
                break;
            case typeAlert.VehicleEvent:
                setVehicleAlerts(value);
                break;
        }
    };

    useEffect(() => {
        if (location.hash.includes('detail')) {
            setActiveTab({ alertsWidget: 2, detailsWidget: 0 });
        } else {
            setActiveTab((current) => {
                return { ...current, detailsWidget: current.detailsWidget || 0 };
            });
        }
    }, [setActiveTab]);

    useEffect(() => {
        isVehicleTyreBayHubVerified && handleTabChange(null, 0);
    }, [isVehicleTyreBayHubVerified]);

    useEffect(() => {
        JSON.stringify(vehicleDetailsActionMenuAtom.data?.attachedSensors[0]) !== JSON.stringify(selectedSensor) &&
            setSelectedSensor(vehicleDetailsActionMenuAtom.data?.attachedSensors[0] || null);
    }, [vehicleDetailsActionMenuAtom.data?.attachedSensors[0]]);

    return isVehicleTyreBayHubVerified ? (
        <VehicleDetailTyreBay
            {...props}
            handleTabChange={handleTabChange}
            displayTab={displayTab}
            changeNumberOfAlerts={changeNumberOfAlerts}
            TPMS={tpmsAlerts}
            GPS={gpsAlerts}
            HistoricalLog={historicalLogAlerts}
            systemAlerts={systemAlerts}
            vehicleAlerts={vehicleAlerts}
        />
    ) : (
        <VehicleDetailsContent
            {...props}
            data-testid={'VehicleDetails-testid'}
            handleTabChange={handleTabChange}
            displayTab={displayTab}
            changeNumberOfAlerts={changeNumberOfAlerts}
            TPMS={tpmsAlerts}
            GPS={gpsAlerts}
            HistoricalLog={historicalLogAlerts}
            systemAlerts={systemAlerts}
            vehicleAlerts={vehicleAlerts}
        />
    );
};

export default VehicleDetails;
