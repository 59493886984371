import React from 'react';
import TyreManufacturerAndModelTableView from './TyreManufacturerAndModelTable.view';
import { TyreManufacturerModel } from 'models/TyreManufacturer.type';
import { useTranslation } from 'react-i18next';
import { TyreModelModel } from 'models/TyreModel.type';
import { Column } from 'components/Ui/UiTable/UiTable.type';
import useTableFilter from 'components/CustomHooks/TableFilterState/TableFilterState';
import TyreManufacturerLazyLoading from '../TyreManufacturerLazyLoading/TyreManufacturerLazyLoading';
import { FilterBox } from './TyreManufacturerAndModelTable.style';

const TyreManufacturerAndModelTable = () => {
    const { t: translate } = useTranslation();

    const columnsManufacturer: Column<TyreManufacturerModel>[] = [
        {
            Header: translate('t.name'),
            accessor: 'name'
        }
    ];

    const columnsModel: Column<TyreModelModel>[] = [
        {
            Header: translate('t.manufacturer'),
            accessor: 'tyreManufacturer',
            disableSortBy: true,
            Cell: (props: { value: TyreManufacturerModel }) => <>{props.value.name}</>,
            Filter: ({ column, setFilter, ...filterProps }) => {
                const { cellValue, setFilterValue } = useTableFilter({
                    filterContent: {
                        name: column.Header,
                        value: column?.filterValue?.value,
                        humanValue: column?.filterValue?.humanValue
                    },
                    setFilter: setFilter,
                    applyFiltersFlag: filterProps.applyFiltersFlag,
                    filterId: column.id
                });
                return (
                    <FilterBox>
                        <TyreManufacturerLazyLoading
                            onChangeManufacturer={(value, label) => {
                                const manufacturerValue = value
                                    ? { name: column.Header, value: `${value};${label}` }
                                    : undefined;
                                setFilterValue(manufacturerValue);
                            }}
                        />
                    </FilterBox>
                );
            }
        },
        {
            Header: translate('t.name'),
            accessor: 'name'
        },
        {
            Header: translate('t.tread_pattern'),
            accessor: 'treadPattern',
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: translate('t.compound'),
            accessor: 'compound',
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: 'ply',
            accessor: 'ply',
            disableFilters: true,
            disableSortBy: true,
            width: 75
        },
        {
            Header: translate('t.tyre_rim_association'),
            accessor: 'tyreRimAssociation',
            disableFilters: true,
            disableSortBy: true
        },
        {
            Header: translate('t.construction'),
            accessor: 'construction',
            disableFilters: true,
            disableSortBy: true
        }
    ];

    return <TyreManufacturerAndModelTableView columnsManufacturer={columnsManufacturer} columnsModel={columnsModel} />;
};

export default TyreManufacturerAndModelTable;
