import React, { useCallback, useMemo } from 'react';
import { LevelIndicator, RuleOverviewTableContainer, TableBox, TableBoxCover } from './RuleOverviewTable.style';
import { ChangeVisualizationTempAndPressProps, RuleOverviewTableProps } from './RuleOverviewTable.type';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@mui/material';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import {
    ALERT_LEVEL_1,
    ALERT_LEVEL_1_DARK,
    ALERT_LEVEL_2,
    ALERT_LEVEL_2_DARK,
    ALERT_LEVEL_3,
    ALERT_LEVEL_3_DARK,
    ALERT_OPTIMAL_PRESSURE,
    ALERT_OPTIMAL_PRESSURE_DARK,
    HexToRGB
} from 'components/Ui/colors';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { useRecoilValue } from 'recoil';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { useTranslation } from 'react-i18next';
import { ThemeMode } from 'states/global/Theme';
import { ALERT_INDEX } from 'components/Alert/AlertSettings/AlertSettings.type';
import UiCircularProgress from 'components/Ui/Components/UiCircularProgress/UiCircularProgress';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import Rule from 'api/Rule';
import { RuleById, RuleQueryKeys } from 'models/Rule.type';
import { useQuery } from '@tanstack/react-query';

const RuleApi = new Rule();

const levelColors = {
    0: {
        light: HexToRGB(ALERT_OPTIMAL_PRESSURE, '1'),
        dark: HexToRGB(ALERT_OPTIMAL_PRESSURE_DARK, '1')
    },
    1: {
        light: HexToRGB(ALERT_LEVEL_1, '1'),
        dark: HexToRGB(ALERT_LEVEL_1_DARK, '1')
    },
    2: {
        light: HexToRGB(ALERT_LEVEL_2, '1'),
        dark: HexToRGB(ALERT_LEVEL_2_DARK, '1')
    },
    3: {
        light: HexToRGB(ALERT_LEVEL_3, '1'),
        dark: HexToRGB(ALERT_LEVEL_3_DARK, '1')
    }
};

const PressureVisualization: React.FC<ChangeVisualizationTempAndPressProps> = ({
    level,
    defaultValue
}): JSX.Element => {
    return (
        <TableBoxCover>
            <TableBox>{defaultValue}</TableBox>
        </TableBoxCover>
    );
};

const TemperatureVisualization: React.FC<ChangeVisualizationTempAndPressProps> = ({
    level,
    defaultValue
}): JSX.Element => {
    return (
        <TableBoxCover>
            <TableBox>{defaultValue}</TableBox>
        </TableBoxCover>
    );
};

const RuleOverviewTableContent: React.FC<RuleOverviewTableProps> = ({ ruleId }): JSX.Element => {
    const tdeme = useTheme();
    const { t: translate } = useTranslation();
    const customerSettings = useRecoilValue(CustomerSettings);
    const { fromServerToUserUnit, convertType, displayUserUnits } = useConverter();

    const { data, isLoading } = useQuery<any, Error, RuleById>(
        [RuleQueryKeys.getById, ruleId],
        () => RuleApi.get({ extendUrl: `${ruleId}` }),
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(1, 'hours'),
            cacheTime: cacheTimeToMilliseconds(1, 'hours')
        }
    );

    const getConvertedValue = useCallback(
        (value: number, temperature?: boolean) => {
            return +fromServerToUserUnit({
                type: temperature ? convertType.temperature : convertType.pressure,
                value,
                fixed: temperature ? 0 : 1,
                displayUnits: false
            });
        },
        [fromServerToUserUnit]
    );

    const prepareData = useMemo(() => {
        const optimal = getConvertedValue(data?.rule.optimalColdPressure || 0);
        const low3 = getConvertedValue(data?.rule.ruleLimit[ALERT_INDEX.COLD_PRESSURE_LOW_LEVEL_3].limitTo || 0);
        const low2 = getConvertedValue(data?.rule.ruleLimit[ALERT_INDEX.COLD_PRESSURE_LOW_LEVEL_2].limitTo || 0);
        const low1 = getConvertedValue(data?.rule.ruleLimit[ALERT_INDEX.COLD_PRESSURE_LOW_LEVEL_1].limitTo || 0);
        const high3 = getConvertedValue(data?.rule.ruleLimit[ALERT_INDEX.COLD_PRESSURE_HIGH_LEVEL_3].limitFrom || 0);
        const high2 = getConvertedValue(data?.rule.ruleLimit[ALERT_INDEX.COLD_PRESSURE_HIGH_LEVEL_2].limitFrom || 0);
        const high1 = getConvertedValue(data?.rule.ruleLimit[ALERT_INDEX.COLD_PRESSURE_HIGH_LEVEL_1].limitFrom || 0);
        const highHot1 = getConvertedValue(data?.rule?.ruleLimit[ALERT_INDEX.HOT_PRESSURE_HIGH_LEVEL_1].limitFrom || 0);
        const highHot2 = getConvertedValue(data?.rule.ruleLimit[ALERT_INDEX.HOT_PRESSURE_HIGH_LEVEL_2].limitFrom || 0);
        const highHot3 = getConvertedValue(data?.rule.ruleLimit[ALERT_INDEX.HOT_PRESSURE_HIGH_LEVEL_3].limitFrom || 0);
        const temperature1 = getConvertedValue(
            data?.rule.ruleLimit[ALERT_INDEX.TEMPERATURE_LEVEL_1].limitFrom || 0,
            true
        );
        const temperature2 = getConvertedValue(
            data?.rule.ruleLimit[ALERT_INDEX.TEMPERATURE_LEVEL_2].limitFrom || 0,
            true
        );
        const temperature3 = getConvertedValue(
            data?.rule.ruleLimit[ALERT_INDEX.TEMPERATURE_LEVEL_3].limitFrom || 0,
            true
        );

        return {
            low3,
            low2,
            low1,
            optimal,
            high1,
            high2,
            high3,
            highHot1,
            highHot2,
            highHot3,
            temperature1,
            temperature2,
            temperature3
        };
    }, [data?.rule?.ruleLimit, data?.rule?.optimalColdPressure]);

    const showValue = (value: JSX.Element): JSX.Element => {
        if (isLoading) {
            return (
                <Box width={'20px'} height={'20px'}>
                    <UiCircularProgress />
                </Box>
            );
        }
        return value;
    };

    return (
        <RuleOverviewTableContainer data-testid='RuleOverviewTableContent'>
            <TableContainer component={Box}>
                <Table aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 100 }}>
                                <UiIcon icon={['fas', 'snowflake']} size='sm' fixedWidth color='#0069FF' />
                                {translate('t.cold_pressure')} ({displayUserUnits.pressure})
                            </TableCell>
                            <TableCell
                                style={{
                                    fontWeight: 100
                                }}
                            >
                                <UiIcon
                                    icon={['fas', 'temperature-three-quarters']}
                                    size='sm'
                                    fixedWidth
                                    color='#ff0000'
                                />
                                {translate('t.temperature')} ({displayUserUnits.temperature})
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <LevelIndicator background={levelColors[3][tdeme.palette.mode]} component='td' scope='col'>
                                <TableBoxCover>
                                    {`${translate('t.level')} 3`}
                                    {showValue(<PressureVisualization defaultValue={prepareData.low3} level={-3} />)}
                                </TableBoxCover>
                            </LevelIndicator>
                            <LevelIndicator background={levelColors[3][tdeme.palette.mode]} component='td' scope='col'>
                                <TableBoxCover>
                                    {`${translate('t.level')} 3`}
                                    {showValue(
                                        <TemperatureVisualization defaultValue={prepareData.temperature3} level={3} />
                                    )}
                                </TableBoxCover>
                            </LevelIndicator>
                        </TableRow>
                        <TableRow>
                            <LevelIndicator background={levelColors[2][tdeme.palette.mode]} component='td' scope='col'>
                                <TableBoxCover>
                                    {`${translate('t.level')} 2`}
                                    {showValue(<PressureVisualization defaultValue={prepareData.low2} level={-2} />)}
                                </TableBoxCover>
                            </LevelIndicator>
                            <LevelIndicator background={levelColors[2][tdeme.palette.mode]} component='td' scope='col'>
                                <TableBoxCover>
                                    {`${translate('t.level')} 2`}
                                    {showValue(
                                        <TemperatureVisualization defaultValue={prepareData.temperature2} level={2} />
                                    )}
                                </TableBoxCover>
                            </LevelIndicator>
                        </TableRow>
                        <TableRow>
                            <LevelIndicator background={levelColors[1][tdeme.palette.mode]} component='td' scope='col'>
                                <TableBoxCover>
                                    {`${translate('t.level')} 1`}
                                    {showValue(<PressureVisualization defaultValue={prepareData.low1} level={-1} />)}
                                </TableBoxCover>
                            </LevelIndicator>

                            <LevelIndicator background={levelColors[1][tdeme.palette.mode]} component='td' scope='col'>
                                <TableBoxCover>
                                    {`${translate('t.level')} 1`}
                                    {showValue(
                                        <TemperatureVisualization defaultValue={prepareData.temperature1} level={1} />
                                    )}
                                </TableBoxCover>
                            </LevelIndicator>
                        </TableRow>
                        <TableRow>
                            <LevelIndicator background={levelColors[0][tdeme.palette.mode]} component='td' scope='col'>
                                <TableBoxCover>
                                    {`${translate('t.optimal')}`}
                                    {showValue(<PressureVisualization defaultValue={prepareData.optimal} level={0} />)}
                                </TableBoxCover>
                            </LevelIndicator>
                            <TableCell
                                style={{
                                    fontWeight: 100,
                                    background: tdeme.palette.mode === ThemeMode.dark ? `rgb(29,38,48)` : '#f2f2f2'
                                }}
                            >
                                <UiIcon icon={['fas', 'sun']} size='sm' fixedWidth color='#ff0000' />
                                {translate('t.high_hot_pressure')} ({displayUserUnits.pressure})
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <LevelIndicator background={levelColors[1][tdeme.palette.mode]} component='td' scope='col'>
                                <TableBoxCover>
                                    {`${translate('t.level')} 1`}
                                    {showValue(<PressureVisualization defaultValue={prepareData.high1} level={1} />)}
                                </TableBoxCover>
                            </LevelIndicator>

                            {customerSettings.high_hot_pressure_enabled ? (
                                <>
                                    <LevelIndicator
                                        background={levelColors[1][tdeme.palette.mode]}
                                        component='td'
                                        scope='col'
                                    >
                                        <TableBoxCover>
                                            {`${translate('t.level')} 1`}
                                            {showValue(
                                                <PressureVisualization defaultValue={prepareData.highHot1} level={11} />
                                            )}
                                        </TableBoxCover>
                                    </LevelIndicator>
                                </>
                            ) : (
                                <>
                                    <TableCell />
                                </>
                            )}
                        </TableRow>
                        <TableRow>
                            <LevelIndicator background={levelColors[2][tdeme.palette.mode]} component='td' scope='col'>
                                <TableBoxCover>
                                    {`${translate('t.level')} 2`}
                                    <PressureVisualization defaultValue={prepareData.high2} level={2} />
                                </TableBoxCover>
                            </LevelIndicator>
                            {customerSettings.high_hot_pressure_enabled ? (
                                <>
                                    <LevelIndicator
                                        background={levelColors[2][tdeme.palette.mode]}
                                        component='td'
                                        scope='col'
                                    >
                                        <TableBoxCover>
                                            {`${translate('t.level')} 2`}
                                            {showValue(
                                                <PressureVisualization defaultValue={prepareData.highHot2} level={12} />
                                            )}
                                        </TableBoxCover>
                                    </LevelIndicator>
                                </>
                            ) : (
                                <>
                                    <TableCell />
                                </>
                            )}
                        </TableRow>
                        <TableRow>
                            <LevelIndicator background={levelColors[3][tdeme.palette.mode]} component='td' scope='col'>
                                <TableBoxCover>
                                    {`${translate('t.level')} 3`}
                                    <PressureVisualization defaultValue={prepareData.high3} level={3} />
                                </TableBoxCover>
                            </LevelIndicator>
                            {customerSettings.high_hot_pressure_enabled ? (
                                <>
                                    <LevelIndicator
                                        background={levelColors[3][tdeme.palette.mode]}
                                        component='td'
                                        scope='col'
                                    >
                                        <TableBoxCover>
                                            {`${translate('t.level')} 3`}
                                            {showValue(
                                                <PressureVisualization defaultValue={prepareData.highHot3} level={13} />
                                            )}
                                        </TableBoxCover>
                                    </LevelIndicator>
                                </>
                            ) : (
                                <>
                                    <TableCell />
                                </>
                            )}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </RuleOverviewTableContainer>
    );
};

export default RuleOverviewTableContent;
