import React from 'react';
import { ImageCell } from './LogoGlobalAdmin.style';
import { LogoGlobalAdminViewProps } from './LogoGlobalAdmin.type';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const LogoGlobalAdminContent: React.FC<LogoGlobalAdminViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    return props.photoDataLoading ? (
        <CircularProgress data-testid={'LogoGlobalAdminContent-CircularProgress-testid'} size='1.75em' />
    ) : props.photo ? (
        <ImageCell
            data-testid='LogoGlobalAdminContent-ImageCell-testid'
            width={props.width}
            $maxWidth={props.maxWidth}
            $maxHeight={props.maxHeight}
            src={props.photo}
            alt={translate('t.customer_logo')}
        />
    ) : (
        <></>
    );
};
