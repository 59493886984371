import React from 'react';
import { TemperaturePressureRuleContainer } from './TemperaturePressureRule.style';
import { TemperaturePressureRuleViewProps } from './TemperaturePressureRule.type';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from '@mui/material';
import { IconLabel } from 'components/Vehicle/VehicleDetails/VehicleDetails.style';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { TabPanelProps } from 'components/Alert/WheelAlerts/WheelAlerts.type';
import RuleByVehicle from 'components/Tyre/RuleByVehicle/RuleByVehicle';
import RuleOverview from 'components/Tyre/RuleOverview';

export const TemperaturePressureRuleContent: React.FC<TemperaturePressureRuleViewProps> = ({
    changeTab,
    tabValue
}): JSX.Element => {
    const { t: translate } = useTranslation();

    const TabPanel = (props: TabPanelProps): JSX.Element => {
        const { children, value, index } = props;

        return (
            <div
                role='tabpanel'
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
            >
                {value === index && <>{children}</>}
            </div>
        );
    };

    return (
        <TemperaturePressureRuleContainer data-testid='TemperaturePressureRuleContent'>
            <Tabs value={tabValue} onChange={changeTab} aria-label='Alert Setting Tabs'>
                <Tab
                    label={
                        <IconLabel>
                            <UiIcon icon={['fas', 'gear']} fontSize={17} fixedWidth transform='up-0 down-1 left-3' />
                            {translate('t.rule_overview')}
                        </IconLabel>
                    }
                />
                <Tab
                    data-testid='WheelAlertLogs-Tab-Button'
                    label={
                        <IconLabel>
                            <UiIcon icon={['fas', 'truck']} fontSize={17} fixedWidth transform='up-0 down-1 left-3' />
                            {translate('t.rule_by_vehicle')}
                        </IconLabel>
                    }
                />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
                <RuleOverview />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <RuleByVehicle />
            </TabPanel>
        </TemperaturePressureRuleContainer>
    );
};
