import React from 'react';
import { AddHubProps, InitialValues } from './AddHub.type';
import { AddHubContent } from './AddHub.view';
import { Wrapper } from 'helpers/wrapper';
import { useQuery } from '@tanstack/react-query';
import { DevicesList } from 'models/HubAdministration.type';
import Device from 'api/Device';
import { optionsCodeBook } from 'components/Ui/Components/UiDropDown/UiDropDown.type';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { DeviceQueryKeys } from 'models/Device.type';

const administrationHub = new Device();

const AddHub: React.FC<AddHubProps> = (props): JSX.Element => {
    const initValues: InitialValues = {
        macAddress: '',
        id: 0
    };

    const parseData = (data: DevicesList): optionsCodeBook[] => {
        return data.device.reduce((acc: optionsCodeBook[], curr) => {
            acc.push({ id: curr?.id, name: curr?.serialNumber });
            return acc;
        }, []);
    };

    const handleSubmit = (values: InitialValues) => {
        props.addHubMutation(values);
    };

    const { data: devicesData, isLoading: devicesLoading } = useQuery<DevicesList, Error>(
        [DeviceQueryKeys.getDevicesList],
        () => administrationHub.getDevicesList(),
        {
            staleTime: cacheTimeToMilliseconds(15, 'minutes'),
            cacheTime: cacheTimeToMilliseconds(15, 'minutes'),
            refetchOnWindowFocus: false
        }
    );

    return (
        <AddHubContent
            data-testid={'AddVehicleType-testid'}
            devices={devicesData ? parseData(devicesData as DevicesList) : []}
            deviceValueRaw={devicesData || { device: [] }}
            devicesLoading={devicesLoading}
            initValues={initValues}
            refAddHub={props.refAddHub}
            handleSubmit={handleSubmit}
        />
    );
};

export default Wrapper(AddHub);
