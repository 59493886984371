import { LoginContentStyle, BackgroundVideo, BlurLayer, ContainerVideoLogin } from './Login.style';
import { Card, CardContent } from '@mui/material';
import { LoginViewProps } from './Login.type';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { getVideoSource } from 'helpers/video';
import Login from 'components/User/Login/Login';
import RecoveryPassword from 'components/User/RecoveryPassword/RecoveryPassword';
import { LoginShowContent, showContentType } from 'states/global/Login';
import UsernameRecovery from 'components/User/UsernameRecovery/UsernameRecovery';
import { SystemInfoState } from 'states/global/Portal';
import LoginSSORedirectionForm from 'components/User/LoginSSORedirectionForm/LoginSSORedirectionForm';

const showContent = (loginShowContent: showContentType): JSX.Element => {
    switch (loginShowContent) {
        case showContentType.RECOVERY_PASSWORD:
            return <RecoveryPassword />;
        case showContentType.USERNAME_RECOVERY:
            return <UsernameRecovery />;
        case showContentType.LOGIN_SSO:
            return <LoginSSORedirectionForm />;
        default:
            return <Login />;
    }
};

export const LoginContent: React.FC<LoginViewProps> = (): JSX.Element => {
    const loginShowContent = useRecoilValue(LoginShowContent);
    const systemInfoState = useRecoilValue(SystemInfoState);

    return (
        <LoginContentStyle data-testid='LoginContent'>
            <ContainerVideoLogin>
                <BackgroundVideo autoPlay loop muted>
                    <source src={getVideoSource('videoOfBackgroundForLogin')} type='video/mp4' />
                </BackgroundVideo>
                <BlurLayer />
            </ContainerVideoLogin>

            <Card className='card-login' sx={{ zIndex: 99 }}>
                <CardContent>
                    <img src={systemInfoState.logo.src} className='logo-login' alt={systemInfoState.logo.alt} />
                    {showContent(loginShowContent)}
                </CardContent>
            </Card>
        </LoginContentStyle>
    );
};
