import { styled as styledMui } from '@mui/system';
import { Box, FormControlLabel } from '@mui/material';
import styled from 'styled-components';

export const VehicleStatsHideColumnsContainer = styled.div``;

export const VehicleStatsHideColumnsCheckboxContainer = styledMui(Box)({
    display: 'grid',
    gridColumnGap: '3px',
    gridRowGap: '3px',
    gridTemplateColumns: 'repeat(2, 180px)',
    paddingLeft: '10px'
});

export const FormControlLabelEl = styled(FormControlLabel)({});
