import React from 'react';
import { useRecoilValue } from 'recoil';
import {
    ColumnStickyHeader,
    ColumnTableSortLabel,
    CustomTableCell,
    CustomTableHead,
    CustomTableRow
} from './TemperaturePressureTableListHeader.style';
import { TempAndPressListHeaderViewProps } from './TemperaturePressureTableListHeader.type';
import { useTranslation } from 'react-i18next';
import { UserInfo } from 'states/global/User';

export const TempAndPressListHeaderContent: React.FC<TempAndPressListHeaderViewProps> = ({
    getSorting,
    tempPressLabel,
    wheelLabel,
    ...passProps
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const wheelsHeader: JSX.Element[] = [];
    const tempPressHeader: JSX.Element[] = [];
    Array.from({ length: passProps.maxPosition }).forEach((_, i) => {
        wheelsHeader.push(wheelLabel(i));
        tempPressHeader.push(tempPressLabel(i));
    });

    return (
        <CustomTableHead>
            <CustomTableRow>
                <ColumnStickyHeader
                    rowSpan={2}
                    key='vehicle'
                    align='center'
                    $lef={0}
                    $minWidth={50}
                    onClick={() =>
                        passProps.handleSortRequest(
                            'name',
                            getSorting('name') === 'desc' ? 'asc' : 'desc',
                            false,
                            false,
                            false,
                            false
                        )
                    }
                    data-testid='SortByVehicleName-Button'
                >
                    <ColumnTableSortLabel $active={!!getSorting('name')} active direction={getSorting('name') ?? 'asc'}>
                        {translate('t.vehicle')}
                    </ColumnTableSortLabel>
                </ColumnStickyHeader>
                <ColumnStickyHeader rowSpan={2} key='VehicleIcons' align='center' $lef={155}>
                    {' '}
                </ColumnStickyHeader>
                {wheelsHeader}

                <CustomTableCell
                    rowSpan={2}
                    key='last_transmission_hub'
                    align='center'
                    $minWidth={130}
                    onClick={() =>
                        passProps.handleSortRequest(
                            'lastTransmission',
                            getSorting('lastTransmission') === 'desc' ? 'asc' : 'desc',
                            true,
                            false,
                            false,
                            false
                        )
                    }
                >
                    <ColumnTableSortLabel
                        $active={!!getSorting('lastTransmission')}
                        active
                        direction={getSorting('lastTransmission') ?? 'asc'}
                        data-testid='SortByLastTransmission-Button'
                    >
                        {translate('t.last_transmission_hub')}
                    </ColumnTableSortLabel>
                </CustomTableCell>

                <CustomTableCell rowSpan={2} key='position_temp_difference ' align='right' $maxWidth={97}>
                    {translate('t.position_temp_difference')}
                </CustomTableCell>

                <CustomTableCell
                    rowSpan={2}
                    key='density'
                    align='right'
                    onClick={() =>
                        passProps.handleSortRequest(
                            'distanceTraveled',
                            getSorting('distanceTraveled') === 'desc' ? 'asc' : 'desc',
                            false,
                            false,
                            false,
                            false
                        )
                    }
                >
                    <ColumnTableSortLabel
                        $active={!!getSorting('distanceTraveled')}
                        active
                        direction={getSorting('distanceTraveled') ?? 'asc'}
                        data-testid='SortByDistanceTraveled-Button'
                    >
                        {translate('t.distance_travelled')}
                        {`(${userInfo.user?.userSetting.speedUnit === 'km/h' ? 'km' : 'mi'})`}
                    </ColumnTableSortLabel>
                </CustomTableCell>
            </CustomTableRow>
            <CustomTableRow>{tempPressHeader}</CustomTableRow>
        </CustomTableHead>
    );
};
