import React from 'react';
import { RuleOverviewContent } from './RuleOverview.view';
import { RuleOverviewProps } from './RuleOverview.type';
import { Wrapper } from 'helpers/wrapper';
import { RULE_TYPE, RuleOverviewModel, RuleOverviewTable } from 'models/Rule.type';
import { useTranslation } from 'react-i18next';
import useConverter from 'components/CustomHooks/Converter/Converter';
import RuleOverviewFilters from '../RuleOverviewFilters/RuleOverviewFilters';
import { Column } from 'components/Ui/UiTable/UiTable.type';

const RuleOverview: React.FC<RuleOverviewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromUTCToTimezone } = useConverter();

    const columns: Column<RuleOverviewTable['items'][0]>[] = [
        {
            Header: 'ID',
            accessor: 'id',
            disableSortBy: true,
            disableFilters: true,
            width: 30
        },
        {
            Header: translate('t.type'),
            accessor: 'ruleType',
            disableSortBy: true,
            disableFilters: true,
            Cell: (props: { row: { original: RuleOverviewModel } }) => {
                const typeLabel = {
                    [RULE_TYPE.CUSTOM]: 'custom',
                    [RULE_TYPE.DEFAULT]: 'default',
                    [RULE_TYPE.WIZARD]: 'wizard'
                };
                return <>{typeLabel[props.row.original.ruleType.id]}</>;
            },
            width: 40
        },
        {
            Header: translate('t.filters'),
            accessor: undefined,
            disableSortBy: true,
            disableFilters: true,
            Cell: (props: { row: { original: RuleOverviewModel } }) => {
                return <RuleOverviewFilters filters={props.row.original} />;
            }
        },
        {
            Header: translate('t.created_at'),
            accessor: 'createdAt',
            disableSortBy: true,
            disableFilters: true,
            Cell: (props) => <>{fromUTCToTimezone(props.value, false)}</>,
            width: 50
        }
    ];

    return <RuleOverviewContent data-testid='RuleOverview-testid' columns={columns} />;
};

export default Wrapper(RuleOverview);
