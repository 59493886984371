import React, { useEffect } from 'react';
import { VehicleStatsTableContent } from './VehicleStatsTable.view';
import { VehicleStatsProps } from './VehicleStatsTable.type';
import { useSetRecoilState } from 'recoil';
import { FilterPeriod } from './VehicleStatsTable.atom';
import { Wrapper } from 'helpers/wrapper';

const VehicleStatsTable: React.FC<VehicleStatsProps> = (): JSX.Element => {
    const setPeriod = useSetRecoilState(FilterPeriod);

    useEffect(() => {
        setPeriod(+(localStorage.getItem('VehicleStatsPeriod') || 24));
    }, []);

    return (
        <VehicleStatsTableContent
            data-testid={'VehicleStats-testid'}
            isLoadingForFirstTime={false}
            errorGettingVehicleStats={null}
        />
    );
};

export default Wrapper(VehicleStatsTable);
