import React from 'react';
import { TyreManufacturerAndModelTableViewType } from './TyreManufacturerAndModelTable.type';
import { TyreManufacturerAndModelTableContent } from './TyreManufacturerAndModelTable.style';
import UiWidget from '../../Ui/Components/UiWidget/UiWidget';
import UiTable from '../../Ui/UiTable/UiTable';
import UiButton from '../../Ui/Components/UiButton/UiButton';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TyreManufacturer from 'api/TyreManufacturer';
import TyreModel from 'api/TyreModel';
import { VARIANT_BUTTON } from '../../Ui/variables';
import AddTyreManufacturer from '../AddTyreManufacturer';
import useModal from 'components/CustomHooks/ModalHook';
import AddTyreModel from '../AddTyreModel';

const tyreManufacturerAPI = new TyreManufacturer();
const tyreModelAPI = new TyreModel();

const TyreManufacturerAndModelTableView: React.FC<TyreManufacturerAndModelTableViewType> = ({
    columnsManufacturer,
    columnsModel
}) => {
    const { t: translate } = useTranslation();
    const { setModalState } = useModal();

    return (
        <TyreManufacturerAndModelTableContent>
            <Grid container spacing={2}>
                <Grid item lg={4} sm={12}>
                    <UiWidget
                        testid='tyre-manufacturer'
                        title={translate('t.manufacturer')}
                        content={
                            <UiTable
                                fetchFn={tyreManufacturerAPI.getManufacturerTable}
                                columns={columnsManufacturer}
                                hiddenColumns={[]}
                                queryKey='TyreManufacturer'
                                defaultSortBy={{
                                    id: 'name',
                                    desc: true
                                }}
                                rightActionBtns={() => (
                                    <UiButton
                                        testid='tyre-manufacturer-add-button'
                                        skin='success'
                                        variant={VARIANT_BUTTON}
                                        onClick={() => {
                                            setModalState({
                                                id: 'tyre-manufacturer-add',
                                                isOpen: true,
                                                content: <AddTyreManufacturer />,
                                                leftTitle: translate('t.add_manufacturer'),
                                                width: 500,
                                                height: 200
                                            });
                                        }}
                                    >
                                        {translate('t.add_manufacturer')}
                                    </UiButton>
                                )}
                            />
                        }
                    />
                </Grid>
                <Grid item lg={8} sm={12}>
                    <UiWidget
                        testid='tyre-model'
                        title={translate('t.model')}
                        content={
                            <UiTable
                                fetchFn={tyreModelAPI.getModelTable}
                                columns={columnsModel}
                                hiddenColumns={[]}
                                queryKey='TyreModel'
                                rightActionBtns={() => (
                                    <UiButton
                                        testid='tyre-model-add-button'
                                        skin='success'
                                        variant={VARIANT_BUTTON}
                                        onClick={() => {
                                            setModalState({
                                                id: 'tyre-model-add',
                                                isOpen: true,
                                                content: <AddTyreModel />,
                                                leftTitle: translate('t.add_model'),
                                                width: 750,
                                                height: 370
                                            });
                                        }}
                                    >
                                        {translate('t.add_model')}
                                    </UiButton>
                                )}
                            />
                        }
                    />
                </Grid>
            </Grid>
        </TyreManufacturerAndModelTableContent>
    );
};

export default TyreManufacturerAndModelTableView;
