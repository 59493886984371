import styled from 'styled-components';

export const SpeedHeatmapContent = styled.div``;

export const SpeedHeatmapLegend = styled.div`
    width: 100px;
    height: 10px;
    background: linear-gradient(90deg, rgb(0 255 0), rgb(255, 255, 0), rgb(255 0 0));
`;

export const SpeedHeatmapLegendLabel = styled.div`
    width: 100px;
    height: 10px;
    display: flex;
    justify-content: space-between;

    .label {
        padding: 0 3px;
    }
`;
