import React from 'react';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete';
import TyreModel from 'api/TyreModel';
import { useTranslation } from 'react-i18next';
import { TyreModelLazyLoadingProps } from './TyreModelLazyLoading.type';

const tyreModelAPI = new TyreModel();

const TyreModelLazyLoading: React.FC<TyreModelLazyLoadingProps> = ({
    onChangeModel,
    error,
    helperText,
    modelValue,
    clearButton,
    queryFilter,
    disabled
}) => {
    const { t: translate } = useTranslation();

    return (
        <div style={{ marginBottom: '20px' }}>
            <LazyloadingAutocomplete
                id='tyre-model'
                queryId='TyreModelLazyLoading'
                query={tyreModelAPI.getTable}
                queryFilter={queryFilter}
                optionKey='name'
                cacheTime={0}
                fullWidth
                optionValue='id'
                label={translate('t.tyre_model')}
                width={320}
                size='small'
                preselectedValue={modelValue}
                error={error}
                clearButton={clearButton}
                helperText={helperText}
                disabled={disabled}
                onValueChange={onChangeModel}
            />
        </div>
    );
};

export default TyreModelLazyLoading;
