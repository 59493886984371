import { CircularProgress } from '@mui/material';
import React from 'react';
import { PopoverViewProps } from './TablePopover.type';
import { CustomUiButton, LoadingContent } from './TablePopover.style';
import { BACKGROUND, PRIMARY_DARK } from '../Ui/colors';
import PopoverListContent from '../PopoverListContent/PopoverListContent';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import UiLegend from 'components/Ui/Components/UiLegend/UiLegend';
import { TableLegend } from 'components/Mixs/SystemTotals/SystemTotals.style';
import { ExternalSensorIcon, SensorRedIcon } from 'components/PopoverListContent/SensorItem/SensorItem.style';

export const PopoverContent: React.FC<PopoverViewProps> = (props): JSX.Element => {
    const [openToolTip, setToolTipOpen] = React.useState(false);
    const { t: translate } = useTranslation();

    const LegendItem = ({ title, icon }) => (
        <tr>
            <td>
                <b>{icon}</b>
            </td>
            <td>{title}</td>
        </tr>
    );

    return (
        <UiLegend
            title={`${translate(
                `t.${props.stateType == 'outOfService' ? 'out_of_service' : props.stateType}`
            )} - ${translate(`t.${props.dataType}`)}  ${translate(`t.total`)} 
                 ${props.transmitting ? translate(`t.transmitting`) : translate(`t.not_transmitting`)} 
                    `}
            content={
                <>
                    {props.isLoading ? (
                        <LoadingContent>
                            <CircularProgress />
                        </LoadingContent>
                    ) : (
                        <PopoverListContent data={props.data} />
                    )}
                </>
            }
            width={300}
            root={
                <Tooltip
                    open={openToolTip}
                    onClose={() => {
                        setToolTipOpen(false);
                    }}
                    onOpen={() => {
                        setToolTipOpen(true);
                    }}
                    title={`${translate(`t.${props.dataType}`)}  ${translate(`t.total`)} 
                 ${props.transmitting ? translate(`t.transmitting`) : translate(`t.not_transmitting`)} 
                    - ${translate(`t.${props.stateType == 'outOfService' ? 'out_of_service' : props.stateType}`)}`}
                >
                    <div>
                        <CustomUiButton
                            style={{
                                minWidth: '25px',
                                maxWidth: '25px',
                                transform: 'scale(1.1)'
                            }}
                            skin={BACKGROUND}
                            textcolor={PRIMARY_DARK}
                            color='secondary'
                            variant='contained'
                            onClick={(event) =>
                                props.handleClick(
                                    {
                                        dataType: props.dataType,
                                        stateType: props.stateType,
                                        transmitting: props.transmitting
                                    },
                                    event
                                )
                            }
                        >
                            {props.value}
                        </CustomUiButton>
                    </div>
                </Tooltip>
            }
            headerElements={
                <UiLegend
                    width={150}
                    minWidth={150}
                    title={translate('t.legend')}
                    content={
                        <TableLegend className='legend-table'>
                            <tbody>
                                <LegendItem
                                    title={translate('t.low_battery_voltage')}
                                    icon={
                                        <SensorRedIcon
                                            icon={['fas', 'battery-low']}
                                            size='lg'
                                            transform={'down-2.5 right-4'}
                                            fixedWidth
                                        />
                                    }
                                />
                                <LegendItem
                                    title={translate('t.external_sensor')}
                                    icon={
                                        <ExternalSensorIcon
                                            style={{
                                                width: '25px',
                                                height: '22px',
                                                paddingLeft: '12px'
                                            }}
                                        />
                                    }
                                />
                                <LegendItem
                                    title={translate('t.faulty')}
                                    icon={<SensorRedIcon transform='down-5' icon={['fas', 'exclamation']} />}
                                />
                            </tbody>
                        </TableLegend>
                    }
                />
            }
        />
    );
};
