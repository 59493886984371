import React from 'react';
import { VehicleStatsCollapseTableContainer } from './VehicleStatsCollapseTable.style';
import { VehicleStatsCollapseTableProps } from './VehicleStatsCollapseTable.type';
import {
    CollapseTable,
    Container,
    ContainerSubTable,
    DividerStyle,
    HeaderSubTable,
    TableLeftSubTable,
    TableRightSubTable,
    TitleMainSubTable
} from '../VehicleStatsRow/VehicleStatsRow.style';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { CollapseVehicleStatsRow } from '../VehicleStatsCollapseButton/VehicleStatsCollapseButton.atom';
import VehicleStatusSubTable from '../VehicleStatsRow/VehicleStatusSubTable';

const VehicleStatsCollapseTableContent: React.FC<VehicleStatsCollapseTableProps> = ({
    vehicleId,
    alertDetailData
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const collapseVehicleRow = useRecoilValue(CollapseVehicleStatsRow);

    return (
        <VehicleStatsCollapseTableContainer data-testid='VehicleStatsCollapseTableContent'>
            <CollapseTable
                in={collapseVehicleRow === vehicleId}
                timeout='auto'
                unmountOnExit
                className='containerSubTable'
            >
                <ContainerSubTable>
                    <Container $textAlign='center' display='flex'>
                        <TableLeftSubTable>
                            <VehicleStatusSubTable vehicleId={vehicleId} />
                        </TableLeftSubTable>
                        <TableRightSubTable>
                            <TitleMainSubTable>{translate('t.alert_detail')}</TitleMainSubTable>
                            <DividerStyle />
                            <Table size='small' aria-label='purchases'>
                                <TableBody>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>{translate('t.last')}</TableCell>
                                        <TableCell>{translate('t.previous')}</TableCell>
                                        <TableCell>+/-</TableCell>
                                    </TableRow>
                                    {Object.entries(alertDetailData.alertDetail).map(([key, value]) => {
                                        return (
                                            <TableRow key={`noti_${key}`}>
                                                <HeaderSubTable component='th' scope='row'>
                                                    {translate(`t.${key}`)}
                                                </HeaderSubTable>
                                                <TableCell>{value.current}</TableCell>
                                                <TableCell>{value.previous}</TableCell>
                                                <TableCell>{value.variance}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    <TableRow key={'noti_total'}>
                                        <HeaderSubTable component='th' scope='row'>
                                            {translate('t.total')}
                                        </HeaderSubTable>
                                        <TableCell>{alertDetailData.totalValues.current}</TableCell>
                                        <TableCell>{alertDetailData.totalValues.previous}</TableCell>
                                        <TableCell>
                                            {alertDetailData.totalValues.current - alertDetailData.totalValues.previous}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableRightSubTable>
                    </Container>
                </ContainerSubTable>
            </CollapseTable>
        </VehicleStatsCollapseTableContainer>
    );
};

export default VehicleStatsCollapseTableContent;
