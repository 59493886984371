import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import { Typography, useTheme } from '@mui/material';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineDot from '@mui/lab/TimelineDot';
import { SUCCESS, ALERT_LEVEL_1_DARK, ALERT_LEVEL_2_DARK, ALERT_LEVEL_3_DARK } from 'components/Ui/colors';

const alertColors = {
    light: {
        1: ALERT_LEVEL_1_DARK,
        2: ALERT_LEVEL_2_DARK,
        3: ALERT_LEVEL_3_DARK
    },
    dark: {
        1: ALERT_LEVEL_1_DARK,
        2: ALERT_LEVEL_2_DARK,
        3: ALERT_LEVEL_3_DARK
    }
};

export const CustomTimelineDot = styled(TimelineDot)<{ level?: number }>`
    &.MuiTimelineDot-root {
        background-color: ${(props) =>
            props.level ? alertColors[useTheme().palette.mode][props.level] : SUCCESS} !important;
    }
`;

export const LifespanReportHistoryViewContent = styled.div`
    width: 98%;

    margin-top: 10px;

    overflow-wrap: break-word;
    && .MuiList-root {
        display: block;
        overflow-y: scroll;
    }
    && .MuiTimeline-root {
        height: 700px;

        & .MuiTimelineItem-root {
        }
    }
`;

export const TagFilterContainer = styled.div`
    padding-bottom: 10px;
`;

export const VehicleSchemaDiv = styled.div`
    margin-top: 10px;
`;

export const ImageContainer = styled.div`
    img {
        max-width: 132px;
        max-height: 270px;
    }
`;

export const TextNoData = styled(Typography)`
&.MuiTypography-root {
    padding-bottom: 16px;
}

}`;

export const ListTimeLineItem = styled(TimelineItem)`
    &.MuiTimelineItem-root {
        max-height: 700px;
        min-height: auto !important;
    }
`;

export const List = styled(Paper)`
    &.MuiPaper-root {
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        text-overflow: ellipsis;
        overflow-wrap: break-word;
    }
`;
