import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { DateTime } from 'luxon';

export type VehicleStatsProps = {};

export type VehicleStatsPropsView = {
    isLoadingForFirstTime: boolean;
    errorGettingVehicleStats: Error | null;
};

export type MenuItem = {
    title: string;
    value: string;
    action?: (value: string, title: string) => void;
};

export type vehicleInfo = {
    measuredAt: DateTime;
    battery: number;
    powerSource: number;
    lastGpsMeasuredAt: DateTime;
};

export type LegenItemType = {
    color?: string;
    title: string | undefined;
    icon?: IconProp;
    iconElement?: JSX.Element;
};

export enum ColumnIndex {
    VEHICLE_NAME = 0,
    TPMS_STATUS = 1,
    SYSTEM_STATUS = 2,
    BES = 3,
    CURRENT_ALERTS = 4,
    PREVIOUS_ALERTS = 5,
    DISTANCE = 6
}
