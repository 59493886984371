import React, { useCallback, useMemo } from 'react';
import {
    AlertSettingsOverviewTableLineContainer,
    LevelIndicator,
    RuleTypeDescription,
    SchemaBox,
    TableBox,
    TableBoxCover,
    TableWithIcon,
    ThemedCell
} from './AlertSettingsOverviewTableLine.style';
import {
    AlertSettingsOverviewTableLineProps,
    TemperaturePressureVisulalisation
} from './AlertSettingsOverviewTableLine.type';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@mui/material';
import { DefaultPressureLevel } from '../AlertSettings/atom';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { DefaultTemperatureSliderValue } from '../AlertTemperatureSlider/atom';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import {
    ALERT_LEVEL_1,
    ALERT_LEVEL_1_DARK,
    ALERT_LEVEL_2,
    ALERT_LEVEL_2_DARK,
    ALERT_LEVEL_3,
    ALERT_LEVEL_3_DARK,
    ALERT_OPTIMAL_PRESSURE,
    ALERT_OPTIMAL_PRESSURE_DARK,
    ERROR,
    HexToRGB,
    SUCCESS
} from 'components/Ui/colors';
import { ThemeMode } from 'states/global/Theme';
import VehicleSchema from 'components/Vehicle/NewVehicleSchema/VehicheSchema';
import { ALERT_INDEX } from '../AlertSettings/AlertSettings.type';

let randomnumber = (minimum, maximum) => Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
const globalDefault = {
    low3: 70,
    low2: 80,
    low1: 90,
    optimal: 100,
    high1: 110,
    high2: 120,
    high3: 130,
    highHot1: 112,
    highHot2: 120,
    highHot3: 125
};

const AlertSettingsOverviewTableLineContent: React.FC<AlertSettingsOverviewTableLineProps> = ({
    showVehicle,
    pos,
    limits,
    optimalColdPressure,
    vehicleConfiguration,
    ruleType
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const theme = useTheme();
    const customerSettings = useRecoilValue(CustomerSettings);

    const { fromServerToUserUnit, convertType, displayUserUnits } = useConverter();

    const getConvertedValue = useCallback(
        (value: number, temperature?: boolean) => {
            return +fromServerToUserUnit({
                type: temperature ? convertType.temperature : convertType.pressure,
                value,
                fixed: temperature ? 0 : 1,
                displayUnits: false
            });
        },
        [fromServerToUserUnit]
    );

    const ruleLimits = useMemo(() => {
        const optimal = getConvertedValue(optimalColdPressure || 0);
        const low3 = getConvertedValue(limits[ALERT_INDEX.COLD_PRESSURE_LOW_LEVEL_3].limitTo || 0);
        const low2 = getConvertedValue(limits[ALERT_INDEX.COLD_PRESSURE_LOW_LEVEL_2].limitTo || 0);
        const low1 = getConvertedValue(limits[ALERT_INDEX.COLD_PRESSURE_LOW_LEVEL_1].limitTo || 0);
        const high3 = getConvertedValue(limits[ALERT_INDEX.COLD_PRESSURE_HIGH_LEVEL_3].limitFrom || 0);
        const high2 = getConvertedValue(limits[ALERT_INDEX.COLD_PRESSURE_HIGH_LEVEL_2].limitFrom || 0);
        const high1 = getConvertedValue(limits[ALERT_INDEX.COLD_PRESSURE_HIGH_LEVEL_1].limitFrom || 0);
        const highHot1 = getConvertedValue(limits[ALERT_INDEX.HOT_PRESSURE_HIGH_LEVEL_1].limitFrom || 0);
        const highHot2 = getConvertedValue(limits[ALERT_INDEX.HOT_PRESSURE_HIGH_LEVEL_2].limitFrom || 0);
        const highHot3 = getConvertedValue(limits[ALERT_INDEX.HOT_PRESSURE_HIGH_LEVEL_3].limitFrom || 0);
        const temperature1 = getConvertedValue(limits[ALERT_INDEX.TEMPERATURE_LEVEL_1].limitFrom || 0, true);
        const temperature2 = getConvertedValue(limits[ALERT_INDEX.TEMPERATURE_LEVEL_2].limitFrom || 0, true);
        const temperature3 = getConvertedValue(limits[ALERT_INDEX.TEMPERATURE_LEVEL_3].limitFrom || 0, true);

        return {
            low3,
            low2,
            low1,
            optimal,
            high1,
            high2,
            high3,
            highHot1,
            highHot2,
            highHot3,
            temperature1,
            temperature2,
            temperature3
        };
    }, [limits, optimalColdPressure]);

    const levelColors = {
        0: {
            light: HexToRGB(ALERT_OPTIMAL_PRESSURE, '1'),
            dark: HexToRGB(ALERT_OPTIMAL_PRESSURE_DARK, '1')
        },
        1: {
            light: HexToRGB(ALERT_LEVEL_1, '1'),
            dark: HexToRGB(ALERT_LEVEL_1_DARK, '1')
        },
        2: {
            light: HexToRGB(ALERT_LEVEL_2, '1'),
            dark: HexToRGB(ALERT_LEVEL_2_DARK, '1')
        },
        3: {
            light: HexToRGB(ALERT_LEVEL_3, '1'),
            dark: HexToRGB(ALERT_LEVEL_3_DARK, '1')
        }
    };

    const ValueVisualization: React.FC<TemperaturePressureVisulalisation> = ({ value }): JSX.Element => {
        return (
            <TableBoxCover>
                <TableBox style={{ minWidth: '3ch', justifyContent: 'end', marginRight: 4 }}>{value}</TableBox>
            </TableBoxCover>
        );
    };

    const SnowFlake = <UiIcon icon={['fas', 'snowflake']} size='sm' fixedWidth color='#0069FF' />;
    const Sun = <UiIcon icon={['fas', 'sun']} size='sm' fixedWidth color='#ff0000' />;
    const Temperature = <UiIcon icon={['fas', 'temperature-three-quarters']} size='sm' fixedWidth color='#ff0000' />;

    return (
        <AlertSettingsOverviewTableLineContainer data-testid='AlertSettingsOverviewTableLineContent'>
            <TableContainer component={Box}>
                <Table aria-label='simple table'>
                    <TableBody>
                        <TableRow>
                            <TableCell
                                style={{
                                    background: theme.palette.mode === ThemeMode.dark ? `rgb(29,38,48)` : '#f2f2f2'
                                }}
                            />
                            <TableCell
                                colSpan={7}
                                style={{
                                    fontWeight: 100,
                                    background: theme.palette.mode === ThemeMode.dark ? `rgb(29,38,48)` : '#f2f2f2'
                                }}
                            >
                                {SnowFlake} {translate('t.cold_pressure')} ({displayUserUnits.pressure})
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {showVehicle && (
                                <TableCell
                                    rowSpan={3}
                                    style={{
                                        fontWeight: 100,
                                        background: theme.palette.mode === ThemeMode.dark ? `rgb(29,38,48)` : '#f2f2f2'
                                    }}
                                >
                                    <SchemaBox>
                                        <VehicleSchema
                                            schema={vehicleConfiguration}
                                            highlightWheels={pos ? { [pos]: { color: 'red' } } : {}}
                                        />
                                    </SchemaBox>
                                    <RuleTypeDescription>{ruleType.description}</RuleTypeDescription>
                                </TableCell>
                            )}
                            <LevelIndicator background={levelColors[3][theme.palette.mode]} component='td' scope='col'>
                                <TableBoxCover>
                                    {`${translate('t.level')} 3`}
                                    <TableWithIcon>
                                        <ValueVisualization value={ruleLimits.low3} />
                                        {SnowFlake}
                                    </TableWithIcon>
                                </TableBoxCover>
                            </LevelIndicator>
                            <LevelIndicator background={levelColors[2][theme.palette.mode]} component='td' scope='col'>
                                <TableBoxCover>
                                    {`${translate('t.level')} 2`}
                                    <TableWithIcon>
                                        <ValueVisualization value={ruleLimits.low2} />
                                        {SnowFlake}
                                    </TableWithIcon>
                                </TableBoxCover>
                            </LevelIndicator>
                            <LevelIndicator background={levelColors[1][theme.palette.mode]} component='td' scope='col'>
                                <TableBoxCover>
                                    {`${translate('t.level')} 1`}
                                    <TableWithIcon>
                                        <ValueVisualization value={ruleLimits.low1} />
                                        {SnowFlake}
                                    </TableWithIcon>
                                </TableBoxCover>
                            </LevelIndicator>
                            <LevelIndicator background={levelColors[0][theme.palette.mode]} component='td' scope='col'>
                                <TableBoxCover>
                                    {`${translate('t.optimal')}`}
                                    <TableWithIcon>
                                        <ValueVisualization value={ruleLimits.optimal} />
                                        {SnowFlake}
                                    </TableWithIcon>
                                </TableBoxCover>
                            </LevelIndicator>
                            <LevelIndicator background={levelColors[1][theme.palette.mode]} component='td' scope='col'>
                                <TableBoxCover>
                                    {`${translate('t.level')} 1`}
                                    <TableWithIcon>
                                        <ValueVisualization value={ruleLimits.high1} />
                                        {SnowFlake}
                                    </TableWithIcon>
                                </TableBoxCover>
                            </LevelIndicator>

                            <LevelIndicator background={levelColors[2][theme.palette.mode]} component='td' scope='col'>
                                <TableBoxCover>
                                    {`${translate('t.level')} 2`}
                                    <TableWithIcon>
                                        <ValueVisualization value={ruleLimits.high2} />
                                        {SnowFlake}
                                    </TableWithIcon>
                                </TableBoxCover>
                            </LevelIndicator>

                            <LevelIndicator background={levelColors[3][theme.palette.mode]} component='td' scope='col'>
                                <TableBoxCover>
                                    {`${translate('t.level')} 3`}
                                    <TableWithIcon>
                                        <ValueVisualization value={ruleLimits.high3} />
                                        {SnowFlake}
                                    </TableWithIcon>
                                </TableBoxCover>
                            </LevelIndicator>
                        </TableRow>
                        <TableRow>
                            <ThemedCell colSpan={3} theme={theme}>
                                {Temperature} {translate('t.temperature')} ({displayUserUnits.temperature})
                            </ThemedCell>
                            <ThemedCell theme={theme}></ThemedCell>
                            <ThemedCell colSpan={3} theme={theme}>
                                {Sun} {translate('t.high_hot_pressure')} ({displayUserUnits.pressure})
                            </ThemedCell>
                        </TableRow>
                        <TableRow>
                            <LevelIndicator background={levelColors[3][theme.palette.mode]} component='td' scope='col'>
                                <TableBoxCover>
                                    {`${translate('t.level')} 3`}
                                    <TableWithIcon>
                                        <ValueVisualization value={ruleLimits.temperature3} />
                                        {Temperature}
                                    </TableWithIcon>
                                </TableBoxCover>
                            </LevelIndicator>

                            <LevelIndicator background={levelColors[2][theme.palette.mode]} component='td' scope='col'>
                                <TableBoxCover>
                                    {`${translate('t.level')} 2`}
                                    <TableWithIcon>
                                        <ValueVisualization value={ruleLimits.temperature2} />
                                        {Temperature}
                                    </TableWithIcon>
                                </TableBoxCover>
                            </LevelIndicator>
                            <LevelIndicator background={levelColors[1][theme.palette.mode]} component='td' scope='col'>
                                <TableBoxCover>
                                    {`${translate('t.level')} 1`}
                                    <TableWithIcon>
                                        <ValueVisualization value={ruleLimits.temperature1} />
                                        {Temperature}
                                    </TableWithIcon>
                                </TableBoxCover>
                            </LevelIndicator>

                            <TableCell
                                style={{
                                    background: theme.palette.mode === ThemeMode.dark ? `rgb(29,38,48)` : '#f2f2f2'
                                }}
                            ></TableCell>
                            {customerSettings.high_hot_pressure_enabled ? (
                                <>
                                    <LevelIndicator
                                        background={levelColors[1][theme.palette.mode]}
                                        component='td'
                                        scope='col'
                                    >
                                        <TableBoxCover>
                                            {`${translate('t.level')} 1`}
                                            <TableWithIcon>
                                                <ValueVisualization value={ruleLimits.highHot1} />
                                                {Sun}
                                            </TableWithIcon>
                                        </TableBoxCover>
                                    </LevelIndicator>
                                </>
                            ) : (
                                <>
                                    <TableCell />
                                </>
                            )}
                            {customerSettings.high_hot_pressure_enabled ? (
                                <>
                                    <LevelIndicator
                                        background={levelColors[2][theme.palette.mode]}
                                        component='td'
                                        scope='col'
                                    >
                                        <TableBoxCover>
                                            {`${translate('t.level')} 2`}
                                            <TableWithIcon>
                                                <ValueVisualization value={ruleLimits.highHot2} />
                                                {Sun}
                                            </TableWithIcon>
                                        </TableBoxCover>
                                    </LevelIndicator>
                                </>
                            ) : (
                                <>
                                    <TableCell />
                                </>
                            )}
                            {customerSettings.high_hot_pressure_enabled ? (
                                <>
                                    <LevelIndicator
                                        background={levelColors[3][theme.palette.mode]}
                                        component='td'
                                        scope='col'
                                    >
                                        <TableBoxCover>
                                            {`${translate('t.level')} 3`}
                                            <TableWithIcon>
                                                <ValueVisualization value={ruleLimits.highHot3} />
                                                {Sun}
                                            </TableWithIcon>
                                        </TableBoxCover>
                                    </LevelIndicator>
                                </>
                            ) : (
                                <>
                                    <TableCell />
                                </>
                            )}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </AlertSettingsOverviewTableLineContainer>
    );
};

export default AlertSettingsOverviewTableLineContent;
