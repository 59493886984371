import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useModal from 'components/CustomHooks/ModalHook';
import { useRecoilValue } from 'recoil';
import { Success } from 'components/Popup/Popup';
import { useMutation, useQuery } from '@tanstack/react-query';
import VehicleModelAPI from 'api/VehicleModel';
import { SIZE_BUTTON, VARIANT_BUTTON } from 'components/Ui/variables';
import UiButton from 'components/Ui/Components/UiButton';
import { TextField, Grid, Autocomplete } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AddVehicleModelType, PostVehicleModel } from './AddVehicleModel.type';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';
import ConfigurationSelector from 'components/Mixs/ConfigurationSelector/ConfigurationSelector';
import { useVehicleActionsQueries } from '../VehicleActions/VehicleActionsQueries';
import { Manufacturer } from 'models/VehicleManufacturer.type';
import { DropdownModel } from 'models/VehicleGroup.type';
import VehicleTypeApi from 'api/VehicleType';
import { cacheTimeToMilliseconds } from 'helpers/cache';
import { VehicleTypeQueryKeys } from 'models/VehicleType.type';
import { AutocompleteBox } from './AddVehicleModel.style';
import { UserInfo } from 'states/global/User';
import { CustomAutocomplete } from '../VehicleActions/VehicleActions.style';
import { RimSizeModel } from 'models/RimSize.type';

const vehicleModelAPI = new VehicleModelAPI();
const vehicleType = new VehicleTypeApi();

const initialValues: AddVehicleModelType = {
    name: '',
    vehicleType: null,
    vehicleConfiguration: null,
    vehicleManufacturer: null,
    rimSize: null
};

const AddVehicleModel = () => {
    const { t: translate } = useTranslation();
    const { updateModalState, closeModal } = useModal();
    const queryTableFn = useRecoilValue(QueryFn(`VehicleModel-QueryFn`));
    const userInfo = useRecoilValue(UserInfo);

    const validationSchema = Yup.object().shape({
        name: Yup.string().min(3, 'Too Short!').required(translate('t.required')),
        vehicleType: Yup.object()
            .shape({
                id: Yup.number(),
                maxSpeed: Yup.number(),
                type: Yup.string(),
                translationKey: Yup.string()
            })
            .required(translate('t.required')),
        vehicleConfiguration: Yup.object()
            .shape({
                active: Yup.boolean(),
                axles: Yup.number(),
                distribution: Yup.string(),
                id: Yup.number(),
                wheels: Yup.number()
            })
            .required(translate('t.required')),
        vehicleManufacturer: Yup.object()
            .shape({
                id: Yup.number(),
                manufacturer: Yup.string()
            })
            .required(translate('t.required')),
        rimSize: Yup.object().shape({ id: Yup.number(), diameter: Yup.string() }).required(translate('t.required'))
    });

    const vehicleActionsQueries = useVehicleActionsQueries();

    const { data: vehicleTypeData } = useQuery(
        [VehicleTypeQueryKeys.getCodebook, userInfo.user?.customer.id],
        () => vehicleType.getCodebook({}),
        {
            refetchOnWindowFocus: false,
            staleTime: cacheTimeToMilliseconds(2, 'hours'),
            cacheTime: cacheTimeToMilliseconds(2, 'hours')
        }
    );

    const { mutate: submit } = useMutation(
        (value: AddVehicleModelType) => vehicleModelAPI.post({ data: prepareValues(value) }),
        {
            onSettled: () => {
                closeModal();
                queryTableFn.refetch && queryTableFn.refetch instanceof Function && queryTableFn.refetch();
            },
            onSuccess: () => {
                Success({
                    text: translate('t.item_created_successfully')
                });
            }
        }
    );

    useEffect(() => {
        updateModalState({
            buttons: (
                <UiButton
                    testid='vehicle-model-add-button'
                    skin='success'
                    size={SIZE_BUTTON}
                    variant={VARIANT_BUTTON}
                    form='vehicle-model-form'
                    type='submit'
                >
                    {translate('t.add')}
                </UiButton>
            )
        });
    }, []);

    const prepareValues = (data: AddVehicleModelType): PostVehicleModel | undefined => {
        if (data.vehicleConfiguration?.id && data.vehicleManufacturer?.id && data.vehicleType?.id && data.rimSize?.id) {
            return {
                modelName: data.name,
                vehicleConfigurationId: data.vehicleConfiguration.id,
                vehicleManufacturerId: data.vehicleManufacturer.id,
                vehicleTypeId: data.vehicleType.id,
                rimSizeId: data.rimSize.id
            };
        }
        return undefined;
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
                submit(values);
            }}
        >
            {(props) => {
                return (
                    <Form id='vehicle-model-form'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8} lg={8}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <TextField
                                            data-testid='vehicle-model-name'
                                            id='modelName'
                                            name='name'
                                            error={!!props.errors.name && props.touched.name}
                                            label={translate('t.name')}
                                            value={props.values.name}
                                            variant='outlined'
                                            size='small'
                                            fullWidth
                                            helperText={
                                                props.errors.name && props.touched.name ? props.errors.name : ''
                                            }
                                            onChange={props.handleChange}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <AutocompleteBox>
                                            <Autocomplete
                                                disableClearable
                                                id='vehicle-model-vehicleType-select'
                                                data-testid='vehicle-model-vehicleType-select'
                                                size='small'
                                                value={props.values.vehicleType || undefined}
                                                isOptionEqualToValue={(option, value) => {
                                                    return option.id === value.id;
                                                }}
                                                options={vehicleTypeData || []}
                                                getOptionLabel={(option) =>
                                                    translate(option.translationKey.replace('vehicle_type_', '')) ?? ''
                                                }
                                                onChange={(event, newValue) => {
                                                    props.setFieldValue('vehicleType', newValue, false);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={translate('t.vehicle_type')}
                                                        variant='outlined'
                                                        type='text'
                                                        size='small'
                                                        key={params.id}
                                                        name={params.id}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        error={
                                                            !!props.errors.vehicleType && !!props.touched.vehicleType
                                                        }
                                                        helperText={
                                                            !!props.errors.vehicleType && !!props.touched.vehicleType
                                                                ? translate('t.required')
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            />
                                        </AutocompleteBox>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <AutocompleteBox>
                                            <Autocomplete
                                                disableClearable
                                                id='vehicle-model-vehicleManufacturer-select'
                                                data-testid='vehicle-model-vehicleManufacturer-select'
                                                size='small'
                                                value={props.values.vehicleManufacturer || undefined}
                                                isOptionEqualToValue={(option, value) => {
                                                    return (option as Manufacturer).id === (value as Manufacturer).id;
                                                }}
                                                options={
                                                    vehicleActionsQueries.vehicleManufacturerData?.sort((a, b) =>
                                                        a.manufacturer > b.manufacturer
                                                            ? 1
                                                            : b.manufacturer > a.manufacturer
                                                            ? -1
                                                            : 0
                                                    ) ?? []
                                                }
                                                getOptionLabel={(option) =>
                                                    (option as { manufacturer: string }).manufacturer ?? ''
                                                }
                                                onChange={(event, newValue) => {
                                                    props.setFieldValue('vehicleManufacturer', newValue, false);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={translate('t.manufacturer')}
                                                        variant='outlined'
                                                        type='text'
                                                        size='small'
                                                        key={params.id}
                                                        name={params.id}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        error={
                                                            !!props.errors.vehicleManufacturer &&
                                                            !!props.touched.vehicleManufacturer
                                                        }
                                                        helperText={
                                                            !!props.errors.vehicleManufacturer &&
                                                            !!props.touched.vehicleManufacturer
                                                                ? translate('t.required')
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            />
                                        </AutocompleteBox>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <AutocompleteBox>
                                            <CustomAutocomplete
                                                disableClearable
                                                id='rim-size-select'
                                                data-testid='rim-size-select'
                                                size='small'
                                                value={props.values.rimSize}
                                                options={vehicleActionsQueries.rimSizeList || []}
                                                isOptionEqualToValue={(a, b) => {
                                                    return (a as RimSizeModel).id === (b as RimSizeModel).id;
                                                }}
                                                getOptionLabel={(option) => {
                                                    return (option as RimSizeModel).diameter ?? '';
                                                }}
                                                onChange={(event, newValue) => {
                                                    props.setFieldValue('rimSize', newValue, false);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={translate('t.rim_size')}
                                                        variant='outlined'
                                                        type='text'
                                                        size='small'
                                                        key={params.id}
                                                        name={params.id}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        error={!!props.errors.rimSize && !!props.touched.rimSize}
                                                        helperText={
                                                            !!props.errors.rimSize && !!props.touched.rimSize
                                                                ? translate('t.required')
                                                                : ''
                                                        }
                                                    />
                                                )}
                                            />
                                        </AutocompleteBox>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                                <ConfigurationSelector
                                    configurationValue={props.values.vehicleConfiguration}
                                    onConfigurationChange={(configuration) => {
                                        props.setFieldValue('vehicleConfiguration', configuration);
                                    }}
                                    error={!!props.errors.vehicleConfiguration && !!props.touched.vehicleConfiguration}
                                    helperText={
                                        !!props.errors.vehicleConfiguration && !!props.touched.vehicleConfiguration
                                            ? translate('t.required')
                                            : ''
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default AddVehicleModel;
