import React, { useEffect, useReducer, useState } from 'react';
import { DataToCompareType, Scales, StatisticsGraphsProps } from './StatisticsGraphs.type';
import { StatisticsGraphsContent } from './StatisticsGraphs.view';
import { useRecoilValue } from 'recoil';
import { UserInfo } from 'states/global/User';
import SensorLogApi from 'api/SensorLog';
import { VehicleSensor, Wheel } from 'models/SensorLog.type';
import useConverter from '../CustomHooks/Converter/Converter';
import { closestNumberToDivisibleBy, deepCopyObj, DEFAULT_GRAPH_SCALE, makeObjectNullable } from 'helpers';
import { DateTime } from 'luxon';
import { AllHubsReading, DateTimePicker, SelectedVehicle, Timeline, Collapse } from 'states/global/Statistics';
import { Wrapper } from 'helpers/wrapper';
import { Scale } from 'components/Tyre/SeverityRankGraph/SeverityRankGraph.type';

const SensorLog = new SensorLogApi();

const CollapseWidget = (
    state: {},
    action: {
        type: string;
        collapse?: boolean;
        collapsedWidget?: { [id: string]: { isSecondary: boolean; collapsed: boolean } };
        widgetId?: string;
    }
) => {
    switch (action.type) {
        case 'setCollapse':
            state = action.collapsedWidget ?? {};
            return state;
        case 'updateSecondary': {
            let newObj = deepCopyObj(state);
            Object.keys(newObj)
                .filter((collapse) => {
                    return newObj[collapse].isSecondary && newObj[collapse].collapse !== action.collapse;
                })
                .map((collapse) => {
                    newObj[collapse] = { ...newObj[collapse], collapsed: action.collapse };
                });
            return newObj;
        }
        case 'updateAll': {
            let newObj = deepCopyObj(state);
            Object.keys(newObj)
                .filter((collapse) => {
                    return newObj[collapse].collapse !== action.collapse;
                })
                .map((collapse) => {
                    newObj[collapse] = { ...newObj[collapse], collapsed: action.collapse };
                });
            return newObj;
        }
        case 'updateWidget':
            Object.keys(state)
                .filter((collapse) => {
                    return collapse === action.widgetId;
                })
                .map((collapse) => {
                    state[collapse] = { ...state[collapse], collapsed: action.collapse };
                });
            return state;
        default:
            throw new Error();
    }
};

const StatisticsGraphs: React.FC<StatisticsGraphsProps> = (): JSX.Element => {
    const globalDateTimePicker = useRecoilValue(DateTimePicker);
    const collapse = useRecoilValue(Collapse);
    const selectedVehicle = useRecoilValue(SelectedVehicle);
    const allHubsReading = useRecoilValue(AllHubsReading);
    const { fromTimezoneToUTC, fromUTCToTimezone, fromServerToUserUnit, displayUserUnits, convertType } =
        useConverter();
    const timeline = useRecoilValue(Timeline);
    const [allSensors, setAllSensors] = useState<VehicleSensor>();
    const [scale, setScale] = useState<Scales>({
        pressure: [],
        temperature: []
    });
    const [dataToCompare, seDataToCompare] = useState<DataToCompareType>({
        wheel1: [],
        wheel2: []
    });
    const [scaleDiff, setScaleDiff] = useState<Scale>({
        left: [],
        right: []
    });
    const [diffData, setDiffData] = useState<unknown[]>([]);
    const [isAllSensorExternal, setIsAllSensorExternal] = useState<boolean>(false);
    const [isReloading, setIsReloading] = useState<boolean>(true);
    const [collapsed, setCollapsed] = useReducer(CollapseWidget, {});
    const userInfo = useRecoilValue(UserInfo);

    const sortSensors = (sensors: VehicleSensor): VehicleSensor => {
        for (let i = 0, length = sensors.vehicleSensor.length; i < length; i++) {
            sensors.vehicleSensor[i].currentSensor?.sort((sensor1, sensor2) =>
                sensor1.priority < sensor2.priority ? -1 : 1
            );
            sensors.vehicleSensor[i]?.overlappingSensor?.sort((sensor1, sensor2) =>
                sensor1.priority < sensor2.priority ? -1 : 1
            );
        }
        return sensors;
    };

    const getAllSensorsInPeriod = async (dateFrom, dateTo) => {
        const sensorsByPeriodXHR = await SensorLog.getSensorsByPeriod(
            selectedVehicle.id,
            fromTimezoneToUTC(dateFrom.valueOf()),
            fromTimezoneToUTC(dateTo.valueOf())
        );

        setAllSensors(sortSensors(sensorsByPeriodXHR));
        setIsReloading(false);
    };

    const processTempPress = (tempPressData, optimalPressure: number): unknown => {
        const copytempPressData = deepCopyObj(tempPressData);
        let copyTimeline = deepCopyObj(timeline.timeline);

        copytempPressData.forEach((copytempPress) => {
            const timekey = copytempPress.timeKey;
            const isAlienData: boolean = copytempPress.readByVehicleId !== copytempPress.vehicleId;

            copytempPress.coldPressure = copytempPress.coldPressure
                ? Number(
                      fromServerToUserUnit({
                          type: convertType.pressure,
                          value: copytempPress.coldPressure,
                          displayUnits: false,
                          fixed: 1,
                          displayIfEmpty: '----'
                      })
                  )
                : copytempPress.coldPressure;

            copytempPress.hotPressure = copytempPress.hotPressure
                ? Number(
                      fromServerToUserUnit({
                          type: convertType.pressure,
                          value: copytempPress.hotPressure,
                          displayUnits: false,
                          fixed: 1,
                          displayIfEmpty: '----'
                      })
                  )
                : copytempPress.hotPressure;

            copytempPress.temperature = copytempPress.temperature
                ? Number(
                      fromServerToUserUnit({
                          type: convertType.temperature,
                          value: copytempPress.temperature,
                          displayUnits: false,
                          fixed: 1,
                          displayIfEmpty: '----'
                      })
                  )
                : copytempPress.temperature;

            copytempPress.showAlienData = isAlienData;
            const data: Wheel[] | undefined = allSensors?.vehicleSensor;

            if (data && data.length) {
                const getSensor: Wheel[] = data.filter(
                    (sensor) => sensor.currentSensor[0].id === copytempPress.sensorId
                );
                if (getSensor && getSensor.length) {
                    copytempPress.sensorType = getSensor[0].currentSensor[0].sensorType;
                }
            }

            copytempPress.measuredAtScale = copytempPress.sensorId
                ? fromUTCToTimezone(copytempPress.measuredAt, true)
                : null;

            if (timekey.toString() in copyTimeline) {
                copyTimeline[timekey] = copytempPress;
            }
        });

        copyTimeline[Object.keys(copyTimeline)[0]] = {
            ...copyTimeline[Object.keys(copyTimeline)[0]],
            optimalPressure: fromServerToUserUnit({
                type: convertType.pressure,
                value: optimalPressure,
                displayUnits: false,
                fixed: 1,
                displayIfEmpty: '----'
            })
        };
        copyTimeline[Object.keys(copyTimeline)[Object.keys(copyTimeline).length - 1]] = {
            ...copyTimeline[Object.keys(copyTimeline)[Object.keys(copyTimeline).length - 1]],
            optimalPressure: fromServerToUserUnit({
                type: convertType.pressure,
                value: optimalPressure,
                displayUnits: false,
                fixed: 1,
                displayIfEmpty: '----'
            })
        };
        return Object.values(copyTimeline);
    };

    const getTempPress = async (
        dateFrom: DateTime,
        dateTo: DateTime,
        vehicleId: number,
        wheelId: number,
        comparingData: number,
        optimalPressure: number,
        translator: (value) => string,
        sensorId?: number
    ): Promise<unknown> => {
        const tempPressXHR = await SensorLog.getTempPressure(
            vehicleId,
            wheelId,
            fromTimezoneToUTC(dateFrom.valueOf()),
            fromTimezoneToUTC(dateTo.valueOf()),
            timeline.granularity,
            sensorId
        );

        const tempPressData = await processTempPress(
            tempPressXHR && tempPressXHR.tempPressWheel ? tempPressXHR.tempPressWheel : [],
            optimalPressure
        );
        if (comparingData) {
            seDataToCompare((current) => {
                let copy = deepCopyObj(current);
                copy[`wheel${comparingData}`] = tempPressData;
                return copy;
            });
        }

        return tempPressData;
    };

    const getScale = async (dateFrom: DateTime, dateTo: DateTime, vehicleId: number) => {
        const scaleXHR = await SensorLog.getScale(
            vehicleId,
            fromTimezoneToUTC(dateFrom.valueOf()),
            fromTimezoneToUTC(dateTo.valueOf())
        );

        const scale = {
            minPressure: fromServerToUserUnit({
                type: convertType.pressure,
                value: scaleXHR.tyreScale.minPressure,
                displayUnits: false,
                fixed: 1,
                displayIfEmpty: '----'
            }),
            maxPressure: fromServerToUserUnit({
                type: convertType.pressure,
                value: scaleXHR.tyreScale.maxPressure,
                displayUnits: false,
                fixed: 1,
                displayIfEmpty: '----'
            }),
            minTemperature: fromServerToUserUnit({
                type: convertType.temperature,
                value: scaleXHR.tyreScale.minTemperature,
                displayUnits: false,
                fixed: 1,
                displayIfEmpty: '----'
            }),
            maxTemperature: fromServerToUserUnit({
                type: convertType.temperature,
                value: scaleXHR.tyreScale.maxTemperature,
                displayUnits: false,
                fixed: 1,
                displayIfEmpty: '----'
            })
        };

        setScale({
            pressure: [
                closestNumberToDivisibleBy(+scale.minPressure, DEFAULT_GRAPH_SCALE, true),
                closestNumberToDivisibleBy(+scale.maxPressure, DEFAULT_GRAPH_SCALE)
            ],
            temperature: [
                closestNumberToDivisibleBy(+scale.minTemperature, 10, true),
                closestNumberToDivisibleBy(+scale.maxTemperature, 10)
            ]
        });
    };

    const evaluateIfAllExternal = (allSensors) => {
        const data = allSensors.vehicleSensor;
        let allSensorsExternal = true;
        for (let i = 0, length = data.length; i < length; i++) {
            let allExternal =
                data[i]?.currentSensor?.reduce((isExt, sensor) => {
                    return sensor.sensorType === 2 && isExt;
                }, true) || false;
            allSensorsExternal = allSensorsExternal && allExternal;
        }
        setIsAllSensorExternal(allSensorsExternal);
    };

    const evaluateCollapsing = (allSensors) => {
        const data = allSensors.vehicleSensor;
        let keys = {};
        for (let i = 0, length = data.length; i < length; i++) {
            if (data[i].overlappingSensor?.length) {
                for (let j = 0, overLength = data[i].overlappingSensor.length; j < overLength; j++) {
                    const id = `statistics-chart-${userInfo?.user?.customer?.name || 12}-${selectedVehicle.id}-${
                        data[i].customPosition
                    }-${data[i].overlappingSensor[j].id}`;
                    keys[id] = { isSecondary: j > 0, collapsed: j > 0 ? collapse.secondary : collapse.all };
                }
            } else {
                if (data[i].currentSensor?.length > 0) {
                    const id = `statistics-chart-${userInfo?.user?.customer?.name || 12}-${selectedVehicle.id}-${
                        data[i].customPosition
                    }-${data[i].currentSensor[0].id}`;
                    keys[id] = { isSecondary: false, collapsed: collapse.all };
                }
            }
        }

        keys[`statistics-diff-chart-${userInfo?.user?.customer?.name || 12}-${selectedVehicle.id}`] = {
            isSecondary: false,
            collapsed: collapse.all
        };
        keys[`speed-chart-${userInfo?.user?.customer?.name || 12}-${selectedVehicle.id}`] = {
            isSecondary: false,
            collapsed: collapse.all
        };
        setCollapsed({ type: 'setCollapse', collapsedWidget: keys });
    };

    const calculateWheel1Wheel2Diff = (dataToCompare: DataToCompareType) => {
        const valueLimit = 10000000;
        let minTemps = valueLimit;
        let maxTemps = -valueLimit;
        let minPressure = 0;
        let maxPressure = -valueLimit;
        let copyTimeline = deepCopyObj(timeline.timeline);

        const decimals: number = displayUserUnits.pressure === 'bar' ? 3 : 1;
        for (let i = 0, length = dataToCompare.wheel1.length; i < length; i++) {
            const wheel1 = dataToCompare.wheel1[i];
            const wheel2 = dataToCompare.wheel2[i];

            if (wheel1?.timeKey && wheel1?.timeKey === wheel2?.timeKey) {
                const coldPressure =
                    wheel1.coldPressure && wheel2.coldPressure
                        ? +parseFloat(
                              Math.abs(((wheel1.coldPressure as number) - wheel2.coldPressure) as number).toString()
                          ).toFixed(decimals)
                        : null;
                const hotPressure =
                    wheel1.hotPressure && wheel2.hotPressure
                        ? +parseFloat(
                              Math.abs(((wheel1.hotPressure as number) - wheel2.hotPressure) as number).toString()
                          ).toFixed(decimals)
                        : null;
                const temperature =
                    wheel1.temperature && wheel2.temperature
                        ? +parseFloat(
                              Math.abs(((wheel1.temperature as number) - wheel2.temperature) as number).toString()
                          ).toFixed(1)
                        : null;
                const sensorId =
                    coldPressure === null && hotPressure === null && temperature === null ? null : wheel1.sensorId;

                if (temperature && !isNaN(temperature as number)) {
                    if ((temperature as number) < minTemps) {
                        minTemps = temperature as number;
                    }

                    if ((temperature as number) > maxTemps) {
                        maxTemps = temperature as number;
                    }
                }

                maxPressure = (
                    maxPressure < (coldPressure as number)
                        ? (coldPressure as number) < (hotPressure as number)
                            ? (hotPressure as number)
                            : (coldPressure as number)
                        : maxPressure < (hotPressure as number)
                        ? hotPressure
                        : maxPressure
                ) as number;

                copyTimeline[wheel1.timeKey] = {
                    timeKey: wheel1.timeKey,
                    measuredAtScale: fromUTCToTimezone(wheel1.measuredAt, true),
                    coldPressure,
                    hotPressure,
                    temperature,
                    sensorId
                };
            }
        }

        setScaleDiff({
            left: [minPressure, maxPressure],
            right: [minTemps, maxTemps]
        });

        if (!dataToCompare.wheel1.length || !dataToCompare.wheel2.length) {
            setDiffData([]);
        } else {
            setDiffData(Object.values(copyTimeline));
        }
    };

    const getFilteredDiffData = (): DataToCompareType => {
        let frontWheelData = deepCopyObj(dataToCompare);
        if (!allHubsReading && allHubsReading !== undefined) {
            const copyOrigWheel1 = deepCopyObj(dataToCompare.wheel1);
            const filteredWheel1 = copyOrigWheel1.map((data) => {
                if (data.showAlienData) {
                    let a = makeObjectNullable(data);
                    return a;
                }
                return data;
            });
            const copyOrigWheel2 = deepCopyObj(dataToCompare.wheel2);
            const filteredWheel2 = copyOrigWheel2.map((data) => {
                if (data.showAlienData) {
                    let a = makeObjectNullable(data);
                    return a;
                }
                return data;
            });
            frontWheelData.wheel1 = filteredWheel1;
            frontWheelData.wheel2 = filteredWheel2;
        }
        return frontWheelData;
    };

    useEffect(() => {
        if (selectedVehicle.id) {
            setDiffData([]);
            getScale(globalDateTimePicker.current.dateFrom, globalDateTimePicker.current.dateTo, selectedVehicle.id);

            const hasChangedPeriod: boolean =
                globalDateTimePicker.current.dateFrom.valueOf() !== globalDateTimePicker.original.dateFrom.valueOf() ||
                globalDateTimePicker.current.dateTo.valueOf() !== globalDateTimePicker.original.dateTo.valueOf();
            if (hasChangedPeriod) {
                setIsReloading(true);
                getAllSensorsInPeriod(globalDateTimePicker.current.dateFrom, globalDateTimePicker.current.dateTo);
            }
        }
    }, [
        globalDateTimePicker.current.dateFrom,
        globalDateTimePicker.current.dateTo,
        globalDateTimePicker.original.dateFrom,
        globalDateTimePicker.original.dateTo,
        selectedVehicle.id
    ]);

    useEffect(() => {
        if (selectedVehicle.id) {
            setDiffData([]);
            setIsReloading(true);
            getAllSensorsInPeriod(globalDateTimePicker.current.dateFrom, globalDateTimePicker.current.dateTo);
        }
    }, [selectedVehicle.id]);

    useEffect(() => {
        setCollapsed({ type: 'updateSecondary', collapse: collapse.secondary });
    }, [collapse.secondary]);

    useEffect(() => {
        setCollapsed({ type: 'updateAll', collapse: collapse.all });
    }, [collapse.all]);

    useEffect(() => {
        if (allHubsReading !== undefined) {
            setDiffData([]);
            const frontWheelData = getFilteredDiffData();
            calculateWheel1Wheel2Diff(frontWheelData);
        }
    }, [allHubsReading]);

    useEffect(() => {
        if (dataToCompare.wheel1.length && dataToCompare.wheel2.length) {
            const frontWheelData = getFilteredDiffData();
            calculateWheel1Wheel2Diff(frontWheelData);
        }
    }, [dataToCompare]);

    useEffect(() => {
        if (allSensors) {
            evaluateIfAllExternal(allSensors);
            evaluateCollapsing(allSensors);
        }
    }, [allSensors]);

    return (
        <StatisticsGraphsContent
            allSensors={allSensors}
            getTempPress={getTempPress}
            globalYDomain={{
                left: scale.pressure,
                right: scale.temperature
            }}
            diffScale={scaleDiff}
            diffData={diffData}
            isAllSensorExternal={isAllSensorExternal}
            isReloading={isReloading}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            data-testid='StatisticsGraphs-testid'
        />
    );
};

export default Wrapper(StatisticsGraphs);
