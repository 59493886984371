import PortalApi from 'helpers/api/PortalApiClient';
import { TableModelManufacturer, VehicleManufacturerType } from '../models/VehicleManufacturer.type';
import { getParams } from '../helpers';

export default class VehicleManufacturer extends PortalApi<VehicleManufacturerType> {
    route = 'vehicle-manufacturer';

    getAll = async (props): Promise<TableModelManufacturer> => {
        return this.getByCriteria({ criteria: getParams(props), version: 'v2' });
    };
}
