import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const RuleAffectedVehiclesListContainer = styled.div`
    padding: 10px 0;
`;

export const VehicleCover = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const SchemaCover = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
`;
