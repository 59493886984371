import React from 'react';
import { SystemTotalsViewProps } from './SystemTotals.type';
import { SystemTotalsContainer, TableLegend } from './SystemTotals.style';
import { useRecoilValue } from 'recoil';
import SystemTotalsLegendView from './Components/SystenTotalsLegend.view';
import NotTransmittingPopover from '../../NotTransmittingPopover';
import UiLegend from 'components/Ui/Components/UiLegend/UiLegend';
import UiWidget from 'components/Ui/Components/UiWidget/UiWidget';
import { useTranslation } from 'react-i18next';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { applyStyleByMode } from 'helpers';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import SystemTotalsMainTable from './Components/SystemTotalsMainTable';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';
import { ExternalSensorIcon, SensorRedIcon } from 'components/PopoverListContent/SensorItem/SensorItem.style';

export const SystemTotalsContent: React.FC<SystemTotalsViewProps> = ({ handleStageChange }) => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    const LegendItem = ({ title, icon }) => (
        <tr>
            <td>
                <b>{icon}</b>
            </td>
            <td>{title}</td>
        </tr>
    );

    return (
        <SystemTotalsContainer data-testid='SystemTotalsContent'>
            <UiWidget
                title={translate('t.system_totals')}
                testid='Widget-SystemTotals'
                avatar={
                    <UiIcon
                        icon={['fas', 'truck']}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            theme: ThemeMode?.mode,
                            light: '#0000008a',
                            dark: WHITE
                        })}
                    />
                }
                headerAction={
                    <>
                        <UiIconButton testid='SystemTotals-Mode-Button' size='small' onClick={handleStageChange}>
                            <UiIcon icon={['fas', 'ellipsis']} title={translate('t.change_stage_system_total_icon')} />
                        </UiIconButton>
                        <UiLegend
                            title={translate('t.not_transmitting')}
                            content={<NotTransmittingPopover />}
                            width={300}
                            root={
                                <UiIconButton testid='SystemTotals-Sensors-Button' size='small'>
                                    <UiIcon icon={['fas', 'wifi']} title={translate('t.not_transmitting_icon')} />
                                </UiIconButton>
                            }
                            headerElements={
                                <UiLegend
                                    width={150}
                                    minWidth={150}
                                    title={translate('t.legend')}
                                    content={
                                        <TableLegend className='legend-table'>
                                            <tbody>
                                                <LegendItem
                                                    title={translate('t.low_battery_voltage')}
                                                    icon={
                                                        <SensorRedIcon
                                                            icon={['fas', 'battery-low']}
                                                            size='lg'
                                                            transform={'down-2.5 right-4'}
                                                            fixedWidth
                                                        />
                                                    }
                                                />
                                                <LegendItem
                                                    title={translate('t.external_sensor')}
                                                    icon={
                                                        <ExternalSensorIcon
                                                            style={{
                                                                width: '25px',
                                                                height: '22px',
                                                                paddingLeft: '12px'
                                                            }}
                                                        />
                                                    }
                                                />
                                                <LegendItem
                                                    title={translate('t.faulty')}
                                                    icon={
                                                        <SensorRedIcon
                                                            transform='down-5'
                                                            icon={['fas', 'exclamation']}
                                                        />
                                                    }
                                                />
                                            </tbody>
                                        </TableLegend>
                                    }
                                />
                            }
                        />
                        <UiLegend
                            width={550}
                            minWidth={550}
                            title={translate('t.legend')}
                            content={<SystemTotalsLegendView />}
                        />
                    </>
                }
                content={<SystemTotalsMainTable />}
            />
        </SystemTotalsContainer>
    );
};
