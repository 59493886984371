import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { VehicleStatsData } from 'models/TrackLog.type';
import TrackLogApi from 'api/TrackLog';
import {
    ColumnNameVehiclesStatsAtom,
    FilterPeriod,
    SearchVehicle
} from 'components/Vehicle/VehicleStatsTable/VehicleStatsTable.atom';
import { ColumnsModel, VehicleStatsBodyProps } from './VehicleStatsBody.type';
import { VehicleStatsBodyContent } from './VehicleStatsBody.view';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { Duration } from 'luxon';
import { cacheTimeToMilliseconds, isCacheAvailable } from 'helpers/cache';
import { TrackLogQueryKeys } from 'models/TrackLog.type';
import { Wrapper } from 'helpers/wrapper';
import { UserInfo } from 'states/global/User';
import AlertsTab from 'components/AlertNotification/AlertsTab/AlertsTab';
import { Typography } from '@mui/material';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import CloseIcon from '@mui/icons-material/Close';
import useUserLocalStorageSettings, {
    useUserLocalStorageSettingsTable
} from 'components/CustomHooks/UserLocalStorageSettings';
import { getDaysHoursPeriodLabel, deepCopyObj } from 'helpers';
import { ColumnIndex } from '../VehicleStatsTable/VehicleStatsTable.type';

const QUERY_KEY = 'VehicleStats';

const TrackLog = new TrackLogApi();

const sortArray = (arr: VehicleStatsData[], orderBy: 'desc' | 'asc'): VehicleStatsData[] => {
    return arr.sort((a, b) => {
        const vehicelNameA = a.name.toLowerCase();
        const vehicleNameB = b.name.toLowerCase();
        if (a.alert === b.alert) {
            return orderBy === 'asc'
                ? vehicelNameA > vehicleNameB
                    ? 1
                    : vehicleNameB > vehicelNameA
                    ? -1
                    : 0
                : vehicelNameA < vehicleNameB
                ? 1
                : vehicleNameB < vehicelNameA
                ? -1
                : 0;
        } else {
            return a.alert < b.alert ? 1 : -1;
        }
    });
};

const getVehicleStats = async (period: number): Promise<VehicleStatsData | undefined> => {
    const { vehicleStats }: VehicleStatsData | undefined = await TrackLog.getWebtrackVehicleStats({ period });
    return vehicleStats;
};

const VehicleStatsBody: React.FC<VehicleStatsBodyProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [orderDirection, setOrderDirection] = useState<'desc' | 'asc'>('asc');
    const vehiclePeriod = useRecoilValue(FilterPeriod);
    const searchVehicle = useRecoilValue(SearchVehicle);
    const [vehicleColumns, setVehicleColumns] = useRecoilState(ColumnNameVehiclesStatsAtom);
    const localStorageTableKey = useMemo(() => `customTable.${QUERY_KEY}Table`, [QUERY_KEY]);
    const { setUserSettings } = useUserLocalStorageSettings([localStorageTableKey]);
    const { getUserSettingTable } = useUserLocalStorageSettingsTable();
    const customerSettings = useRecoilValue(CustomerSettings);
    const queryCache = useQueryClient().getQueryCache();
    const userInfo = useRecoilValue(UserInfo);
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispatch = ModalDispatcher(modalAtom, setStateModalAtom);

    const { data: vehicleStatsData, refetch } = useQuery<VehicleStatsData | undefined, Error, VehicleStatsData[]>(
        [TrackLogQueryKeys.getWebtrackVehicleStats, vehiclePeriod, userInfo.user?.customer.id],
        () => (vehiclePeriod ? getVehicleStats(vehiclePeriod) : undefined),
        {
            refetchOnWindowFocus: false,
            refetchInterval: searchVehicle.length ? false : Duration.fromObject({ seconds: 30 }).as('milliseconds'),
            staleTime: cacheTimeToMilliseconds(30, 'seconds'),
            cacheTime: cacheTimeToMilliseconds(30, 'seconds'),
            retry: 1,
            retryDelay: 1000,
            enabled: !!vehiclePeriod,
            onSuccess: (vehicleStatsDataOnSuccess) => {
                setIsLoading(false);
                if (vehicleStatsDataOnSuccess && vehicleStatsDataOnSuccess.length) {
                    const data = sortArray(vehicleStatsDataOnSuccess, orderDirection);
                    return data;
                }
                return [];
            }
        }
    );

    const getSortedAndFilteredData = (data: VehicleStatsData[]): VehicleStatsData[] => {
        const filteredData = data.filter((d) => d.name.toLocaleLowerCase().includes(searchVehicle.toLowerCase()));
        return sortArray(filteredData, orderDirection === 'asc' ? 'asc' : 'desc');
    };

    const handleSortRequest = (): void => {
        setOrderDirection((current) => (current === 'asc' ? 'desc' : 'asc'));
    };

    const columns: ColumnsModel[] = [
        { id: 'vehicleName', label: translate('t.vehicle_name'), minWidth: 100, align: 'left' },

        {
            id: 'status',
            label: translate('t.tpms_status'),
            minWidth: 100,
            align: 'left'
        },

        {
            id: 'system_status',
            label: translate('t.system_status'),
            minWidth: 120,
            align: 'left'
        },

        {
            id: 'besRank',
            label: translate('t.highest_BES', { value: customerSettings.mdd_bes_display_name }),
            minWidth: 50,
            align: 'left'
        },
        {
            id: 'last_alerts',
            label: translate('t.current_tpms'),
            minWidth: 30,
            align: 'right'
        },
        {
            id: 'previous_alerts',
            label: translate('t.previous_TPMS_alerts', {
                value: vehiclePeriod ? getDaysHoursPeriodLabel(vehiclePeriod, translate) : ''
            }),
            minWidth: 30,
            align: 'right'
        },

        {
            id: 'distance',
            label: `${translate('t.distance')}`,
            minWidth: 10,
            align: 'right'
        }
    ];

    const updateAlertsModal = (contentModal, open: boolean) => {
        modalDispatch({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModal,
                isOpen: open
            }
        });
    };

    const showModalAlerts = (
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
        vehicleId: number,
        showTab: number
    ) => {
        const contentModal = {
            id: 'AlertModals',
            customContent: <AlertsTab showTab={showTab} vehicleId={vehicleId} />,
            leftTitle: <Typography marginTop='4px'>{translate('t.alert_notifications')}</Typography>,
            rightTitle: (
                <UiIconButton
                    aria-label='close'
                    onClick={() => updateAlertsModal(contentModal, false)}
                    testid='CloseAlertsModal-Button'
                >
                    <CloseIcon fontSize='small' />
                </UiIconButton>
            ),
            width: 420,
            height: 500,
            widthUnit: 'px',
            onClose: () => updateAlertsModal(contentModal, false)
        };

        updateAlertsModal(contentModal, true);
        event.stopPropagation();
    };

    const updateLabelState = (columnIndex: number, label: string): void => {
        if (getUserSettingTable(localStorageTableKey) && getUserSettingTable(localStorageTableKey)['hiddenColumns']) {
            let newVal = deepCopyObj(getUserSettingTable(localStorageTableKey)['hiddenColumns'][columnIndex]);
            newVal.label = label;

            setUserSettings(localStorageTableKey, {
                ...getUserSettingTable(localStorageTableKey),
                hiddenColumns: [
                    ...getUserSettingTable(localStorageTableKey)['hiddenColumns'].slice(0, columnIndex),
                    newVal,
                    ...getUserSettingTable(localStorageTableKey)['hiddenColumns'].slice(columnIndex + 1)
                ]
            });
        }

        if (vehicleColumns.length) {
            let newVal = deepCopyObj(vehicleColumns[columnIndex]);
            newVal.label = label;
            setVehicleColumns([
                ...vehicleColumns.slice(0, columnIndex),
                newVal,
                ...vehicleColumns.slice(columnIndex + 1)
            ]);
        }
    };

    useEffect(() => {
        if (getUserSettingTable(localStorageTableKey) && getUserSettingTable(localStorageTableKey)['hiddenColumns']) {
            setVehicleColumns(getUserSettingTable(localStorageTableKey)['hiddenColumns']);
        } else {
            const hiddenColumnsByDefault = columns.map((column, index) => ({
                id: column.id,
                label: column.label,
                isVisible: index !== ColumnIndex.PREVIOUS_ALERTS // todo: make a escalable feature for default columns option
            }));

            setUserSettings(localStorageTableKey, {
                ...getUserSettingTable(localStorageTableKey),
                hiddenColumns: hiddenColumnsByDefault
            });
            setVehicleColumns(hiddenColumnsByDefault);
        }

        return () => {
            setVehicleColumns([]);
        };
    }, []);

    useEffect(() => {
        if (customerSettings.mdd_bes) {
            updateLabelState(
                ColumnIndex.BES,
                translate('t.highest_BES', { value: customerSettings.mdd_bes_display_name })
            );
        }
    }, [customerSettings.mdd_bes_display_name]);

    useEffect(() => {
        setIsLoading(true);
        if (!isCacheAvailable([TrackLogQueryKeys.getWebtrackVehicleStats, vehiclePeriod], queryCache)) {
            refetch();
        } else {
            setIsLoading(false);
        }

        if (vehiclePeriod) {
            updateLabelState(
                ColumnIndex.PREVIOUS_ALERTS,
                translate('t.previous_TPMS_alerts', { value: getDaysHoursPeriodLabel(vehiclePeriod, translate) })
            );
        }
    }, [vehiclePeriod]);

    return (
        <VehicleStatsBodyContent
            data-testid={'VehicleStatsTable-testid'}
            vehicleStatsData={getSortedAndFilteredData(vehicleStatsData || [])}
            columns={columns}
            handleSortRequest={handleSortRequest}
            orderDirection={orderDirection}
            isLoading={isLoading}
            showModalAlerts={showModalAlerts}
        />
    );
};

export default Wrapper(VehicleStatsBody);
