import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { ColorType } from './UiIcon.type';
import { useTheme } from '@mui/material';
import { ThemeMode } from 'states/global/Theme';
import { WHITE } from '../../colors';

export const LayoutContent = styled.div`
    display: flex;
    background: ${() => useTheme().palette.background.default};
    position: absolute;
    min-height: 100%;
    width: 100%;
`;
export const UiIconViewContent = styled.div``;

export const UiIconFontAwesome = styled(FontAwesomeIcon)<{ $colorTheme?: ColorType }>`
    ${({ $colorTheme, color }) => {
        if (useTheme().palette.mode === ThemeMode.light) {
            return $colorTheme || color ? `color: ${color || $colorTheme?.light}` : '';
        }
        return $colorTheme || color ? `color: ${color || $colorTheme?.dark}` : '';
    }}
`;
