import { ERROR, ERROR_DARK, WHITE } from 'components/Ui/colors';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { ReactComponent as ExternalIcon } from 'assets/svg/externalSensorIcon.svg';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';
import { ThemeMode } from 'states/global/Theme';
import { applyStyleByMode } from 'helpers';

export const LinkContainer = styled.div`
    width: 100%;
`;

export const SensorRedIcon = styled(UiIcon)`
    color: ${() => `${useTheme().palette.mode === ThemeMode.dark ? ERROR_DARK : ERROR}`} !important;
`;

export const ExternalSensorIcon = styled(ExternalIcon)`
    ${() => {
        return applyStyleByMode({
            theme: useTheme().palette.mode,
            light: 'color: #0000008a;',
            dark: `color: ${WHITE};`
        });
    }}
`;
