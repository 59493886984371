import React, { useMemo } from 'react';
import { CustomerSettingsViewContent } from './CustomerSettings.style';
import CustomerSettings from 'components/Customer/CustomerSettings/CustomerSettings';
import Grid from '@mui/material/Grid';
import CustomerTable from 'components/Customer/CustomerTable';
import { ROLES } from 'variables';
import { useRecoilValue } from 'recoil';
import { UserInfo } from 'states/global/User';
import { checkRole } from 'helpers/authentication';
import CustomerReportWidget from 'components/Customer/CustomerReportWidget';
import ShiftTimeTabManager from 'components/Customer/ShiftTimeTabManager/ShiftTimeTabManager';

export const CustomerSettingsContent: React.FC = (): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const isAdmin = useMemo(
        () => checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN, ROLES.ROLE_TECHNICAL_ADMIN]),
        [userInfo.user]
    );
    const isSuperAdmin = useMemo(() => checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN]), [userInfo.user]);
    const isAdminOrSuperAdmin = useMemo(
        () => checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN]),
        [userInfo.user]
    );

    return (
        <CustomerSettingsViewContent data-testid='CustomerSettingsContent'>
            <Grid container spacing={2}>
                <Grid item md={5} lg={5} sm={12} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <CustomerTable />
                        </Grid>
                        <Grid item sm={12}>
                            {isSuperAdmin && <CustomerReportWidget />}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={7} lg={7} sm={12} xs={12}>
                    {isAdmin ? (
                        <Grid item sm={12}>
                            {' '}
                            <CustomerSettings />{' '}
                        </Grid>
                    ) : (
                        <></>
                    )}

                    {isAdminOrSuperAdmin ? (
                        <Grid item sm={12} sx={{ mt: 2 }}>
                            <ShiftTimeTabManager />
                        </Grid>
                    ) : (
                        <></>
                    )}
                </Grid>
            </Grid>
        </CustomerSettingsViewContent>
    );
};
