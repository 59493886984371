import React from 'react';
import { LogoutButtonPropsView } from './LogoutButton.type';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { LogoutButton, LogoutButtonNavbar } from './LogoutButton.style';
import { useTranslation } from 'react-i18next';
import { useProSidebar } from 'react-pro-sidebar';
import Tooltip from '@mui/material/Tooltip';

export const LogoutButtonContent: React.FC<LogoutButtonPropsView> = ({ handleModalLogout, mode }): JSX.Element => {
    const { t: translate } = useTranslation();
    const { collapsed } = useProSidebar();

    switch (mode) {
        case 'sidebar':
            return (
                <>
                    <Tooltip title={collapsed ? translate('t.logout') : ''} placement='bottom' arrow disableInteractive>
                        <LogoutButtonNavbar
                            $collapsedSidebar={collapsed}
                            onClick={handleModalLogout}
                            data-testid='Sidebar-LogoutButton'
                            aria-label={collapsed ? translate('t.logout') : ''}
                        >
                            <ExitToAppIcon fontSize='inherit' />
                            {collapsed ? '' : translate('t.logout')}
                        </LogoutButtonNavbar>
                    </Tooltip>
                </>
            );
        default:
            return (
                <>
                    <LogoutButton
                        onClick={handleModalLogout}
                        aria-label={translate('t.logout')}
                        testid='TopBar-LogoutButton'
                    >
                        <Tooltip
                            title={translate('t.logout')}
                            placement='bottom'
                            enterDelay={1000}
                            leaveDelay={0}
                            arrow
                            disableFocusListener
                        >
                            <ExitToAppIcon fontSize='inherit' />
                        </Tooltip>
                    </LogoutButton>
                </>
            );
    }
};
