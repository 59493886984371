import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useModal from 'components/CustomHooks/ModalHook';
import { useRecoilValue } from 'recoil';
import { Success } from 'components/Popup/Popup';
import { useMutation } from '@tanstack/react-query';
import TyreModelAPI from 'api/TyreModel';
import TyreManufacturerAPI from 'api/TyreManufacturer';
import TyreSizeAPI from 'api/TyreSize';
import { SIZE_BUTTON, VARIANT_BUTTON } from 'components/Ui/variables';
import UiButton from 'components/Ui/Components/UiButton';
import { TextField, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormikValuesType } from './AddTyreModel.type';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete/LazyloadingAutocomplete';

const tyreModelAPI = new TyreModelAPI();
const tyreManufacturerAPI = new TyreManufacturerAPI();
const tyreSizeAPI = new TyreSizeAPI();

const initialValues: FormikValuesType = {
    name: '',
    treadPattern: '',
    compound: '',
    ply: '',
    tyreRimAssociation: '',
    construction: '',
    tyreManufacturer: null,
    tyreSize: null
};

const AddTyreModel = () => {
    const { t: translate } = useTranslation();
    const { updateModalState, closeModal } = useModal();
    const queryTableFn = useRecoilValue(QueryFn(`TyreModel-QueryFn`));

    const validationSchema = Yup.object().shape({
        name: Yup.string().min(3, 'Too Short!').required(translate('t.required')),
        treadPattern: Yup.string().required(translate('t.required')),
        compound: Yup.string(),
        ply: Yup.string(),
        tyreRimAssociation: Yup.string(),
        construction: Yup.string().required(translate('t.required')),
        tyreManufacturer: Yup.mixed().required(translate('t.required')),
        tyreSize: Yup.mixed().required(translate('t.required'))
    });

    const { mutate: submit } = useMutation((value: FormikValuesType) => tyreModelAPI.post({ data: value }), {
        onSettled: () => {
            closeModal();
            queryTableFn.refetch && queryTableFn.refetch instanceof Function && queryTableFn.refetch();
        },
        onSuccess: () => {
            Success({
                text: translate('t.item_created_successfully')
            });
        }
    });

    useEffect(() => {
        updateModalState({
            buttons: (
                <UiButton
                    testid='tyre-model-add-button'
                    skin='success'
                    size={SIZE_BUTTON}
                    variant={VARIANT_BUTTON}
                    form='tyre-model-form'
                    type='submit'
                >
                    {translate('t.add')}
                </UiButton>
            )
        });
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
                submit(values);
            }}
        >
            {(props) => (
                <Form id='tyre-model-form'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                data-testid='tyre-model-name'
                                id='name'
                                name='name'
                                error={!!props.errors.name && props.touched.name}
                                label={translate('t.name')}
                                value={props.values.name}
                                variant='outlined'
                                size='small'
                                fullWidth
                                helperText={props.errors.name && props.touched.name ? props.errors.name : ''}
                                onChange={props.handleChange}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                data-testid='tyre-model-treadPattern'
                                id='treadPattern'
                                name='treadPattern'
                                label={translate('t.tread_pattern')}
                                value={props.values.treadPattern}
                                variant='outlined'
                                size='small'
                                fullWidth
                                error={!!props.errors.treadPattern && props.touched.treadPattern}
                                helperText={
                                    props.errors.treadPattern && props.touched.treadPattern
                                        ? props.errors.treadPattern
                                        : ''
                                }
                                onChange={props.handleChange}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                data-testid='tyre-model-compound'
                                id='compound'
                                name='compound'
                                label={translate('t.compound')}
                                value={props.values.compound}
                                variant='outlined'
                                size='small'
                                fullWidth
                                onChange={props.handleChange}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                data-testid='tyre-model-ply'
                                id='ply'
                                name='ply'
                                label='PLY'
                                value={props.values.ply}
                                variant='outlined'
                                size='small'
                                fullWidth
                                onChange={props.handleChange}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                data-testid='tyre-model-tra'
                                id='tyreRimAssociation'
                                name='tyreRimAssociation'
                                label={translate('t.tyre_rim_association')}
                                value={props.values.tyreRimAssociation}
                                variant='outlined'
                                size='small'
                                fullWidth
                                onChange={props.handleChange}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                data-testid='tyre-model-construction'
                                id='construction'
                                name='construction'
                                label={translate('t.construction')}
                                value={props.values.construction}
                                variant='outlined'
                                size='small'
                                fullWidth
                                error={!!props.errors.construction && props.touched.construction}
                                helperText={
                                    props.errors.construction && props.touched.construction
                                        ? props.errors.construction
                                        : ''
                                }
                                onChange={props.handleChange}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LazyloadingAutocomplete
                                id={'tyre-model-mafuncturer'}
                                label={translate('t.manufacturer')}
                                query={tyreManufacturerAPI.getTable}
                                queryId={'tyre-model-mafuncturer'}
                                optionKey={'name'}
                                fullWidth
                                optionValue={'id'}
                                size='small'
                                error={!!props.errors.tyreManufacturer && props.touched.tyreManufacturer}
                                helperText={
                                    props.errors.tyreManufacturer && props.touched.tyreManufacturer
                                        ? props.errors.tyreManufacturer
                                        : ''
                                }
                                querySortBy={[]}
                                onValueChange={(itemId) => {
                                    if (itemId) {
                                        props.setFieldValue('tyreManufacturer', itemId);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LazyloadingAutocomplete
                                id={'tyre-size'}
                                label={translate('t.tyre_size')}
                                query={tyreSizeAPI.getTable}
                                queryId={'tyre-size'}
                                optionKey={'name'}
                                fullWidth
                                optionValue={'id'}
                                size='small'
                                error={!!props.errors.tyreSize && props.touched.tyreSize}
                                helperText={
                                    props.errors.tyreSize && props.touched.tyreSize ? props.errors.tyreSize : ''
                                }
                                querySortBy={[]}
                                onValueChange={(itemId) => {
                                    if (itemId) {
                                        props.setFieldValue('tyreSize', itemId);
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default AddTyreModel;
