import React from 'react';
import { useRecoilValue } from 'recoil';
import {
    ContainerImage,
    ContainerBtnImage,
    AvatarImg,
    CustomTextField,
    ContainerButton,
    EmailWarning
} from './AccountSettingsForm.style';
import { FormikValidationViewProps, TypeRole, InicialvaluesForm } from './AccountSettingsForm.type';
import { Clear, PhotoCamera } from '@mui/icons-material';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid, Tooltip } from '@mui/material';
import RolesDropDown from '../RolesDropDown/RolesDropDown';
import Button from 'components/Ui/Components/UiButton';
import * as Yup from 'yup';
import NotAvatar from 'assets/images/not_avatar.png';
import { UserInfo } from 'states/global/User';
import ButtonsToTestAlerts from '../ButtonsToTestAlerts/ButtonsToTestAlerts';
import { GridFieldFormik } from '../ProfileTabManager/ProfileTabManager.style';
import UiPhoneInputNumber from 'components/Ui/Components/UiPhoneInputNumber/UiPhoneInputNumber';
import { freeEmailService } from 'helpers';
import UiIconButton from 'components/Ui/Components/UiIconButton/UiIconButton';
import { NO_ARIA_LABEL_ALLOWED } from 'variables';

export const AccountSettingsFormik: React.FC<FormikValidationViewProps> = (props): JSX.Element => {
    const userInfo = useRecoilValue(UserInfo);
    const { t: translate } = useTranslation();
    const [isValid, setIsValid] = React.useState<string | null>(null);

    const schemaValidateForm = Yup.object({
        username: Yup.string()
            .trim()
            .required(translate('p.this_field_is_required'))
            .min(3, 'Name is Too Short.')
            .trim()
            .test('exists-username', translate('t.username_already_exists'), async function () {
                return (await props.userNameAvailable(
                    this.parent.username,
                    props.isCurrentUser ? userInfo.user?.username : props.userData?.username
                )) as boolean;
            }),
        email: Yup.string()
            .trim()
            .required(translate('p.this_field_is_required'))
            .email()
            .trim()
            .test('exists-email', translate('t.email_already_exists'), async function () {
                return (await props.userEmailAvailable(
                    this.parent.email,
                    props.isCurrentUser ? userInfo.user?.email : props.userData?.email
                )) as boolean;
            }),
        phoneNumber: Yup.string(),
        role: Yup.string().required(translate('p.this_field_is_required')),
        firstname: Yup.string().trim().required(translate('p.this_field_is_required')),
        lastname: Yup.string().trim().required(translate('p.this_field_is_required'))
    });

    return (
        <Formik
            initialValues={props.inicialvaluesFormik}
            onSubmit={(values: InicialvaluesForm, actions) => {
                props.handleSubmit(values, actions.setSubmitting);
            }}
            validationSchema={schemaValidateForm}
        >
            {(propsFormik: FormikProps<InicialvaluesForm>) => {
                const { touched, errors, setFieldValue, handleChange, values, isSubmitting, setTouched } = propsFormik;

                props.selectedFile ? (values.avatar = props.selectedFile) : '';

                return (
                    <Form>
                        <Grid container justify-content='flex-start' direction='row'>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <ContainerImage>
                                    <ContainerBtnImage previewImg={props.previewImg ? 'red' : '#2d863d'}>
                                        {props.previewImg ? (
                                            <Tooltip title='Remove Image'>
                                                <UiIconButton
                                                    color='primary'
                                                    aria-label={NO_ARIA_LABEL_ALLOWED}
                                                    role={NO_ARIA_LABEL_ALLOWED}
                                                    component='label'
                                                    onClick={() => {
                                                        props.setPreviewImg('');
                                                    }}
                                                    testid='Remove-Imagen-Buttton'
                                                    size='large'
                                                >
                                                    <Clear titleAccess={translate('t.remove_photo_profile')} />
                                                </UiIconButton>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title='Select Image'>
                                                <UiIconButton
                                                    aria-label={NO_ARIA_LABEL_ALLOWED}
                                                    role={NO_ARIA_LABEL_ALLOWED}
                                                    component='label'
                                                    testid='Change-Imagen-Buttton'
                                                >
                                                    <>
                                                        <PhotoCamera
                                                            titleAccess={translate('t.upload_photo_profile')}
                                                        />
                                                        <input
                                                            type='file'
                                                            hidden
                                                            accept='image/*'
                                                            aria-hidden='true'
                                                            onChange={props.handleCapture}
                                                        />
                                                    </>
                                                </UiIconButton>
                                            </Tooltip>
                                        )}
                                    </ContainerBtnImage>
                                    <AvatarImg src={values.avatar ? `${values.avatar}` : NotAvatar} alt=' Avatar' />
                                </ContainerImage>
                            </Grid>

                            <GridFieldFormik item xl={4} lg={4} md={6} sm={6} xs={12}>
                                <CustomTextField
                                    id='username'
                                    label={translate('t.username')}
                                    value={values.username}
                                    variant='outlined'
                                    size='small'
                                    name='username'
                                    error={!!errors.username}
                                    onBlur={() =>
                                        setTouched({
                                            ...propsFormik.touched,
                                            username: false
                                        })
                                    }
                                    onFocus={() =>
                                        setTouched({
                                            ...propsFormik.touched,
                                            username: true
                                        })
                                    }
                                    type='text'
                                    fullWidth
                                    onChange={handleChange}
                                    inputProps={{ step: 0.1, min: 0 }}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={errors.username}
                                    data-testid='Profile-UserName-input'
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item xl={4} lg={4} md={6} sm={6} xs={12}>
                                <CustomTextField
                                    id='email'
                                    label={translate('t.email')}
                                    value={values.email}
                                    variant='outlined'
                                    size='small'
                                    name='email'
                                    error={!!errors.email}
                                    onBlur={() =>
                                        setTouched({
                                            ...propsFormik.touched,
                                            username: false
                                        })
                                    }
                                    onFocus={() =>
                                        setTouched({
                                            ...propsFormik.touched,
                                            username: true
                                        })
                                    }
                                    type='mail'
                                    fullWidth
                                    onChange={handleChange}
                                    inputProps={{ step: 0.1, min: 0 }}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={errors.email}
                                    data-testid='Email-input'
                                />
                            </GridFieldFormik>

                            <RolesDropDown
                                value={values.role}
                                error={errors.role}
                                disabled={
                                    userInfo.user?.role.id !== TypeRole.ROLE_SUPER_ADMIN &&
                                    userInfo.user?.role.id !== TypeRole.ROLE_ADMIN
                                }
                                getValue={(e) => setFieldValue('role', e ?? null)}
                                defaultValue={values.role ?? null}
                                xl={4}
                                lg={4}
                                md={6}
                                sm={6}
                                xs={12}
                                testId='Profile-RolesDropDown'
                            />

                            <GridFieldFormik item xl={4} lg={4} md={6} sm={6} xs={12}>
                                <UiPhoneInputNumber
                                    value={values.phoneNumber}
                                    setFieldValue={setFieldValue}
                                    international={true}
                                    className='PhoneInputCustom'
                                    defaultCountry=''
                                    placeholder={translate('t.phone_number')}
                                    name='phoneNumber'
                                    setIsValid={setIsValid}
                                    isValid={isValid}
                                    testId='Profile-UiPhoneInputNumber'
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item xl={4} lg={4} md={6} sm={6} xs={12}>
                                <CustomTextField
                                    id='firstname'
                                    label={translate('t.first_name')}
                                    value={values.firstname}
                                    variant='outlined'
                                    size='small'
                                    name='firstname'
                                    error={!!errors.firstname && !!touched.firstname}
                                    type='mail'
                                    fullWidth
                                    onChange={handleChange}
                                    inputProps={{ step: 0.1, min: 0 }}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={errors.firstname}
                                    data-testid='Profile-FirstName-Input'
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item xl={4} lg={4} md={6} sm={6} xs={12}>
                                <CustomTextField
                                    id='lastname'
                                    label={translate('t.last_name')}
                                    value={values.lastname}
                                    variant='outlined'
                                    size='small'
                                    name='lastname'
                                    error={!!errors.lastname && !!touched.lastname}
                                    type='mail'
                                    fullWidth
                                    onChange={handleChange}
                                    inputProps={{ step: 0.1, min: 0 }}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={errors.lastname}
                                    data-testid='Profile-LastName-Input'
                                />
                            </GridFieldFormik>
                            <Grid item lg={8} md={12} sm={12} xs={12}>
                                <ButtonsToTestAlerts
                                    accountSettings={true}
                                    isCurrentUser={props.isCurrentUser}
                                    disabledSMSAlert={props.userData && props.userData.phoneNumber ? false : true}
                                    emailTestId='Profile-EmailAlert-Button'
                                    smsTestId='Profile-SMSAlert-Button'
                                    whatsappTestId='Profile-WhatsappAlert-Button'
                                />
                            </Grid>

                            <Grid item lg={4} md={12} sm={12} xs={12}>
                                <ContainerButton direction='right'>
                                    <Button
                                        variant='contained'
                                        data-testid='Save-Form-Button-AccountSettingsFormik'
                                        type='submit'
                                        disabled={
                                            isSubmitting ||
                                            !!isValid ||
                                            !props.sendform ||
                                            !!errors.email ||
                                            !!errors.username ||
                                            touched.email ||
                                            touched.username
                                        }
                                        skin='success'
                                    >
                                        {isSubmitting ? <CircularProgress size={25} /> : translate('t.save')}
                                    </Button>
                                </ContainerButton>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                {freeEmailService(values.email) && (
                                    <EmailWarning severity='warning' data-testid='free-mail-service-warning'>
                                        {translate('p.advise_not_use_free_email_services')}{' '}
                                        {values.email.includes('@gmail.com') && translate('p.no_gmail_support')}
                                    </EmailWarning>
                                )}
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};
