import React from 'react';
import { Skeleton } from '@mui/material';
import { ShiftTImeCalendarLoaderLoaderContainer } from './ShiftTimeCalendar.style';

const ShiftTImeCalendarLoader: React.FC = (): JSX.Element => {
    const elements = Array.from({ length: 30 }, (_, i) => i);

    return (
        <ShiftTImeCalendarLoaderLoaderContainer data-testid='ShiftTImeCalendarLoader-Container'>
            {elements.map((_v, i) => (
                <Skeleton key={i} variant='rounded' width='19.2%' height={100} />
            ))}
        </ShiftTImeCalendarLoaderLoaderContainer>
    );
};

export default ShiftTImeCalendarLoader;
