import { Box, TableCell, Typography, Theme } from '@mui/material';
import { ThemeMode } from 'states/global/Theme';
import styled from 'styled-components';

export const AlertSettingsOverviewTableLineContainer = styled.div``;

export const LevelIndicator = styled(TableCell)((props: { background?: string }) => ({
    backgroundImage: `linear-gradient(to right, ${props.background}, ${props.background
        ?.replace('rgb(', 'rgba(')
        .replace('1)', '0) 80%')})`,
    minWidth: 150,
    paddingRight: 5,
    paddingLeft: 10
}));

export const TableBoxCover = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between'
});

export const TableBox = styled(Box)((props: { color?: string }) => ({
    display: 'flex',
    alignItems: 'center',
    color: props.color || 'inherit'
}));

export const TableWithIcon = styled(Box)({
    display: 'flex',
    alignItems: 'center'
});

export const SchemaBox = styled(Box)({
    width: 50,
    margin: 'auto'
});

export const RuleTypeDescription = styled(Typography)({
    textAlign: 'center'
});

export const ThemedCell = styled(TableCell)((props: { theme: Theme }) => ({
    background: props.theme?.palette?.mode === ThemeMode.dark ? `rgb(29,38,48)` : '#f2f2f2'
}));
