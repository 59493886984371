import styled from 'styled-components';
import { Grid, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import { DARK_STEEL_GREY, BACKGROUND_BLUE, WHITE } from '../../Ui/colors';
import { ThemeMode } from '../../../states/global/Theme';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const ButtonTabs = styled.div`
    display: inline-flex;
    width: -webkit-fill-available;
`;

export const IconArrowTab = styled.div`
    position: absolute;
    right: 5px;
    top: 36px;

    & svg {
        font-size: 19px;
    }
`;

export const SubTitleMainTabBtn = styled.div`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `font-size: ${common.textSize};`;
    }}
    text-align: left;
    width: inherit;
    text-transform: none;
    width: 100%;
    letter-spacing: 0.4px;
`;

export const TitleMainTabBtn = styled.div`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `font-size: ${common.titleSize};`;
    }}
    display: inline-flex;
    font-weight: 600;
    margin-left: -4px;
    text-transform: capitalize;
`;

export const MainContainerBodyTab = styled.div`
    width: 100%;
    min-height: 78vh;
    max-height: 85vh;
    max-width: 90vw;
    overflow-y: auto;
    padding: 0 0 15px 0 !important;
`;

export const ContainerBodyTab = styled.div`
    text-align: center;
    padding: 10px;

    .form-btns {
        margin-top: 10px;
    }
`;

export const GridFieldFormik = styled(Grid)`
    width: 100%;
    padding: 10px;
    display: inline-grid;
    min-width: 60px;
`;

export const TitleContentTab = styled.span`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `font-size: ${common.titleSize};`;
    }}
    border-bottom: 1px solid #c0c0c0;
    font-weight: 600;
    padding: 3px;
    text-transform: capitalize;
`;

export const ProfileTabManagerContent = styled.div`
    .PrivateTabIndicator-colorSecondary-6 {
        background-color: transparent;
    }

    ${() =>
        useTheme().palette.mode === ThemeMode.dark
            ? `
            && .MuiTextField-root label { 
                padding: 0 5px 0 0;
            }
            && .autocomplete-sound label {
                color: #a1a1a1;
            }
            & .MuiInputLabel-shrink {
                transform: translate(10px, -9px) scale(0.78);
            }`
            : ''})}

    @media (max-width: 599.95px) {
        .css-1vfemal-MuiTabs-root .MuiTabs-scrollButtons {
            display: flex;
        }
    }
`;

export const TabsContainer = styled.div`
    flex-grow: 1;
    display: flex;
    height: 100%;
    @media only screen and (max-width: 769px) {
        display: block;
    }
`;

export const TabsCustom = styled(Tabs)`
    width: auto;
    min-width: 80px;

    & .MuiTabs-indicator {
        display: none;
    }

    @media only screen and (min-width: 1536px) {
        min-width: 400px;
    }
`;

export const TabCustom = styled(Tab)`
    padding: 12px 15px;
    max-height: none;
    text-transform: capitalize;

    @media only screen and (min-width: 1536px) {
        max-width: 400px;
    }

    @media only screen and (min-width: 769px) {
        max-width: 350px;
        &.Mui-selected {
            ${() => {
                return applyStyleByMode({
                    theme: useTheme().palette.mode,
                    light: `background-color: ${DARK_STEEL_GREY} !important;`,
                    dark: `background-color: ${BACKGROUND_BLUE} !important;`
                });
            }}

            & .MuiSvgIcon-root {
                ${() => {
                    return applyStyleByMode({
                        theme: useTheme().palette.mode,
                        light: `background-color: ${DARK_STEEL_GREY} !important;`,
                        dark: `background-color: ${BACKGROUND_BLUE} !important;`
                    });
                }}
                color: ${WHITE} !important;
            }
            & .MuiTypography-root {
                color: ${WHITE} !important;
            }
        }
        & .MuiSvgIcon-root {
            margin: 0 7px 0 0;
            padding-bottom: 10px;
            ${() => {
                return applyStyleByMode({
                    theme: useTheme().palette.mode,
                    light: `color: ${DARK_STEEL_GREY};`,
                    dark: `color: ${BACKGROUND_BLUE};`
                });
            }}
        }
    }

    @media (max-width: 768px) {
        padding: 12px 15px;
    }

    @media (max-width: 426px) {
        padding: 7px 18px;
    }
`;

export const Title = styled(Typography)`
    &.MuiTypography-root {
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return `font-size: ${common.titleSize};`;
        }}
        font-weight: bolder;

        ${() => {
            return applyStyleByMode({
                theme: useTheme().palette.mode,
                light: `color: ${DARK_STEEL_GREY};`,
                dark: `color: ${BACKGROUND_BLUE};`
            });
        }}

        ${() => useTheme().breakpoints.between('xs', 'md')} {
            ${() => {
                const common = useTheme().palette.common as CommonTypeTheme;
                return `font-size: ${common.textSize};`;
            }}
        }

        @media (max-width: 426px) {
            display: none;
        }
    }
`;

export const SubTitle = styled(Typography)`
    &.MuiTypography-root {
        font-size: 13px;
        font-weight: lighter;
        padding-left: 2.53em;
        @media (max-width: 768px) {
            display: none;
        }
    }
`;

export const IconWrapper = styled(Typography)`
    &.MuiTypography-root {
        & .MuiSvgIcon-root {
            width: 1.3em !important;
            height: 1.3em !important;
        }

        @media (max-width: 769px) {
            & .MuiSvgIcon-root {
                width: 1.3rem !important;
                height: 1.3rem !important;
            }
        }
    }
`;
