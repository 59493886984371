import React, { useEffect, useState } from 'react';
import useModal from 'components/CustomHooks/ModalHook';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import UiButton from 'components/Ui/Components/UiButton';
import { Grid, TextField, InputAdornment, CircularProgress } from '@mui/material';
import HelperText from 'components/Mixs/HelperText';
import Tyre from 'api/Tyre';
import { MountDateAtom } from '../TyreManager.atom';
import DatePicker from 'components/Ui/Components/UiDatePicker/UiDatePicker';
import { useMutation, useQuery } from '@tanstack/react-query';
import UiIcon from 'components/Ui/Components/UiIcon';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { VehicleDetailsActionMenuAtom } from 'components/Vehicle/VehicleDetailsActionMenu/VehicleDetailsActionMenu.atom';
import { FetchVehicleDetailsData } from 'components/Vehicle/VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { TyreQueryKeys } from 'models/Tyre.type';
import { hasSpaces } from 'helpers';
import TyreManufacturerLazyLoading from 'components/Tyre/TyreManufacturerLazyLoading/TyreManufacturerLazyLoading';
import TyreModelLazyLoading from 'components/Tyre/TyreModelLazyLoading/TyreModelLazyLoading';

const tyreAPI = new Tyre();

const AddTyre = ({ value }: { value: string }): JSX.Element => {
    const [serialNumber, setSerialNumber] = useState<string>(value);
    const [mountDate, setMountDate] = useRecoilState(MountDateAtom);
    const [manufacturer, setManufacturer] = useState<number>();
    const [model, setModel] = useState<number>();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isUniqSerialNumber, setIsUniqSerialNumber] = useState<boolean>(true);
    const { t: translate } = useTranslation();
    const { updateModalState } = useModal(1);

    const vehicleDetailsActionMenuAtom = useRecoilValue(VehicleDetailsActionMenuAtom);
    const { fromTimezoneToUTC } = useConverter();
    const setFetchVehicleDetailsData = useSetRecoilState(FetchVehicleDetailsData);

    const checkFormValuesAssignedValues = (): boolean => {
        return !!(serialNumber.length && mountDate && manufacturer && model);
    };

    const { refetch, isFetching } = useQuery(
        [TyreQueryKeys.getUniqueSerialNumber],
        () => {
            if (serialNumber && !serialNumber.includes('/')) {
                return tyreAPI.getUniqueSerialNumber(serialNumber);
            }
            return false;
        },
        {
            staleTime: 0,
            cacheTime: 0,
            onSuccess: (res) => setIsUniqSerialNumber(res),
            enabled: false,
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false
        }
    );

    const { mutate } = useMutation(
        [TyreQueryKeys.addTyre],
        () =>
            tyreAPI.addTyre({
                date: fromTimezoneToUTC(mountDate),
                comment: '',
                tyreSerialNumber: serialNumber,
                wheel: vehicleDetailsActionMenuAtom.data?.wheel.id || 0,
                tyreManufacturerId: manufacturer,
                tyreModelId: model
            }),
        {
            onSettled: () => {
                setFetchVehicleDetailsData((prevState) => !prevState);
            }
        }
    );

    const contentButton = {
        buttons: (
            <UiButton
                skin='success'
                disabled={
                    !checkFormValuesAssignedValues() ||
                    !isUniqSerialNumber ||
                    isFetching ||
                    hasSpaces(serialNumber) ||
                    mountDate?.invalid
                }
                onClick={() => {
                    mutate();
                    setIsSaving(true);
                }}
                loading={isSaving}
            >
                {translate('t.add')}
            </UiButton>
        )
    };

    useEffect(() => {
        refetch();
        updateModalState(contentButton);
    }, [serialNumber, isUniqSerialNumber]);

    useEffect(() => {
        updateModalState(contentButton);
    }, [isFetching, mountDate, manufacturer, model]);

    return (
        <Grid container spacing={2} data-testid='add-tyre-container-testid'>
            <Grid item xs={6}>
                <TextField
                    data-testid='add-tyre-serial_number-textfield-testid'
                    id='tyre-serial-number'
                    fullWidth
                    label={translate('t.serial_number')}
                    value={serialNumber}
                    onChange={(event) => {
                        setSerialNumber(event.target.value);
                    }}
                    size='small'
                    error={!isUniqSerialNumber || hasSpaces(serialNumber)}
                    helperText={
                        <HelperText
                            text={
                                hasSpaces(serialNumber)
                                    ? translate('t.tyre_serial_spaces_msg')
                                    : !serialNumber.length
                                    ? translate('t.required')
                                    : translate('t.tyre_serial_number_exists')
                            }
                            error={!isUniqSerialNumber || hasSpaces(serialNumber) || !serialNumber.length}
                        />
                    }
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                {isFetching ? (
                                    <CircularProgress size='1.4375rem' />
                                ) : serialNumber.length && !hasSpaces(serialNumber) && isUniqSerialNumber ? (
                                    <UiIcon icon={['fas', 'circle-check']} color='green' size='lg' />
                                ) : (
                                    <UiIcon icon={['fas', 'circle-xmark']} color='red' size='lg' />
                                )}
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <DatePicker
                    value={mountDate}
                    onDateChange={(newData) => setMountDate(newData)}
                    label={translate('t.action_date')}
                    noMargin
                    fullWidth
                    datetest-id='mount-date-picker'
                />
            </Grid>

            <Grid item xs={6}>
                <TyreManufacturerLazyLoading
                    data-testid='add-tyre-TyreManufacturerLazyLoading-testid'
                    onChangeManufacturer={(id, label, orig) => {
                        setManufacturer(orig?.id);
                    }}
                    helperText={!manufacturer ? translate('p.this_field_is_required') : ''}
                />
            </Grid>
            <Grid item xs={6}>
                <TyreModelLazyLoading
                    data-testid='add-tyre-TyreModelLazyLoading-testid'
                    onChangeModel={(id, label, orig) => {
                        setModel(orig?.id);
                    }}
                    disabled={!manufacturer}
                    queryFilter={manufacturer ? { tyreManufacturer: manufacturer } : {}}
                    helperText={!model ? translate('p.this_field_is_required') : ''}
                />
            </Grid>
        </Grid>
    );
};

export default AddTyre;
