import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { TextField, Grid } from '@mui/material';
import useModal from 'components/CustomHooks/ModalHook';
import UiButton from 'components/Ui/Components/UiButton';
import { SIZE_BUTTON, VARIANT_BUTTON } from 'components/Ui/variables';
import VehicleManufacturerAPI from 'api/VehicleManufacturer';
import { useMutation } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { Success } from 'components/Popup/Popup';
import { QueryFn } from 'components/Ui/UiTable/TableAtom';

const vehicleManufacturerAPI = new VehicleManufacturerAPI();

const AddVehicleManufacturer = () => {
    const { t: translate } = useTranslation();
    const { updateModalState, closeModal } = useModal();
    const queryTableFn = useRecoilValue(QueryFn(`VehicleManufacturer-QueryFn`));

    const validationSchema = Yup.object().shape({
        manufacturer: Yup.string().min(3, 'Too Short!').required(translate('t.required'))
    });

    const { mutate: submit } = useMutation(
        (value: { manufacturer: string }) => vehicleManufacturerAPI.post({ data: value }),
        {
            onSettled: () => {
                closeModal();
                queryTableFn.refetch && queryTableFn.refetch instanceof Function && queryTableFn.refetch();
            },
            onSuccess: () => {
                Success({
                    text: translate('t.item_created_successfully')
                });
            }
        }
    );

    useEffect(() => {
        updateModalState({
            buttons: (
                <UiButton
                    testid='vehicle-manufacturer-add-button'
                    skin='success'
                    size={SIZE_BUTTON}
                    variant={VARIANT_BUTTON}
                    form='vehicle-manufacturer-form'
                    type='submit'
                >
                    {translate('t.add')}
                </UiButton>
            )
        });
    }, []);

    return (
        <Formik
            initialValues={{ manufacturer: '' }}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
                submit(values);
            }}
        >
            {(props) => (
                <Form id='vehicle-manufacturer-form'>
                    <Grid paddingTop={4} container>
                        <Grid item xs={12}>
                            <TextField
                                data-testid='vehicle-manufacturer-name'
                                id='manufacturer'
                                name='manufacturer'
                                error={!!props.errors.manufacturer && props.touched.manufacturer}
                                label={translate('t.name')}
                                value={props.values.manufacturer}
                                variant='outlined'
                                size='small'
                                fullWidth
                                helperText={
                                    props.errors.manufacturer && props.touched.manufacturer
                                        ? props.errors.manufacturer
                                        : ''
                                }
                                onChange={props.handleChange}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default AddVehicleManufacturer;
