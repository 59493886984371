import { atom } from 'recoil';

export const TyreFilter = atom<{
    tyreManufacturer?: { value: number; label: string };
    tyreModel?: { value: number; label: string };
}>({
    key: 'TyreFilter',
    default: {
        tyreManufacturer: undefined,
        tyreModel: undefined
    }
});
