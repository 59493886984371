import { Divider } from '@mui/material';
import styled from 'styled-components';

export const LoginViewContent = styled.div``;

export const AlertContainer = styled.div`
    && .MuiPaper-root {
        margin: 15px 0;
    }
`;

export const CustomDivider = styled(Divider)`
    margin: 20px 1px 5px 1px;
`;
