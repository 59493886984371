import React, { useEffect, useRef, useState } from 'react';
import {
    ConfigurationSelectorContainer,
    ExpandIcon,
    HiddenInput,
    Label,
    SelectConfigurationButton,
    VehicleConfigCover,
    VehicleConfigInput,
    WarningText
} from './ConfigurationSelector.style';
import { ConfigurationSelectorProps } from './ConfigurationSelector.type';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, Popper, TextField, Typography, useTheme } from '@mui/material';
import VehicleSchema from 'components/Vehicle/NewVehicleSchema/VehicheSchema';
import VehicleConfigurationApi from 'api/VehicleConfiguration';
import { useQuery } from '@tanstack/react-query';
import { VehicleConfigurationQueryKeys, VehicleConfigurationsType } from 'models/VehicleConfiguration.type';
import { isVehicleTyreBayHub } from 'helpers';

const VehicleConfiguration = new VehicleConfigurationApi();

const ConfigurationSelectorContent: React.FC<ConfigurationSelectorProps> = ({
    onConfigurationChange,
    configurationValue,
    error,
    helperText
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const theme = useTheme();
    const wrapperRef = useRef() as any;
    const toggleOpen = () => {
        setOpen((current) => !current);
    };

    const { data } = useQuery<VehicleConfigurationsType>(
        [VehicleConfigurationQueryKeys.getV2],
        () => VehicleConfiguration.get({ version: 'v2' }),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            staleTime: 0,
            cacheTime: 0
        }
    );

    const handleClickOutside = (event) => {
        if (wrapperRef && wrapperRef?.current && !wrapperRef?.current.contains(event.target)) {
            toggleOpen();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <ConfigurationSelectorContainer data-testid='ConfigurationSelectorContent'>
            <Label theme={theme} error={error}>
                {translate('t.configuration')}
            </Label>
            <Autocomplete
                disableClearable
                id='configuration-selector'
                data-testid='configurator-selector'
                size='small'
                open={open}
                PopperComponent={(props) => <Popper {...props} ref={wrapperRef} />}
                options={data?.codeBook.filter((val) => !isVehicleTyreBayHub(val.id)) || []}
                getOptionLabel={(option) => option.distribution ?? ''}
                renderOption={(props, option) => {
                    return (
                        <Box component='li' {...props} display={'flex'} justifyContent={'center !important'}>
                            <Box height={100} width={100}>
                                <VehicleSchema schema={option?.distribution} emptySchema={true} />
                            </Box>
                        </Box>
                    );
                }}
                onChange={(event, newValue) => {
                    onConfigurationChange && onConfigurationChange(newValue);
                    toggleOpen();
                }}
                renderInput={(params) => (
                    <VehicleConfigCover>
                        <VehicleConfigInput error={error}>
                            {configurationValue?.distribution && (
                                <Box height={240} width={200} paddingTop={'10px'}>
                                    <VehicleSchema schema={configurationValue.distribution} emptySchema />
                                </Box>
                            )}
                            <SelectConfigurationButton
                                variant='contained'
                                onClick={toggleOpen}
                                endIcon={<ExpandIcon expand={open} />}
                            >
                                <Typography variant='subtitle1' gutterBottom>
                                    {translate('t.select_configuration')}
                                </Typography>
                            </SelectConfigurationButton>
                        </VehicleConfigInput>
                        <HiddenInput
                            {...params}
                            label={translate('t.configuration')}
                            variant='outlined'
                            size='small'
                            key={params.id}
                            name={params.id}
                            InputLabelProps={{
                                shrink: true
                            }}
                            hidden={true}
                        />
                        <WarningText variant='caption'>{helperText}</WarningText>
                    </VehicleConfigCover>
                )}
            />
        </ConfigurationSelectorContainer>
    );
};

export default ConfigurationSelectorContent;
