import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import Legend from 'components/Ui/Components/UiLegend/UiLegend';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { Checkbox, IconButton, Tooltip } from '@mui/material';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { ColumnIndex } from '../VehicleStatsTable/VehicleStatsTable.type';
import useUserLocalStorageSettings, {
    useUserLocalStorageSettingsTable
} from 'components/CustomHooks/UserLocalStorageSettings';
import { FormControlLabelEl, VehicleStatsHideColumnsCheckboxContainer } from './VehicleStatsHideColumns.style';
import { ColumnNameVehiclesStats, ColumnNameVehiclesStatsAtom } from '../VehicleStatsTable/VehicleStatsTable.atom';
import { CustomerSettings } from 'states/global/CustomerSettings';

const QUERY_KEY = 'VehicleStats';

const VehicleStatsHideColumnsContent: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [vehicleColumns, setVehicleColumns] = useRecoilState(ColumnNameVehiclesStatsAtom);
    const localStorageTableKey = useMemo(() => `customTable.${QUERY_KEY}Table`, [QUERY_KEY]);
    const { setUserSettings } = useUserLocalStorageSettings([localStorageTableKey]);
    const { getUserSettingTable } = useUserLocalStorageSettingsTable();
    const customerSettings = useRecoilValue(CustomerSettings);

    const setHiddenColumns = (index, isVisible) => {
        const hiddenColumns: ColumnNameVehiclesStats[] = [
            ...new Set([...getUserSettingTable(localStorageTableKey)['hiddenColumns']])
        ];
        hiddenColumns[index].isVisible = isVisible;

        setUserSettings(localStorageTableKey, {
            ...getUserSettingTable(localStorageTableKey),
            hiddenColumns: hiddenColumns
        });

        setVehicleColumns([...new Set(hiddenColumns)]);
    };

    return (
        <Legend
            data-testid={'vehicle-stats-hide-columns-legend'}
            root={
                <Tooltip title={translate('t.show_hide_columns')}>
                    <IconButton data-testid={`vehicle-stats-hide-columns-btn`}>
                        <ViewColumnIcon />
                    </IconButton>
                </Tooltip>
            }
            icon={<ViewColumnIcon />}
            width={400}
            headerElements={
                <>
                    <UiButton
                        onClick={() => {
                            const restartedColumns: ColumnNameVehiclesStats[] = getUserSettingTable(
                                localStorageTableKey
                            )['hiddenColumns'].map((column, index) => ({
                                ...column,
                                isVisible: index !== ColumnIndex.PREVIOUS_ALERTS // todo: make a escalable feature for default columns option
                            }));

                            setUserSettings(localStorageTableKey, {
                                ...getUserSettingTable(localStorageTableKey),
                                hiddenColumns: restartedColumns
                            });
                            setVehicleColumns([...new Set(restartedColumns)]);
                        }}
                        testid={`HideColumns-${QUERY_KEY}Table-Reset-Button`}
                        title={translate('p.reset_column_tooltip')}
                    >
                        {translate('t.reset')}
                    </UiButton>
                </>
            }
        >
            <VehicleStatsHideColumnsCheckboxContainer data-testid={`HideColumnsViewContent-${QUERY_KEY}Table`}>
                {vehicleColumns.map((column, index) => (
                    <>
                        {index === ColumnIndex.BES && !customerSettings.mdd_bes ? (
                            <></>
                        ) : (
                            <FormControlLabelEl
                                data-testid={`HideColumnsCheckbox-${QUERY_KEY}Table-${column.id}`}
                                title={column.label}
                                control={
                                    <Checkbox
                                        disabled={false}
                                        color='primary'
                                        onChange={() => {
                                            setHiddenColumns(index, !column.isVisible);
                                        }}
                                    />
                                }
                                label={<>{column.label}</>}
                                checked={column.isVisible}
                            />
                        )}
                    </>
                ))}
            </VehicleStatsHideColumnsCheckboxContainer>
        </Legend>
    );
};

export default VehicleStatsHideColumnsContent;
