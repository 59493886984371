export type Sound = {
    id: number;
    name: string;
    filePath: string;
};

export type SoundModel = Sound;

export enum SoundQueryKeys {
    getTable = 'Sound-getTable'
}
