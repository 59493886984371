import React from 'react';
import { TeamShiftTimeContainer } from './TeamShiftTime.style';
import { TeamShiftTimeViewProps } from './TeamShiftTime.type';
import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import UiTable from 'components/Ui/UiTable/UiTable';
import TeamShiftTime from 'api/TeamShiftTime';
import { ShowTeamShiftTimeActionsModal } from 'states/global/Modal';
import { CRUD } from 'variables';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { TeamShiftTimeActionsState } from '../TeamShiftTimeActions/TeamShiftTimeActions.atom';
import TeamShiftTimeActions from '../TeamShiftTimeActions/TeamShiftTimeActions';
import { TeamShiftTimeQueryKeys } from 'models/TeamShiftTime.type';

const teamShiftTimeApi = new TeamShiftTime();

export const TeamShiftTimeContent: React.FC<TeamShiftTimeViewProps> = ({ columns }): JSX.Element => {
    const { t: translate } = useTranslation();
    const showShowTeamShiftTimeActionsModal = useSetRecoilState(ShowTeamShiftTimeActionsModal);
    const teamShiftTimeActionsState = useSetRecoilState(TeamShiftTimeActionsState);

    return (
        <>
            <TeamShiftTimeContainer data-testid='TeamShiftTimeContent'>
                <UiTable
                    data-testid='TeamShiftTimeList-uiTable'
                    fetchFn={teamShiftTimeApi.getAll}
                    columns={columns}
                    refresh={false}
                    onlyIconsForDefActionBtns
                    queryKey={TeamShiftTimeQueryKeys.getTeamShiftTimeList}
                    hideFilterAction
                    rightActionBtns={() => (
                        <UiButton
                            testid='Add-teamShiftTime-button'
                            color='primary'
                            variant='contained'
                            sx={{ color: '#fff' }}
                            onClick={() => {
                                teamShiftTimeActionsState({
                                    action: CRUD.ADD
                                });
                                showShowTeamShiftTimeActionsModal(true);
                            }}
                        >
                            {translate('t.add')}
                        </UiButton>
                    )}
                />
            </TeamShiftTimeContainer>
            <TeamShiftTimeActions />
        </>
    );
};
