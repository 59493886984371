import React from 'react';
import * as style from './AlertsLevelPopUp.style';
import { PopUpalert } from './AlertsLevelPopUp.type';
import * as styleAlertCustom from './AlertsLevelPopUp.style';
import { useTranslation } from 'react-i18next';
import { MoreVert } from '@mui/icons-material';
import UiLegend from '../../../../Ui/Components/UiLegend';
import { applyStyleByMode } from 'helpers';

const AlertsLevelPopUpView: React.FC<PopUpalert> = (props): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <div>
            <UiLegend
                minWidth={265}
                width={300}
                title={applyStyleByMode({ styleJade: translate('t.filters') })}
                content={
                    <style.PopUpFilterContainer>
                        <style.GroupOption>
                            <style.FilterCover>
                                <style.FilterLabel>
                                    {translate('t.alerts')} {translate('t.level')} {translate('t.filters')}
                                </style.FilterLabel>
                                <style.FilterContent>
                                    <styleAlertCustom.ChipLevel
                                        data-testid={`AlertsLevelPopUpView-ChipLevel-1`}
                                        size='small'
                                        level={1}
                                        label={`${translate('t.level')} 1`}
                                        clickable
                                        onClick={() => props.AddFilterAlertByLevel(1)}
                                    />

                                    <styleAlertCustom.ChipLevel
                                        data-testid={`AlertsLevelPopUpView-ChipLevel-2`}
                                        size='small'
                                        level={2}
                                        label={`${translate('t.level')} 2`}
                                        clickable
                                        onClick={() => props.AddFilterAlertByLevel(2)}
                                    />

                                    <styleAlertCustom.ChipLevel
                                        data-testid={`AlertsLevelPopUpView-ChipLevel-3`}
                                        size='small'
                                        level={3}
                                        label={`${translate('t.level')} 3`}
                                        clickable
                                        onClick={() => props.AddFilterAlertByLevel(3)}
                                    />
                                </style.FilterContent>
                            </style.FilterCover>
                        </style.GroupOption>
                    </style.PopUpFilterContainer>
                }
                root={
                    <style.CustomIconButton size='small' title={translate('t.filters')}>
                        <MoreVert data-testid={`AlertsLevelPopUpView-MoreVert`} />
                    </style.CustomIconButton>
                }
            />
        </div>
    );
};

export default AlertsLevelPopUpView;
