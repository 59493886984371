import React from 'react';
import { LoginSSOProps } from './LoginSSO.type';
import { useRecoilValue } from 'recoil';
import { Theme, ThemeMode } from 'states/global/Theme';
import { BackgroundVideo, BlurLayer, ContainerVideoLogin, LoginContentStyle } from './LoginSSO.style';
import { Card, CardContent } from '@mui/material';
import LoginSSORedirectionForm from 'components/User/LoginSSORedirectionForm/LoginSSORedirectionForm';
import { getVideoSource } from 'helpers/video';
import { getImgSource } from 'helpers/image';

// Temporal page, will be removed in the future

const LoginSSO: React.FC<LoginSSOProps> = (): JSX.Element => {
    const theme = useRecoilValue(Theme);
    return (
        <LoginContentStyle data-testid='LoginContent'>
            <ContainerVideoLogin>
                <BackgroundVideo autoPlay loop muted>
                    <source src={getVideoSource('videoOfBackgroundForLogin')} type='video/mp4' />
                </BackgroundVideo>
                <BlurLayer />
            </ContainerVideoLogin>

            <Card className='card-login' sx={{ zIndex: 99 }}>
                <CardContent>
                    <img
                        src={theme.mode === ThemeMode.light ? getImgSource('logo') : getImgSource('logolight')}
                        className='logo-login'
                        alt='logo'
                    />
                    <LoginSSORedirectionForm />
                </CardContent>
            </Card>
        </LoginContentStyle>
    );
};

export default LoginSSO;
