import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
    Content,
    DetailContent,
    DetailContentLine,
    DetailContentLinePressure,
    FontAwesomeIconWithPadding,
    TextGreen,
    TyreDetailsViewContent,
    TyreNameCover,
    TyrePreviewCover
} from './TyreDetails.style';
import { TyreDetailsViewProps } from './TyreDetails.type';
import { useTranslation } from 'react-i18next';
import { VehicleSchemaHoverAtom } from 'components/Vehicle/VehicleDetailsSchema/VehicleDetailsSchema.atom';
import { VehicleDetailsActionMenuAtom } from 'components/Vehicle/VehicleDetailsActionMenu/VehicleDetailsActionMenu.atom';
import AttachedSensors from './AttachedSensors';
import Pressure from './Pressure';
import Temperature from './Temperature';
import { SelectedSensorAtom } from './SelectedSensor.atom';
import { Tooltip } from '@mui/material';
import { faIndustryWindows } from '@fortawesome/pro-solid-svg-icons';

export const TyreDetailsContent: React.FC<TyreDetailsViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [vehicleSchemaHoverAtom, setVehicleSchemaHoverAtom] = useRecoilState(VehicleSchemaHoverAtom());
    const setVehicleDetailsActionMenuAtom = useSetRecoilState(VehicleDetailsActionMenuAtom);
    const selectedSensor = useRecoilValue(SelectedSensorAtom(props.tyreDetails.wheel.position));
    const maxAlerts = props.getMaxAlerts(selectedSensor?.sensorAlerts);

    return (
        <TyreDetailsViewContent
            data-testid='TyreDetailsContent'
            className={vehicleSchemaHoverAtom.hoveredPosition === props.tyreDetails.wheel.position ? 'hovered' : ''}
            onMouseEnter={() => {
                setVehicleSchemaHoverAtom({
                    hoveredPosition: props.tyreDetails.wheel.position,
                    hasHover: true
                });
            }}
            onMouseLeave={() => {
                setVehicleSchemaHoverAtom({
                    hoveredPosition: 0,
                    hasHover: true
                });
            }}
            onClick={(event) => {
                setVehicleDetailsActionMenuAtom({
                    isOpen: true,
                    data: props.tyreDetails,
                    positionX: event.clientX,
                    positionY: event.clientY
                });
            }}
        >
            <Content>
                <DetailContent width={25} $firstColumn>
                    <DetailContentLine>
                        <span>
                            <TextGreen>
                                {translate('t.pos')} <strong>{props.tyreDetails.wheel.customPosition}</strong>
                            </TextGreen>
                        </span>
                    </DetailContentLine>
                    <DetailContentLine>
                        <TextGreen>
                            <TyrePreviewCover>
                                <FontAwesomeIconWithPadding paddingRight={3} icon={['fas', 'tire-rugged']} />
                                {props.tyreDetails.wheel.tyreSerialNumber ? (
                                    <TyreNameCover>
                                        <Tooltip
                                            title={`${translate('t.tyre_serial_number')}: ${
                                                props.tyreDetails.wheel.tyreSerialNumber
                                            }`}
                                            arrow
                                            slotProps={{
                                                popper: {
                                                    modifiers: [
                                                        {
                                                            name: 'offset',
                                                            options: {
                                                                offset: [0, -10]
                                                            }
                                                        }
                                                    ]
                                                }
                                            }}
                                        >
                                            <span>{props.tyreDetails.wheel.tyreSerialNumber}</span>
                                        </Tooltip>
                                    </TyreNameCover>
                                ) : (
                                    <Tooltip
                                        title={`${translate('t.tyre_serial_number')}: ${translate('t.not_assigned')}`}
                                        arrow
                                        slotProps={{
                                            popper: {
                                                modifiers: [
                                                    {
                                                        name: 'offset',
                                                        options: {
                                                            offset: [0, -10]
                                                        }
                                                    }
                                                ]
                                            }
                                        }}
                                    >
                                        <span>{'S/N'}</span>
                                    </Tooltip>
                                )}
                            </TyrePreviewCover>
                        </TextGreen>
                    </DetailContentLine>
                    <DetailContentLine>
                        <TyrePreviewCover>
                            <FontAwesomeIconWithPadding paddingRight={3} icon={faIndustryWindows} />
                            {(props.tyreDetails.wheel.tyreManufacturer?.id ?? 0) > 1 &&
                            (props.tyreDetails.wheel.tyreModel?.id ?? 0) > 1 ? (
                                <TyreNameCover>
                                    <Tooltip
                                        title={
                                            <table>
                                                <tr>
                                                    <td>{`${translate('t.tyre_manufacturer')}: `}</td>
                                                    <td>{props.tyreDetails.wheel.tyreManufacturer?.name}</td>
                                                </tr>
                                                <tr>
                                                    <td>{`${translate('t.tyre_model')}: `}</td>
                                                    <td>{props.tyreDetails.wheel.tyreModel?.name}</td>
                                                </tr>
                                            </table>
                                        }
                                        arrow
                                        slotProps={{
                                            popper: {
                                                modifiers: [
                                                    {
                                                        name: 'offset',
                                                        options: {
                                                            offset: [0, -10]
                                                        }
                                                    }
                                                ]
                                            }
                                        }}
                                    >
                                        <span>{props.tyreDetails.wheel.tyreManufacturer?.name}</span>
                                    </Tooltip>
                                </TyreNameCover>
                            ) : (
                                <Tooltip
                                    title={
                                        <table>
                                            <tr>
                                                <td>{`${translate('t.tyre_manufacturer')}: `}</td>
                                                <td>{translate('t.not_assigned')}</td>
                                            </tr>
                                            <tr>
                                                <td>{`${translate('t.tyre_model')}: `}</td>
                                                <td>{translate('t.not_assigned')}</td>
                                            </tr>
                                        </table>
                                    }
                                    arrow
                                    slotProps={{
                                        popper: {
                                            modifiers: [
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, -10]
                                                    }
                                                }
                                            ]
                                        }
                                    }}
                                >
                                    <span>{'-'}</span>
                                </Tooltip>
                            )}
                        </TyrePreviewCover>
                    </DetailContentLine>
                    <DetailContentLine>
                        <span>
                            {!!props.tyreDetails.attachedSensors.length && (
                                <FontAwesomeIconWithPadding
                                    paddingRight={3}
                                    icon={['fas', 'search-plus']}
                                    title={translate('t.attached_sensors')}
                                />
                            )}
                            <AttachedSensors
                                attachedSensors={props.tyreDetails.attachedSensors}
                                wheelPosition={props.tyreDetails.wheel.position}
                            />
                        </span>
                    </DetailContentLine>
                </DetailContent>
                <DetailContent width={22}>
                    <DetailContentLine style={{ height: '50px', display: 'flex', alignItems: 'center' }}>
                        <Temperature
                            hasAttachedSensors={props.tyreDetails.attachedSensors.length > 0}
                            wheelPosition={props.tyreDetails.wheel.position}
                            alertLevel={maxAlerts.temperature}
                            temperaturePredictionAlert={maxAlerts.temperaturePrediction}
                            lastTemperaturePredictedAt={props.tyreDetails.wheel.lastTemperaturePredictedAt}
                            predictionTemperature={props.vehicleDetails?.predictionTemperature || false}
                        />
                    </DetailContentLine>
                </DetailContent>
                <DetailContent width={34}>
                    <DetailContentLinePressure>
                        <Pressure
                            displayingHotPressure={false}
                            wheelPosition={props.tyreDetails.wheel.position}
                            alertLevel={maxAlerts.pressure}
                        />
                    </DetailContentLinePressure>
                    <DetailContentLinePressure>
                        <Pressure
                            displayingHotPressure={true}
                            wheelPosition={props.tyreDetails.wheel.position}
                            alertLevel={maxAlerts.pressureHot}
                        />
                    </DetailContentLinePressure>
                </DetailContent>
                <DetailContent width={25}>
                    <DetailContentLine>
                        {props.showMeasuredAt(
                            selectedSensor?.sensorLog?.measuredAt || null,
                            props.tyreDetails.attachedSensors.length > 0
                        )}
                    </DetailContentLine>
                </DetailContent>
            </Content>
        </TyreDetailsViewContent>
    );
};
