import React, { useEffect, useMemo, useState } from 'react';
import { AreaEditorFormContent } from './AreaEditorForm.view';
import { AreaEditorFormProps, AreaMode } from './AreaEditorForm.type';
import { AreaEditorFormAtom, AreaEditorFormDispatcher, AreaEditorFormTypesEnum } from './AreaEditorForm.atom';
import { CreateConfirmtionMessage } from '../../StatedModal/ConfirmationMessage';
import { MapActionsEnum } from '../../Map/Map.type';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AreaFeaturesResponse, AreaRequest, AreaFeaturesResponseData, AreaQueryKeys } from '../../../models/Area.type';
import AreaApi from '../../../api/Area';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ModalAtom } from '../../../states/global/Modal';
import { useTranslation } from 'react-i18next';
import { MapAction } from '../../../states/global/Map';
import { AxiosResponse } from 'axios';
import { AreaEditorFormLoader, CustomSpinner } from './AreaEditorForm.style';
import { Fade } from '@mui/material';
import { Success } from '../../Popup/Popup';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { AreaTypeModel } from 'models/AreaType.type';
import { useLocation, useNavigate } from 'react-router-dom';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { cacheTimeToMilliseconds } from 'helpers/cache';

const Area = new AreaApi();

const AreaEditorForm: React.FC<AreaEditorFormProps> = ({ reload }): JSX.Element => {
    const { t: translate } = useTranslation();
    const [formState, setFormState] = useRecoilState(AreaEditorFormAtom);
    const dispatch = AreaEditorFormDispatcher(formState, setFormState);
    const setMapAction = useSetRecoilState(MapAction);
    const setModalState = useSetRecoilState(ModalAtom);
    const { fromServerToUserUnit, fromUserToServerUnit, convertType, displayUserUnits } = useConverter();
    const [warningColorMessage, setWarningColorMessage] = useState<boolean>(false);
    const isKMH = useMemo(() => {
        return displayUserUnits.speed === 'km/h' ? 162 : 100;
    }, [displayUserUnits.speed]);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const mode = searchParams.get('mode');

    const onSuccessCreateArea = (editResponse: AxiosResponse<{ area: AreaFeaturesResponse }>) => {
        if (dataResponse && editResponse.data) {
            const updatedAreas = parseSpeedValuesToUserUnits(editResponse.data.area);
            dispatch({
                type: AreaEditorFormTypesEnum.SET_SELECTED_AREA,
                formPayload: updatedAreas || undefined
            });

            setMapAction({
                action: MapActionsEnum.DRAW_AREA,
                params: {
                    data: editResponse.data.area
                }
            });
        }
        let successfulMessage: string;

        if (formState.mode === AreaMode.CREATE) {
            successfulMessage = `${translate('t.area')} ${translate('p.has_been_added')}`;

            editResponse?.data?.area &&
                navigate(`/area-editor?mode=edit&id=${editResponse?.data?.area?.id}`, { replace: true });
        } else {
            navigate(`/area-editor?mode=edit&id=${(editResponse as any)?.area?.id}`, { replace: true });
            successfulMessage = `${translate('t.area')} ${translate('p.has_been_edited')}`;
        }

        Success({ text: successfulMessage });
    };

    const parseSpeedValuesToUserUnits = (data: AreaFeaturesResponse) => ({
        ...data,
        maxSpeed: +fromServerToUserUnit({ type: convertType.speed, value: data.maxSpeed ?? 0, fixed: 0 }),
        minSpeed: +fromServerToUserUnit({ type: convertType.speed, value: data.minSpeed ?? 0, fixed: 0 }),
        maxSpeedTemperatureL1: +fromServerToUserUnit({
            type: convertType.speed,
            value: data.maxSpeedTemperatureL1 ?? 0,
            fixed: 1
        }),
        maxSpeedTemperatureL2: +fromServerToUserUnit({
            type: convertType.speed,
            value: data.maxSpeedTemperatureL2 ?? 0,
            fixed: 1
        }),
        maxSpeedTemperatureL3: +fromServerToUserUnit({
            type: convertType.speed,
            value: data.maxSpeedTemperatureL3 ?? 0,
            fixed: 1
        })
    });

    const prepareDataBeforeLoad = (data: AreaFeaturesResponseData) => {
        return parseSpeedValuesToUserUnits(data.area);
    };

    const {
        isLoading,
        data: dataResponse,
        isFetching,
        refetch: ReloadData
    } = useQuery<AreaFeaturesResponseData>([AreaQueryKeys.getByIdArea, id], () => Area.getByIdArea(id, 'webtrack'), {
        refetchOnWindowFocus: false,
        enabled: !!id,
        staleTime: cacheTimeToMilliseconds(5, 'minutes'),
        cacheTime: cacheTimeToMilliseconds(5, 'minutes')
    });

    const prepareDataBeforeSave = (area: Partial<AreaFeaturesResponse>): AreaRequest => {
        return {
            ...area,
            areaType: area.areaType?.id,
            maxSpeed: fromUserToServerUnit({ type: convertType.speed, value: area.maxSpeed, fixed: 0 }),
            minSpeed: fromUserToServerUnit({ type: convertType.speed, value: area.minSpeed, fixed: 0 }),
            maxSpeedTemperatureL1: fromUserToServerUnit({
                type: convertType.speed,
                value: area.maxSpeedTemperatureL1,
                fixed: 1
            }),
            maxSpeedTemperatureL2: fromUserToServerUnit({
                type: convertType.speed,
                value: area.maxSpeedTemperatureL2,
                fixed: 1
            }),
            maxSpeedTemperatureL3: fromUserToServerUnit({
                type: convertType.speed,
                value: area.maxSpeedTemperatureL3,
                fixed: 1
            })
        };
    };

    const { mutate: saveNewArea, isLoading: saveNewAreaIsLoading } = useMutation<
        AxiosResponse<{ area: AreaFeaturesResponse }>,
        Error,
        AreaRequest
    >((areaData) => Area.post({ data: areaData }), {
        onSuccess: onSuccessCreateArea
    });

    const { mutate: editArea, isLoading: editAreaIsLoading } = useMutation<
        AxiosResponse<{ area: AreaFeaturesResponse }>,
        Error,
        AreaRequest
    >(
        (areaData) =>
            Area.patchById({
                id: areaData.id ?? 0,
                data: areaData as Partial<AreaFeaturesResponse>
            }),
        {
            onSuccess: onSuccessCreateArea
        }
    );

    const dataIsSaving = editAreaIsLoading || saveNewAreaIsLoading;

    const selectArea = () => {
        const selectedArea = dataResponse && prepareDataBeforeLoad(dataResponse);

        if (selectedArea) {
            setMapAction({
                action: MapActionsEnum.DRAW_AREA,
                params: {
                    data: selectedArea
                }
            });
        } else if (formState.mode === AreaMode.CREATE) {
            setMapAction({
                action: MapActionsEnum.DRAW_AREAS
            });
        }
        dataResponse &&
            dispatch({ type: AreaEditorFormTypesEnum.SET_SELECTED_AREA, formPayload: selectedArea || undefined });
    };

    const onChangeSelectorOption = () => {
        if (formState.hasNewChanges) {
            setModalState(
                CreateConfirmtionMessage({
                    messageType: 'UNSAVED_CHANGES',
                    translate: translate,
                    onClickYes: () => {
                        dispatch({ type: AreaEditorFormTypesEnum.SET_SELECTED_AREA, formPayload: undefined });
                        setModalState({ isOpen: false });
                        selectArea();
                    },
                    onClickNo: () => {
                        setModalState({ isOpen: false });
                    }
                })
            );

            return;
        }
        selectArea();
    };

    const onClickCancel = () => {
        if (formState.hasNewChanges) {
            setModalState(
                CreateConfirmtionMessage({
                    messageType: 'UNSAVED_CHANGES',
                    translate: translate,
                    onClickYes: () => {
                        if (dataResponse) {
                            if (formState.mode === AreaMode.EDIT) {
                                dispatch({
                                    type: AreaEditorFormTypesEnum.SET_SELECTED_AREA,
                                    formPayload: prepareDataBeforeLoad(dataResponse)
                                });
                            } else {
                                dispatch({ type: AreaEditorFormTypesEnum.CREATE_NEW_AREA });
                            }
                            setModalState({ isOpen: false });
                        }
                    },
                    onClickNo: () => {
                        setModalState({ isOpen: false });
                    }
                })
            );
        } else {
            navigate(`/areas`, { replace: true });
        }
    };

    const onClickCreate = () => {
        dispatch({ type: AreaEditorFormTypesEnum.CREATE_NEW_AREA });
    };

    const onClickSave = () => {
        if (formState.selectedArea) {
            const data = prepareDataBeforeSave(formState.selectedArea);
            if (formState.mode === AreaMode.CREATE) {
                saveNewArea(data);
            } else {
                editArea(data);
            }
            dispatch({ type: AreaEditorFormTypesEnum.TOGGLE_HAS_NEW_CHANGES });
        }
    };

    const onChangeAreaType = (areaType: AreaTypeModel): void => {
        dispatch({ type: AreaEditorFormTypesEnum.SET_AREA_TYPE, formPayload: areaType });
    };

    const resetColorByAreaType = (): void => {
        dispatch({ type: AreaEditorFormTypesEnum.RESET_COLOR_BY_AREA_TYPE });
    };

    const onChangeAreaName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({ type: AreaEditorFormTypesEnum.SET_AREA_NAME, formPayload: e.target.value });
    };

    const onChangeCounterZone = () => {
        dispatch({ type: AreaEditorFormTypesEnum.TOGGLE_COUNTER_ZONE });
    };

    const onChangeMaintananceZone = () => {
        dispatch({ type: AreaEditorFormTypesEnum.TOGGLE_MAINTENANCE_ZONE });
    };

    const onChangeTempSpeedZone = () => {
        dispatch({ type: AreaEditorFormTypesEnum.TOGGLE_TEMP_SPEED_ZONE });
    };

    const onChangeTempSpeedLvl1 = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const parsedValue = parseFloat(e.target.value !== '' ? e.target.value : '0');
        if (parsedValue > -1 && parsedValue < isKMH)
            dispatch({ type: AreaEditorFormTypesEnum.SET_TEMP_SPEED_LVL_1, formPayload: parsedValue });
    };

    const onChangeTempSpeedLvl2 = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const parsedValue = parseFloat(e.target.value !== '' ? e.target.value : '0');
        if (parsedValue > -1 && parsedValue < isKMH)
            dispatch({ type: AreaEditorFormTypesEnum.SET_TEMP_SPEED_LVL_2, formPayload: parsedValue });
    };

    const onChangeTempSpeedLvl3 = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const parsedValue = parseFloat(e.target.value !== '' ? e.target.value : '0');
        if (parsedValue > -1 && parsedValue < isKMH)
            dispatch({ type: AreaEditorFormTypesEnum.SET_TEMP_SPEED_LVL_3, formPayload: parsedValue });
    };

    const onChangeMaxSpeed = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const parsedValue = parseInt(e.target.value !== '' ? e.target.value : '0');
        if (parsedValue > -1 && parsedValue < isKMH) {
            dispatch({ type: AreaEditorFormTypesEnum.SET_MAX_AREA_SPEED, formPayload: parsedValue });
        }
    };

    const onChangeMinSpeed = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const parsedValue = parseInt(e.target.value !== '' ? e.target.value : '0');

        if (parsedValue > isKMH) return false;

        dispatch({ type: AreaEditorFormTypesEnum.SET_MIN_AREA_SPEED, formPayload: parsedValue });
    };

    const onChangeAreaColor = (color: string) => {
        dispatch({ type: AreaEditorFormTypesEnum.SET_AREA_COLOR, formPayload: color });
    };

    const onChangeSpeedZone = () => {
        dispatch({ type: AreaEditorFormTypesEnum.TOGGLE_SPEED_ZONE });
    };

    useEffect(() => {
        if (formState.selectedArea?.areaType?.color?.length) {
            setWarningColorMessage(formState.selectedArea?.color !== formState.selectedArea?.areaType?.color);
        } else {
            setWarningColorMessage(false);
        }
    }, [formState.selectedArea]);

    useEffect(() => {
        if (mode === AreaMode.edit) {
            selectArea();
        } else if (mode === AreaMode.new) {
            dispatch({ type: AreaEditorFormTypesEnum.CREATE_NEW_AREA });
        }

        return () => {
            dispatch({ type: AreaEditorFormTypesEnum.RESET_SELECTED_AREA });
        };
    }, [isLoading, isFetching]);

    useEffect(() => {
        if (mode === AreaMode.edit) {
            ReloadData();
        }
    }, [reload]);

    return id && isLoading ? (
        <UiLoadingPage size='30px' />
    ) : (
        <>
            <Fade in={(!!id && isLoading) || (!!id && dataIsSaving)}>
                <AreaEditorFormLoader>
                    <CustomSpinner />
                </AreaEditorFormLoader>
            </Fade>
            <AreaEditorFormContent
                data={dataResponse ? prepareDataBeforeLoad(dataResponse) : undefined}
                onChangeAreaColor={onChangeAreaColor}
                onChangeCounterZone={onChangeCounterZone}
                onChangeAreaName={onChangeAreaName}
                onChangeMaintananceZone={onChangeMaintananceZone}
                onChangeMaxSpeed={onChangeMaxSpeed}
                onChangeTempSpeedZone={onChangeTempSpeedZone}
                onChangeMinSpeed={onChangeMinSpeed}
                onChangeSpeedZone={onChangeSpeedZone}
                onChangeTempSpeedLvl1={onChangeTempSpeedLvl1}
                onChangeTempSpeedLvl2={onChangeTempSpeedLvl2}
                onChangeTempSpeedLvl3={onChangeTempSpeedLvl3}
                onClickCancel={onClickCancel}
                onClickCreate={onClickCreate}
                onChangeAreaType={onChangeAreaType}
                onClickSave={onClickSave}
                onChangeSelectorOption={onChangeSelectorOption}
                isLoading={isLoading}
                showWarningColorMessage={warningColorMessage}
                resetColorByAreaType={resetColorByAreaType}
                isSaving={dataIsSaving}
                data-testid='AreaEditorForm-testid'
            />
        </>
    );
};

export default AreaEditorForm;
