import React, { useCallback, useMemo } from 'react';
import {
    AlertBtnSetting,
    AlertContent,
    AlertCustomContainer,
    AlertFooter,
    ChipLevel,
    DenseCover,
    DenseInfoCover
} from './AlertCustom.style';
import { AlertCustomProps } from './AlertCustom.type';
import Grid from '@mui/material/Grid';
import SettingsIcon from '@mui/icons-material/Settings';
import Menu from 'components/Ui/Components/UiMenu';
import { useTranslation } from 'react-i18next';
import useConverter from 'components/CustomHooks/Converter/Converter';
import useModal from 'components/CustomHooks/ModalHook';
import PredictedGraph from 'components/Mixs/PredictedGraph/PredictedGraph';
import { Tooltip } from '@mui/material';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { WHITE } from 'components/Ui/colors';
import { faChartMixedUpCircleCurrency } from '@fortawesome/pro-solid-svg-icons';

export const AlertCustomContent: React.FC<AlertCustomProps> = (props): JSX.Element => {
    const { fromUTCtoUserTimezone } = useConverter();
    const { t: translate } = useTranslation();
    const { setModalState } = useModal();
    const showFooter = useCallback((tooltip = false) => {
        return fromUTCtoUserTimezone({
            date: props.footer,
            format: tooltip ? 'time' : 'dateTime',
            displaySeconds: tooltip
        });
    }, []);

    const gridSize = useMemo(() => {
        if (!props.isTempPredAlert) {
            if (props.sizeAlert !== 'sm') {
                return {
                    chip: {
                        left: { xl: 12, lg: 12 },
                        right: { xl: 0, lg: 0 }
                    },
                    grid: {
                        left: { xl: 4, lg: 4 },
                        right: { xl: 8, lg: 8 }
                    }
                };
            }
            return {
                chip: {
                    left: { xl: 12, lg: 12 },
                    right: { xl: 0, lg: 0 }
                },
                grid: {
                    left: { xl: 4, lg: 4 },
                    right: { xl: 8, lg: 8 }
                }
            };
        }
        if (props.sizeAlert !== 'sm') {
            return {
                chip: {
                    left: { xl: 4, lg: 12 },
                    right: { xl: 8, lg: 12 }
                },
                grid: {
                    left: { xl: 7, lg: 5 },
                    right: { xl: 5, lg: 7 }
                }
            };
        }
        return {
            chip: {
                left: { xl: 6, lg: 6 },
                right: { xl: 6, lg: 6 }
            },
            grid: {
                left: { xl: 7, lg: 7 },
                right: { xl: 5, lg: 5 }
            }
        };
    }, [props.isTempPredAlert]);

    const alertLevel = useMemo(() => {
        if (props.isTempPredAlert) {
            if (props.level === 1) return 2;
            return 3;
        }

        return props.level;
    }, [props.isTempPredAlert, props.level]);

    return (
        <AlertCustomContainer
            $displayBody={props.displayBody}
            $disableRedirect={props.disableRedirect}
            data-testid={props.testId ? `AlertCustomView-${props.testId}` : 'AlertCustomView-testid'}
        >
            <AlertContent
                key={props.keyProps}
                level={alertLevel}
                onClick={() => {
                    if (!props.disableRedirect) location.href = props.link;
                }}
                $float={props.float}
                dense={props.dense}
            >
                {props.dense ? (
                    <Grid container xs md lg>
                        <Grid xs={12} md={12} lg={12}>
                            {props.chip ? (
                                <>
                                    <DenseCover item xs={12} md={12} lg={12}>
                                        <DenseInfoCover>{props.content && props.content}</DenseInfoCover>
                                    </DenseCover>
                                </>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            {props.actions ? (
                                <AlertBtnSetting>
                                    <Menu
                                        items={props.actions}
                                        rootEl={
                                            <button
                                                data-testid={`Alert-button-action-${props.keyProps.replace(
                                                    /[_\s]+/g,
                                                    '-'
                                                )}`}
                                                aria-controls='simple-menu'
                                                aria-haspopup='true'
                                            >
                                                <SettingsIcon titleAccess={translate('t.actions')} />
                                            </button>
                                        }
                                    />
                                </AlertBtnSetting>
                            ) : (
                                ''
                            )}
                            {props.action ? <AlertBtnSetting>{props.action}</AlertBtnSetting> : ''}

                            {props.content && props.content}
                        </Grid>

                        <Grid item xl={gridSize.grid.left.xl} lg={gridSize.grid.left.lg}>
                            {props.chip && (
                                <Grid container xs={12} md={12}>
                                    <Grid item xl={gridSize.chip.left.xl} lg={gridSize.chip.left.lg} sx={{ mb: 1 }}>
                                        <ChipLevel size='small' level={alertLevel} label={`Level ${props.level}`} />
                                    </Grid>
                                    {props.dataForPredictionGraph && (
                                        <Grid item xl={gridSize.chip.right.lg} lg={gridSize.chip.right.lg}>
                                            <Tooltip
                                                hidden={Boolean(props.sizeAlert !== 'sm')}
                                                title={translate('t.predicted_temperature_graph')}
                                                placement='bottom'
                                                arrow
                                            >
                                                <ChipLevel
                                                    size='small'
                                                    level={alertLevel}
                                                    label={
                                                        props.sizeAlert === 'sm' ? (
                                                            <UiIcon
                                                                icon={faChartMixedUpCircleCurrency}
                                                                size='1x'
                                                                transform='left-4'
                                                                fixedWidth
                                                                color={WHITE}
                                                            />
                                                        ) : (
                                                            translate('t.predicted_temperature')
                                                        )
                                                    }
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setModalState({
                                                            isOpen: true,
                                                            leftTitle: `${translate('t.remaining_hours')} - ${
                                                                props.dataForPredictionGraph?.vehicleName
                                                            }`,
                                                            height: 72,
                                                            width: 90,
                                                            widthUnit: '%',
                                                            heightUnit: '%',
                                                            content: (
                                                                <PredictedGraph
                                                                    vehicleName={
                                                                        props.dataForPredictionGraph?.vehicleName || ''
                                                                    }
                                                                    vehicleId={
                                                                        props.dataForPredictionGraph?.vehicleId || 0
                                                                    }
                                                                    wheelPredictionId={
                                                                        props.dataForPredictionGraph
                                                                            ?.wheelPredictedId || 0
                                                                    }
                                                                    alertLevel={props.level}
                                                                />
                                                            )
                                                        });
                                                    }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </Grid>

                        <Grid item xl={gridSize.grid.right.xl} lg={gridSize.grid.right.lg}>
                            {props.footer && (
                                <Tooltip
                                    title={`${translate('t.detected_on')} : ${showFooter(true)}`}
                                    disableInteractive
                                >
                                    <AlertFooter>{showFooter()}</AlertFooter>
                                </Tooltip>
                            )}
                        </Grid>
                    </Grid>
                )}
            </AlertContent>
        </AlertCustomContainer>
    );
};
